import React from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import classes from '../CSS/Checkout.module.css';

const CheckoutQuestions = () => {
  return (
    <Paper elevation={4} sx={{ maxWidth: '120rem', p: '2rem' }}>
      <Typography variant='h2' component='h2' sx={{ pb: '30px' }}>
        Frequently Asked Questions
      </Typography>

      <Box className={classes['content-holder']}>
        <List>
          <ListItem>
            <ListItemIcon>
              <HelpIcon color='primary' />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='label'>Can I edit my order after check out?</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography>
                Yes, you will have the option to modify your order before final payment. You can
                locate your order under PLACED orders and you will be able to message the vendor
                your order modification requests
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <HelpIcon color='primary' />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='label'>Can I cancel my order?</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography>
                Prior to final payment you can cancel your order by clicking the red icon under
                ACTIONS in your PLACED orders tab.
              </Typography>
              <Typography>
                Most Fueling Partners will allow you to cancel your order if done within a certain
                time period. Please contact your Fueling Partner directly for their specific
                requirements.
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <HelpIcon color='primary' />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='label'>How do I track my order?</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography>
                After payment you will find your order under the SCHEDULED tab. You will be able to
                open your order and message the vendor to see the progress of your order.
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <HelpIcon color='primary' />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='label'>Will I get a confirmation</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography>
                You will receive an email or verification when you order is PLACED, and then when
                payment has been accepted and the order is SCHEDULED. We recommend that you stay in
                touch with your Fueling Partner directly on game day regarding the status of your
                delivery.
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <HelpIcon color='primary' />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='label'>Do I get a receipt?</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography>
                Yes, you will be able to download and print a copy of your order in the COMPLETED
                tab under ORDERS
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Box>

      {/* <div className={classes['content-grid']}>
        <div className={classes['content-item']}>
          <Typography variant='h7' component='h6'>
            Is this really free?
          </Typography>
          <Typography variant='body1' component='p'>
            Yes, with an Elite Eats Membership placing orders using our Service is free.
          </Typography>
        </div>
        <div className={classes['content-item']}>
          <Typography variant='h7' component='h6'>
            Can I edit my order after checkout?
          </Typography>
          <Typography variant='body1' component='p'>
            Yes. Just click Edit on your Scheduled orders tab. Or call us and get a real live person
            ready to help. No 'Press 1' nonsense. There are no modification fees.
          </Typography>
        </div>
        <div className={classes['content-item']}>
          <Typography variant='h7' component='h6'>
            Can I cancel my order?
          </Typography>
          <Typography variant='body1' component='p'>
            Yes. Just click Cancel on your Orders tab, or contact us. There is no cancellation fee,
            but the caterer may have to charge for food already prepared.
          </Typography>
        </div>

        <div className={classes['content-item']}>
          <Typography variant='h7' component='h6'>
            How do I track my order?
          </Typography>
          <Typography variant='body1' component='p'>
            After checkout, go to your Scheduled orders tab, and click on your order. It's like the
            FedEx of catering. You can follow your order through every step.
          </Typography>
        </div>
        <div className={classes['content-item']}>
          <Typography variant='h7' component='h6'>
            Will I get a confirmation?
          </Typography>
          <Typography variant='body1' component='p'>
            Yes. We will immediately email you an order confirmation. We will also reconfirm your
            order on the day-of, and send you a delivery update by text message or email (your
            choice).
          </Typography>
        </div>
        <div className={classes['content-item']}>
          <Typography variant='h7' component='h6'>
            Do I get a receipt?
          </Typography>
          <Typography variant='body1' component='p'>
            Yes. Elite Eats will email you a receipt on the day of delivery. You can also download
            PDF receipts and expense reports from your Receipts tab.
          </Typography>
        </div>
      </div> */}
    </Paper>
  );
};

export default CheckoutQuestions;
