import React, { useState, useEffect, useContext, useRef } from 'react';
import GlobalFoodTypeForm from './GlobalFoodTypeForm';

import AuthContext from '../../store/auth-context';
import * as Constants from '../../helpers/Constants';
import { displayListFilterSelect } from '../../helpers/FilterListHelperFunctions';
import classes from '../CSS/styled-table.module.css';

import Popup from '../MUI/UI/Popup';
import Notification from '../MUI/UI/Notification';

let popupTitle = 'Add Global Food Type';

const GlobalFoodTypes = () => {
  const authCtx = useContext(AuthContext);

  const [uuid, setUuid] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [updateList, setUpdateList] = useState('Initial');
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const [foodTypes, setFoodTypes] = useState([]);
  const [displayedFoodTypes, setDisplayedFoodTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const statusFilterRef = useRef();

  // console.log(' - Render - ');

  useEffect(() => {
    // console.log(' - useEffect - ');
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'globalfoodtypes', {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(responseData);

        const transformedRecords = responseData.data.global_food_types.map((record) => {
          return {
            uuid: record.globalFoodTypeUuid,
            name: record.name,
            description: record.description,
            sortOrder: record.sortOrder,
            status: record.status,
          };
        });

        setFoodTypes(transformedRecords);
        setDisplayedFoodTypes(transformedRecords);

        // const filteredRecords = transformedRecords.filter((record) => record.status === 'Active');
        // setDisplayedFoodTypes(filteredRecords);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, updateList]);

  const displayTableHeader = () => {
    return (
      <tr>
        <th>Name</th>
        <th>Description</th>
        <th>Sort Order</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
    );
  };

  const displayTableBody = () => {
    return displayedFoodTypes.map((record, index) => (
      <tr key={index}>
        <td>{record.name}</td>
        <td>{record.description}</td>
        <td>{record.sortOrder}</td>
        <td>{record.status}</td>
        <td>
          <div className={classes['table-actions-container']}>
            <button onClick={() => onEditRecordHandler(`${record.uuid}`)} className='small'>
              Edit
            </button>
            {/* {record.status !== 'Deleted' && (
              <button data-uuid={record.uuid} onClick={deleteRecordHandler} className='small'>
                Delete
              </button>
            )} */}
          </div>
        </td>
      </tr>
    ));
  };

  const clearFilterHandler = () => {
    statusFilterRef.current.value = 'Show All'; // 'Active';

    filterDisplayedRecords();
  };

  const filterDisplayedRecords = () => {
    let statusFilter = statusFilterRef.current.value;

    // console.log(' -- filterDisplayedRecords -- ');
    // console.log({ statusFilter: statusFilter });

    if (statusFilter === 'Show All') {
      setDisplayedFoodTypes(foodTypes);
    } else {
      let filteredRecords = foodTypes;

      if (statusFilter !== 'Show All') {
        filteredRecords = filteredRecords.filter((record) => record.status === statusFilter);
      }

      setDisplayedFoodTypes(filteredRecords);
    }
  };

  const addNewHandler = () => {
    popupTitle = 'Add Global Food Type';
    setUuid('');
    setOpenPopup(true);
  };

  const onEditRecordHandler = (uuid) => {
    popupTitle = 'Edit Global Food Type';
    setUuid(uuid);
    setOpenPopup(true);
  };

  const handleUpdateList = (value) => {
    setUpdateList(value);
  };

  return (
    <>
      <div>
        {!isLoading && !isError && (
          <div className='button-holder'>
            <label>Filter By: </label>
            <label htmlFor='filter-by-status'>Status</label>
            {displayListFilterSelect(
              'filter-by-status',
              statusFilterRef,
              filterDisplayedRecords,
              Constants.STATUS_ARRAY,
              'Show All'
            )}
            <button className='small' onClick={clearFilterHandler}>
              Clear Filters
            </button>
            <button className='small-button-variant' onClick={addNewHandler}>
              Add Food Type
            </button>
          </div>
        )}
        {isLoading && (
          <div className='message-holder'>
            <p className='loading'>Loading...</p>
          </div>
        )}
        {isError && (
          <div className='message-holder'>
            <p className='error'>Error...</p>
          </div>
        )}
        {!isLoading && foodTypes.length === 0 && (
          <div className='message-holder'>
            <p className='error'>No Locations Found...</p>
          </div>
        )}
        {!isLoading && foodTypes.length > 0 && (
          <div className='table-holder'>
            <table className={`${classes['styled-table']} ${classes['full-width']}`}>
              <thead>{displayTableHeader()}</thead>
              <tbody>{displayTableBody()}</tbody>
            </table>
          </div>
        )}
      </div>

      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} maxWidth='sm' title={popupTitle}>
        <GlobalFoodTypeForm
          uuid={uuid}
          setOpenPopup={setOpenPopup}
          setNotify={setNotify}
          updateList={handleUpdateList}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default GlobalFoodTypes;
