import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import OrderContext from '../../store/order-context';
import MenuItemForm from './MenuItemForm';
import classes from '../CSS/MenuItemDetails.module.css';

const MenuItemDetails = (props) => {
  // console.log('-MenuItemDetails-');
  // console.log(props);
  const authCtx = useContext(AuthContext);
  const orderCtx = useContext(OrderContext);

  const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  const { selectedMenuItem, setNotify, setOpenPopup } = props;

  const orderItemUuid = selectedMenuItem.orderItemUuid ? selectedMenuItem.orderItemUuid : '';
  // const menuItemUuid = selectedMenuItem.menuItemUuid ? selectedMenuItem.menuItemUuid : '';

  const saveOrderItemData = (menuItemUuid, quantity, specialInstructions) => {
    let apiURL = '';
    let apiMethod = '';

    if (orderItemUuid !== '') {
      apiURL = authCtx.baseApiUrl + 'orderitems/' + orderItemUuid;
      apiMethod = 'PATCH';
    } else {
      apiURL = authCtx.baseApiUrl + 'orderitems';
      apiMethod = 'POST';
    }

    // apiURL = authCtx.baseApiUrl + 'orderitems';
    // apiMethod = 'POST';

    // console.log('saveOrderItemData');
    // console.log('apiURL = ' + apiURL + ' -- apiMethod = ' + apiMethod + ' - ');
    // console.log(' == orderCtx.orderDetails.orderUuid = ' + orderCtx.orderDetails.orderUuid + ' ');
    // console.log(' == orderItemUuid = ' + orderItemUuid + ' ');
    // console.log(' == menuItemUuid = ' + menuItemUuid + ' ');
    // console.log(' == quantity = ' + quantity + ' ');
    // console.log(' == specialInstructions = ' + specialInstructions + ' ');

    // apiURL = authCtx.baseApiUrl + 'orderitems';
    // apiMethod = 'POST';

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        order_uuid: orderCtx.orderDetails.orderUuid,
        order_item_uuid: orderItemUuid,
        menu_item_uuid: menuItemUuid,
        quantity: quantity,
        special_instructions: specialInstructions,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      // setIsLoading(false);

      if (res.ok) {
        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          const orderItem = responseData.data.order_items[0];
          // const orderItemUuid = responseData.data.order_items[0].orderItemUuid;

          // console.log(' **** orderItemUuid = ' + orderItemUuid + ' **** ');

          // console.log('orderItem');
          // console.table(orderItem);

          if (apiMethod === 'POST') {
            orderCtx.addOrderItem(orderItem);
          } else {
            orderCtx.updateOrderItem(orderItem);
          }

          setOpenPopup(false);
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          console.log(' ** ERROR ** ');
          console.log(responseData);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            // setDisplayMessage(responseData.messages);
          } else {
            // setDisplayMessage(['Failed to save Order Data.']);
          }
        });
      }
    });
  };

  const addToCartHandler = (orderItemUuid, quantity, specialInstructions) => {
    // Save order_items data to the DB.
    // console.log(
    //   'addToCartHandler - orderItemUuid = ' +
    //     orderItemUuid +
    //     ' - quantity = ' +
    //     quantity +
    //     ' - specialInstructions = ' +
    //     specialInstructions
    // );
    saveOrderItemData(orderItemUuid, quantity, specialInstructions);
  };

  const updateItemInCartHandler = (orderItemUuid, quantity, specialInstructions) => {
    // console.log(
    //   'updateItemInCartHandler - orderItemUuid = ' +
    //     orderItemUuid +
    //     ' - quantity = ' +
    //     quantity +
    //     ' - specialInstructions = ' +
    //     specialInstructions
    // );
    saveOrderItemData(orderItemUuid, quantity, specialInstructions);
  };

  const deleteItemFromCartHandler = (orderItemUuid) => {
    // console.log('deleteItemFromCartHandler');
    // console.log('orderItemUuid = ' + orderItemUuid);

    const deleteData = async () => {
      const response = await fetch(authCtx.baseApiUrl + 'orderitems/' + orderItemUuid, {
        method: 'DELETE',
        headers: {
          Authorization: authCtx.token,
        },
      });

      if (response.ok) {
        // const responseData = await response.json();
        // console.log(responseData);
        // Todo:  Show a notification.
      } else {
        // setIsError(true);
        console.log(' ******** ERROR - deleting an Order Item - ERROR ******** ');
      }
      // setIsLoading(false);
    };

    deleteData().catch(console.error);

    orderCtx.deleteOrderItem(orderItemUuid);
    setOpenPopup(false);
  };

  const formattedPrice = selectedMenuItem.priceFormatted
    ? selectedMenuItem.priceFormatted
    : selectedMenuItem.price
    ? priceFormatter.format(selectedMenuItem.price)
    : '$0.00';

  return (
    <div className={classes['menu-item-details']}>
      {/* <Typography variant='h7' component='h5'>
        {selectedMenuItem.name}
      </Typography> */}
      <Typography className={classes['price']} sx={{ display: 'inline-block' }}>
        {formattedPrice}
      </Typography>
      {selectedMenuItem.priceDetails ? (
        <Typography variant='body1' component='p' sx={{ display: 'inline-block', pl: '2rem' }}>
          {selectedMenuItem.priceDetails}
        </Typography>
      ) : null}
      {selectedMenuItem.dietaryOptions ? (
        <Typography variant='body1' component='p' align='right' sx={{ pb: '1rem' }}>
          {selectedMenuItem.dietaryOptions}
        </Typography>
      ) : null}

      {/* <Typography className={classes['price']}>
        {priceFormatter.format(selectedMenuItem.price)}
      </Typography> */}

      {selectedMenuItem.description ? (
        <Box>
          <Typography variant='labelAbove'>Description</Typography>
          <Typography variant='bodyContent' component='p'>
            {selectedMenuItem.description}
          </Typography>
        </Box>
      ) : null}
      {selectedMenuItem.details ? (
        <Box sx={{ pt: '1rem' }}>
          <Typography variant='labelAbove'>Details</Typography>
          <Typography variant='bodyContent' component='p'>
            {selectedMenuItem.details}
          </Typography>
        </Box>
      ) : null}

      <div className={classes['form-holder']}>
        <MenuItemForm
          orderItemUuid={orderItemUuid}
          selectedMenuItem={selectedMenuItem}
          setNotify={setNotify}
          onAddToCart={addToCartHandler}
          onUpdateCart={updateItemInCartHandler}
          onDeleteItem={deleteItemFromCartHandler}
        />
      </div>
    </div>
  );
};

export default MenuItemDetails;
