import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box } from '@mui/material';

import classes from '../CSS/TopMenu.module.css';

const pathStart = '/team-dashboard/';

const TeamMenu = () => {
  return (
    <Box component='div' className={classes['top-menu-container']}>
      <nav className={classes['top-nav']}>
        <ul>
          <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}new-order`}>
              Start New Order
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}orders`}>
              Orders
            </NavLink>
          </li>

          <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}expense-report`}>
              Expense Reports
            </NavLink>
          </li>

          {/* <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}receipts`}>
              Receipts
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}Reviews`}>
              Reviews
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}delivery-locations`}>
              Delivery Locations
            </NavLink>
          </li> */}
          <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}refer-vendor`}>
              Refer A Fueling Partner
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}restaurants`}>
              Restaurants
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}team-profile`}>
              Team Profile
            </NavLink>
          </li>
        </ul>
      </nav>
    </Box>
  );
};

export default TeamMenu;
