import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, List, Typography } from '@mui/material';

import OrderContext from '../../store/order-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import FileAttachmentForm from '../FileAttachments/FileAttachmentForm';
import FileAttachmentList from '../FileAttachments/FileAttachmentList';
import ListItemWithIcon from '../UI/ListItemWithIcon';
import classes from '../CSS/Checkout.module.css';

const FileAttachments = () => {
  const orderCtx = useContext(OrderContext);

  const orderDetails = orderCtx.orderDetails;

  const history = useHistory();

  const timestamp = new Date().getTime();
  const [refreshData, setRefreshData] = useState(timestamp);

  const [selectedRecord, setSelectedRecord] = useState(null);

  const continueOrderHandler = () => {
    // console.log('continueOrderHandler');
    history.replace('/checkout/special-requests');
  };

  const refreshDataHandler = () => {
    // console.log('##### refreshDataHandler #####');
    const timestamp = new Date().getTime();
    // console.log('##### ' + timestamp + ' #####');
    setRefreshData(timestamp);
  };

  const editFileUploadHandler = (record) => {
    // console.log('### FileAttachments - editFileUploadHandler ###');
    // console.log('### record ###');
    // console.log(record);
    setSelectedRecord(record);

    // To make sure that the form reloads.
    refreshDataHandler();

    window.scrollTo(0, 0);
  };

  const clearFormHandler = () => {
    // console.log('### clearFormHandler ###');
    setSelectedRecord(null);
  };

  return (
    <StandardPageWrapper usePaper='true'>
      <Box className={classes['content-holder']}>
        <Typography variant='h1' component='h1' className='header'>
          File Attachments
        </Typography>

        <List>
          <ListItemWithIcon textContent='You can upload and attach files that contain order details, requirements or any other important information.' />
        </List>

        <Box className='mb-20'>
          <FileAttachmentForm
            orderUuid={orderDetails.orderUuid}
            fileUpload={selectedRecord}
            onRefreshData={refreshDataHandler}
            onClearForm={clearFormHandler}
            key={refreshData}
          />
        </Box>

        <FileAttachmentList
          orderUuid={orderDetails.orderUuid}
          allowEdit={true}
          onEdit={editFileUploadHandler}
          key={refreshData}
        />
      </Box>

      <Box sx={{ pt: '20px' }}>
        <Button variant='contained' size='custom' color='accent' onClick={continueOrderHandler}>
          Continue Checkout
        </Button>
      </Box>
    </StandardPageWrapper>
  );
};

export default FileAttachments;
