import React, { useState } from 'react';

import StandardPageWrapper from '../Layout/StandardPageWrapper';
import UserDetails from '../Admin/UserDetails';
import UserForm from '../Admin/UserForm';
import UserList from '../Admin/UserList';

const Users = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showList, setShowList] = useState(true);
  const [userUuid, setUserUuid] = useState('');
  const [pageTitle, setPageTitle] = useState('Users');

  const showDetailsHandler = (userUuid) => {
    setUserUuid(userUuid);
    setShowDetails(true);
    setShowForm(false);
    setShowList(false);
    setPageTitle('User Details');
  };

  const addUserHandler = () => {
    setUserUuid('');
    setShowDetails(false);
    setShowForm(true);
    setShowList(false);
    setPageTitle('Create New User');
  };

  const editUserHandler = (userUuid) => {
    setUserUuid(userUuid);
    setShowDetails(false);
    setShowForm(true);
    setShowList(false);
    setPageTitle('Edit User Details');
  };

  const showListHandler = () => {
    setShowDetails(false);
    setShowForm(false);
    setShowList(true);
    setPageTitle('Users');
  };

  return (
    <StandardPageWrapper>
      {showDetails ? (
        <UserDetails
          onAddNew={addUserHandler}
          onEdit={editUserHandler}
          onShowList={showListHandler}
          userUuid={userUuid}
          pageTitle={pageTitle}
        />
      ) : null}
      {showForm ? (
        <UserForm onCancel={showListHandler} userUuid={userUuid} pageTitle={pageTitle} />
      ) : null}
      {showList ? (
        <UserList
          onAddNew={addUserHandler}
          onEdit={editUserHandler}
          onShowDetails={showDetailsHandler}
          pageTitle={pageTitle}
        />
      ) : null}
    </StandardPageWrapper>
  );
};

export default Users;
