import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    // flexDirection: 'row',
    backgroundColor: '#e4e4e4',
  },
  sideBySideSection: {
    flexDirection: 'row',
    gap: '20px',
  },
  section: {
    margin: 10,
    padding: 10,
    // flexGrow: 1,
  },
});

const PdfDeliveryDetails = () => {
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text>Delivery Date</Text>
          <Text>Friday, June 23, 2023 at 4:30:00 PM</Text>
        </View>

        <View style={styles.section}>
          <View style={styles.sideBySideSection}>
            <View>
              <Text>Address</Text>
              <Text>Denver Broncos</Text>
              <Text>Empower Field at Mile High</Text>
              <Text>1701 Bryant Street</Text>
              <Text>Denver, CO, 80204</Text>
            </View>

            <View>
              <Text>Contact</Text>
              <Text>Frank Moses</Text>
              <Text>Food Guy</Text>
              <Text>555-555-1234</Text>
              <Text>jeffsacummings+fm@hotmail.com</Text>
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <Text>Delivery Instructions</Text>
          <Text>Pull into Parking Lot A and stop next to Gate 4.</Text>
          <Text>
            Ring the Buzzer and someone will let you in and guide you to where you need to go.
          </Text>
        </View>

        <View style={styles.section}>
          <Text>Team Instructions</Text>
          <Text>
            We look forward to you catering our game. Delivery to the stadium as stated above is
            about half time of the game. This will give your team time to set up and be ready to go
            for post-game. Your game day contact above will meet you once you have cleared security.
            Service must be speedy and efficient; our goal is to have the players spend little to no
            time waiting. Service will go from 10 minutes before the game ends until 2 hours
            following the game or until you are released by the team. Just a reminder, that when
            your staff is serving the team to please act professionally – no photos, no autographs,
            chatting up the players, etc. We also ask that your staff wear appropriate attire (no
            opposing team apparel including hats). Delivery to the stadium logistics is communicated
            by our team. Vendors serving our team will have security clearance through us.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default PdfDeliveryDetails;
