import React, { useContext, useState, useRef } from 'react';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
// import WorkflowMessage from '../UI/WorkflowMessage';
import FormControls from '../MUI/FormControls/FormControls';
import classes from '../CSS/standard-mui-form.module.css';

const FileUploadComplex = ({ orderUuid = '666-order-uuid-666', fileUpload = null }) => {
  const authCtx = useContext(AuthContext);

  // const [isLoading, setIsLoading] = useState(false);
  // const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFilename, setSelectedFileName] = useState('');

  const selectedFilenameRef = useRef();
  const nameRef = useRef();
  const descriptionRef = useRef();

  const [imageLink, setImageLink] = useState(null);
  const [fileLink, setFileLink] = useState(null);
  const [validationError, setValidationError] = useState(null);

  const fileInputRef = useRef(null);

  // const fileUploadUuid = fileUpload ? fileUpload.fileUploadUuid : '';
  const filename = fileUpload ? fileUpload.name : '';
  const description = fileUpload ? fileUpload.description : '';

  // The functions below are the original ones and they do work.
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileExtensionsAllowed = 'doc, docx, jpg, jpeg, pdf, png, txt, xls, xlsx';
      const arrayFileExtensionsAllowed = [
        '.doc',
        '.docx',
        '.jpg',
        '.jpeg',
        '.pdf',
        '.png',
        '.txt',
        '.xls',
        '.xlsx',
      ];
      const selectedFileExtension = file.name.split('.').pop().toLowerCase();

      if (arrayFileExtensionsAllowed.includes('.' + selectedFileExtension)) {
        setSelectedFile(file);
        setValidationError(null);

        const filename = file.name;

        setSelectedFileName(filename);

        selectedFilenameRef.current.value = filename;
      } else {
        setSelectedFile(null);
        setValidationError(
          'Invalid file extension.  Please select a file with one of the following extensions: ' +
            fileExtensionsAllowed
        );

        fileInputRef.current.value = '';
      }
    } else {
    }
  };

  const submitHandler = async () => {
    setFileLink(null);
    setImageLink(null);

    if (selectedFile) {
      const apiURL = authCtx.baseApiUrl + 'testingfileupload';
      const apiMethod = 'POST';

      // const orderUuid = '666-order-uuid-666';  // passed in as prop
      const enteredName = nameRef.current.value;
      const enteredDescription = descriptionRef.current.value; // 'This is just a Test Description';

      console.log('### enteredName = ' + enteredName + ' ###');

      const jsonObj = { order_uuid: orderUuid, name: enteredName, description: enteredDescription };

      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('json', JSON.stringify(jsonObj));

      // This doesn't work.
      // formData.append('name', enteredName);

      const response = await fetch(apiURL, {
        method: apiMethod,
        headers: {
          Authorization: authCtx.signupToken,
          // 'Content-Type': 'application/json',
        },
        body: formData,
        // body: JSON.stringify(formData),
        // body: { file: selectedFile },
        // body: JSON.stringify({
        //   name: enteredName,
        //   file: selectedFile,
        // }),
        // body: {
        //   name: enteredName,
        //   file: selectedFile,
        // },
      });

      // Notes:
      // using JSON.stringify means we don't get the $_FILES

      const responseData = await response.json();

      console.log('##### responseData #####');
      console.log(responseData);

      setImageLink(
        responseData.data && responseData.data.imageLink ? responseData.data.imageLink : null
      );
      setFileLink(
        responseData.data && responseData.data.fileLink ? responseData.data.fileLink : null
      );

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } else {
      setValidationError('Please select a file before uploading.');
    }
  };

  const openFileInNewTab = (pathAndFilename) => {
    // console.log('### openFileInNewTab - pathAndFilename = ' + pathAndFilename + ' ###');
    window.open(pathAndFilename, '_blank');
  };

  const handleChooseFile = () => {
    setFileLink(null);
    setImageLink(null);
  };

  const clearFormHandler = () => {
    // console.log('### clearFormHandler ###');
    selectedFilenameRef.current.value = '';
    nameRef.current.value = '';
    descriptionRef.current.value = '';
  };

  return (
    <>
      {/* {isLoading ? <WorkflowMessage type='loading' /> : null} */}
      {/* {isError ? <WorkflowMessage type='error' /> : null} */}

      <Box className='p-20'>
        <h1>File Upload Testing</h1>

        <Box>
          <form>
            <Box className={classes['form-container']}>
              <Box className={classes['side-by-side-form-controls-holder']}>
                <Button variant='contained' component='label' size='small' sx={{ width: '140px' }}>
                  Select File
                  <input
                    hidden
                    accept='.xls,.xlsx,.pdf,.txt,.doc,.docx,.jpg,.jpeg,.png'
                    multiple
                    type='file'
                    name='selected_file'
                    onChange={handleFileChange}
                    onClick={handleChooseFile}
                  />
                </Button>

                <FormControls.InputControl
                  type='text'
                  name='filename'
                  label='Selected Filename'
                  inputRef={selectedFilenameRef}
                  defaultValue={selectedFilename}
                  disabled
                  inputProps={{ maxLength: 200 }}
                  className={classes['mui-control']}
                />
              </Box>

              <FormControls.InputControl
                type='text'
                name='name'
                label='Name'
                inputRef={nameRef}
                defaultValue={filename}
                required
                inputProps={{ maxLength: 200 }}
                className={classes['mui-control']}
              />

              <FormControls.InputControl
                type='text'
                name='description'
                label='Description'
                inputRef={descriptionRef}
                defaultValue={description}
                className={classes['mui-control']}
                multiline
                minRows={3}
                maxRows={12}
              />
            </Box>

            <Box component='div' className={classes['actions-container']}>
              <FormControls.ButtonControl
                text='Save File Attachment'
                type='button'
                color='primary'
                size='small'
                id='submit-button'
                onClick={submitHandler}
              />

              <FormControls.ButtonControl
                text='Clear'
                onClick={clearFormHandler}
                type='button'
                color='neutral200'
                size='small'
              />
            </Box>
          </form>
        </Box>

        {validationError ? (
          <Box className='pt-20'>
            <Typography color='error'>{validationError}</Typography>
          </Box>
        ) : null}

        {imageLink ? (
          <Box className='pt-20'>
            <Typography>Uploaded Image</Typography>
            <img src={imageLink} alt='Uploaded' />
          </Box>
        ) : null}

        {fileLink ? (
          <Box className='pt-20'>
            <Typography>Uploaded File</Typography>
            <button
              onClick={() => {
                openFileInNewTab(fileLink);
              }}
            >
              Open/Download File
            </button>
          </Box>
        ) : null}

        <Notification notify={notify} setNotify={setNotify} />
      </Box>
    </>
  );
};

export default FileUploadComplex;
