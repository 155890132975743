import React, { useContext } from 'react';

import AuthContext from '../../store/auth-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import Team from '../Team/Team';

const TeamProfile = () => {
  const authCtx = useContext(AuthContext);
  const teamUuid = authCtx.orgUuid;

  return (
    <StandardPageWrapper>
      <Team teamUuid={teamUuid} />
    </StandardPageWrapper>
  );
};

export default TeamProfile;
