import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import WorkflowMessage from '../UI/WorkflowMessage';
import TeamDetailsCard from '../Team/TeamDetailsCard';
import TeamApprovalForm from './TeamApprovalForm';
import { useFormatDateAndTime } from '../Hooks/useFormatDateAndTime';

const AdminTeamApproval = () => {
  const authCtx = useContext(AuthContext);

  // const userRole = authCtx.userRole;
  const dashboardUrl = authCtx.dashboardUrl;

  const [teamDetails, setTeamDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const history = useHistory();
  const params = useParams();
  const teamUuid = params.teamUuid;

  const { FormatDateAndTimeForDisplay } = useFormatDateAndTime();

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const response = await fetch(authCtx.baseApiUrl + 'teams/' + teamUuid, {
        headers: {
          Authorization: authCtx.token,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(responseData);

        const team = responseData.data.team;

        const returnedTeamDetails = {
          teamUuid: team.teamUuid,
          name: team.name,
          description: team.description,
          league: team.league,
          logoImage: team.logoImage,
          teamInstructions: team.teamInstructions,
          status: team.status,
          dateAdded: team.dateAdded,
          dateUpdated: team.dateUpdated,
        };
        // console.log(' -- returnedTeamDetails -- ');
        // console.log(returnedTeamDetails);

        if (returnedTeamDetails.dateAdded !== '') {
          returnedTeamDetails.dateAdded = FormatDateAndTimeForDisplay(
            returnedTeamDetails.dateAdded
          );
        }
        if (returnedTeamDetails.dateUpdated !== '') {
          returnedTeamDetails.dateUpdated = FormatDateAndTimeForDisplay(
            returnedTeamDetails.dateUpdated
          );
        }

        setTeamDetails(returnedTeamDetails);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, teamUuid, FormatDateAndTimeForDisplay]);

  const backHandler = () => {
    history.push(dashboardUrl + 'dashboard');
  };

  const baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath + teamUuid + '/';
  // console.log('baseApiUploadedImagesPath = ' + baseApiUploadedImagesPath);

  return (
    <StandardPageWrapper>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      <Box className='title-and-button-holder'>
        <Box>
          <Typography variant='h1' component='h1'>
            Team Approval
          </Typography>
        </Box>
        <Box className='button-holder-no-pb'>
          <Button variant='contained' status='small' color='accent' onClick={backHandler}>
            Back
          </Button>
        </Box>
      </Box>

      {!isLoading && !isError ? (
        <Box>
          <TeamDetailsCard
            teamDetails={teamDetails}
            baseApiUploadedImagesPath={baseApiUploadedImagesPath}
          />

          <TeamApprovalForm teamUuid={teamUuid} />
        </Box>
      ) : null}
    </StandardPageWrapper>
  );
};

export default AdminTeamApproval;
