import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Paper } from '@mui/material';

import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
import classes from '../CSS/standard-form.module.css';

const PaymentForm = () => {
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const cardholderNameRef = useRef();
  const cardNumberRef = useRef();
  // const expiryMonthRef = useRef();
  // const expiryYearRef = useRef();
  const cvvRef = useRef();

  const history = useHistory();

  const handleExpiryMonthChange = (event) => {
    setExpiryMonth(event.target.value);
  };

  const handleExpiryYearChange = (event) => {
    setExpiryYear(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    setNotify({
      isOpen: true,
      message: 'Sending Request...',
      type: 'info',
    });
    setNotify({
      isOpen: true,
      message: 'Functionality not setup yet.',
      type: 'error',
    });

    history.replace('/checkout/checkout-complete');
  };

  const createExpiryMonthArray = () => {
    const min = 1;
    const max = 12;
    let monthArray = [];

    for (let i = min; i <= max; i++) {
      monthArray.push({ id: i, title: i });
    }

    return monthArray;
  };

  const createExpiryYearsArray = () => {
    const currentYear = new Date().getFullYear();
    const min = currentYear;
    const max = currentYear + 20;
    let monthArray = [];

    for (let i = min; i <= max; i++) {
      monthArray.push({ id: i, title: i });
    }

    return monthArray;
  };

  const monthOptions = createExpiryMonthArray();
  const yearOptions = createExpiryYearsArray();

  return (
    <>
      <Paper elevation={4} sx={{ width: '40rem', p: '2rem', m: '0 auto' }}>
        <form onSubmit={submitHandler}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
            <FormControls.InputControl
              type='text'
              name='cardholder_name'
              label='Cardholder Name'
              inputRef={cardholderNameRef}
              required
              className={classes['mui-control']}
              inputProps={{ maxLength: 50 }}
            />
            <FormControls.InputControl
              type='text'
              name='card_number'
              label='Card Number'
              inputRef={cardNumberRef}
              required
              placeholder='4111-1234-1234-1234'
              className={classes['mui-control']}
              inputProps={{ maxLength: 19 }}
            />
            <FormControls.SelectControl
              name='expiry_month'
              label='Expiration Month'
              value={expiryMonth}
              onChange={handleExpiryMonthChange}
              options={monthOptions}
              initialOptionValue='MM'
              makeRequired={true}
            />
            <FormControls.SelectControl
              name='expiry_year'
              label='Expiration Year'
              value={expiryYear}
              onChange={handleExpiryYearChange}
              options={yearOptions}
              initialOptionValue='YYYY'
              makeRequired={true}
            />
            <FormControls.InputControl
              type='number'
              name='cvv'
              label='CVV'
              inputRef={cvvRef}
              required
              min='1'
              max='999'
              step='1'
              placeholder='123'
              inputProps={{ maxLength: 3 }}
              className={classes['mui-control']}
            />
          </Box>
          <Box component='div' className={classes.actions}>
            <FormControls.ButtonControl
              text='Continue Checkout'
              type='button'
              onClick={submitHandler}
            />
          </Box>
        </form>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default PaymentForm;
