import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import { useFormatDateAndTime } from '../Hooks/useFormatDateAndTime';
import WorkflowMessage from '../UI/WorkflowMessage';
import classes from '../CSS/styled-table.module.css';

const NewRestaurants = () => {
  const authCtx = useContext(AuthContext);
  const dashboardUrl = authCtx.dashboardUrl;

  const history = useHistory();

  const [restaurants, setRestaurants] = useState([]);
  const [displayedRestaurants, setDisplayedRestaurants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const { FormatDateAndTimeForDisplay } = useFormatDateAndTime();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'restaurants/status/new', {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.log(' -- response.ok -- ');
        // console.log(responseData);
        // console.log(responseData.data.restaurants);

        const transformedRestaurants = responseData.data.restaurants.map((restaurant) => {
          return {
            restaurantUuid: restaurant.restaurantUuid,
            name: restaurant.name,
            description: restaurant.description,
            address:
              restaurant.city !== '' &&
              restaurant.city !== null &&
              restaurant.state !== '' &&
              restaurant.state !== null
                ? restaurant.city + ', ' + restaurant.state
                : restaurant.city !== ''
                ? restaurant.city
                : restaurant.status !== ''
                ? restaurant.state
                : '',
            email: restaurant.email,
            phone: restaurant.phone,
            foodType: restaurant.foodType,
            status: restaurant.status,
            dateAddedFormatted: restaurant.dateAdded
              ? FormatDateAndTimeForDisplay(restaurant.dateAdded)
              : '',
          };
        });

        setRestaurants(transformedRestaurants);
        setDisplayedRestaurants(transformedRestaurants);
      } else {
        // If a 401 is returned then there is an issue with the session (most likely).
        if (responseData.statusCode === 401) {
          history.push(dashboardUrl + 'sessionexpired');
        }

        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, dashboardUrl, history, FormatDateAndTimeForDisplay]);

  const displayTableHeader = () => {
    return (
      <tr>
        <th>Date</th>
        <th>Name</th>
        <th>City/State</th>
        {/* <th>Email</th> */}
        {/* <th>Phone</th> */}
        <th>Type of Food</th>
        {/* <th>Status</th> */}
        <th>Actions</th>
      </tr>
    );
  };

  const displayTableBody = () => {
    return displayedRestaurants.map((restaurant, index) => (
      <tr key={index}>
        <td>{restaurant.dateAddedFormatted}</td>
        <td>{restaurant.name}</td>
        <td>{restaurant.address}</td>
        {/* <td>{restaurant.email}</td> */}
        {/* <td>{restaurant.phone}</td> */}
        <td>{restaurant.foodType}</td>
        {/* <td>{restaurant.status}</td> */}
        <td>
          <div className={classes['table-actions-container']}>
            <Link
              to={`${dashboardUrl}restaurant/approval/${restaurant.restaurantUuid}`}
              className='button small'
            >
              Approve
            </Link>
          </div>
        </td>
      </tr>
    ));
  };

  return (
    <>
      <Typography variant='h2' component='h2'>
        New Restaurant Approvals
      </Typography>

      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}
      {!isLoading && !isError && restaurants.length === 0 ? (
        <WorkflowMessage type='nodata' message='No New Restaurant Approvals...' alignText='left' />
      ) : null}

      {!isLoading && !isError && restaurants.length > 0 ? (
        <div className='table-holder'>
          <table className={`${classes['styled-table']} ${classes['full-width']}`}>
            <thead>{displayTableHeader()}</thead>
            <tbody>{displayTableBody()}</tbody>
          </table>
        </div>
      ) : null}
    </>
  );
};

export default NewRestaurants;
