import { useState, useRef, useEffect, useContext } from 'react';
import { Box } from '@mui/material';

import { servingSizeLabelForForm, priceDetailsLabelForForm } from '../../constants/global';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import WorkflowMessage from '../UI/WorkflowMessage';
import FormControls from '../MUI/FormControls/FormControls';
import classes from '../CSS/standard-form.module.css';

const statusOptions = [
  { id: 'Active', title: 'Active' },
  { id: 'Inactive', title: 'Inactive' },
  { id: 'Deleted', title: 'Deleted' },
];

const ChildMenuItemForm = (props) => {
  let { menuItemDetails, childMenuItemDetails, onDataUpdated, setOpenPopup } = props;

  // console.log('menuItemDetails');
  // console.log(menuItemDetails);
  // console.log('childMenuItemDetails');
  // console.log(childMenuItemDetails);

  const authCtx = useContext(AuthContext);

  const restaurantUuid = authCtx.selectedRestaurantUuid;
  // const userRole = authCtx.userRole;

  if (!childMenuItemDetails.name) {
    childMenuItemDetails = {
      menuItemUuid: '',
      parentUuid: '',
      restaurantUuid: '',
      uuid: '',
      name: '',
      description: '',
      details: '',
      servingSize: '',
      feeds: 1,
      price: '',
      priceDetails: '',
      menuItemCategory: '',
      status: '',
    };
  }

  const [menuItemStatus, setMenuItemStatus] = useState(
    menuItemDetails.status ? menuItemDetails.status : 'Active'
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const servingSizeRef = useRef();
  const feedsRef = useRef();
  const priceRef = useRef();
  const priceDetailsRef = useRef();

  const displayStatusFormField = false;
  // const displayStatusFormField = userRole === 'Admin' ? true : false;

  const menuItemUuid = childMenuItemDetails.menuItemUuid;
  // const parentUuid = menuItemDetails.menuItemUuid;

  useEffect(() => {
    if (servingSizeRef.current) {
      servingSizeRef.current.focus();
    }
  }, [isLoading]);

  const submitHandler = (event) => {
    event.preventDefault();

    setNotify({
      isOpen: true,
      message: 'Sending Request...',
      type: 'info',
    });

    const enteredParentUuid = menuItemDetails.menuItemUuid;
    const enteredRestaurantUuid = restaurantUuid; //restaurantUuidRef.current.value;
    const enteredName = menuItemDetails.name;
    const enteredDescription = menuItemDetails.description;
    const enteredDetails = menuItemDetails.details;

    const enteredServingSize = servingSizeRef.current.value;
    const enteredFeeds = feedsRef.current.value;
    const enteredPrice = priceRef.current.value;
    const enteredPriceDetails = priceDetailsRef.current.value;

    // Values that are not going to change.
    const enteredCategoryId = menuItemDetails.menuItemCategoryId;
    const enteredStatus = menuItemDetails.status;
    // const enteredStatus = menuItemStatus;

    let apiURL = '';
    let apiMethod = '';

    if (menuItemUuid !== '' && menuItemUuid !== undefined) {
      apiURL = authCtx.baseApiUrl + 'menuitems/' + menuItemUuid;
      apiMethod = 'PATCH';
    } else {
      apiURL = authCtx.baseApiUrl + 'menuitems';
      apiMethod = 'POST';
    }

    // console.log(' - apiURL = ' + apiURL);
    // console.log(' - apiMethod = ' + apiMethod);

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        parent_uuid: enteredParentUuid,
        restaurant_uuid: enteredRestaurantUuid,
        name: enteredName,
        description: enteredDescription,
        details: enteredDetails,
        serving_size: enteredServingSize,
        feeds: enteredFeeds,
        price: enteredPrice,
        price_details: enteredPriceDetails,
        menu_item_category_id: enteredCategoryId,
        status: enteredStatus,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      setIsLoading(false);

      if (res.ok) {
        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          servingSizeRef.current.value = '';
          feedsRef.current.value = '';
          priceRef.current.value = '';
          priceDetailsRef.current.value = '';

          onDataUpdated();
        });
      } else {
        return res.json().then((responseData) => {
          setIsError(true);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: 'Menu Item Not Saved',
              type: 'error',
            });
          }

          setOpenPopup(false);
        });
      }
    });
  };

  const statusChangeHandler = (event) => {
    setMenuItemStatus(event.target.value);
  };

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {/* {isError ? <WorkflowMessage type='error' /> : null} */}

      {!isLoading && !isError ? (
        <Box>
          <form onSubmit={submitHandler}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
              <FormControls.InputControl
                type='text'
                name='servingSize'
                label={servingSizeLabelForForm}
                inputRef={servingSizeRef}
                defaultValue={childMenuItemDetails.servingSize}
                inputProps={{ maxLength: 20 }}
                className={classes['mui-control']}
              />
              <FormControls.InputControl
                type='text'
                name='feeds'
                label='Feeds ? People'
                inputRef={feedsRef}
                defaultValue={childMenuItemDetails.feeds}
                required
                inputProps={{ type: 'number', min: '1', max: '1000', step: '1' }}
                className={classes['mui-control']}
              />
              <FormControls.InputControl
                type='text'
                name='price'
                label='Price'
                inputRef={priceRef}
                defaultValue={childMenuItemDetails.price}
                required
                inputProps={{ type: 'number', min: '0', max: '19999.99', step: '0.01' }}
                className={classes['mui-control']}
              />
              <FormControls.InputControl
                type='text'
                name='priceDetails'
                label={priceDetailsLabelForForm}
                inputRef={priceDetailsRef}
                defaultValue={childMenuItemDetails.priceDetails}
                inputProps={{ maxLength: 20 }}
                className={classes['mui-control']}
              />
            </Box>
            {displayStatusFormField ? (
              <Box sx={{ width: '15rem', pt: '2rem' }}>
                <FormControls.SelectControl
                  name='status'
                  label='Status'
                  value={menuItemStatus}
                  onChange={statusChangeHandler}
                  options={statusOptions}
                />
              </Box>
            ) : null}

            <Box component='div' className={classes.actions}>
              <FormControls.ButtonControl text='Save' type='submit' color='primary' />
              {/* <FormControls.ButtonControl
                text='Cancel'
                onClick={backHandler}
                type='button'
                color='neutral200'
              /> */}
            </Box>
          </form>
        </Box>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default ChildMenuItemForm;
