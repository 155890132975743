import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

import ChildMenuItemDetailsCard from './ChildMenuItemDetailsCard';
// import classes from './MenuItem.module.css';

const MenuItemDetailsCard = (props) => {
  // console.log('##### MenuItemDetailsCard #####');

  // onDeleteChild was removed from props.
  const { menuItemDetails, menuItemChildren, onEditChild, onDataUpdated, userRole, authCtx } =
    props;

  // const dashboardUrl = authCtx.dashboardUrl;
  const restaurantUuid = menuItemDetails ? menuItemDetails.restaurantUuid : '';
  const baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath + restaurantUuid + '/';

  // console.log('menuItemDetails');
  // console.log(menuItemDetails);
  // console.log('menuItemChildren');
  // console.log(menuItemChildren);
  // console.log('restaurantUuid = ' + restaurantUuid);
  // console.log('baseApiUploadedImagesPath = ' + baseApiUploadedImagesPath);

  const displayMenuItemChildren = () => {
    return menuItemChildren.map((menuItem, index) => (
      <ChildMenuItemDetailsCard
        key={index}
        menuItemDetails={menuItem}
        onEditChild={onEditChild}
        // onDeleteChild={onDeleteChild}
        onDataUpdated={onDataUpdated}
        userRole={userRole}
      />
    ));
  };

  return (
    <Paper elevation={4} sx={{ p: '20px' }}>
      <Box>
        <Box className='pb-20'>
          <img
            src={baseApiUploadedImagesPath + menuItemDetails.image}
            alt='Current Menu Item Header'
            className='uploaded-menu-item-image-cover'
          />
          <Typography variant='caption'>
            Current Menu Item Image (Dimensions: 600px wide, 140px high)
          </Typography>
        </Box>

        <Typography variant='h1' component='h1' sx={{ pb: '1rem' }}>
          {menuItemDetails.name}
        </Typography>

        <Box>
          {menuItemDetails.dietaryOptions ? (
            <Box>
              <Typography variant='body1' component='p'>
                <Typography variant='label' sx={{ pr: '1rem' }}>
                  Dietary Flags
                </Typography>
                {menuItemDetails.dietaryOptions}
              </Typography>
            </Box>
          ) : null}
        </Box>

        {menuItemDetails.description ? (
          <Box sx={{ mb: '20px' }}>
            <Typography variant='label'>Description</Typography>
            <Typography variant='bodyContent'>{menuItemDetails.description}</Typography>
          </Box>
        ) : null}

        {menuItemDetails.details ? (
          <Box sx={{ mb: '20px' }}>
            <Typography variant='label'>Details</Typography>
            <Typography variant='bodyContent'>{menuItemDetails.details}</Typography>
          </Box>
        ) : null}
      </Box>

      <Box>
        <Typography variant='label'>Category</Typography>
        <Typography variant='body1' component='pre'>
          {menuItemDetails.menuItemCategory}
        </Typography>
      </Box>

      <ChildMenuItemDetailsCard
        menuItemDetails={menuItemDetails}
        onEditChild={onEditChild}
        // onDeleteChild={onDeleteChild}
        onDataUpdated={onDataUpdated}
        userRole={userRole}
        itemType='parent'
        // onEditChild={onEditChild.bind(null, menuItemDetails.menuItemUuid)}
      />

      <Box>{displayMenuItemChildren()}</Box>
    </Paper>
  );
};

export default MenuItemDetailsCard;
