import React from 'react';
import { useParams } from 'react-router-dom';

import StandardPageWrapper from '../Layout/StandardPageWrapper';
import DeliveryLocationList from './DeliveryLocationList';
import DeliveryLocationDetails from './DeliveryLocationDetails';

const DeliveryLocations = (props) => {
  const { mode } = props;
  const params = useParams();
  const deliveryLocationUuid = params.deliveryLocationUuid ? params.deliveryLocationUuid : '';

  // console.log('**** DeliveryLocations ****');
  // console.log('deliveryLocationUuid = ' + deliveryLocationUuid);
  // console.log('params');
  // console.log(params);

  return (
    <StandardPageWrapper>
      {!deliveryLocationUuid ? <DeliveryLocationList /> : null}

      {deliveryLocationUuid && (mode === 'details' || mode === 'edit') ? (
        <DeliveryLocationDetails deliveryLocationUuid={deliveryLocationUuid} mode={mode} />
      ) : null}
    </StandardPageWrapper>
  );
};

export default DeliveryLocations;
