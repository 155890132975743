import StandardPageWrapper from '../Layout/StandardPageWrapper';
import GlobalFoodTypes from '../GlobalFoodTypes/GlobalFoodTypes';

const FoodTypes = () => {
  return (
    <StandardPageWrapper title='Food Types'>
      <GlobalFoodTypes />
    </StandardPageWrapper>
  );
};

export default FoodTypes;
