import React, { useState, useEffect, useContext } from 'react';

import AuthContext from '../../store/auth-context';

const GlobalEndpoints = () => {
  console.log('***** GlobalEndpoints.jsx ***** ');
  const authCtx = useContext(AuthContext);

  const [leagues, setLeagues] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [foodTypes, setFoodTypes] = useState([]);
  const [menuItemCategories, setMenuItemCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchDataGlobalLeagues = async () => {
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'globaldata/leagues', {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(' -- response.ok -- ');
        // console.log(responseData);

        setLeagues(responseData.data.active_leagues);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchDataGlobalLeagues().catch(console.error);

    const fetchDataGlobalDeliveryLocationCities = async () => {
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'globaldata/globaldeliverycities', {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(' -- response.ok -- ');
        // console.log(responseData);

        setCities(responseData.data.global_delivery_cities);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchDataGlobalDeliveryLocationCities().catch(console.error);

    const fetchDataGlobalDeliveryLocationStates = async () => {
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'globaldata/globaldeliverystates', {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(' -- response.ok -- ');
        // console.log(responseData);

        setStates(responseData.data.global_delivery_states);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchDataGlobalDeliveryLocationStates().catch(console.error);

    // New Code.
    const fetchDataFoodTypes = async () => {
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'globaldata/foodtypes', {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(' -- response.ok -- ');
        // console.log(responseData);

        setFoodTypes(responseData.data.food_types);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchDataFoodTypes().catch(console.error);

    const fetchDataGlobalMenuItemCategories = async () => {
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'globaldata/menuitemcategories', {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(' -- response.ok -- ');
        // console.log(responseData);

        setMenuItemCategories(responseData.data.active_menu_item_categories);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchDataGlobalMenuItemCategories().catch(console.error);

    // End of Functions
  }, [authCtx.baseApiUrl, authCtx.token]);

  // Testing.
  // if (menuItemCategories) {
  //   authCtx.setGlobalMenuItemCategories(menuItemCategories);
  // }

  const displayGlobalLeagues = () => {
    return leagues.map((league, index) => (
      <div key={index} className='text-centered'>
        {league}
      </div>
    ));
  };

  const displayGlobalDeliveryLocationCities = () => {
    return cities.map((city, index) => (
      <div key={index} className='text-centered'>
        {city}
      </div>
    ));
  };

  const displayGlobalDeliveryLocationStates = () => {
    return states.map((state, index) => (
      <div key={index} className='text-centered'>
        {state}
      </div>
    ));
  };

  const displayFoodTypes = () => {
    return foodTypes.map((foodType, index) => (
      <div key={index} className='text-centered'>
        {foodType}
      </div>
    ));
  };

  const displayGlobalMenuItemCategories = () => {
    return menuItemCategories.map((menuItemCategory, index) => (
      <div key={index} className='text-centered'>
        {menuItemCategory}
      </div>
    ));
  };

  return (
    <div>
      <h3>Global Endpoint Testing</h3>
      {!isLoading && !isError && (
        <>
          <div>
            <h3>GLobal Leagues</h3>
            {displayGlobalLeagues()}
          </div>
          <div className='padding-above-normal'>
            <h3>Global Delivery Location Cities</h3>
            {displayGlobalDeliveryLocationCities()}
          </div>
          <div className='padding-above-normal'>
            <h3>Global Delivery Location States</h3>
            {displayGlobalDeliveryLocationStates()}
          </div>
          <div className='padding-above-normal'>
            <h3>Food Types</h3>
            {displayFoodTypes()}
          </div>
          <div className='padding-above-normal'>
            <h3>Global Menu Item Categories</h3>
            {displayGlobalMenuItemCategories()}
          </div>
        </>
      )}
      {isLoading && (
        <div className='message-holder'>
          <p className='loading'>Loading...</p>
        </div>
      )}
      {isError && (
        <div className='message-holder'>
          <p className='error'>Error...</p>
        </div>
      )}
      {!isLoading && leagues.length === 0 && (
        <div className='message-holder'>
          <p className='error'>No Leagues Found...</p>
        </div>
      )}
    </div>
  );
};

export default GlobalEndpoints;
