import React from 'react';
import { useParams } from 'react-router-dom';

import StandardPageWrapper from '../Layout/StandardPageWrapper';
import RestaurantList from '../Restaurant/RestaurantList';
import RestaurantDetails from '../Restaurant/RestaurantDetails';

const Restaurants = (props) => {
  const { mode } = props;
  const params = useParams();
  const restaurantUuid = params.restaurantUuid ? params.restaurantUuid : '';

  return (
    <StandardPageWrapper>
      {!restaurantUuid ? <RestaurantList /> : null}

      {restaurantUuid && mode !== 'list' ? (
        <RestaurantDetails pRestaurantUuid={restaurantUuid} mode={mode} />
      ) : null}
    </StandardPageWrapper>
  );
};

export default Restaurants;
