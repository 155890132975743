import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import AuthContext from '../../store/auth-context';
import DeliveryFeesListCard from './DeliveryFeesListCard';
import Notification from '../MUI/UI/Notification';
import WorkflowMessage from '../UI/WorkflowMessage';
import { useFormatData } from '../Hooks/useFormatData';
import classes from '../CSS/styled-table.module.css';

const DeliveryFeesList = ({ restaurantUuid, mode = 'main' }) => {
  const authCtx = useContext(AuthContext);

  const { FormatCurrencyForDisplay } = useFormatData();

  const [deliveryFees, setDeliveryFees] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  // const restaurantUuid = '0ee763a2-a26c-4d4c-adac-549256e8a45d';

  // console.log('apiUrl', apiUrl);

  const useComponent = true;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetch(
        authCtx.baseApiUrl + 'deliveryfees/restaurant/' + restaurantUuid,
        {
          headers: {
            Authorization: authCtx.token,
            'Content-Type': 'application/json',
          },
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        // console.log('responseData.data');
        // console.log(responseData.data);

        const transformedRecords = responseData.data.delivery_fees.map((record) => {
          return {
            deliveryFeeUuid: record.deliveryFeeUuid,
            restaurantUuid: record.restaurantUuid,
            rangeStart: record.rangeStart,
            rangeEnd: record.rangeEnd,
            fee: record.fee,
            feeFormatted: record.feeFormatted,
            percent: record.percent,
            percentFormatted: record.percentFormatted,
          };
        });

        setDeliveryFees(transformedRecords);

        // console.log('transformedRecords');
        // console.log(transformedRecords);
      } else {
        setIsError(true);
        // console.log('responseData');
        // console.log(responseData);
      }
      setIsLoading(false);
      setRefreshData(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, restaurantUuid, refreshData]);

  const displayTableHeader = () => {
    return (
      <tr>
        <th>From</th>
        <th>To</th>
        <th>Fee</th>
      </tr>
    );
  };

  const displayTableBody = () => {
    return deliveryFees.map((record, index) => (
      <tr key={index}>
        <td className={`${classes['text-right']}`}>
          {FormatCurrencyForDisplay(record.rangeStart)}
        </td>
        <td className={`${classes['text-right']}`}>{FormatCurrencyForDisplay(record.rangeEnd)}</td>
        <td className={`${classes['text-right']}`}>
          {record.percentFormatted ? record.percentFormatted : record.feeFormatted}
        </td>
      </tr>
    ));
  };

  const deleteHandler = (deliveryFeeUuid) => {
    console.log(' - handleDelete - deliveryFeeUuid = ' + deliveryFeeUuid);
    deleteDeliveryFeeHandler(deliveryFeeUuid);
  };

  const deleteDeliveryFeeHandler = (deliveryFeeUuid) => {
    // console.log(' -- deleteDeliveryFeeHandler -- ');
    // const deliveryFeeUuid = event.target.getAttribute('data-user-uuid');
    // console.log('deliveryFeeUuid = ' + deliveryFeeUuid);

    const deleteData = async () => {
      const response = await fetch(authCtx.baseApiUrl + 'deliveryfees/' + deliveryFeeUuid, {
        method: 'DELETE',
        headers: {
          Authorization: authCtx.token,
        },
      });

      if (response.ok) {
        // const responseData = await response.json();
        // console.log(responseData);

        setNotify({ isOpen: true, message: 'Deleted Successfully', type: 'success' });
      } else {
        setIsError(true);
      }
      setIsLoading(false);
      setRefreshData(true);
    };

    deleteData().catch(console.error);
  };

  const restaurantDetails = {
    restaurantUuid: restaurantUuid,
    deliveryFees: deliveryFees,
  };

  return (
    <Box>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {!isLoading && !isError ? (
        <>
          {useComponent ? (
            <Box>
              <DeliveryFeesListCard
                restaurantDetails={restaurantDetails}
                displayButton={false}
                displayAddButton={true}
                onDelete={deleteHandler}
                mode='main'
              />
            </Box>
          ) : null}

          {!useComponent ? (
            <table className={`${classes['styled-table']}`}>
              <thead>{displayTableHeader()}</thead>
              <tbody>{displayTableBody()}</tbody>
            </table>
          ) : null}
        </>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default DeliveryFeesList;
