import React from 'react';
import { Box, Paper, Rating, Typography } from '@mui/material';

import RestaurantReviewImages from './RestaurantReviewImages';

const RestaurantReviewListCard = (props) => {
  const { review, baseApiUploadedImagesPath } = props;

  // console.log(review);

  return (
    <Paper elevation={4} sx={{ p: '20px', pb: '0', mb: '20px', height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '40px',
          // border: '1px solid blue',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            gap: '10px',
            pb: '20px',
            // border: '1px solid red',
            flexShrink: 1,
            flexGrow: 1,
          }}
        >
          <Rating name='rating' value={review.rating} precision={0.5} readOnly size='large' />
          <Typography component='pre' variant='bold'>
            {'"' + review.review + '"'}
          </Typography>
          <Typography component='div'>{review.teamName}</Typography>
          <Typography component='div'>{review.dateAddedFormatted}</Typography>
        </Box>

        <Box sx={{ flexShrink: '0' }}>
          {review.reviewImages.length > 0 ? (
            <Box>
              <RestaurantReviewImages
                baseApiUploadedImagesPath={baseApiUploadedImagesPath}
                reviewImages={review.reviewImages}
                imageSize='thumbnail'
                displaySingleImage={true}
                showMoreImagesText={true}
              />
            </Box>
          ) : null}
        </Box>
      </Box>
    </Paper>
  );
};

export default RestaurantReviewListCard;
