import React from 'react';

import StandardPageWrapper from '../Layout/StandardPageWrapper';
import RestaurantReviewsList from '../RestaurantReviews/RestaurantReviewsList';

const Reviews = () => {
  return (
    <StandardPageWrapper>
      <RestaurantReviewsList mode='main' />
    </StandardPageWrapper>
  );
};

export default Reviews;
