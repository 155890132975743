import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import classes from '../CSS/CartItem.module.css';

const OrderCartItem = (props) => {
  const { item, onEditCartItem, onDeleteCartItem } = props;

  // console.log('### OrderCartItem ### ');
  // console.table(item);

  const displayServingSize = false;
  const displayServingSizeLabel = false;
  const displayServingSizeInline = false;
  const displayFeeds = false;

  const editCartItem = () => {
    onEditCartItem(item);
  };

  const deleteCartItem = (event) => {
    event.stopPropagation();
    onDeleteCartItem(item.orderItemUuid);
  };

  const itemTotal = +item.quantity * +item.price;
  const formattedItemTotal = typeof itemTotal === 'number' ? itemTotal.toFixed(2) : itemTotal;
  const displayItemTotal = `$${formattedItemTotal}`;

  return (
    <div>
      <Box className={classes['cart-item-clickable']} onClick={editCartItem}>
        <div className={classes['cart-item-header-holder']}>
          <div className={classes['cart-item-quantity-holder']}>{item.quantity} x</div>
          <div className={classes['cart-item-title-and-total-holder']}>
            <Box>
              <Typography variant='label' component='div' className={classes['cart-item-title']}>
                {item.name}
                {displayServingSizeInline && item.servingSize ? (
                  <span className={classes['extra-info']}>{'(' + item.servingSize + ')'}</span>
                ) : (
                  ''
                )}
              </Typography>

              {!displayServingSizeInline && item.servingSize ? (
                <Typography component='div' className='small-text'>
                  {'(Feeds ' + item.feeds + ' - ' + item.servingSize + ')'}
                </Typography>
              ) : (
                <Typography component='div' className='small-text'>
                  {'(Feeds ' + item.feeds + ')'}
                </Typography>
              )}
            </Box>
            <Typography component='span' className={classes['cart-item-total']}>
              {displayItemTotal}
            </Typography>
          </div>
          <div className={classes['cart-item-delete-button-holder']}>
            <IconButton onClick={deleteCartItem} sx={{ p: '0px' }}>
              <DeleteForeverIcon color='error' />
            </IconButton>
          </div>
        </div>

        {displayServingSize && item.servingSize ? (
          <Box className={classes['cart-item-serving-size']}>
            <Typography variant='caption' component='span'>
              {displayServingSizeLabel ? 'Serving Size:' : null}
              {item.servingSize}
            </Typography>
          </Box>
        ) : null}

        {displayFeeds && item.feeds && !item.servingSize ? (
          <Box className={classes['cart-item-serving-size']}>
            <Typography variant='caption' component='span'>
              Feeds: {item.feeds}
            </Typography>
          </Box>
        ) : null}

        {item.specialInstructions ? (
          <Box className={classes['special-instructions']}>
            <Typography variant='labelSmall' component='div'>
              Meal Selection / Special Instructions
            </Typography>
            <Typography variant='bodyContentSmall' component='p'>
              {item.specialInstructions}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </div>
  );
};

export default OrderCartItem;
