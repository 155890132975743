import React, { useContext, useState } from 'react';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';

const OrderEmailTesting = () => {
  const authCtx = useContext(AuthContext);

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const sendOrderNotificationEmail = (emailType) => {
    console.log('##### sendOrderNotificationEmail = emailType = ' + emailType);
    console.log('authCtx.userRole = ' + authCtx.userRole);

    const apiURL = authCtx.baseApiUrl + 'testing/notificationemail/' + emailType;
    const apiMethod = 'GET';

    fetch(apiURL, {
      method: apiMethod,
      headers: {
        Authorization: authCtx.signupToken,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        return res.json().then((responseData) => {
          console.log(' ### SUCCESS ### ');
          console.log(responseData);
        });
      } else {
        return res.json().then((responseData) => {
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: 'Email not sent.',
              type: 'error',
            });
          }
        });
      }
    });
  };

  return (
    <div className='p-20'>
      <h1>Email Testing</h1>
      <h3>Order Emails</h3>
      <div>
        <button onClick={() => sendOrderNotificationEmail('orderPlaced')} className='small'>
          Order Placed
        </button>
        <div className='pt-10'>
          <p>
            When an Order is first place the Restaurant and Elite Eats Admins should receive an
            email.
          </p>
        </div>
      </div>
      <div>
        <button onClick={() => sendOrderNotificationEmail('orderAccepted')} className='small'>
          Order Accepted
        </button>
        <div className='pt-10'>
          <p>When a Restaurant Accepts an Order the Team should be notified.</p>
        </div>
      </div>
      <div>
        <button onClick={() => sendOrderNotificationEmail('orderRejected')} className='small'>
          Order Rejected
        </button>
        <div className='pt-10'>
          <p>
            When a Restaurant Rejects and Order the Team should be notified and told why the order
            was rejected.
          </p>
        </div>
      </div>
      <div>
        <button onClick={() => sendOrderNotificationEmail('orderPaid')} className='small'>
          Order Paid For
        </button>
        <div className='pt-10'>
          <p>When a Team Pays for an Order the Restaurant, Team and Admins should be notified.</p>
        </div>
      </div>

      <br />
      <h3>Account Emails</h3>

      <div>
        <button
          onClick={() => sendOrderNotificationEmail('initialRegistrationTeam')}
          className='small'
        >
          Initial Registration (Team)
        </button>
        <div className='pt-10'>
          <p>The email received after a Team creates an Account.</p>
        </div>
      </div>
      <div>
        <button onClick={() => sendOrderNotificationEmail('accountApprovedTeam')} className='small'>
          Account Approved (Team)
        </button>
        <div className='pt-10'>
          <p>The email received when a new Team Account has been approved.</p>
        </div>
      </div>

      <div>
        <button onClick={() => sendOrderNotificationEmail('accountRejectedTeam')} className='small'>
          Account Rejected (Team)
        </button>
        <div className='pt-10'>
          <p>The email received when a new Team Account has been rejected.</p>
        </div>
      </div>

      <div>
        <button
          onClick={() => sendOrderNotificationEmail('initialRegistrationVendor')}
          className='small'
        >
          Initial Registration (Fueling Partner)
        </button>
        <div className='pt-10'>
          <p>The email received after a Fueling Partner creates an Account.</p>
        </div>
      </div>
      <div>
        <button
          onClick={() => sendOrderNotificationEmail('accountApprovedVendor')}
          className='small'
        >
          Account Approved (Fueling Partner)
        </button>
        <div className='pt-10'>
          <p>The email received when a new Fueling Partner Account has been approved.</p>
        </div>
      </div>
      <div>
        <button
          onClick={() => sendOrderNotificationEmail('accountRejectedVendor')}
          className='small'
        >
          Account Rejected (Fueling Partner)
        </button>
        <div className='pt-10'>
          <p>The email received when a new Fueling Partner Account has been rejected.</p>
        </div>
      </div>

      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default OrderEmailTesting;
