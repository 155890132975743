import React, { useContext } from 'react';

import OrderContext from '../../store/order-context';
import classes from './OrderDetailsHeader.module.css';

const OrderDetailsHeader = (props) => {
  const orderCtx = useContext(OrderContext);

  const orderMode = 'Active'; //orderCtx.orderMode;
  const orderNumber = orderCtx.orderDetails.orderNumber;
  // const deliveryCity = orderCtx.orderDetails.deliveryCity;
  const deliveryLocation = orderCtx.deliveryLocation;
  // const orderStatus = orderCtx.orderDetails.status;
  let orderStatus = orderCtx.orderDetails.status;
  if (
    orderCtx.orderDetails.restaurantStatus === 'Modified' &&
    orderCtx.orderDetails.status === 'Placed'
  ) {
    orderStatus = 'Placed / Modified';
  }
  // const deliveryDate = orderCtx.orderDetails.deliveryDate;
  // const deliveryTime = orderCtx.orderDetails.deliveryTime;
  const headcount = orderCtx.orderDetails.headcount;

  const restaurant = orderCtx.restaurant;
  const restaurantName = restaurant.name;
  // console.log('restaurant');
  // console.log(restaurant);

  let displayDeliveryLocation = '';
  if (deliveryLocation.name) {
    displayDeliveryLocation = deliveryLocation.name;
  }

  return (
    <div className={classes['order-details-header']}>
      {orderMode === 'Active' ? (
        <div className={classes['order-details-header-content']}>
          {/* <span>Order Details:</span> */}

          <div>
            <span className={classes['title']}>Order Number:</span>
            <span>{orderNumber}</span>
          </div>
          <div>
            <span className={classes['title']}>Delivery Location:</span>
            <span>{displayDeliveryLocation}</span>
          </div>
          {restaurantName ? (
            <div>
              <span className={classes['title']}>Restaurant:</span>
              <span>{restaurantName}</span>
            </div>
          ) : null}
          <div>
            <span className={classes['title']}>Headcount:</span>
            <span>{headcount}</span>
          </div>
          <div>
            <span className={classes['title']}>Status:</span>
            <span>{orderStatus}</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default OrderDetailsHeader;
