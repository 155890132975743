import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { apiEnvironment } from './constants/global';

import './index.css';
import App from './App';
import { AuthContextProvider } from './store/auth-context';
import GlobalDataProvider from './store/GlobalDataProvider';
// import CartProvider from './store/CartProvider';
import OrderProvider from './store/OrderProvider';

let basename = '/elite-eats-app';

if (apiEnvironment === 'production') {
  basename = '/';
} else if (apiEnvironment === 'staging') {
  basename = '/staging/elite-eats-app';
} else if (apiEnvironment === 'development') {
  basename = '/elite-eats-app';
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <BrowserRouter basename='/elite-eats-app'>
  <BrowserRouter basename={basename}>
    <AuthContextProvider>
      <GlobalDataProvider>
        {/* <CartProvider> */}
        <OrderProvider>
          <App />
        </OrderProvider>
        {/* </CartProvider> */}
      </GlobalDataProvider>
    </AuthContextProvider>
  </BrowserRouter>
);
