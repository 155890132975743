import React, { useContext, useState } from 'react';
import { Box, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import Notification from '../MUI/UI/Notification';

const ImageUpload = () => {
  const authCtx = useContext(AuthContext);

  let baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath;
  const orgFolder = authCtx.orgDetails.orgUuid;
  baseApiUploadedImagesPath += orgFolder + '/';
  console.log(authCtx.orgDetails);
  console.log('baseApiUploadedImagesPath');
  console.log(baseApiUploadedImagesPath);

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [uploadedFilename, setUploadedFilename] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const [leagueDetails, setLeagueDetails] = useState([]);

  const onFileChange = (event) => {
    console.log('-- onFileChange -- ');

    let files = event.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);

    setSelectedImageName(files[0].name);

    fileReader.onload = (event) => {
      setSelectedImage(event.target.result);
    };

    // fileReader.onload = () => {
    //   setSelectedImage(fileReader.result);
    // }
  };

  const submitHandler = (event) => {
    event.preventDefault();

    setIsLoading(true);
    setIsError(false);

    // const enteredName = nameRef.current.value;
    // const enteredDescription = descriptionRef.current.value;
    // const enteredSortOrder = sortOrderRef.current.value;
    // const enteredStatus = statusRef.current ? statusRef.current.value : 'Active';

    const enteredName = 'Image Upload Test - 6';
    const enteredDescription =
      'Started padding the selectedImage. ##### selectedImageName = ' + selectedImageName;
    const enteredSortOrder = 99;
    const enteredStatus = 'Inactive';

    // Todo:  Add Validation.

    let apiURL = '';
    let apiMethod = '';
    let leagueUuid = '';

    if (leagueUuid !== '' && leagueUuid !== undefined) {
      apiURL = authCtx.baseApiUrl + 'testimageupload/' + leagueUuid;
      apiMethod = 'PATCH';
    } else {
      // apiURL = authCtx.baseApiUrl + 'globalleagues';
      apiURL = authCtx.baseApiUrl + 'testimageupload';
      apiMethod = 'POST';
    }

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        name: enteredName,
        description: enteredDescription,
        sort_order: enteredSortOrder,
        status: enteredStatus,
        image: selectedImage,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        return res.json().then((responseData) => {
          console.log(' ** SUCCESS ** ');
          console.log(responseData);

          setUploadedFilename(responseData.data.uploaded_filename);

          console.log('uploadedFilename');
          console.log(uploadedFilename);
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          // console.log(responseData);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            // setDisplayMessage(responseData.messages);
            console.log('**** ERROR *****');
          } else {
            // setDisplayMessage([[textFailedApiCallMessage]]);
            console.log('**** ERROR *****');
          }
        });
      }
    });

    setIsLoading(false);
  };

  const saveLeague = (event) => {
    event.preventDefault();

    setIsLoading(true);
    setIsError(false);

    // const enteredName = nameRef.current.value;
    // const enteredDescription = descriptionRef.current.value;
    // const enteredSortOrder = sortOrderRef.current.value;
    // const enteredStatus = statusRef.current ? statusRef.current.value : 'Active';

    const enteredName = 'Image Upload Test - Update 3';
    const enteredDescription = 'Doing some testing for Image Uploads.';
    const enteredSortOrder = 99;
    const enteredStatus = 'Inactive';

    // Todo:  Add Validation.

    let apiURL = '';
    let apiMethod = '';
    let leagueUuid = '';

    if (leagueUuid !== '' && leagueUuid !== undefined) {
      apiURL = authCtx.baseApiUrl + 'globalleagues/' + leagueUuid;
      apiMethod = 'PATCH';
    } else {
      // apiURL = authCtx.baseApiUrl + 'globalleagues';
      apiURL = authCtx.baseApiUrl + 'testimageupload';
      apiMethod = 'POST';
    }

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        name: enteredName,
        description: enteredDescription,
        sort_order: enteredSortOrder,
        status: enteredStatus,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        return res.json().then((responseData) => {
          console.log(' ** SUCCESS ** ');
          console.log(responseData);
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          // console.log(responseData);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            // setDisplayMessage(responseData.messages);
            console.log('**** ERROR *****');
          } else {
            // setDisplayMessage([[textFailedApiCallMessage]]);
            console.log('**** ERROR *****');
          }
        });
      }
    });

    setIsLoading(false);
  };

  const getLeague = () => {
    setIsError(false);

    const fetchTeamData = async () => {
      console.log(' *** getLeague *** ');
      try {
        setIsLoading(true);

        const leagueUuid = 'f7f7674f-6b86-4f28-a02e-fa67d732c630';

        const apiURL = authCtx.baseApiUrl + 'testimageupload/' + leagueUuid;
        const apiMethod = 'GET';

        const response = await fetch(apiURL, {
          method: apiMethod,
          headers: {
            Authorization: authCtx.token,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const responseData = await response.json();
          console.log(' ** SUCCESS ** ');
          console.log(responseData);

          const returnedData = {
            globalLeaguUuid: responseData.data.global_leagues[0].globalLeaguUuid,
            name: responseData.data.global_leagues[0].name,
            description: responseData.data.global_leagues[0].description,
            // logoImage: responseData.data.global_leagues[0].logoImage,
          };

          setLeagueDetails(returnedData);
        } else {
          setIsError(true);
        }
      } catch (err) {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchTeamData();
  };

  return (
    <Box sx={{ py: '1rem' }}>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      <form encType='multiport/form-data' onSubmit={submitHandler}>
        <Box sx={{ pb: '2rem' }}>
          <Typography variant='labelInline'>Select Image File (Test)</Typography>
          <input type='file' name='image' onChange={onFileChange} />
        </Box>

        <Box>
          <button type='submit' className='small'>
            Upload Image
          </button>
        </Box>
      </form>

      <Box sx={{ pt: '2rem' }}>
        <Typography variant='labelInline'>Seletected Image:</Typography>
        <Typography>{selectedImageName}</Typography>
      </Box>

      <Box sx={{ pt: '4rem' }}>
        <button onClick={saveLeague}>Create League - Test POST API CALL</button>
      </Box>

      <Box sx={{ pt: '4rem' }}>
        <button onClick={getLeague}>Get League</button>
      </Box>

      <Box sx={{ pt: '4rem' }}>
        <Typography>{leagueDetails.name}</Typography>
      </Box>

      <Box sx={{ pt: '4rem', maxWidth: '80rem' }}>
        <img src={baseApiUploadedImagesPath + uploadedFilename} alt='Upload Test' />
        <Typography>{uploadedFilename}</Typography>
      </Box>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default ImageUpload;
