import React, { useState } from 'react';
import ComponentDataTestForm from './ComponentDataTestForm';

// const ComponentDataTest = ({ person, onUpdatePerson }) => {
const ComponentDataTest = () => {
  console.log('***** ComponentDataTest *****');
  // console.log('person');
  // console.log(person);

  const testPerson = {
    firstName: 'Jeff',
    lastName: 'Cummings',
  };

  const [person, setPerson] = useState(testPerson);

  const testUpdatePersonHandler = () => {
    console.log('testUpdatePersonHandler');

    const updatedPerson = {
      firstName: 'John',
      lastName: 'Wick',
    };

    setPerson(updatedPerson);

    // onUpdatePerson(updatedPerson);
  };

  const updatePersonHandler = (person) => {
    console.log('updatePersonHandler');
    console.log('person');
    console.log(person);
    setPerson(person);
  };

  console.log('person - state');
  console.log(person);

  return (
    <div className='p-20'>
      <h1>ComponentDataTest</h1>
      <div>
        <h4>Person</h4>
        <p>{person.firstName + ' ' + person.lastName}</p>
      </div>
      <div className='pt-20'>
        <button onClick={testUpdatePersonHandler} className='small'>
          Update Person
        </button>
      </div>
      <div className='pt-20'>
        <ComponentDataTestForm person={person} onUpdatePerson={updatePersonHandler} />
      </div>
    </div>
  );
};

export default ComponentDataTest;
