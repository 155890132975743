import React, { useContext } from 'react';
import { Box } from '@mui/material';

// import AuthContext from '../../store/auth-context';
import OrderContext from '../../store/order-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import VendorOrderStatusMessage from './VendorOrderStatusMessage';
import OrderDetails from './OrderDetails';
import DeliveryLocationDetails from './DeliveryLocationDetails';
import RestaurantDetails from './RestaurantDetails';
import classes from '../CSS/Checkout.module.css';

const OrderConfirmation = () => {
  // const authCtx = useContext(AuthContext);
  const orderCtx = useContext(OrderContext);

  // const orderUuid = orderCtx.orderDetails;
  // const deliveryLocation = orderCtx.deliveryLocation;
  // const restaurant = orderCtx.restaurant;

  return (
    <div className='page-container-after-top-menu'>
      <StandardPageWrapper title='Order Confirmation'>
        <div className={classes['content-holder']}>
          <VendorOrderStatusMessage status='Placed' />

          <Box sx={{ display: 'block' }} className={classes['details-section']}>
            <OrderDetails orderDetails={orderCtx.orderDetails} displayDeliveryAddress={false} />
            <DeliveryLocationDetails deliveryLocation={orderCtx.deliveryLocation} />
            <RestaurantDetails restaurant={orderCtx.restaurant} />
          </Box>
        </div>
      </StandardPageWrapper>
    </div>
  );
};

export default OrderConfirmation;
