import React from 'react';
import { Box, Typography } from '@mui/material';

const RestaurantDetails = (props) => {
  const { restaurant, restaurantContacts } = props;

  const restaurantCity = restaurant.city ? restaurant.city : '';
  const restaurantState = restaurant.state ? restaurant.state : '';
  const restaurantZipcode = restaurant.zipcode ? restaurant.zipcode : '';

  let restaurantAddressLine1 = restaurant.address1 ? restaurant.address1 : '';
  if (restaurant.address2) {
    restaurantAddressLine1 = restaurantAddressLine1.concat(' ', restaurant.address2);
  }
  let restaurantAddressLine2 = restaurantCity ? restaurantCity : '';
  if (restaurantState) {
    restaurantAddressLine2 = restaurantAddressLine2.concat(', ', restaurantState);
  }
  if (restaurantZipcode) {
    restaurantAddressLine2 = restaurantAddressLine2.concat(', ', restaurantZipcode);
  }

  const displayRestaurantContacts = false;

  return (
    <div className='condensed-content'>
      <Box sx={{ pt: '20px' }}>
        {restaurant.name ? (
          <Typography variant='h3' component='h3'>
            Restaurant
          </Typography>
        ) : null}
        {restaurant.name ? <Typography>{restaurant.name}</Typography> : null}
        {/* {restaurant.foodType ? <Typography>{restaurant.foodType}</Typography> : null} */}
        {restaurantAddressLine1 ? <Typography>{restaurantAddressLine1}</Typography> : null}
        {restaurantAddressLine2 ? <Typography>{restaurantAddressLine2}</Typography> : null}
        {restaurant.phone ? <Typography>{restaurant.phone}</Typography> : null}
        {restaurant.email ? <Typography>{restaurant.email}</Typography> : null}
        {/* {restaurant.taxRate ? <Typography>Tax Rate: {restaurant.taxRate}%</Typography> : null} */}

        {displayRestaurantContacts &&
        Array.isArray(restaurantContacts) &&
        restaurantContacts.length > 0 ? (
          <Box component='div' sx={{ py: '2rem' }}>
            <Typography variant='h8'>
              {restaurantContacts.length === 1 ? 'Contact' : 'Contacts'}
            </Typography>

            {restaurantContacts.map((record, index) => (
              <Box key={index} sx={{ pb: '1rem' }}>
                <div>{record.name}</div>
                <div>{record.position}</div>
                <div>{record.phone}</div>
                <div>{record.email}</div>
              </Box>
            ))}
          </Box>
        ) : null}
      </Box>
    </div>
  );
};

export default RestaurantDetails;
