import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box } from '@mui/material';

import classes from '../CSS/TopMenu.module.css';

const CheckoutMenu = () => {
  return (
    <Box component='div' className={classes['top-menu-container']}>
      <nav className={classes['top-nav']}>
        <ul>
          {/* <li>
            <NavLink activeClassName={classes.active} to={`delivery-location`}>
              Delivery Location
            </NavLink>
          </li> */}
          <li>
            <NavLink activeClassName={classes.active} to={`delivery-details`}>
              Delivery Details
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={classes.active} to={`delivery-instructions`}>
              Delivery Instructions
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={classes.active} to={`file-attachments`}>
              File Attachments
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={classes.active} to={`special-requests`}>
              Message Your Fueling Partner
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={classes.active} to='review-order'>
              Review Order
            </NavLink>
          </li>
        </ul>
      </nav>
    </Box>
  );
};

export default CheckoutMenu;
