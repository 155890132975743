import React from 'react';
import { Alert, Box } from '@mui/material';

const VendorOrderStatusMessage = (props) => {
  return (
    <>
      {props.status === 'Placed' ? (
        <Box component='div'>
          <Alert severity='info'>
            Your Order has been Placed with the Vendor. They have not yet Reviewed the Order
            Details.
          </Alert>
        </Box>
      ) : null}
      {props.status === 'Under Review' ? (
        <Box component='div'>
          <Alert severity='info'>
            This Order is being Reviewed by the Vendor as we speak. You should hear back one way or
            the other shortly.
          </Alert>
        </Box>
      ) : null}
      {props.status === 'Confirmed' ? (
        <Box component='div'>
          <Alert severity='info'>
            This Order has been Confirmed by the Vendor. Please PAY FOR THE FRIGGIN' MEAL!!!
          </Alert>
        </Box>
      ) : null}
      {props.status === 'On Hold' ? (
        <Box component='div'>
          <Alert severity='warning'>The Vendor has put a Hold on the Order.</Alert>
        </Box>
      ) : null}
      {props.status === 'Working On' ? (
        <Box component='div'>
          <Alert severity='info'>The Vendor is currently working on your Order.</Alert>
        </Box>
      ) : null}
      {props.status === 'Ready for Delivery' ? (
        <Box component='div'>
          <Alert severity='info'>Your Order is Ready for Delivery.</Alert>
        </Box>
      ) : null}
      {props.status === 'Out for Delivery' ? (
        <Box component='div'>
          <Alert severity='info'>Your Order is Out for Delivery.</Alert>
        </Box>
      ) : null}
      {props.status === 'Delivered' ? (
        <Box component='div'>
          <Alert severity='info'>Your Order has been Delivered.</Alert>
        </Box>
      ) : null}
    </>
  );
};

export default VendorOrderStatusMessage;
