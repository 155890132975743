import React from 'react';
import { Box } from '@mui/material';

import PrivacyPolicy from '../components/GeneralContent/PrivacyPolicy';

const PrivacyPolicyPage = () => {
  return (
    <Box className='page-container'>
      <PrivacyPolicy />
    </Box>
  );
};

export default PrivacyPolicyPage;
