import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import WorkflowMessage from '../UI/WorkflowMessage';
import DeliveryLocationDetailsTable from './DeliveryLocationDetailsTable';
import DeliveryLocationCard from './DeliveryLocationCard';
// import DeliveryLocationForm from './DeliveryLocationForm';
// import classes from '../CSS/styled-table.module.css';

const DeliveryLocationDetails = (props) => {
  const { deliveryLocationUuid } = props;

  const authCtx = useContext(AuthContext);
  const userRole = authCtx.userRole;
  const dashboardUrl = authCtx.dashboardUrl;

  const [locationDetails, setLocationDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const history = useHistory();

  const displayContentMode = 'card';

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const response = await fetch(
        authCtx.baseApiUrl + 'deliverylocations/' + deliveryLocationUuid,
        {
          headers: {
            Authorization: authCtx.token,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        // console.log(responseData);

        const returnedLocationDetails = {
          deliveryLocationUuid: responseData.data.delivery_locations[0].deliveryLocationUuid,
          name: responseData.data.delivery_locations[0].name,
          description: responseData.data.delivery_locations[0].description,
          leagues: responseData.data.delivery_locations[0].leagues,
          address1: responseData.data.delivery_locations[0].address1,
          address2: responseData.data.delivery_locations[0].address2,
          city: responseData.data.delivery_locations[0].city,
          state: responseData.data.delivery_locations[0].state,
          zipcode: responseData.data.delivery_locations[0].zipcode,
          email: responseData.data.delivery_locations[0].email,
          phone: responseData.data.delivery_locations[0].phone,
          deliveryInstructions: responseData.data.delivery_locations[0].deliveryInstructions,
          status: responseData.data.delivery_locations[0].status,
          isGlobal: responseData.data.delivery_locations[0].isGlobal,
        };
        // console.log(' -- returnedLocationDetails -- ');
        // console.log(returnedLocationDetails);

        setLocationDetails(returnedLocationDetails);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, deliveryLocationUuid]);

  const addNewHandler = () => {
    history.push(dashboardUrl + 'delivery-locations/add');
  };

  const editHandler = () => {
    history.push(dashboardUrl + 'delivery-locations/edit/' + deliveryLocationUuid);
  };

  const backToListHandler = () => {
    history.push(dashboardUrl + 'delivery-locations');
  };

  return (
    <StandardPageWrapper>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      <Box className='content-container'>
        {!isLoading && !isError && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='h1' component='h1'>
              Delivery Location
            </Typography>
            <Box className='button-holder'>
              {!locationDetails.isGlobal || (locationDetails.isGlobal && userRole === 'Admin') ? (
                <Button variant='contained' size='custom' color='accent' onClick={editHandler}>
                  Edit
                </Button>
              ) : null}
              {userRole === 'Admin' ? (
                <Button variant='contained' size='custom' color='accent' onClick={addNewHandler}>
                  Add New
                </Button>
              ) : null}
              <Button variant='contained' size='custom' color='accent' onClick={backToListHandler}>
                Back to List
              </Button>
            </Box>
          </Box>
        )}

        {displayContentMode === 'table' && !isLoading && !isError && (
          <DeliveryLocationDetailsTable details={locationDetails} userRole={userRole} />
        )}

        {displayContentMode === 'card' && !isLoading && !isError && (
          <DeliveryLocationCard details={locationDetails} userRole={userRole} />
        )}
      </Box>
    </StandardPageWrapper>
  );
};

export default DeliveryLocationDetails;
