import React, { useRef, useState } from 'react';

const ComponentDataTestForm = ({ person, onUpdatePerson }) => {
  console.log('***** ComponentDataTestForm *****');
  console.log('person');
  console.log(person);

  const firstNameRef = useRef();
  const lastNameRef = useRef();

  const [firstName, setFirstName] = useState(person.firstName ? person.firstName : '');
  const [lastName, setLastName] = useState(person.lastName ? person.lastName : '');

  const submitHandler = (event) => {
    event.preventDefault();
    console.log('submitHandler');

    const enteredFirstName = firstNameRef.current.value;
    const enteredLastName = lastNameRef.current.value;

    console.log('enteredFirstName = ' + enteredFirstName);
    console.log('enteredLastName = ' + enteredLastName);

    setFirstName(enteredFirstName);
    setLastName(enteredLastName);

    const updatedPerson = {
      firstName: enteredFirstName,
      lastName: enteredLastName,
    };

    console.log('updatedPerson');
    console.log(updatedPerson);

    onUpdatePerson(updatedPerson);
  };

  return (
    <div>
      <h3>ComponentDataTestForm</h3>
      <div>
        <form onSubmit={submitHandler}>
          <div>
            <table className='table'>
              <tbody>
                <tr>
                  <td className='label-right'>First Name</td>
                  <td>
                    <input
                      type='text'
                      name='first_name'
                      ref={firstNameRef}
                      defaultValue={firstName ?? ''}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='label-right'>Last Name</td>
                  <td>
                    <input
                      type='text'
                      name='last_name'
                      ref={lastNameRef}
                      defaultValue={lastName ?? ''}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <button type='submit'>Save</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ComponentDataTestForm;
