import { useState, useContext, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import Notification from '../MUI/UI/Notification';
import StandardPageWrapper from '../Layout/StandardPageWrapper';

const PaymentSuccessful = () => {
  console.log('######## PaymentSuccessful ########');
  const params = useParams();
  const orderUuid = params.orderUuid;

  const history = useHistory();

  const authCtx = useContext(AuthContext);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const updateOrderPayment = useCallback(async () => {
    console.log('##### updateOrderPayment - orderUuid = ' + orderUuid + ' #####');

    // const keepGoing = false;
    // if (!keepGoing) {
    //   return;
    // }

    const apiURL = authCtx.baseApiUrl + 'orders/' + orderUuid;
    const apiMethod = 'PATCH';

    // const status = 'Scheduled';
    const statusNotes =
      'Order should be paid for at this point.   The Webhook should really be used to update the status and restaurant_stats.';

    const response = await fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        // status: status,
        // restaurant_status: status,
        status_notes: statusNotes,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseData = await response.json();
      console.log(responseData);

      history.replace('/team-dashboard/orders/' + orderUuid);
    } else {
      setIsError(true);
      console.log(' ** ERROR ** ');
      setNotify({
        isOpen: true,
        message: 'An Error occurred while saving Order Payment Information.',
        type: 'error',
      });
    }
    setIsLoading(false);
  }, [authCtx.baseApiUrl, authCtx.token, orderUuid, history]);

  // Commenting this out for now.
  // TODO:  Cleanup this code at some point.
  // useEffect(() => {
  //   // console.log(' -- useEffect - OrderDetails - authCtx.resetLogoutTimer -- ');
  //   authCtx.resetLogoutTimer();
  // }, [authCtx]);

  useEffect(() => {
    updateOrderPayment();
    setIsLoading(false);
  }, [updateOrderPayment]);

  return (
    <StandardPageWrapper title='Order Payment Successfully Processed' usePaper='true'>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      <Box sx={{ maxWidth: '120rem', p: '2rem' }}>
        <Typography align='center' component='p'>
          Your Order is being Scheduled...
        </Typography>
        {/* <Typography align='center' component='p'>
          You will be redirected to the Order Details page when complete.
        </Typography> */}
        {/* <Typography>orderUuid = {orderUuid}</Typography> */}
      </Box>

      <Notification notify={notify} setNotify={setNotify} />
    </StandardPageWrapper>
  );
};

export default PaymentSuccessful;
