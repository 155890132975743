import React from 'react';
import { Box, Typography } from '@mui/material';

const DeliveryLocationDetails = (props) => {
  const { deliveryLocation, orderContacts } = props;

  const noDeliveryLocationMessage = 'Not Set';
  const deliveryLocationName = deliveryLocation.name ? deliveryLocation.name : '';
  const deliveryLocationCity = deliveryLocation.city ? deliveryLocation.city : '';
  const deliveryLocationState = deliveryLocation.state ? deliveryLocation.state : '';
  const deliveryLocationZipcode = deliveryLocation.zipcode ? deliveryLocation.zipcode : '';

  let deliveryLocationAddressLine1 = deliveryLocation.address1 ? deliveryLocation.address1 : '';
  if (deliveryLocation.address2) {
    deliveryLocationAddressLine1 = deliveryLocationAddressLine1.concat(
      ' ',
      deliveryLocation.address2
    );
  }
  let deliveryLocationAddressLine2 =
    deliveryLocationCity + ', ' + deliveryLocationState + ', ' + deliveryLocationZipcode;

  return (
    <div className='condensed-content'>
      <Box sx={{ pt: '20px' }}>
        <Typography variant='h3' component='h3'>
          Delivery Location
        </Typography>
        {deliveryLocationName ? (
          <>
            <Typography>{deliveryLocationName}</Typography>
            <Typography>{deliveryLocationAddressLine1}</Typography>
            <Typography>{deliveryLocationAddressLine2}</Typography>
          </>
        ) : (
          <Typography>{noDeliveryLocationMessage}</Typography>
        )}

        {Array.isArray(orderContacts) && orderContacts.length > 0 ? (
          <Box component='div' sx={{ py: '2rem' }}>
            <Typography variant='h8'>
              {orderContacts.length === 1 ? 'Contact' : 'Contacts'}
            </Typography>

            {orderContacts.map((record, index) => (
              <Box key={index} sx={{ pb: '1rem' }}>
                <div>{record.name}</div>
                <div>{record.position}</div>
                <div>{record.phone}</div>
                <div>{record.email}</div>
              </Box>
            ))}
          </Box>
        ) : null}
      </Box>
    </div>
  );
};

export default DeliveryLocationDetails;
