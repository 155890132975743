import React from 'react';
import { Box, Typography } from '@mui/material';

const OrderDetailsWidget = ({ orderDetails }) => {
  const deliveryLocationAddressPart = orderDetails.deliveryLocation.address2
    ? orderDetails.deliveryLocation.address1 + ', ' + orderDetails.deliveryLocation.address2
    : orderDetails.deliveryLocation.address1;

  const deliveryLocationAddress =
    deliveryLocationAddressPart +
    ', ' +
    orderDetails.deliveryLocation.city +
    ', ' +
    orderDetails.deliveryLocation.state;
  return (
    <Box>
      <Typography>
        <Typography variant='labelInline' component='span'>
          Order Number
        </Typography>
        {orderDetails.orderNumber}
      </Typography>
      <Typography variant='label'>Delivery On</Typography>
      <Typography>{orderDetails.deliveryDateAndTimeForDisplay}</Typography>
      <Typography variant='label'>Delivery To</Typography>
      <Typography component='span'>{orderDetails.deliveryLocation.name}</Typography>
      <Typography>{deliveryLocationAddress}</Typography>
    </Box>
  );
};

export default OrderDetailsWidget;
