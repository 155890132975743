import React from 'react';
import { Box, Typography } from '@mui/material';

import FormControls from '../MUI/FormControls/FormControls';
// import classes from './OrderForm.module.css';

const RestaurantAutocompleteFormField = (props) => {
  // Testing.
  // console.count('##### RestaurantAutocompleteFormField - rendered - #####');

  const { restaurants, currentRestaurant, onChange, label, formFieldId } = props;

  // console.log('RestaurantAutocompleteFormField - currentRestaurant');
  // console.log(currentRestaurant);
  // console.log('RestaurantAutocompleteFormField - restaurants');
  // console.log(restaurants);

  // console.log('restaurants');
  // console.log(restaurants);

  // let restaurants = [];
  // if (!propsDeliveryLocations) {
  //   restaurants = propsDeliveryLocations;
  // }

  return restaurants ? (
    <Box component='div'>
      <Typography variant='formLabel' component='label'>
        {label}
        {/* Restaurant */}
      </Typography>

      <FormControls.AutocompleteControl
        id={formFieldId}
        // id='sourceRestaurant'
        // label='Source Restaurant'
        options={restaurants}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        // inputRef={deliveryLocationRef}
        // inputRef={props.deliveryLocationRef}
        // className={classes['mui-control']}
        // fullWidth={true}
        // value={props.restaurants[0]}
        // value={currentDeliveryLocationName}
        defaultValue={currentRestaurant}
        placeholder='Select a Restaurant'
        onChange={onChange}
        // InputLabelProps={{ shrink: true }}
        sx={{ minWidth: '50rem' }}
      />
    </Box>
  ) : null;
};

export default RestaurantAutocompleteFormField;
