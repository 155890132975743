import { useState } from 'react';
import { Box } from '@mui/material';

// import TeamSignupForm from '../components/Signup/TeamSignupForm';
import TeamSignupMessage from '../components/Signup/TeamSignupMessage';
import TeamSignupForm from '../components/MUI/Signup/TeamSignupForm';
// import TeamSignupChecklist from '../components/Signup/TeamSignupChecklist';
import TermsOfUse from '../components/GeneralContent/TermsOfUse';
import PrivacyPolicy from '../components/GeneralContent/PrivacyPolicy';

import Popup from '../components/MUI/UI/Popup';
import Notification from '../components/MUI/UI/Notification';

let popupTitle = 'Popup Title';

const TeamSignupPage = () => {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [openPopup, setOpenPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('none');

  const showTermsOfUseHandler = () => {
    popupTitle = 'Terms of Use';
    setPopupContent('terms');
    setOpenPopup(true);
  };

  const showPrivacyPolicyHandler = () => {
    popupTitle = 'Privacy Policy';
    setPopupContent('privacy');
    setOpenPopup(true);
  };

  return (
    <div className='page-container'>
      <Box className='content-container normal-content-area' sx={{ maxWidth: '80rem' }}>
        <TeamSignupMessage />
        <div>
          <TeamSignupForm
            setFocus='true'
            setNotify={setNotify}
            showTermsOfUse={showTermsOfUseHandler}
            showPrivacyPolicy={showPrivacyPolicyHandler}
          />
          {/* <TeamSignupChecklist /> */}
        </div>
      </Box>

      <Notification notify={notify} setNotify={setNotify} />
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} maxWidth='md' title={popupTitle}>
        {popupContent === 'terms' ? <TermsOfUse /> : null}
        {popupContent === 'privacy' ? <PrivacyPolicy /> : null}
      </Popup>
    </div>
  );
};

export default TeamSignupPage;
