import React from 'react';
import { Box } from '@mui/material';

import StandardPageWrapper from '../Layout/StandardPageWrapper';
import OrderForm from '../Order/OrderForm';
import FeaturedOrders from '../Orders/FeaturedOrders';

const NewOrder = () => {
  return (
    <StandardPageWrapper title='Place an Order'>
      <Box className='content-spacer'>
        <OrderForm mode='new' />
      </Box>
      <Box>
        <FeaturedOrders />
      </Box>
    </StandardPageWrapper>
  );
};

export default NewOrder;
