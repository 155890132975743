import { useState, useRef, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Box, Button, Paper, Tooltip, Typography } from '@mui/material';

import { servingSizeLabelForForm, priceDetailsLabelForForm } from '../../constants/global';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
import WorkflowMessage from '../UI/WorkflowMessage';
// import classes from '../CSS/standard-form.module.css';
import classes from '../CSS/standard-mui-form.module.css';
// import classes from './MenuItem.module.css';

const statusOptions = [
  { id: 'Active', title: 'Active' },
  { id: 'Inactive', title: 'Inactive' },
  { id: 'Deleted', title: 'Deleted' },
];

const MenuItemForm = (props) => {
  // console.log('##### MenuItemForm #####');

  let { menuItemUuid, menuItemDetails } = props;

  const newRecord = menuItemDetails ? false : true;

  // console.log('menuItemDetails');
  // console.log(menuItemDetails);
  // console.log('newRecord = ' + newRecord);

  const authCtx = useContext(AuthContext);

  const history = useHistory();

  // const userRole = authCtx.userRole;
  const dashboardUrl = authCtx.dashboardUrl;
  // const restaurantUuid = menuItemDetails ? menuItemDetails.restaurantUuid : '';
  const restaurantUuid = menuItemDetails
    ? menuItemDetails.restaurantUuid
    : authCtx.selectedRestaurantUuid;
  const baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath + restaurantUuid + '/';
  // const restaurantUuid = authCtx.selectedRestaurantUuid;
  // let baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath;
  // const orgFolder = menuItemDetails ? menuItemDetails.restaurantUuid : restaurantUuid;
  // baseApiUploadedImagesPath += orgFolder + '/';
  // const restaurantFolder = restaurantDetails.restaurantUuid;
  // baseApiUploadedImagesPath += restaurantFolder + '/';

  // if (menuItemDetails) {
  //   console.log('menuItemDetails.restaurantUuid = ' + menuItemDetails.restaurantUuid);
  // }
  // console.log('restaurantUuid = ' + restaurantUuid);
  // console.log('baseApiUploadedImagesPath = ' + baseApiUploadedImagesPath);

  if (!menuItemDetails) {
    menuItemDetails = {
      menuItemUuid: '',
      parentUuid: '',
      restaurantUuid: '',
      name: '',
      description: '',
      details: '',
      servingSize: '',
      feeds: 1,
      price: '',
      priceDetails: '',
      menuItemCategoryId: '',
      dietaryOptions: '',
      status: '',
      image: '',
    };
  }

  // console.log('** MenuItemForm ** menuItemDetails');
  // console.table(menuItemDetails);

  // console.log('menuItemDetails.status = ' + menuItemDetails.status);

  const [menuItemCategoryOptions, setMenuItemCategoryOptions] = useState([]);
  const [menuItemCategoryId, setMenuItemCategoryId] = useState(
    menuItemDetails.menuItemCategoryId ? menuItemDetails.menuItemCategoryId : ''
  );
  const [dietaryOptionsString, setDietaryOptionsString] = useState(
    menuItemDetails.dietaryOptions ? menuItemDetails.dietaryOptions : ''
  );
  const [menuItemStatus, setMenuItemStatus] = useState(
    menuItemDetails.status ? menuItemDetails.status : 'Active'
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');

  const nameRef = useRef();
  const descriptionRef = useRef();
  const detailsRef = useRef();
  const servingSizeRef = useRef();
  const feedsRef = useRef();
  const priceRef = useRef();
  const priceDetailsRef = useRef();
  // const categoryRef = useRef();
  // const statusRef = useRef();

  let submitButtonText = 'Save';
  const displayImageTooltipAndLink = false;

  const onFileChange = (event) => {
    // console.log('-- onFileChange -- ');

    let files = event.target.files;

    // console.log('files.length = ' + files.length);
    // console.log('files');
    // console.log(files);

    if (files.length === 0) {
      return;
    }

    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);

    const filename = files[0].name;

    setSelectedImageName(filename);

    fileReader.onload = (event) => {
      setSelectedImage(event.target.result);

      // Test Code.
      // submitHandler();
      // document.getElementById('submit-button').click();
    };

    // fileReader.onload = () => {
    //   setSelectedImage(fileReader.result);
    // }
  };

  // console.log('**** menuItemDetails.dietaryOptions = ' + menuItemDetails.dietaryOptions + ' **** ');
  // console.log('**** dietaryOptionsString = ' + dietaryOptionsString + ' **** ');

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, [isLoading]);

  useEffect(() => {
    const fetchDataGlobalMenuItemCategories = async () => {
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'globaldata/menuitemcategories', {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(' -- response.ok -- ');
        // console.log(responseData);

        const categories = responseData.data.active_menu_item_categories;

        // setMenuItemCategories(categories);

        // console.log('categories');
        // console.log(categories);

        const categoryOptions = categories.map((category) => {
          return {
            id: category.id,
            title: category.name,
          };
        });

        setMenuItemCategoryOptions(categoryOptions);

        // console.log('categoryOptions');
        // console.log(categoryOptions);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchDataGlobalMenuItemCategories().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token]);

  const submitHandler = (event) => {
    event.preventDefault();

    setNotify({
      isOpen: true,
      message: 'Sending Request...',
      type: 'info',
    });

    const enteredParentUuid = menuItemDetails.parentUuid;
    const enteredRestaurantUuid = restaurantUuid; //restaurantUuidRef.current.value;
    const enteredName = nameRef.current.value;
    const enteredDescription = descriptionRef.current.value;
    const enteredDetails = detailsRef.current.value;
    const enteredServingSize = servingSizeRef.current.value;
    const enteredFeeds = feedsRef.current.value;
    const enteredPrice = priceRef.current.value;
    const enteredPriceDetails = priceDetailsRef.current.value;
    // const enteredCategory = categoryRef.current.value;
    // const enteredStatus = statusRef.current.value;

    // console.log('enteredServingSize = ' + enteredServingSize);
    // console.log('enteredPriceDetails = ' + enteredPriceDetails);
    // console.log('dietaryOptionsString = ' + dietaryOptionsString);

    const keepGoing = true;

    if (!keepGoing) {
      console.log('keepGoing = ' + keepGoing);
      return;
    }

    let apiURL = '';
    let apiMethod = '';

    if (menuItemUuid !== '' && menuItemUuid !== undefined) {
      apiURL = authCtx.baseApiUrl + 'menuitems/' + menuItemUuid;
      apiMethod = 'PATCH';
    } else {
      apiURL = authCtx.baseApiUrl + 'menuitems';
      apiMethod = 'POST';
    }

    // console.log(' - apiURL = ' + apiURL);
    // console.log(' - apiMethod = ' + apiMethod);
    // console.log(' - enteredParentUuid = ' + enteredParentUuid);
    // console.log(' - enteredRestaurantUuid = ' + enteredRestaurantUuid);

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        parent_uuid: enteredParentUuid,
        restaurant_uuid: enteredRestaurantUuid,
        name: enteredName,
        description: enteredDescription,
        details: enteredDetails,
        serving_size: enteredServingSize,
        feeds: enteredFeeds,
        price: enteredPrice,
        price_details: enteredPriceDetails,
        menu_item_category_id: menuItemCategoryId,
        dietary_options: dietaryOptionsString,
        status: menuItemStatus,
        image: selectedImage,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      setIsLoading(false);

      if (res.ok) {
        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          // restaurantUuidRef.current.value = '';
          nameRef.current.value = '';
          descriptionRef.current.value = '';
          detailsRef.current.value = '';
          servingSizeRef.current.value = '';
          feedsRef.current.value = '';
          priceRef.current.value = '';
          priceDetailsRef.current.value = '';
          // categoryRef.current.value = '';
          // statusRef.current.value = '';

          const menuItemUuid = responseData.data.menu_items[0].menuItemUuid;

          if (newRecord) {
            history.replace(dashboardUrl + 'restaurants/menu-item/edit/' + menuItemUuid);
          } else {
            // history.replace(dashboardUrl + 'menu-items/details/' + menuItemUuid);
            history.replace(dashboardUrl + 'restaurants/menu-items/details/' + menuItemUuid);
          }
        });
      } else {
        return res.json().then((responseData) => {
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: 'Menu Item Not Saved',
              type: 'error',
            });
          }
        });
      }
    });
  };

  const categoryChangeHandler = (event) => {
    // console.log('### categoryChangeHandler ###');
    // console.log(event);

    setMenuItemCategoryId(event.target.value);
  };

  const dietaryOptionsChangeHandler = (options) => {
    // console.log('dietaryOptionsChangeHandler');
    // console.log('event.target.value = ' + event.target.value);
    // console.table(options);
    const dietaryOptionsString = options.join(', ');
    // console.log(' - dietaryOptionsString - ');
    // console.log(dietaryOptionsString);
    setDietaryOptionsString(dietaryOptionsString);
  };

  const statusChangeHandler = (event) => {
    setMenuItemStatus(event.target.value);
  };

  const backHandler = () => {
    // history.push(dashboardUrl + 'menu-items/' + restaurantUuid);
    history.push(dashboardUrl + 'menu-items/details/' + menuItemUuid);
  };

  // const deleteUploadedImageHander = () => {
  //   console.log('deleteUploadedImageHander');
  // };

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {/* {isError ? <WorkflowMessage type='error' /> : null} */}

      {!isLoading && !isError ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Paper elevation={4} sx={{ maxWidth: '120rem', width: '100%' }}>
            <form onSubmit={submitHandler}>
              {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}> */}
              <Box className={classes['form-container']}>
                <Box className={classes['grid-2']}>
                  <FormControls.InputControl
                    type='text'
                    name='name'
                    label='Name'
                    inputRef={nameRef}
                    defaultValue={menuItemDetails.name}
                    required
                    inputProps={{ maxLength: 50 }}
                    className={classes['mui-control']}
                  />

                  <FormControls.SelectControl
                    name='menu_item_category'
                    label='Menu Item Category *'
                    value={menuItemCategoryId}
                    onChange={categoryChangeHandler}
                    options={menuItemCategoryOptions}
                    required
                  />
                </Box>
                <FormControls.InputControl
                  type='text'
                  name='description'
                  label='Description'
                  inputRef={descriptionRef}
                  defaultValue={menuItemDetails.description}
                  className={classes['mui-control']}
                  multiline
                  minRows={3}
                  maxRows={12}
                />
                <FormControls.InputControl
                  type='text'
                  name='details'
                  label='Details'
                  inputRef={detailsRef}
                  defaultValue={menuItemDetails.details}
                  className={classes['mui-control']}
                  multiline
                  minRows={3}
                  maxRows={12}
                />

                <Box className={classes['grid-4']}>
                  <FormControls.InputControl
                    type='text'
                    name='servingSize'
                    label={servingSizeLabelForForm}
                    inputRef={servingSizeRef}
                    defaultValue={menuItemDetails.servingSize}
                    inputProps={{ maxLength: 20 }}
                    className={classes['mui-control']}
                  />
                  <FormControls.InputControl
                    type='text'
                    name='feeds'
                    label='Feeds ? People'
                    inputRef={feedsRef}
                    defaultValue={menuItemDetails.feeds}
                    required
                    inputProps={{ type: 'number', min: '1', max: '1000', step: '1' }}
                    className={classes['mui-control']}
                  />

                  <FormControls.InputControl
                    type='text'
                    name='price'
                    label='Price'
                    inputRef={priceRef}
                    defaultValue={menuItemDetails.price}
                    required
                    inputProps={{ type: 'number', min: '0', max: '19999.99', step: '0.01' }}
                    className={classes['mui-control']}
                  />
                  <FormControls.InputControl
                    type='text'
                    name='priceDetails'
                    label={priceDetailsLabelForForm}
                    inputRef={priceDetailsRef}
                    defaultValue={menuItemDetails.priceDetails}
                    inputProps={{ maxLength: 20 }}
                    className={classes['mui-control']}
                  />
                </Box>

                <Box className='flex-wrap-container'>
                  <FormControls.CheckboxListControl
                    name='dietary_options'
                    label='Dietary Flags'
                    value={dietaryOptionsString}
                    onChange={dietaryOptionsChangeHandler}
                  />

                  <Box sx={{ pt: '10px', width: '15rem' }}>
                    <FormControls.SelectControl
                      name='status'
                      label='Status'
                      value={menuItemStatus}
                      onChange={statusChangeHandler}
                      options={statusOptions}
                    />
                  </Box>
                </Box>
              </Box>

              <Box component='div' className={classes['actions-container']}>
                <FormControls.ButtonControl
                  text={submitButtonText}
                  type='submit'
                  color='primary'
                  id='submit-button'
                />
                <FormControls.ButtonControl
                  text='Cancel'
                  onClick={backHandler}
                  type='button'
                  color='neutral200'
                />
              </Box>

              {menuItemUuid && restaurantUuid ? (
                <Box align='center'>
                  <Box sx={{ my: '20px' }}>
                    {menuItemDetails.image && displayImageTooltipAndLink ? (
                      <>
                        <Tooltip title='Click to open the full size Header Image in a New Tab'>
                          <a
                            href={baseApiUploadedImagesPath + menuItemDetails.image}
                            target='_blank'
                            rel='noreferrer'
                          >
                            <img
                              src={baseApiUploadedImagesPath + menuItemDetails.image}
                              alt='Current Header'
                              className='uploaded-menu-item-image-cover'
                            />
                          </a>
                        </Tooltip>
                        <Box sx={{ pt: '10px' }}>
                          <Typography variant='caption'>
                            (Dimensions: 600px wide, 140px high)
                          </Typography>
                        </Box>
                      </>
                    ) : null}

                    {menuItemDetails.image && !displayImageTooltipAndLink ? (
                      <>
                        <img
                          src={baseApiUploadedImagesPath + menuItemDetails.image}
                          alt='Current Header'
                          className='uploaded-menu-item-image-cover'
                        />
                        {/* <Typography>{menuItemDetails.image}</Typography> */}
                        <Box sx={{ pt: '10px' }}>
                          <Typography variant='caption'>
                            (Dimensions: 600px wide, 140px high)
                          </Typography>
                        </Box>
                      </>
                    ) : null}

                    {!menuItemDetails.image ? (
                      <Box className='uploaded-menu-item-image-filler'>
                        <Typography variant='h2' component='h2'>
                          NO IMAGE
                        </Typography>{' '}
                        <Box sx={{ pt: '10px' }}>
                          <Typography variant='caption'>
                            (Dimensions: 600px wide, 140px high)
                          </Typography>
                        </Box>
                      </Box>
                    ) : null}
                  </Box>

                  <Box className='pb-20'>
                    <Button variant='contained' component='label' size='small'>
                      Select Image File
                      <input
                        hidden
                        accept='image/*'
                        multiple
                        type='file'
                        name='image'
                        onChange={onFileChange}
                      />
                    </Button>

                    {/* <Tooltip title='Click to Delete Uploaded Image'>
                        <Button
                          variant='contained'
                          type='button'
                          color='error'
                          sx={{ minWidth: '2rem', ml: '0.5rem' }}
                          onClick={deleteUploadedImageHander}
                        >
                          X
                        </Button>
                      </Tooltip> */}

                    {selectedImageName ? (
                      <Box align='center' sx={{ pt: '20px' }}>
                        <Alert severity='info' align='center' sx={{ width: '500px' }}>
                          Image file selected. Click the SAVE button to upload the image.
                        </Alert>
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              ) : (
                <Box align='center' sx={{ pb: '20px' }}>
                  <Alert severity='info' align='center' sx={{ width: '500px' }}>
                    Before you can add an image you must save the menu item first.
                  </Alert>
                </Box>
              )}
            </form>
          </Paper>
        </Box>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default MenuItemForm;
