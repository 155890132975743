import React, { useState, useEffect, useContext } from 'react';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import TestRestaurantList from './TestRestaurantList';
import TestRestaurantDetails from './TestRestaurantDetails';
import TestRestaurantForm from './TestRestaurantForm';

const TestRestaurants = () => {
  const authCtx = useContext(AuthContext);

  // const apiUrl = authCtx.baseApiUrl + 'restaurants/status/notnew';

  const [restaurants, setRestaurants] = useState([]);
  const [foodTypes, setFoodTypes] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState({});
  const [displayedComponent, setDisplayedComponent] = useState('List');
  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const selectedRestaurantUuid = authCtx.selectedRestaurantUuid;

  // console.log(
  //   ' ** TestRestaurants -- selectedRestaurantUuid = ' + selectedRestaurantUuid + ' ** '
  // );

  console.log('TestRestaurants - refreshData = ' + refreshData);

  const apiUrl = authCtx.baseApiUrl + 'restaurants';

  useEffect(() => {
    // console.log(' - useEffect - ');
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'globalfoodtypes', {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(responseData);

        const filteredRecords = responseData.data.global_food_types.filter(
          (record) => record.status === 'Active'
        );

        const transformedRecords = filteredRecords.map((record) => {
          return {
            id: record.name,
            title: record.name,
          };
        });

        setFoodTypes(transformedRecords);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'globaldata/leagues', {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(' -- response.ok -- ');
        // console.log(responseData.data.active_leagues);

        const transformedLeagues = responseData.data.active_leagues.map((league) => {
          return {
            name: league,
          };
        });

        // console.log('transformedLeagues');
        // console.table(transformedLeagues);

        setLeagues(transformedLeagues);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setRefreshData(false);

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(' -- response.ok -- ');
        // console.log(responseData.data.restaurants);

        const transformedRestaurants = responseData.data.restaurants.map((restaurant) => {
          return {
            restaurantUuid: restaurant.restaurantUuid,
            name: restaurant.name,
            description: restaurant.description,
            address: restaurant.address1,
            address1: restaurant.address1,
            address2: restaurant.address2,
            city: restaurant.city,
            state: restaurant.state,
            email: restaurant.email,
            phone: restaurant.phone,
            foodType: restaurant.foodType,
            leagueExperience: restaurant.leagueExperience,
            deliveryHours: restaurant.deliveryHours,
            deliveryHoursStart: restaurant.deliveryHoursStart,
            deliveryHoursEnd: restaurant.deliveryHoursEnd,
            orderLeadTime: restaurant.orderLeadTime,
            taxRate: restaurant.taxRate,
            status: restaurant.status,
          };
        });

        setRestaurants(transformedRestaurants);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    // console.log(' FETCHING DATA ');
    fetchData().catch(console.error);
  }, [apiUrl, authCtx.token, selectedRestaurantUuid, refreshData]);

  const showListHandler = () => {
    setSelectedRestaurant({});
    setDisplayedComponent('List');
  };

  const getSelectedRestaurant = (uuid) => {
    const restaurant = restaurants.filter((record) => record.restaurantUuid === uuid);
    // console.log('getSelectedRestaurant - restaurant');
    // console.table(restaurant);
    return restaurant[0];
  };

  const showDetailsHandler = (uuid) => {
    // console.log('-- showDetailsHandler - uuid = ' + uuid + ' --');
    const restaurant = getSelectedRestaurant(uuid);
    // console.log('showDetailsHandler - restaurant');
    // console.table(restaurant);
    setSelectedRestaurant(restaurant);
    setDisplayedComponent('Details');
  };

  const editDetailsHandler = (uuid) => {
    // console.log('-- editDetailsHandler - uuid = ' + uuid + ' --');
    const restaurant = getSelectedRestaurant(uuid);
    // console.log('editDetailsHandler - restaurant');
    // console.table(restaurant);
    setSelectedRestaurant(restaurant);
    setDisplayedComponent('Form');
  };

  const addNewHandler = () => {
    // console.log('-- addNewHandler --');
    setSelectedRestaurant({});

    // console.log('addNewHandler - selectedRestaurant');
    // console.table(selectedRestaurant);

    setDisplayedComponent('Form');
  };

  const reloadDataHandler = () => {
    setSelectedRestaurant({});

    // console.log('reloadDataHandler - selectedRestaurant');
    // console.table(selectedRestaurant);
    setRefreshData(true);
    // console.log('reloadDataHandler - refreshData = ' + refreshData);
    showListHandler();

    // setTimeout(showListHandler, 200);
    // setTimeout(setRefreshData(true), 500);
  };

  return (
    <>
      <div className='button-holder'>
        {/* {displayedComponent !== 'Form' ||
        (displayedComponent === 'Form' && selectedRestaurant.restaurantUuid) ? ( */}
        {displayedComponent !== 'Form' ? (
          <button onClick={addNewHandler} className='small'>
            Add New
          </button>
        ) : null}

        {selectedRestaurant.restaurantUuid && displayedComponent === 'Details' ? (
          <button
            onClick={() => editDetailsHandler(selectedRestaurant.restaurantUuid)}
            className='small'
          >
            Edit
          </button>
        ) : null}

        <button onClick={showListHandler} className='small'>
          Locations
        </button>
      </div>

      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' message='Something did not work...' /> : null}
      {!isLoading && restaurants.length === 0 ? (
        <WorkflowMessage type='error' message='No Restaurants Found....' />
      ) : null}

      {displayedComponent === 'List' && !isLoading && !isError && restaurants.length > 0 ? (
        <TestRestaurantList
          restaurants={restaurants}
          showDetails={showDetailsHandler}
          editDetails={editDetailsHandler}
        />
      ) : null}

      {displayedComponent === 'Details' && !isLoading && !isError && restaurants.length > 0 ? (
        <TestRestaurantDetails restaurantDetails={selectedRestaurant} />
      ) : null}

      {displayedComponent === 'Form' && !isLoading && !isError ? (
        <TestRestaurantForm
          restaurantDetails={selectedRestaurant}
          foodTypes={foodTypes}
          leagues={leagues}
          showList={showListHandler}
          reloadData={reloadDataHandler}
        />
      ) : null}
    </>
  );
};

export default TestRestaurants;
