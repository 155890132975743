import React, { useContext, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, InputAdornment, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import AuthContext from '../../store/auth-context';
import * as Constants from '../../helpers/Constants';
import { displayListFilterSelect } from '../../helpers/FilterListHelperFunctions';
import WorkflowMessage from '../UI/WorkflowMessage';
import FormControls from '../MUI/FormControls/FormControls';
import classes from '../CSS/styled-table.module.css';

const TeamList = () => {
  // console.log('######## TeamList ########');
  const authCtx = useContext(AuthContext);
  const dashboardUrl = authCtx.dashboardUrl;

  const history = useHistory();
  const leagueFilterRef = useRef();
  const statusFilterRef = useRef();
  const searchRef = useRef();

  const [teams, setTeams] = useState([]);
  const [displayedTeams, setDisplayedTeams] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const defaultStatusFilter = 'Active';
  const pageTitle = 'Teams';

  useEffect(() => {
    setIsLoading(true);

    // declare the async data fetching function
    const fetchData = async () => {
      // get the data from the api
      const response = await fetch(authCtx.baseApiUrl + 'teams', {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.log(' -- response.ok -- ');
        // console.log(responseData);
        // console.log(responseData.data.teams);

        const transformedTeams = responseData.data.teams.map((team) => {
          return {
            teamUuid: team.teamUuid,
            name: team.name,
            teamInstructions: team.teamInstructions,
            league: team.league,
            logoImage: team.logoImage,
            status: team.status,
          };
        });

        setTeams(transformedTeams);

        if (defaultStatusFilter === 'Show All') {
          setDisplayedTeams(transformedTeams);
        } else {
          const filteredRecords = transformedTeams.filter(
            (record) => record.status === defaultStatusFilter
          );
          setDisplayedTeams(filteredRecords);
        }

        setLeagues(responseData.data.leagues);
      } else {
        // If a 401 is returned then there is an issue with the session (most likely).
        if (responseData.statusCode === 401) {
          history.push(dashboardUrl + 'sessionexpired');
        }

        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, history, dashboardUrl]);

  const displayTableHeader = () => {
    return (
      <tr>
        <th>Name</th>
        <th>League</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
    );
  };

  const displayTableBody = () => {
    return displayedTeams.map((team, index) => (
      <tr key={index}>
        <td style={{ width: '100%' }}>{team.name}</td>
        <td>{team.league}</td>
        <td>{team.status}</td>
        <td>
          <div className={classes['table-actions-container']}>
            <button onClick={() => displayDetailsHandler(`${team.teamUuid}`)} className='small'>
              Details
            </button>
            <button onClick={() => editTeamHandler(`${team.teamUuid}`)} className='small'>
              Edit
            </button>
          </div>
        </td>
      </tr>
    ));
  };

  const displayDetailsHandler = (teamUuid) => {
    // console.log('### displayDetailsHandler ###');
    history.replace(`${dashboardUrl}teams/details/${teamUuid}`);
  };

  const editTeamHandler = (teamUuid) => {
    // console.log('### editTeamHandler ###');
    history.replace(`${dashboardUrl}teams/edit/${teamUuid}`);
  };

  const clearFilterHandler = () => {
    leagueFilterRef.current.value = 'Show All';
    statusFilterRef.current.value = defaultStatusFilter;

    filterDisplayedRecords();
  };

  const filterDisplayedRecords = () => {
    let leagueFilter = leagueFilterRef.current.value;
    let statusFilter = statusFilterRef.current.value;
    let searchValue = searchRef.current ? searchRef.current.value.toLowerCase() : '';

    if (leagueFilter === 'Show All' && statusFilter === 'Show All' && searchValue === '') {
      setDisplayedTeams(teams);
    } else {
      let filteredRecords = teams;

      if (leagueFilter !== 'Show All') {
        filteredRecords = filteredRecords.filter((record) => record.league === leagueFilter);
      }

      if (statusFilter !== 'Show All') {
        filteredRecords = filteredRecords.filter((record) => record.status === statusFilter);
      }

      if (searchValue !== '') {
        filteredRecords = filteredRecords.filter(
          (record) =>
            (record.name !== null && record.name.toLowerCase().includes(searchValue)) ||
            (record.league !== null && record.league.toLowerCase().includes(searchValue))
        );
      }

      setDisplayedTeams(filteredRecords);
    }
  };

  const clearSearchHandler = () => {
    if (searchRef.current) {
      searchRef.current.value = '';
    }
    filterDisplayedRecords();
  };

  return (
    <>
      {!isLoading && !isError ? (
        <Box className='title-and-button-holder'>
          <Typography variant='h1' component='h1'>
            {pageTitle}
          </Typography>
        </Box>
      ) : null}

      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}
      {!isLoading && !isError && teams.length === 0 ? (
        <WorkflowMessage type='nodata' message='No Teams Found...' />
      ) : null}

      {!isLoading && teams.length > 0 && (
        <>
          {!isLoading && !isError ? (
            <Box className='mb-20'>
              <Box>
                <label htmlFor='search'>
                  Search <span className='small-text'>(by Name, League)</span>
                </label>
              </Box>

              <Box
                component='div'
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  minWidth: '300px',
                  height: '40px',
                  // ml: '10px',
                  // border: '1px solid red',
                }}
              >
                <FormControls.InputControl
                  type='text'
                  name='search'
                  id='search'
                  // label='Search'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ flexGrow: '1', mr: '2rem' }}
                  onChange={filterDisplayedRecords}
                  inputRef={searchRef}
                />
                <FormControls.ButtonControl
                  name='clearSearch'
                  text='Clear'
                  variant='contained'
                  size='small'
                  endIcon={<SearchIcon />}
                  onClick={clearSearchHandler}
                  sx={{ p: '2rem', fontWeight: 'bold', height: '4rem' }}
                />
              </Box>
            </Box>
          ) : null}

          <Box className='button-holder'>
            <Box>
              <Box>
                <label htmlFor='filter-by-league'>League</label>
              </Box>
              {displayListFilterSelect(
                'filter-by-league',
                leagueFilterRef,
                filterDisplayedRecords,
                leagues
              )}
            </Box>
            <Box>
              <Box>
                <label htmlFor='filter-by-status'>Status</label>
              </Box>
              {displayListFilterSelect(
                'filter-by-status',
                statusFilterRef,
                filterDisplayedRecords,
                Constants.STATUS_ARRAY_WITH_NEW,
                defaultStatusFilter
              )}
            </Box>
            <Box className='align-bottom'>
              <button className='small' onClick={clearFilterHandler}>
                Clear Filters
              </button>
            </Box>
          </Box>

          <div className='table-holder'>
            <table className={`${classes['styled-table']} ${classes['full-width']}`}>
              <thead>{displayTableHeader()}</thead>
              <tbody>{displayTableBody()}</tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default TeamList;
