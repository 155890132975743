import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import { useFormatDateAndTime } from '../Hooks/useFormatDateAndTime';
import * as Constants from '../../helpers/Constants';
import FeaturedOrderCard from './FeaturedOrderCard';

// import classes from './FeaturedOrderCard.module.css';
import classes from '../CSS/Card.module.css';

const FeaturedOrders = () => {
  const authCtx = useContext(AuthContext);
  const dashboardUrl = authCtx.dashboardUrl;
  const userRole = authCtx.userRole;

  const history = useHistory();

  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const { FormatTimeForDisplay, FormatLongDateForDisplay } = useFormatDateAndTime();

  const imageFolder = Constants.IMAGE_FOLDER_PATH;
  const baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath;
  const defaultHeaderImage = imageFolder + 'restaurants/default_header_image.jpg';

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const apiUrl = authCtx.baseApiUrl + 'orders/status/Scheduled';

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.table(responseData.data.orders);
        // console.log('responseData.data');
        // console.log(responseData.data);

        const transformedRecords = responseData.data.orders.map((order) => {
          return {
            orderUuid: order.orderUuid,
            orderNumber: order.orderNumber,
            deliveryLocationName: order.deliveryLocationName,
            deliveryAddress1: order.deliveryAddress1,
            deliveryAddress2: order.deliveryAddress2,
            deliveryAddress: order.deliveryAddress2
              ? order.deliveryAddress1 + ', ' + order.deliveryAddress2
              : order.deliveryAddress1,
            deliveryCity: order.deliveryCity,
            deliveryState: order.deliveryState,
            deliveryZipcode: order.deliveryZipcode,
            deliveryDate:
              order.deliveryDate !== '' && order.deliveryDate !== null ? order.deliveryDate : '',
            deliveryTime:
              order.deliveryTime !== '' && order.deliveryTime !== null ? order.deliveryTime : '',
            deliveryDateFormatted: order.deliveryDate
              ? FormatLongDateForDisplay(order.deliveryDate)
              : '',
            deliveryTimeFormatted: order.deliveryTime
              ? FormatTimeForDisplay(order.deliveryTime)
              : '',
            subtotal:
              order.subtotal && order.additionalCharges
                ? +order.subtotal.toString().replace(/[","]/g, '') +
                  +order.additionalCharges.toString().replace(/[","]/g, '')
                : order.subtotal
                ? +order.subtotal.toString().replace(/[","]/g, '')
                : '0.00',
            additionalCharges: order.additionalCharges
              ? +order.additionalCharges.toString().replace(/[","]/g, '')
              : '0.00',
            // additionalCharges: order.additionalCharges
            //   ? order.additionalCharges.replaceAll(',', '')
            //   : '0.00',
            status: order.status,
            restaurantStatus: order.restaurantStatus,
            orderStatus:
              userRole === 'Vendor' &&
              order.status === 'Placed' &&
              order.restaurantStatus === 'Accepted'
                ? 'New'
                : order.status === 'Placed' && order.restaurantStatus === 'Rejected'
                ? 'Rejected'
                : order.status === 'Canceled' || order.restaurantStatus === 'Canceled'
                ? 'Canceled'
                : userRole === 'User'
                ? order.status
                : userRole === 'Vendor'
                ? order.restaurantStatus
                : order.status,
            displayStatus:
              order.status === 'Placed' && order.restaurantStatus === 'Accepted'
                ? 'Payment Required'
                : order.status === 'Placed' && order.restaurantStatus === 'Rejected'
                ? 'Rejected'
                : order.status === 'Canceled' || order.restaurantStatus === 'Canceled'
                ? 'Canceled'
                : userRole === 'User'
                ? order.status
                : userRole === 'Vendor'
                ? order.restaurantStatus
                : order.status,
            restaurantName: order.restaurantName,
            headerImage:
              order.headerImage === '' || order.headerImage === null
                ? defaultHeaderImage
                : baseApiUploadedImagesPath + order.restaurantUuid + '/' + order.headerImage,
            teamName: order.teamName,
            calculatedTotalFormatted: order.calculatedTotalFormatted,
            deliveryDateAndTimeForDisplay: order.deliveryDateAndTimeForDisplay,
          };
        });

        // console.log('transformedRecords');
        // console.log(transformedRecords);

        setRecords(transformedRecords);
      } else {
        // If a 401 is returned then there is an issue with the session (most likely).
        if (responseData.statusCode === 401) {
          history.push(dashboardUrl + 'sessionexpired');
        }

        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [
    authCtx.baseApiUrl,
    authCtx.token,
    userRole,
    FormatLongDateForDisplay,
    FormatTimeForDisplay,
    defaultHeaderImage,
    baseApiUploadedImagesPath,
    dashboardUrl,
    history,
  ]);

  return (
    <Box>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {!isLoading && records.length > 0 ? (
        <>
          <Box sx={{ pb: '1rem' }}>
            <Typography variant='h1' className='header'>
              Upcoming Orders
            </Typography>
          </Box>

          <Box className={classes['order-card-holder']}>
            {records.map((record, index) => (
              <FeaturedOrderCard orderDetails={record} key={index} />
            ))}
          </Box>
        </>
      ) : null}

      {!isLoading && records.length === 0 ? (
        <WorkflowMessage type='nodata' message='You do not have any Scheduled Orders.' />
      ) : null}
    </Box>
  );
};

export default FeaturedOrders;
