import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, List } from '@mui/material';

import AuthContext from '../../store/auth-context';
import OrderContext from '../../store/order-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import OrderMessages from '../OrderMessages/OrderMessages';
import ListItemWithIcon from '../UI/ListItemWithIcon';
import classes from '../CSS/Checkout.module.css';

const SpecialRequests = () => {
  const authCtx = useContext(AuthContext);
  const orderCtx = useContext(OrderContext);

  const orderDetails = orderCtx.orderDetails;

  const history = useHistory();

  const reviewOrderHandler = () => {
    // console.log('reviewOrderHandler');
    history.replace('/checkout/review-order');
  };

  return (
    <StandardPageWrapper title='Message Your Fueling Partner' usePaper='true'>
      <Box className={classes['content-holder']}>
        <Box>
          <List>
            <ListItemWithIcon textContent='Please use this messaging system to communicate with your fueling partner.' />
            <ListItemWithIcon textContent='You can indicate any additional details of your order such as special requests or set up information.' />
            <ListItemWithIcon textContent='You will be notified by email when a new message is sent.' />
          </List>

          <OrderMessages
            orderUuid={orderDetails.orderUuid}
            status={orderDetails.status}
            userRole={authCtx.userRole}
            // checkout={true}
            mode='special-requests'
          />
        </Box>
      </Box>

      <Box sx={{ pt: '20px' }}>
        <Button variant='contained' size='custom' color='accent' onClick={reviewOrderHandler}>
          Review Order
        </Button>
      </Box>
    </StandardPageWrapper>
  );
};

export default SpecialRequests;
