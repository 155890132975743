import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import WorkflowMessage from '../UI/WorkflowMessage';
import classes from '../CSS/styled-table.module.css';

const VendorDeliveryLocationsList = (props) => {
  // console.log(' ***** VendorDeliveryLocationsList ***** ');
  const { restaurantUuid, restaurantState, returnTo } = props;

  const authCtx = useContext(AuthContext);
  const dashboardUrl = authCtx.dashboardUrl;

  // console.log('restaurantUuid = ' + restaurantUuid);
  // console.log('restaurantState = ' + restaurantState);
  // console.log('returnTo = ' + returnTo);

  const [deliveryLocations, setDeliveryLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const response = await fetch(
        authCtx.baseApiUrl + 'restaurantdeliverylocations/' + restaurantUuid,
        {
          headers: {
            Authorization: authCtx.token,
            'Content-Type': 'application/json',
          },
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        const transformedLocations = responseData.data.delivery_locations
          .filter((location) => location.willDeliverTo === 1)
          .map((location) => {
            return {
              deliveryLocationUuid: location.deliveryLocationUuid,
              name: location.name,
              description: location.description,
              leagues: location.leagues,
              address:
                location.city !== '' && location.state !== ''
                  ? location.city + ', ' + location.state
                  : location.city !== ''
                  ? location.city
                  : location.state !== ''
                  ? location.state
                  : '',
              address1: location.address1,
              address2: location.address2,
              city: location.city,
              state: location.state,
              email: location.email,
              phone: location.phone,
              deliveryInstructions: location.delivery_instructions,
              status: location.status,
              isGlobal: location.isGlobal,
              willDeliverTo: location.willDeliverTo,
            };
          });

        setDeliveryLocations(transformedLocations);

        // console.log('transformedLocations');
        // console.log(transformedLocations);
      } else {
        // If a 401 is returned then there is an issue with the session (most likely).
        if (responseData.statusCode === 401) {
          history.push(dashboardUrl + 'sessionexpired');
        }

        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, restaurantUuid, history, dashboardUrl]);

  const displayTableHeader = () => {
    return (
      <tr>
        <th>Name</th>
        <th>Address</th>
        <th>City</th>
        <th>State</th>
        <th>Leagues</th>
      </tr>
    );
  };

  const displayTableBody = () => {
    return deliveryLocations.map((record, index) => (
      <tr key={index}>
        <td>{record.name}</td>
        <td>
          {record.address1}
          {record.address2 ? ', ' + record.address2 : ''}
        </td>
        <td>{record.city}</td>
        <td>{record.state}</td>
        <td>{record.leagues}</td>
      </tr>
    ));
  };

  const editDeliveryLocationsHandler = () => {
    history.push(
      dashboardUrl +
        'restaurants/delivery-locations-form/' +
        restaurantUuid +
        '/' +
        restaurantState +
        '/' +
        returnTo
    );
  };

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      <div className='content-container'>
        {!isLoading && !isError ? (
          <Box className='title-and-button-holder'>
            <Typography variant='h1' component='h1'>
              Delivery Locations
            </Typography>

            <Box className='button-holder-no-pb'>
              <Button
                variant='contained'
                size='custom'
                color='accent'
                onClick={editDeliveryLocationsHandler}
              >
                Edit
              </Button>
            </Box>
          </Box>
        ) : null}

        {!isLoading && deliveryLocations.length > 0 && (
          <div className='table-holder'>
            <table className={`${classes['styled-table']} ${classes['full-width']}`}>
              <thead>{displayTableHeader()}</thead>
              <tbody>{displayTableBody()}</tbody>
            </table>
          </div>
        )}

        {!isLoading && !isError && deliveryLocations.length === 0 ? (
          <Typography variant='h2' component='h2' color='error'>
            No Delivery Locations Found
          </Typography>
        ) : null}

        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </>
  );
};

export default VendorDeliveryLocationsList;
