import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Paper, Typography } from '@mui/material';

// import classes from './FeaturedOrderCard.module.css';
import classes from '../CSS/Card.module.css';

const FeaturedOrderCard = (props) => {
  const { orderDetails } = props;
  const history = useHistory();
  const displayDateAndTimeTogether = true;
  const displayOrderNumberAndTotalTogether = true;

  const showOrderDetailsHandler = () => {
    // console.log('showOrderDetailsHandler - orderDetails.orderUuid = ' + orderDetails.orderUuid);
    history.replace('/team-dashboard/orders/' + orderDetails.orderUuid);
  };

  return (
    <Box className={classes['card']}>
      <Paper elevation={4} sx={{ p: '20px' }}>
        {/* <Box className={classes['header']}>Scheduled Order</Box> */}

        {orderDetails.headerImage ? (
          <Box className={classes['header-image']}>
            <img src={orderDetails.headerImage} alt='Restaurant Header' />
          </Box>
        ) : (
          <Box className={classes['header']}>Scheduled Order</Box>
        )}

        <Box className={classes['content-holder']}>
          {/* <Typography variant='h3'>Restaurant Name</Typography> */}
          <Typography variant='h1'>{orderDetails.restaurantName}</Typography>
        </Box>

        {displayDateAndTimeTogether ? (
          <Box className={classes['content-holder']}>
            <Typography variant='h3'>Delivery Date</Typography>
            <Typography component='div'>{orderDetails.deliveryDateAndTimeForDisplay}</Typography>
          </Box>
        ) : (
          <>
            <Box className={classes['content-holder']}>
              <Typography variant='h3'>Delivery Date</Typography>
              <Typography component='div'>{orderDetails.deliveryDateFormatted}</Typography>
            </Box>
            <Box className={classes['content-holder']}>
              <Typography variant='h3'>Delivery Time</Typography>
              <Typography component='div'>{orderDetails.deliveryTimeFormatted}</Typography>
            </Box>
          </>
        )}

        <Box className={classes['content-holder']}>
          <Typography variant='h3'>Delivery Location</Typography>
          <Typography component='div'>{orderDetails.deliveryLocationName}</Typography>
          <Typography component='div'>{orderDetails.deliveryAddress}</Typography>
          <Typography component='div'>
            {orderDetails.deliveryCity +
              ', ' +
              orderDetails.deliveryState +
              ', ' +
              orderDetails.deliveryZipcode}
          </Typography>
        </Box>

        {displayOrderNumberAndTotalTogether ? (
          <Box className='flex-column-space-between'>
            <Box className={classes['content-holder']}>
              <Typography variant='h3'>Order Number</Typography>
              <Typography component='div'>{orderDetails.orderNumber}</Typography>
            </Box>

            <Box className={classes['content-holder']}>
              <Typography variant='h3'>Order Total</Typography>
              <Typography component='div'>{orderDetails.calculatedTotalFormatted}</Typography>
            </Box>
          </Box>
        ) : (
          <>
            <Box className={classes['content-holder']}>
              <Typography variant='h3'>Order Number</Typography>
              <Typography component='div'>{orderDetails.orderNumber}</Typography>
            </Box>
            <Box className={classes['content-holder']}>
              <Typography variant='h3'>Order Total</Typography>
              <Typography component='div'>{orderDetails.calculatedTotalFormatted}</Typography>
            </Box>
          </>
        )}

        <Box className={classes['footer']}>
          <Button variant='contained' size='small' color='accent' onClick={showOrderDetailsHandler}>
            Order Details
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default FeaturedOrderCard;
