import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Box } from '@mui/material';

import AuthContext from '../../store/auth-context';
import classes from '../CSS/TopMenu.module.css';

const pathStart = '/vendor-dashboard/';

const TeamMenu = () => {
  const authCtx = useContext(AuthContext);
  const multipleRestaurants = authCtx.multipleRestaurants;

  // console.log('TeamMenu - multipleRestaurants = ' + multipleRestaurants);

  return (
    <Box component='div' className={classes['top-menu-container']}>
      <nav className={classes['top-nav']}>
        <ul>
          <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}orders`}>
              Orders
            </NavLink>
          </li>
          {/* <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}receipts`}>
              Receipts
            </NavLink>
          </li> */}
          <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}reviews`}>
              Reviews
            </NavLink>
          </li>
          {/* <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}locations`}>
              Locations
            </NavLink>
          </li> */}

          <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}menu-items`}>
              Menu
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}delivery-locations`}>
              Delivery Locations
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}vendor-profile`}>
              Profile
            </NavLink>
          </li>
          {multipleRestaurants ? (
            <li>
              <NavLink activeClassName={classes.active} to={`${pathStart}restaurants`}>
                Restaurants
              </NavLink>
            </li>
          ) : null}
          {/* <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}payment-account`}>
              Payment Account
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}test-profile`}>
              Test Profile
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}account`}>
              Account
            </NavLink>
          </li> */}
        </ul>
      </nav>
    </Box>
  );
};

export default TeamMenu;
