import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';

// import AuthContext from '../../store/auth-context';
import OrderContext from '../../store/order-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import OrderDetails from './OrderDetails';
import DeliveryLocationDetails from './DeliveryLocationDetails';
import RestaurantDetails from './RestaurantDetails';
import classes from '../CSS/Checkout.module.css';

const CheckoutComplete = () => {
  // const authCtx = useContext(AuthContext);
  const orderCtx = useContext(OrderContext);

  // const orderUuid = orderCtx.orderDetails;
  // const deliveryLocation = orderCtx.deliveryLocation;
  // const restaurant = orderCtx.restaurant;

  return (
    <div className='page-container-after-top-menu'>
      <StandardPageWrapper title='Checkout Complete'>
        <Box className={classes['content-holder']}>
          <Typography variant='body1' component='p'>
            You have successfully completed Checkout.
          </Typography>
          <Typography variant='body1' component='p'>
            Your Order has been placed with the Vendor and it has been confirmed and paid for.
          </Typography>
          <Typography variant='body1' component='p'>
            We make no promises, but you can always contact the Vendor with any issues or questions.
            They will do the best they can to help you out.
          </Typography>

          <Box sx={{ display: 'block' }} className={classes['details-section']}>
            <OrderDetails orderDetails={orderCtx.orderDetails} displayDeliveryAddress={false} />
            <DeliveryLocationDetails deliveryLocation={orderCtx.deliveryLocation} />
            <RestaurantDetails restaurant={orderCtx.restaurant} />
          </Box>
        </Box>
      </StandardPageWrapper>
    </div>
  );
};

export default CheckoutComplete;
