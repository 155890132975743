import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Box } from '@mui/material';

import AdminMenu from '../components/AdminDashboard/AdminMenu';
import Orders from '../components/Orders/Orders';
import Users from '../components/AdminDashboard/Users';
import Teams from '../components/AdminDashboard/Teams';
import Leagues from '../components/AdminDashboard/Leagues';
import Restaurants from '../components/AdminDashboard/Restaurants';
import CopyMenuForm from '../components/CopyMenu/CopyMenuForm';
// import VendorReferrals from '../components/AdminDashboard/VendorReferrals';
import Referrals from '../components/AdminDashboard/Referrals';
// import DeliveryLocations from '../components/AdminDashboard/DeliveryLocations';
import DeliveryLocations from '../components/DeliveryLocations/DeliveryLocations';
import VendorDeliveryLocationsForm from '../components/DeliveryLocations/VendorDeliveryLocationsForm';
import DeliveryHours from '../components/DeliveryHours/DeliveryHours';
import DeliveryFees from '../components/DeliveryFees/DeliveryFees';
import DeliveryFee from '../components/DeliveryFees/DeliveryFee';
import FoodTypes from '../components/AdminDashboard/FoodTypes';
import MenuItemCategories from '../components/AdminDashboard/MenuItemCategories';
import Account from '../components/AdminDashboard/Account';
import Dashboard from '../components/AdminDashboard/Dashboard';
// import UserList from '../components/Admin/UserList';
// import UserDetails from '../components/Admin/UserDetails';
// import UserEdit from '../components/Admin/UserEdit';
// import OrganizationList from '../components/Organization/OrganizationList';
// import OrganizationDetails from '../components/Organization/OrganizationDetails';
// import OrganizationEdit from '../components/Organization/OrganizationEdit';
import AdminTeamApproval from '../components/Admin/AdminTeamApproval';
import AdminRestaurantApproval from '../components/Admin/AdminRestaurantApproval';
import MenuItemAdd from '../components/Menu/MenuItemAdd';
import MenuItemList from '../components/Menu/MenuItemList';
import MenuItemDetails from '../components/Menu/MenuItemDetails';
import MenuItemEdit from '../components/Menu/MenuItemEdit';
import PaymentAccount from '../components/Stripe/PaymentAccount';

// import Leagues from '../components/Admin/Leagues';

import GlobalMenuItemCategories from '../components/GlobalMenuItemCategories/GlobalMenuItemCategories';

import DeliveryLocationAdd from '../components/DeliveryLocations/DeliveryLocationAdd';
import DeliveryLocationEdit from '../components/DeliveryLocations/DeliveryLocationEdit';

import SessionExpired from './SessionExpired';

import Testing from '../components/Testing/Testing';

const AdminDashboardPage = () => {
  const pathStart = '/admin-dashboard/';

  return (
    <>
      <AdminMenu />
      <Box component='div' className='page-container-after-top-menu'>
        <Switch>
          <Route path={pathStart + 'sessionexpired'} exact>
            <SessionExpired />
          </Route>
          <Route path={pathStart + 'orders'} exact>
            <Orders />
          </Route>
          <Route path={pathStart + 'orders/:orderUuid'}>
            <Orders />
          </Route>
          <Route path={pathStart + 'users'} exact>
            <Users />
          </Route>
          {/* <Route path={pathStart + 'users'} exact>
            <UserList />
          </Route> */}
          {/* <Route path={pathStart + 'users/edit/:userUuid'}>
            <UserEdit />
          </Route> */}
          {/* <Route path={pathStart + 'users/details/:userUuid'}>
            <UserDetails />
          </Route> */}

          <Route path={pathStart + 'teams'} exact>
            <Teams mode='list' />
          </Route>
          <Route path={pathStart + 'teams/details/:teamUuid'} exact>
            <Teams mode='details' />
          </Route>
          <Route path={pathStart + 'teams/edit/:teamUuid'} exact>
            <Teams mode='edit' />
          </Route>

          <Route path={pathStart + 'team/approval/:teamUuid'}>
            <AdminTeamApproval />
          </Route>

          <Route path={pathStart + 'leagues'} exact>
            <Leagues />
          </Route>

          <Route path={pathStart + 'restaurants'} exact>
            <Restaurants mode='list' />
          </Route>
          <Route path={pathStart + 'restaurants/details/:restaurantUuid'} exact>
            <Restaurants mode='details' />
          </Route>
          <Route path={pathStart + 'restaurants/edit/:restaurantUuid'} exact>
            <Restaurants mode='edit' />
          </Route>
          <Route path={pathStart + 'restaurants/edit-images/:restaurantUuid'} exact>
            <Restaurants mode='editImages' />
          </Route>

          <Route path={pathStart + 'restaurants/copy-menu'} exact>
            <CopyMenuForm />
          </Route>

          <Route path={pathStart + 'restaurants/menu-items/:restaurantUuid'} exact>
            <MenuItemList />
          </Route>
          <Route path={pathStart + 'restaurants/menu-item/add'} exact>
            <MenuItemAdd />
          </Route>
          <Route path={pathStart + 'restaurants/menu-items/details/:menuItemUuid'} exact>
            <MenuItemDetails />
          </Route>
          <Route path={pathStart + 'restaurants/menu-item/edit/:menuItemUuid'} exact>
            <MenuItemEdit />
          </Route>
          <Route path={pathStart + 'restaurants/delivery-hours/:restaurantUuid'} exact>
            <DeliveryHours mode='form' />
          </Route>
          <Route path={pathStart + 'restaurants/delivery-fees/:restaurantUuid'} exact>
            <DeliveryFees />
          </Route>
          <Route path={pathStart + 'restaurants/delivery-fees/:restaurantUuid'} exact>
            <DeliveryFees mode='list' />
          </Route>
          {/* <Route path={pathStart + 'restaurants/delivery-fee/:restaurantUuid'} exact>
            <DeliveryFees mode='form' />
          </Route> */}
          <Route
            path={pathStart + 'restaurants/delivery-fee/:restaurantUuid/:deliveryFeeUuid'}
            exact
          >
            <DeliveryFee />
          </Route>

          <Route
            path={
              pathStart +
              'restaurants/delivery-locations-form/:restaurantUuid/:restaurantState/:returnTo'
            }
            exact
          >
            <VendorDeliveryLocationsForm />
          </Route>

          {/* Moved the menu-item Routes up to the restaurants section so that the Restaurants Menu Item will be selected when working on Menu related things. */}

          <Route path={pathStart + 'menu-items/:restaurantUuid'} exact>
            <MenuItemList />
          </Route>
          <Route path={pathStart + 'menu-item/add'} exact>
            <MenuItemAdd />
          </Route>
          <Route path={pathStart + 'menu-item/edit/:menuItemUuid'}>
            <MenuItemEdit />
          </Route>
          <Route path={pathStart + 'menu-items/details/:menuItemUuid'}>
            <MenuItemDetails />
          </Route>
          <Route path={pathStart + 'restaurant/approval/:restaurantUuid'}>
            <AdminRestaurantApproval />
          </Route>

          <Route path={pathStart + 'payment-account/:restaurantUuid'}>
            <PaymentAccount />
          </Route>

          <Route path={pathStart + 'vendor-referrals'} exact>
            <Referrals />
          </Route>

          <Route path={pathStart + 'delivery-locations'} exact>
            <DeliveryLocations mode='list' />
          </Route>
          <Route path={pathStart + 'delivery-locations/add'} exact>
            <DeliveryLocationAdd dashboardUrl={pathStart} />
          </Route>
          <Route path={pathStart + 'delivery-locations/edit/:deliveryLocationUuid'}>
            <DeliveryLocationEdit mode='edit' />
          </Route>
          <Route path={pathStart + 'delivery-locations/details/:deliveryLocationUuid'}>
            <DeliveryLocations mode='details' />
          </Route>

          {/* <Route path={pathStart + 'delivery-fees/:restaurantUuid'} exact>
            <DeliveryFees />
          </Route> */}

          <Route path={pathStart + 'food-types'} exact>
            <FoodTypes />
          </Route>

          <Route path={pathStart + 'menu-item-categories'} exact>
            <MenuItemCategories />
          </Route>
          <Route path={pathStart + 'menu-item-categories'} exact>
            <GlobalMenuItemCategories />
          </Route>

          <Route path={pathStart + 'account'}>
            <Account />
          </Route>

          <Route path={pathStart + 'dashboard'}>
            <Dashboard />
          </Route>

          <Route path={pathStart + 'testing'}>
            <Testing />
          </Route>
          <Route path='*'>
            <Redirect to={pathStart + 'dashboard'} />
          </Route>
        </Switch>
      </Box>
    </>
  );
};

export default AdminDashboardPage;
