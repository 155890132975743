import React, { useContext, useRef, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import Notification from '../MUI/UI/Notification';
import Popup from '../MUI/UI/Popup';
import ExpenseReportOptions from './ExpenseReportOptions';
import ExpenseReport from './ExpenseReport';
import PrintExpenseReport from '../ReactPdf/PrintExpenseReport';

import { useFormatDateAndTime } from '../Hooks/useFormatDateAndTime';
import { useCommonFunctions } from '../Hooks/useCommonFunctions';

const ExpenseReportForm = () => {
  const authCtx = useContext(AuthContext);

  const teamName = authCtx.orgName;

  const [startDate, setStartDate] = useState([]);
  const [endDate, setEndDate] = useState([]);

  const [orders, setOrders] = useState([]);
  const [ordersTaxTotal, setOrdersTaxTotal] = useState([]);
  const [ordersTotal, setOrdersTotal] = useState([]);

  const [ordersTipTotal, setOrdersTipTotal] = useState([]);
  const [ordersDeliveryFeeTotal, setOrdersDeliveryFeeTotal] = useState([]);
  const [ordersTeamChargeTotal, setOrdersTeamChargeTotal] = useState([]);

  const [displayOptions, setDisplayOptions] = useState([]);

  const [printOrderButtonDisabled, setPrintOrderButtonDisabled] = useState(true);
  const [displayNoReportMessage, setDisplayNoReportMessage] = useState(false);

  const [openPopupPrintExpenseReport, setOpenPopupPrintExpenseReport] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const startDateRef = useRef();
  const endDateRef = useRef();

  // const history = useHistory();

  const { FormatTimeForDisplay } = useFormatDateAndTime();
  const { addOrRemove } = useCommonFunctions();

  // Options
  const displayOptionalFields = false;

  // Get the current date.
  const today = new Date();
  const todaysDate = new Date(today.getTime() - today.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0];
  // console.log('todaysDate = ' + todaysDate);

  const firstDayInPreviousMonth = (yourDate) => {
    let d = new Date(yourDate);

    return new Date(d.getFullYear(), d.getMonth() - 1, 1).toISOString().split('T')[0];

    // TODO - Uncomment the code above when testing is over.
    // This code will return a date from last year.
    // let d_test = new Date(d.getFullYear() - 1, d.getMonth(), d.getDay());
    // return new Date(d_test.getFullYear(), d_test.getMonth() - 1, 1).toISOString().split('T')[0];
  };

  const lastDayInPreviousMonth = (yourDate) => {
    let d = new Date(yourDate);
    let lastDate = new Date(d.getFullYear(), d.getMonth(), 0).getDate();
    return new Date(d.getFullYear(), d.getMonth() - 1, lastDate).toISOString().split('T')[0];
  };

  const defaultStartDate = firstDayInPreviousMonth(todaysDate);
  const defaultEndDate = lastDayInPreviousMonth(todaysDate);

  // console.log('defaultStartDate = ' + defaultStartDate);
  // console.log('defaultEndDate = ' + defaultEndDate);

  const runReportHandler = (event) => {
    event.preventDefault();

    setIsLoading(true);
    setIsError(false);

    const startDate = startDateRef.current.value ? startDateRef.current.value : defaultStartDate;
    const endDate = endDateRef.current.value ? endDateRef.current.value : defaultEndDate;

    setStartDate(startDate);
    setEndDate(endDate);

    // let apiUrl = authCtx.baseApiUrl + 'orders';
    const apiUrl = authCtx.baseApiUrl + 'expensereport/' + startDate + '/' + endDate;
    // console.log('apiUrl = ' + apiUrl);

    fetch(apiUrl, {
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      setIsLoading(false);

      if (response.ok) {
        return response.json().then((responseData) => {
          // console.log(' *** SUCCESS *** ');
          // console.log(responseData);
          // console.log(responseData.data.orders);

          // setOrders(responseData.data.orders);

          const transformedRecords = responseData.data.orders.map((order) => {
            return {
              orderUuid: order.orderUuid,
              orderNumber: order.orderNumber,
              deliveryLocation: order.deliveryLocationName,
              deliveryAddress:
                order.deliveryCity !== '' &&
                order.deliveryCity !== null &&
                order.deliveryState !== '' &&
                order.deliveryState !== null
                  ? order.deliveryCity + ', ' + order.deliveryState
                  : order.deliveryCity !== ''
                  ? order.deliveryCity
                  : order.deliveryState !== ''
                  ? order.deliveryState
                  : '',
              deliveryCity: order.deliveryCity,
              deliveryState: order.deliveryState,
              deliveryDate: order.deliveryDate,
              deliveryTime: order.deliveryTime ? FormatTimeForDisplay(order.deliveryTime) : '',
              // deliveryTime: order.deliveryTime,
              paymentDate: order.datePayment,
              paymentDateDateOnly: order.datePaymentDateOnly,
              subtotal: order.subtotal ? +order.subtotal.toString().replace(/[","]/g, '') : '0.00',
              orderItemsSubtotal: order.orderItemsSubtotal
                ? +order.orderItemsSubtotal.toString().replace(/[","]/g, '')
                : '0.00',
              additionalCharges: order.additionalCharges
                ? +order.additionalCharges.toString().replace(/[","]/g, '')
                : '0.00',
              status: order.status,
              restaurantStatus: order.restaurantStatus,
              restaurantName: order.restaurantName,
              restaurantAddress: order.restaurantAddress,
              teamName: order.teamName,
              deliveryFeeFormatted: order.deliveryFeeFormatted,
              tipFormatted: order.tipFormatted,
              teamChargeFormatted: order.teamChargeFormatted,
              orderItemsSubtotalFormatted: order.orderItemsSubtotalFormatted,
              additionalChargesFormatted: order.additionalChargesFormatted,
              taxFormatted: order.taxFormatted,
              subtotalFormatted: order.subtotalFormatted,
              calculatedTotalFormatted: order.calculatedTotalFormatted,
              totalFormatted: order.totalFormatted,
            };
          });

          // console.log(transformedRecords);

          setOrders(transformedRecords);

          if (transformedRecords.length > 0) {
            disablePrinting(false);
            setDisplayNoReportMessage(false);
          } else {
            disablePrinting(true);
            setDisplayNoReportMessage(true);
          }

          // disablePrinting(false);

          // Get the orders tax total and orders total for the summary.
          setOrdersTaxTotal(responseData.data.ordersTaxTotalFormatted);
          setOrdersTotal(responseData.data.ordersTotalFormatted);
          setOrdersDeliveryFeeTotal(responseData.data.ordersDeliveryFeeTotalFormatted);
          setOrdersTipTotal(responseData.data.ordersTipTotalFormatted);
          setOrdersTeamChargeTotal(responseData.data.ordersTeamChargeTotalFormatted);
        });
      } else {
        return response.json().then((responseData) => {
          // setIsError(true);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: 'Expence Report not created.',
              type: 'error',
            });
          }
        });
      }
    });

    setIsLoading(false);
  };

  const disablePrinting = (value) => {
    // console.log('### disablePrinting --- value = ' + value + ' ###');
    setPrintOrderButtonDisabled(value);

    if (value) {
      document.getElementById('print-button').classList.add('disabled');
    } else {
      document.getElementById('print-button').classList.remove('disabled');
    }
  };

  const optionsUpdatedHandler = (option) => {
    // console.log('### optionsUpdatedHandler --- option = ' + option + ' ###');
    // console.log('--- display options ---');
    // console.log(displayOptions);

    const updatedArray = addOrRemove(displayOptions, option);

    setDisplayOptions(updatedArray);

    // console.log('--- UPDATED - display options ---');
    // console.log(updatedArray);
  };

  const printReport = () => {
    // console.log('### printReport ###');

    setOpenPopupPrintExpenseReport(true);
  };

  return (
    <Box>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      <Paper elevation={4} sx={{ maxWidth: '120rem', width: '100%', p: '10px', m: '0 auto' }}>
        <Box component='form' onSubmit={runReportHandler}>
          <Box className='flex-between-container' sx={{ alignItems: 'flex-end' }}>
            <Box className='flex-container-side-by-side-small-gap'>
              <Box>
                <Typography variant='formLabel' component='label'>
                  Start Date
                </Typography>
                <TextField
                  type='date'
                  id='start_date'
                  size='small'
                  inputRef={startDateRef}
                  defaultValue={defaultStartDate}
                />
              </Box>

              <Box>
                <Typography variant='formLabel' component='label'>
                  End Date
                </Typography>
                <TextField
                  type='date'
                  id='end_date'
                  size='small'
                  inputRef={endDateRef}
                  defaultValue={defaultEndDate}
                />
              </Box>
            </Box>

            {displayOptionalFields ? (
              <Box>
                <ExpenseReportOptions
                  displayOptions={displayOptions}
                  optionsUpdated={optionsUpdatedHandler}
                />
              </Box>
            ) : null}

            <Box>
              <Box className='flex-container-side-by-side-tiny-gap'>
                <Button variant='contained' color='primary' type='submit'>
                  Run Report
                </Button>

                <button
                  id='print-button'
                  disabled={printOrderButtonDisabled}
                  // variant='contained'
                  // color='success'
                  type='button'
                  className='green disabled'
                  onClick={printReport}
                >
                  Print Report
                </button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>

      {!isLoading && orders && orders.length > 0 ? (
        <ExpenseReport
          orders={orders}
          displayOptions={displayOptions}
          startDate={startDate}
          endDate={endDate}
          ordersTaxTotal={ordersTaxTotal}
          ordersTotal={ordersTotal}
          ordersTipTotal={ordersTipTotal}
          ordersDeliveryFeeTotal={ordersDeliveryFeeTotal}
          ordersTeamChargeTotal={ordersTeamChargeTotal}
        />
      ) : null}

      {!isLoading && !isError && displayNoReportMessage ? (
        <Box className='mt-40 content-centered'>
          <Typography variant='h2' component='h2' color='error'>
            No Orders were found that match the selected criteria.
          </Typography>
        </Box>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />

      <Popup
        openPopup={openPopupPrintExpenseReport}
        setOpenPopup={setOpenPopupPrintExpenseReport}
        maxWidth='md'
        title={'Expense Report'}
      >
        <PrintExpenseReport
          orders={orders}
          team={teamName}
          startDate={startDate}
          endDate={endDate}
          ordersTotalLabel={'Expense Report Totals (' + startDate + ' - ' + endDate + ')'}
          ordersTaxTotal={ordersTaxTotal}
          ordersTotal={ordersTotal}
        />
      </Popup>
    </Box>
  );
};

export default ExpenseReportForm;
