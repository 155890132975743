import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import WorkflowMessage from '../UI/WorkflowMessage';
import RestaurantDetailsTable from '../Restaurant/RestaurantDetailsTable';
import RestaurantDetailsCard from '../Restaurant/RestaurantDetailsCard';
import RestaurantApprovalForm from './RestaurantApprovalForm';
import { useFormatDateAndTime } from '../Hooks/useFormatDateAndTime';
// import classes from '../CSS/styled-table.module.css';

const AdminRestaurantApproval = () => {
  const authCtx = useContext(AuthContext);

  const userRole = authCtx.userRole;
  const dashboardUrl = authCtx.dashboardUrl;

  const [restaurantDetails, setRestaurantDetails] = useState([]);

  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const history = useHistory();
  const params = useParams();
  const restaurantUuid = params.restaurantUuid;

  const { FormatDateAndTimeForDisplay, FormatTimeForDisplay } = useFormatDateAndTime();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'restaurants/' + restaurantUuid, {
        headers: {
          Authorization: authCtx.token,
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.log(responseData);

        const returnedRestaurantDetails = {
          restaurantUuid: responseData.data.restaurants[0].restaurantUuid,
          name: responseData.data.restaurants[0].name,
          description: responseData.data.restaurants[0].description,
          address1: responseData.data.restaurants[0].address1,
          address2: responseData.data.restaurants[0].address2,
          city: responseData.data.restaurants[0].city ? responseData.data.restaurants[0].city : '',
          state: responseData.data.restaurants[0].state
            ? responseData.data.restaurants[0].state
            : '',
          zipcode: responseData.data.restaurants[0].zipcode,
          email: responseData.data.restaurants[0].email,
          phone: responseData.data.restaurants[0].phone,
          foodType: responseData.data.restaurants[0].foodType,
          leagueExperience: responseData.data.restaurants[0].leagueExperience,
          deliveryFees: responseData.data.restaurants[0].deliveryFees,
          deliveryHours: responseData.data.restaurants[0].deliveryHours,
          // deliveryHoursStart: responseData.data.restaurants[0].deliveryHoursStart,
          // deliveryHoursEnd: responseData.data.restaurants[0].deliveryHoursEnd,
          // deliveryHoursStartFormatted: responseData.data.restaurants[0].deliveryHoursStart
          //   ? FormatTimeForDisplay(responseData.data.restaurants[0].deliveryHoursStart)
          //   : '',
          // deliveryHoursEndFormatted: responseData.data.restaurants[0].deliveryHoursEnd
          //   ? FormatTimeForDisplay(responseData.data.restaurants[0].deliveryHoursEnd)
          //   : '',
          // deliveryHours: responseData.data.restaurants[0].deliveryHours,
          // orderLeadTime: responseData.data.restaurants[0].orderLeadTime,
          orderLeadTime: responseData.data.restaurants[0].orderLeadTime
            ? responseData.data.restaurants[0].orderLeadTime + ' Hours'
            : '',
          taxRate: responseData.data.restaurants[0].taxRate,
          status: responseData.data.restaurants[0].status,
          dateAdded: responseData.data.restaurants[0].dateAdded,
          dateUpdated: responseData.data.restaurants[0].dateUpdated,
        };
        // console.log(' -- returnedRestaurantDetails -- ');
        // console.log(returnedRestaurantDetails);

        if (returnedRestaurantDetails.dateAdded !== '') {
          returnedRestaurantDetails.dateAdded = FormatDateAndTimeForDisplay(
            returnedRestaurantDetails.dateAdded
          );
        }
        if (returnedRestaurantDetails.dateUpdated !== '') {
          returnedRestaurantDetails.dateUpdated = FormatDateAndTimeForDisplay(
            returnedRestaurantDetails.dateUpdated
          );
        }

        setRestaurantDetails(returnedRestaurantDetails);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [
    authCtx.baseApiUrl,
    authCtx.token,
    restaurantUuid,
    FormatDateAndTimeForDisplay,
    FormatTimeForDisplay,
    refreshData,
  ]);

  const backHandler = () => {
    history.push(dashboardUrl + 'dashboard');
  };

  const backFromFormHandler = () => {
    // console.log('backFromFormHandler');
    setRefreshData(true);
  };

  let baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath;
  // const orgFolder = authCtx.orgDetails.orgUuid;
  const orgFolder = restaurantDetails.restaurantUuid;
  baseApiUploadedImagesPath += orgFolder + '/';

  const displayDetailsTable = false;

  return (
    <StandardPageWrapper>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      <Box className='title-and-button-holder'>
        <Box>
          <Typography variant='h1' component='h1'>
            Restaurant Approval
          </Typography>
        </Box>
        <Box className='button-holder-no-pb'>
          <Button variant='contained' status='small' color='accent' onClick={backHandler}>
            Back
          </Button>
        </Box>
      </Box>

      {!isLoading && !isError && (
        <Box>
          <RestaurantDetailsCard
            mode='approval'
            restaurantDetails={restaurantDetails}
            baseApiUploadedImagesPath={baseApiUploadedImagesPath}
            reloadData={backFromFormHandler}
          />

          {displayDetailsTable ? (
            <RestaurantDetailsTable
              userRole={userRole}
              details={restaurantDetails}
              purpose='Approval'
            />
          ) : null}

          <RestaurantApprovalForm restaurantUuid={restaurantUuid} />
        </Box>
      )}
    </StandardPageWrapper>
  );
};

export default AdminRestaurantApproval;
