import { useState, useRef, useEffect, useContext } from 'react';
import { Box, Paper } from '@mui/material';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
import classes from '../CSS/standard-mui-form.module.css';

const ReferVendorForm = (props) => {
  const authCtx = useContext(AuthContext);

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const nameRef = useRef();
  const contactNameRef = useRef();
  const contactPositionRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const websiteRef = useRef();
  const locationRef = useRef();
  const notesRef = useRef();

  useEffect(() => {
    nameRef.current.focus();
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();

    setNotify({
      isOpen: true,
      message: 'Sending Request...',
      type: 'info',
    });

    const enteredName = nameRef.current.value;
    const enteredContactName = contactNameRef.current.value;
    const enteredContactPosition = contactPositionRef.current.value;
    const enteredEmail = emailRef.current.value;
    const enteredPhone = phoneRef.current.value;
    const enteredWebsite = websiteRef.current.value;
    const enteredLocation = locationRef.current.value;
    const enteredNotes = notesRef.current.value;

    // Todo:  Add Validation.

    fetch(authCtx.baseApiUrl + 'restaurantreferrals', {
      method: 'POST',
      body: JSON.stringify({
        name: enteredName,
        contact_name: enteredContactName,
        contact_position: enteredContactPosition,
        email: enteredEmail,
        phone: enteredPhone,
        website: enteredWebsite,
        location: enteredLocation,
        notes: enteredNotes,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        setNotify({
          isOpen: true,
          message: 'Vendor Referral Successfully Submitted.  Thank You',
          type: 'success',
        });

        nameRef.current.value = '';
        contactNameRef.current.value = '';
        contactPositionRef.current.value = '';
        emailRef.current.value = '';
        phoneRef.current.value = '';
        websiteRef.current.value = '';
        locationRef.current.value = '';
        notesRef.current.value = '';

        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);
        });
      } else {
        return res.json().then((responseData) => {
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: 'Vendor Referral Failed',
              type: 'error',
            });
          }
        });
      }
    });
  };

  return (
    <Box>
      <Paper elevation={4} className='content-card'>
        <form onSubmit={submitHandler}>
          <Box className={classes['form-container']}>
            <FormControls.InputControl
              type='text'
              name='name'
              label='Restaurant Name'
              inputRef={nameRef}
              required
              className={classes['mui-control']}
            />

            <Box className={classes['grid-2']}>
              <FormControls.InputControl
                type='text'
                name='contact_name'
                label='Contact Name'
                inputRef={contactNameRef}
                required
                className={classes['mui-control']}
              />
              <FormControls.InputControl
                type='text'
                name='contact_position'
                label='Contact Position'
                inputRef={contactPositionRef}
                className={classes['mui-control']}
              />
            </Box>

            <Box className={classes['grid-2']}>
              <FormControls.InputControl
                type='text'
                name='phone'
                label='Phone'
                inputRef={phoneRef}
                required
                className={classes['mui-control']}
              />
              <FormControls.InputControl
                type='email'
                name='email'
                label='Email'
                inputRef={emailRef}
                className={classes['mui-control']}
              />
            </Box>

            <Box className={classes['grid-2']}>
              <FormControls.InputControl
                type='text'
                name='location'
                label='Location'
                inputRef={locationRef}
                className={classes['mui-control']}
              />
              <FormControls.InputControl
                type='text'
                name='website'
                label='Website'
                inputRef={websiteRef}
                className={classes['mui-control']}
              />
            </Box>

            <FormControls.InputControl
              type='text'
              name='notes'
              label='Notes'
              inputRef={notesRef}
              className={classes['mui-control']}
              multiline
              minRows={3}
              maxRows={12}
            />
          </Box>

          <Box component='div' className={classes['actions-container']}>
            <FormControls.ButtonControl text='Refer A Vendor' type='submit' />
          </Box>
        </form>
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default ReferVendorForm;
