import { useState, useContext, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import Notification from '../MUI/UI/Notification';
import StandardPageWrapper from '../Layout/StandardPageWrapper';

const AccountLinkRefresh = () => {
  const history = useHistory();

  const authCtx = useContext(AuthContext);

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const selectedRestaurantUuid = authCtx.selectedRestaurantUuid;

  // console.log(
  //   ' ##### AccountLinkRefresh -- selectedRestaurantUuid = ' + selectedRestaurantUuid + ' ##### '
  // );

  const checkOnboardingComplete = useCallback(async () => {
    // console.log(
    //   '##### checkOnboardingComplete - selectedRestaurantUuid = ' +
    //     selectedRestaurantUuid +
    //     ' #####'
    // );

    const apiURL = authCtx.baseApiUrl + 'paymentaccount/' + selectedRestaurantUuid;
    const apiMethod = 'GET';

    const response = await fetch(apiURL, {
      method: apiMethod,
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseData = await response.json();
      // console.log(responseData);

      const onboardingComplete = responseData.data.onboarding_complete;
      // console.log('##### onboardingComplete', onboardingComplete + ' #####');

      // const redirectAfter = onboardingComplete;

      setIsLoading(false);

      if (onboardingComplete) {
        history.replace('/vendor-dashboard/vendor-profile/' + selectedRestaurantUuid);
      } else {
        history.replace('/vendor-dashboard/payment-account/' + selectedRestaurantUuid);
      }

      // if (redirectAfter) {
      //   history.replace('/vendor-dashboard/vendor-profile/' + selectedRestaurantUuid);
      // }
    } else {
      setIsError(true);
      console.log(' ##### ERROR ##### ');
      setNotify({
        isOpen: true,
        message:
          'An Error occurred while checking to see if the Payment Account Onboarding has been completed.',
        type: 'error',
      });
    }
    setIsLoading(false);
  }, [authCtx.baseApiUrl, authCtx.token, selectedRestaurantUuid, history]);

  // Commenting this out for now.
  // TODO:  Cleanup this code at some point.
  // useEffect(() => {
  //   // console.log(' -- useEffect - OrderDetails - authCtx.resetLogoutTimer -- ');
  //   authCtx.resetLogoutTimer();
  // }, [authCtx]);

  useEffect(() => {
    setTimeout(checkOnboardingComplete, 15000);
    // checkOnboardingComplete();
    // setIsLoading(false);
  }, [checkOnboardingComplete]);

  return (
    <StandardPageWrapper title='Payment Account Onboarding' usePaper='true'>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      <Box sx={{ maxWidth: '120rem', p: '2rem' }}>
        <Typography align='center' component='p'>
          Checking to see if Payment Account Onboarding has been successfully completed...
        </Typography>
        <Typography align='center' component='p'>
          This should take less than a minute.
        </Typography>
        <Typography align='center' component='p'>
          .....
        </Typography>
      </Box>

      <Notification notify={notify} setNotify={setNotify} />
    </StandardPageWrapper>
  );
};

export default AccountLinkRefresh;
