import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

import Popup from '../MUI/UI/Popup';
import useAdditionalCharge from '../Hooks/useAdditionalCharge';
import AdditionalChargesForm from './AdditionalChargeForm';

let popupTitle = 'Additional Charge';

const AdditionalCharge = () => {
  const [openPopup, setOpenPopup] = useState(false);

  const { additionalCharges } = useAdditionalCharge();

  return (
    <div>
      <Typography variant='h5' align='center' sx={{ pb: '2rem' }}>
        Additional Charges
      </Typography>
      <Box>
        <button
          className='small'
          onClick={() => {
            setOpenPopup(true);
          }}
        >
          Add Additional Charge
        </button>
      </Box>
      <div>
        {additionalCharges ? (
          <Box sx={{ pt: '2rem' }}>
            {additionalCharges.map((record, index) => (
              <Box sx={{ pb: '1rem' }} key={index}>
                <div>{record.name}</div>
                <div>{record.description}</div>
                <div>{record.priceFormatted}</div>
              </Box>
            ))}
          </Box>
        ) : null}
      </div>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} maxWidth='md' title={popupTitle}>
        <AdditionalChargesForm />
      </Popup>
    </div>
  );
};

export default AdditionalCharge;
