import StandardPageWrapper from '../Layout/StandardPageWrapper';
import GlobalLeagues from '../GlobalLeagues/GlobalLeagues';

const Leagues = () => {
  return (
    <StandardPageWrapper title='Leagues'>
      <GlobalLeagues />
    </StandardPageWrapper>
  );
};

export default Leagues;
