import React from 'react';
import { useParams } from 'react-router-dom';

import StandardPageWrapper from '../Layout/StandardPageWrapper';
// import AdminTeams from '../Admin/AdminTeams';

import TeamList from '../Team/TeamList';
import Team from '../Team/Team';

// console.log('AdminDashboard - Teams');

const Teams = (props) => {
  const { mode } = props;
  const params = useParams();
  const teamUuid = params.teamUuid ? params.teamUuid : '';

  return (
    <StandardPageWrapper>
      {/* <AdminTeams /> */}
      {/* <div>mode = {mode}</div> */}
      {/* <div>teamUuid = {teamUuid}</div> */}

      {mode === 'list' ? <TeamList /> : null}
      {mode !== 'list' && teamUuid ? <Team teamUuid={teamUuid} mode={mode} /> : null}
    </StandardPageWrapper>
  );
};

export default Teams;
