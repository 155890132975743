import { useState, useRef, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Paper } from '@mui/material';

import AuthContext from '../../store/auth-context';
import OrderContext from '../../store/order-context';
import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
import classes from '../CSS/standard-form.module.css';

const DeliveryInstructionsForm = () => {
  // console.log('######## DeliveryInstructionsForm ########');
  const authCtx = useContext(AuthContext);
  const orderCtx = useContext(OrderContext);

  const history = useHistory();

  // const userRole = authCtx.userRole;
  const teamUuid = authCtx.orgDetails.orgUuid;
  const orderUuid = orderCtx.orderDetails.orderUuid;
  const orderDeliveryLocation = orderCtx.deliveryLocation;

  if (!orderUuid) {
    history.replace('/team-dashboard/orders');
  }

  // let deliveryLocationUuid = '';
  // if (orderDeliveryLocation) {
  //   deliveryLocationUuid = orderDeliveryLocation.deliveryLocationUuid;
  // }

  const deliveryLocationDeliveryInstructions = orderDeliveryLocation.deliveryInstructions
    ? orderDeliveryLocation.deliveryInstructions
    : '';

  // console.log('deliveryLocationDeliveryInstructions');
  // console.log(deliveryLocationDeliveryInstructions);

  const orderDeliveryInstructions = orderCtx.orderDetails.deliveryInstructions
    ? orderCtx.orderDetails.deliveryInstructions
    : '';
  const orderTeamInstructions = orderCtx.orderDetails.teamInstructions
    ? orderCtx.orderDetails.teamInstructions
    : '';

  const deliveryInstructions = orderDeliveryInstructions
    ? orderDeliveryInstructions
    : deliveryLocationDeliveryInstructions;
  // const [deliveryInstructions, setDeliveryInstructions] = useState(
  //   orderDeliveryInstructions ? orderDeliveryInstructions : deliveryLocationDeliveryInstructions
  // );
  const [teamInstructions, setTeamInstructions] = useState(orderTeamInstructions);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  // console.log('orderDetails');
  // console.table(orderDetails);
  // console.log('orderDeliveryInstructions');
  // console.table(orderDeliveryInstructions);
  // console.log('orderTeamInstructions');
  // console.table(orderTeamInstructions);

  const deliveryInstructionsRef = useRef();
  const teamInstructionsRef = useRef();

  // const submitButtonText = 'Continue Checkout';

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const response = await fetch(authCtx.baseApiUrl + 'teams/' + teamUuid, {
        headers: {
          Authorization: authCtx.token,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(responseData);

        const teamInstructions = responseData.data.team.teamInstructions
          ? responseData.data.team.teamInstructions
          : null;

        setTeamInstructions(teamInstructions);

        // We don't want to save Team Instructions to the team record anymmore.
        // setSaveTeamInstructions(teamInstructions ? false : true);
      } else {
        console.log('$$$$$ ERROR in Team Details $$$$$');

        setIsError(true);
      }
      setIsLoading(false);
    };

    if (!orderTeamInstructions) {
      fetchData().catch(console.error);
    } else {
      setIsLoading(false);
    }
  }, [authCtx.baseApiUrl, authCtx.token, teamUuid, orderTeamInstructions]);

  useEffect(() => {
    if (deliveryInstructionsRef.current) {
      deliveryInstructionsRef.current.focus();
    }
  }, []);

  const saveDeliveryInstructionsForOrder = (
    enteredDeliveryInstructions,
    enteredTeamInstructions
  ) => {
    const apiURL = authCtx.baseApiUrl + 'orders/' + orderUuid;
    const apiMethod = 'PATCH';

    // console.log(' - apiURL = ' + apiURL);
    // console.log(' - apiMethod = ' + apiMethod);

    // console.log('** saveDeliveryInstructionsForOrder **');
    // console.log('enteredDeliveryInstructions');
    // console.log(enteredDeliveryInstructions);

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        delivery_instructions: enteredDeliveryInstructions,
        team_instructions: enteredTeamInstructions,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        setNotify({
          isOpen: true,
          message: 'Delivery Instructions Successfully Saved.',
          type: 'success',
        });

        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          const orderDetails = responseData.data.orders[0];
          // console.log('orderDetails');
          // console.log(orderDetails);

          orderCtx.setOrderDetails(orderDetails);
        });
      } else {
        return res.json().then((responseData) => {
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: 'Delivery Instructions Not Saved',
              type: 'error',
            });
          }
        });
      }
    });
  };

  const submitHandler = (event) => {
    event.preventDefault();

    setNotify({
      isOpen: true,
      message: 'Sending Request...',
      type: 'info',
    });

    let wasDataUpdated = false;
    const enteredDeliveryInstructions = deliveryInstructionsRef.current.value;
    const enteredTeamInstructions = teamInstructionsRef.current.value;

    // console.log('enteredDeliveryInstructions');
    // console.log(enteredDeliveryInstructions);

    // Todo:  Add Validation.

    wasDataUpdated = enteredDeliveryInstructions !== orderDeliveryInstructions; //deliveryInstructions;
    if (!wasDataUpdated) {
      wasDataUpdated = enteredTeamInstructions !== teamInstructions;
    }

    // console.log('enteredDeliveryInstructions');
    // console.log(enteredDeliveryInstructions);
    // console.log('deliveryInstructions');
    // console.log(deliveryInstructions);
    // console.log('wasDataUpdated = ' + wasDataUpdated);

    saveDeliveryInstructionsForOrder(enteredDeliveryInstructions, enteredTeamInstructions);

    // history.replace('/checkout/review-order');
    // history.replace('/checkout/special-requests');
    history.replace('/checkout/file-attachments');
  };

  return (
    <>
      {isLoading ? (
        <div className={classes['message-holder']}>
          <p className={classes['loading']}>Loading...</p>
        </div>
      ) : null}

      {isError ? (
        <div className={classes['message-holder']}>
          <p className={classes['error']}>Error...</p>
        </div>
      ) : null}

      {!isLoading && !isError ? (
        <Paper elevation={4} sx={{ maxWidth: '120rem', width: '100%', p: '2rem', m: '0 auto' }}>
          <form onSubmit={submitHandler}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
              <FormControls.InputControl
                type='text'
                name='delivery_instructions'
                label='Delivery Instructions'
                inputRef={deliveryInstructionsRef}
                defaultValue={deliveryInstructions}
                className={classes['mui-control']}
                multiline
                minRows={3}
                maxRows={12}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', pt: '4rem' }}>
              <FormControls.InputControl
                type='text'
                name='team_instructions'
                label='Team Instructions'
                inputRef={teamInstructionsRef}
                defaultValue={teamInstructions}
                className={classes['mui-control']}
                multiline
                minRows={3}
                maxRows={12}
              />
            </Box>

            <Box sx={{ pt: '2rem' }}>
              <Button variant='contained' color='accent' size='custom' type='submit'>
                Continue Checkout
              </Button>
            </Box>
          </form>
        </Paper>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default DeliveryInstructionsForm;
