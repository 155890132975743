import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Paper, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
import classes from '../CSS/standard-mui-form.module.css';

const RestaurantApprovalForm = (props) => {
  const { restaurantUuid, notes } = props;
  const authCtx = useContext(AuthContext);

  const dashboardUrl = authCtx.dashboardUrl;

  const notesRef = useRef();
  const history = useHistory();

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  // const textFailedApiCallMessage = 'Failed to Approve the Team';
  const redirectPath = dashboardUrl + 'dashboard';

  const approveTeam = () => {
    submitHandler('Approved');
  };

  const rejectTeam = () => {
    submitHandler('Rejected');
  };

  const submitHandler = (decision) => {
    const enteredNotes = notesRef.current.value;

    // console.log(' - submitHandler - decision = ' + decision + ' - enteredNotes = ' + enteredNotes);

    // if ('a' === 'a') {
    //   console.log('STOPPED ON PURPOSE');

    //   setNotify({
    //     isOpen: true,
    //     message: 'Restaurant Approved.',
    //     type: 'success',
    //   });

    //   return false;
    // }

    // Todo:  Add Validation.

    let apiURL = authCtx.baseApiUrl + 'restaurantapprovals';
    let apiMethod = 'POST';

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        restaurantUuid: restaurantUuid,
        decision: decision,
        notes: enteredNotes,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        notesRef.current.value = '';

        setNotify({
          isOpen: true,
          message: 'Team Approved Saved',
          type: 'success',
        });

        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          history.replace(redirectPath);
        });
      } else {
        return res.json().then((responseData) => {
          console.log(responseData);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            console.table(responseData.messages);
            setNotify({
              isOpen: true,
              message: responseData.messages,
              type: 'error',
            });
          } else {
            console.log('*** SOMETHING HAPPENED ***');
            setNotify({
              isOpen: true,
              message: 'Something Happened.  Team Approval may not have been successfully saved.',
              type: 'error',
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    notesRef.current.focus();
  }, []);

  return (
    <Box sx={{ pt: '2rem', pb: '4rem' }}>
      <Paper elevation={4}>
        <form onSubmit={submitHandler}>
          <Box className={classes['form-container']}>
            <FormControls.InputControl
              type='text'
              name='notes'
              label='Notes'
              inputRef={notesRef}
              defaultValue={notes}
              className={classes['mui-control']}
              multiline
              minRows={4}
              maxRows={16}
            />
          </Box>

          <Box>
            <Typography className='px-20 small-text'>
              Notes entered here will be included in any email notifications.
            </Typography>
          </Box>

          <Box component='div' className={classes['actions-container']}>
            <FormControls.ButtonControl
              text='Approve'
              type='button'
              size='small'
              onClick={approveTeam}
            />
            <FormControls.ButtonControl
              text='Reject'
              type='button'
              color='error'
              size='small'
              onClick={rejectTeam}
            />
          </Box>
        </form>
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default RestaurantApprovalForm;
