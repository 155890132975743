import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Collapse } from '@mui/material';

import AuthContext from '../../store/auth-context';
import { useFormatDateAndTime } from '../Hooks/useFormatDateAndTime';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import UserDetails from '../User/UserDetails';
import UserForm from '../User/UserForm';
import ChangePasswordForm from '../MUI/User/ChangePasswordForm';

const UserProfile = () => {
  const authCtx = useContext(AuthContext);

  const [showForm, setShowForm] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [userDetails, setUserDetails] = useState('');
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const userUuid = authCtx.userUuid;

  const { FormatDateAndTimeForDisplay } = useFormatDateAndTime();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'users/' + userUuid, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(responseData);

        const returnedUserDetails = {
          userUuid: responseData.data.users[0].userUuid,
          firstName: responseData.data.users[0].firstName,
          lastName: responseData.data.users[0].lastName,
          position: responseData.data.users[0].position,
          phone: responseData.data.users[0].phone,
          email: responseData.data.users[0].email,
          notificationPreference: responseData.data.users[0].notificationPreference,
          status: responseData.data.users[0].status,
          roleType: responseData.data.users[0].roleType,
          dateLastLogin: responseData.data.users[0].dateLastLogin,
          dateAdded: responseData.data.users[0].dateAdded,
        };
        // console.log(' -- returnedUserDetails - HERE -- ');
        // console.log(returnedUserDetails);

        // Format values for display.
        if (returnedUserDetails.dateLastLogin !== '') {
          returnedUserDetails.dateLastLogin = FormatDateAndTimeForDisplay(
            returnedUserDetails.dateLastLogin
          );
        }
        if (returnedUserDetails.dateAdded !== '') {
          returnedUserDetails.dateAdded = FormatDateAndTimeForDisplay(
            returnedUserDetails.dateAdded
          );
        }

        setUserDetails(returnedUserDetails);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    // call the function
    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, userUuid, FormatDateAndTimeForDisplay]);

  const toggleShowPasswordForm = () => {
    setShowChangePasswordForm((current) => !current);
  };

  const showFormHandler = () => {
    setShowForm(true);
    setShowDetails(false);
  };

  const showDetailsHandler = (formUserDetails) => {
    // console.log(' -- formUserDetails = ' + formUserDetails);
    if (formUserDetails.firstName) {
      setUserDetails(formUserDetails);
    }

    setShowDetails(true);
    setShowForm(false);
  };

  return (
    <StandardPageWrapper title='User Profile'>
      {isLoading && (
        <div className='message-holder'>
          <p className='loading'>Loading...</p>
        </div>
      )}

      {isError && (
        <div className='message-holder'>
          <p className='error'>Error...</p>
        </div>
      )}

      {showForm ? (
        <UserForm
          onCancel={showDetailsHandler}
          userDetails={userDetails}
          formHeaderText='Update Profile Data'
        />
      ) : null}

      {!isLoading && showDetails ? (
        <>
          <div className='button-holder'>
            <button onClick={showFormHandler} className='small'>
              Edit
            </button>
          </div>
          <UserDetails userDetails={userDetails} />
        </>
      ) : null}

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', py: '2rem' }}>
        <Button variant='text' onClick={toggleShowPasswordForm} className='text-button'>
          Change Your Password
        </Button>
      </Box>
      <Collapse in={showChangePasswordForm}>
        <ChangePasswordForm />
      </Collapse>
    </StandardPageWrapper>
  );
};

export default UserProfile;
