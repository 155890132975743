import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import WorkflowMessage from '../UI/WorkflowMessage';
import DeliveryFeeForm from './DeliveryFeeForm';

import { deliveryFeeLabel, deliveryFeesLabel } from '../../constants/global';

const DeliveryFee = () => {
  console.log(' ***** DeliveryFee ****** ');
  const authCtx = useContext(AuthContext);
  const dashboardUrl = authCtx.dashboardUrl;

  const history = useHistory();
  const params = useParams();
  const restaurantUuid = params.restaurantUuid ? params.restaurantUuid : null;
  const deliveryFeeUuid = params.deliveryFeeUuid ? params.deliveryFeeUuid : '';

  const [deliveryFee, setDeliveryFee] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const pageTitle =
    !deliveryFeeUuid || deliveryFeeUuid === 'null'
      ? 'Add ' + deliveryFeeLabel
      : 'Edit ' + deliveryFeeLabel;

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(authCtx.baseApiUrl + 'deliveryfees/' + deliveryFeeUuid, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.log('responseData.data');
        // console.log(responseData.data);

        setDeliveryFee(responseData.data.delivery_fee);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };
    if (deliveryFeeUuid.length < 5) {
      setIsLoading(false);
    } else {
      fetchData().catch(console.error);
    }
  }, [authCtx.baseApiUrl, authCtx.token, deliveryFeeUuid]);

  const displayDeliveryFeesHandler = () => {
    console.log('displayDeliveryFeesHandler');
    history.push(dashboardUrl + 'restaurants/delivery-fees/' + restaurantUuid);
  };

  const displayRestaurantDetailsHandler = () => {
    console.log('displayRestaurantDetailsHandler');
    history.push(dashboardUrl + 'restaurants/details/' + restaurantUuid);
  };

  return (
    <StandardPageWrapper>
      <Box>
        {isLoading ? <WorkflowMessage type='loading' /> : null}
        {isError ? <WorkflowMessage type='error' /> : null}

        {!isLoading && !isError ? (
          <Box className='title-and-button-holder'>
            <Typography variant='h1' component='h1'>
              {pageTitle}
            </Typography>
            <Box className='button-holder-no-pb'>
              <Button
                variant='contained'
                status='custom'
                color='accent'
                // onClick={() => displayDeliveryFeesHandler(restaurantUuid)}
                onClick={displayDeliveryFeesHandler}
              >
                {deliveryFeesLabel}
              </Button>
              <Button
                variant='contained'
                status='custom'
                color='accent'
                onClick={displayRestaurantDetailsHandler}
              >
                Restaurant Details
              </Button>
            </Box>
          </Box>
        ) : null}

        {!isLoading && !isError ? (
          <DeliveryFeeForm restaurantUuid={restaurantUuid} deliveryFee={deliveryFee} />
        ) : null}
      </Box>
    </StandardPageWrapper>
  );
};

export default DeliveryFee;
