import React, { useState } from 'react';

import GlobalDataContext from './global-data-context';

// const defaultGlobalDataState = {
//   globalMenuItemCategories: [],
// };

const GlobalDataProvider = (props) => {
  const [globalMenuItemCategories, setGlobalMenuItemCategories] = useState([]);

  const setGlobalMenuItemCategoriesHandler = (menuItemCategories) => {
    // console.log(' ** GlobalDataProvider -- setGlobalMenuItemCategoriesHandler ** ');
    setGlobalMenuItemCategories(menuItemCategories);
    // console.log('----- menuItemCategories ----');
    // console.log(menuItemCategories);
  };

  const globalDataContext = {
    globalMenuItemCategories: globalMenuItemCategories,
    setGlobalMenuItemCategories: setGlobalMenuItemCategoriesHandler,
  };

  return (
    <GlobalDataContext.Provider value={globalDataContext}>
      {props.children}
    </GlobalDataContext.Provider>
  );
};

export default GlobalDataProvider;
