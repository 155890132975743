import { useState, useRef, useEffect, useContext } from 'react';
import { Box, Paper } from '@mui/material';

import AuthContext from '../../store/auth-context';
import DeliveryLocationAutocompleteFormField from '../Order/DeliveryLocationAutocompleteFormField';
import Notification from '../MUI/UI/Notification';
import classes from '../CSS/standard-form.module.css';

const DeliveryLocationForm = () => {
  const authCtx = useContext(AuthContext);

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [deliveryLocations, setDeliveryLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const deliveryLocationRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'deliverylocations', {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(responseData);

        const transformedLocations = responseData.data.delivery_locations;

        const filteredRecords = transformedLocations.filter((record) => record.status === 'Active');

        setDeliveryLocations(filteredRecords);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token]);

  return (
    <>
      {isLoading && (
        <div className={classes['message-holder']}>
          <p className={classes['loading']}>Loading...</p>
        </div>
      )}
      {isError && (
        <div className={classes['message-holder']}>
          <p className={classes['error']}>Error...</p>
        </div>
      )}

      <Box sx={{ mb: '2rem' }}>
        <Paper elevation={4} sx={{ maxWidth: '60rem', p: '2rem', m: '0 auto' }}>
          <DeliveryLocationAutocompleteFormField
            deliveryLocationRef={deliveryLocationRef}
            deliveryLocations={deliveryLocations}
          />
        </Paper>
      </Box>

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default DeliveryLocationForm;
