import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import { displayListFilterSelect } from '../../helpers/FilterListHelperFunctions';
import WorkflowMessage from '../UI/WorkflowMessage';
import classes from '../CSS/styled-table.module.css';

const VendorDeliveryLocationsForm = () => {
  // console.log(' ***** VendorDeliveryLocationsForm ***** ');

  const history = useHistory();
  const params = useParams();
  const restaurantUuid = params.restaurantUuid ? params.restaurantUuid : null;
  const restaurantState = params.restaurantState ? params.restaurantState : '';
  const returnTo = params.returnTo ? params.returnTo : '';

  const authCtx = useContext(AuthContext);
  const selectedRestaurantState = authCtx.selectedRestaurantState
    ? authCtx.selectedRestaurantState
    : null;

  const dashboardUrl = authCtx.dashboardUrl;
  const userRole = authCtx.userRole;

  const defaultState = restaurantState ? restaurantState : '';

  // console.log('restaurantUuid = ' + restaurantUuid);
  // console.log('defaultState = ' + defaultState);
  // console.log('returnTo = ' + returnTo);
  // console.log('userRole = ' + userRole);

  const [deliveryLocations, setDeliveryLocations] = useState([]);
  const [displayedDeliveryLocations, setDisplayedDeliveryLocations] = useState([]);
  const [willDeliverToLocations, setWillDeliverToLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const cityFilterRef = useRef();
  const stateFilterRef = useRef();

  const addOrRemove = useCallback((array, item, action = 'check') => {
    const exists = array.includes(item);

    if (exists && action !== 'add') {
      return array.filter((c) => {
        return c !== item;
      });
    } else {
      if (action !== 'remove') {
        const result = array;
        result.push(item);
        return result;
      } else {
        return array;
      }
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const response = await fetch(
        authCtx.baseApiUrl + 'restaurantdeliverylocations/' + restaurantUuid,
        {
          headers: {
            Authorization: authCtx.token,
            'Content-Type': 'application/json',
          },
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        // console.log('response.ok');
        // console.log(responseData);
        // console.log('responseData.data.delivery_locations');
        // console.log(responseData.data.delivery_locations);

        const transformedLocations = responseData.data.delivery_locations.map((location) => {
          // console.log(location.willDeliverTo + ' --- ' + location.deliveryLocationUuid);

          if (location.willDeliverTo === 1) {
            setWillDeliverToLocations((previousLocations) =>
              addOrRemove(previousLocations, location.deliveryLocationUuid)
            );
          }

          return {
            deliveryLocationUuid: location.deliveryLocationUuid,
            name: location.name,
            description: location.description,
            leagues: location.leagues,
            address:
              location.city !== '' && location.state !== ''
                ? location.city + ', ' + location.state
                : location.city !== ''
                ? location.city
                : location.state !== ''
                ? location.state
                : '',
            address1: location.address1,
            address2: location.address2,
            city: location.city,
            state: location.state,
            email: location.email,
            phone: location.phone,
            deliveryInstructions: location.delivery_instructions,
            status: location.status,
            isGlobal: location.isGlobal,
            willDeliverTo: location.willDeliverTo,
          };
        });

        setDeliveryLocations(transformedLocations);

        // console.log('transformedLocations');
        // console.log(transformedLocations);

        let filteredLocations = transformedLocations.filter(
          (location) => location.status === 'Active'
        );

        const uniqueCities = responseData.data.delivery_location_cities;
        const uniqueStates = responseData.data.delivery_location_states;

        // Check to see if the defaultState is included in the uniqueStates.
        if (defaultState && uniqueStates.includes(defaultState)) {
          if (defaultState) {
            filteredLocations = transformedLocations.filter(
              (location) => location.state === defaultState
            );
          }
        }

        setDisplayedDeliveryLocations(filteredLocations);
        setCities(uniqueCities);
        setStates(uniqueStates);
      } else {
        // If a 401 is returned then there is an issue with the session (most likely).
        if (responseData.statusCode === 401) {
          history.push(dashboardUrl + 'sessionexpired');
        }

        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [
    authCtx.baseApiUrl,
    authCtx.token,
    restaurantUuid,
    defaultState,
    history,
    dashboardUrl,
    addOrRemove,
  ]);

  const saveDeliveryLocationsHandler = () => {
    const selectedDeliveryLocationUuidsString = willDeliverToLocations.join(', ');

    const apiURL = authCtx.baseApiUrl + 'restaurantdeliverylocations';
    const apiMethod = 'POST';

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        restaurant_uuid: restaurantUuid,
        delivery_location_uuids: selectedDeliveryLocationUuidsString,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        setNotify({
          isOpen: true,
          message: 'Delivery Location(s) Successfully Saved.',
          type: 'success',
        });

        return res.json().then((responseData) => {
          // console.log(' ##### SUCCESS ##### ');
          // console.log(responseData);

          // If the user should be taken to another page after saving, uncomment the code below.
          // returnToCallingPage();

          dataHasChanged(false);
        });
      } else {
        return res.json().then((responseData) => {
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: 'Selected Delivery Locations Not Saved',
              type: 'error',
            });
          }
        });
      }
    });
  };

  const clearFilterHandler = () => {
    cityFilterRef.current.value = 'Show All';
    stateFilterRef.current.value = selectedRestaurantState ? selectedRestaurantState : 'Show All';
    filterDisplayedRecords();
  };

  const filterDisplayedRecords = () => {
    let cityFilter = cityFilterRef.current.value;
    let stateFilter = stateFilterRef.current.value;

    if (cityFilter === 'Show All' && stateFilter === 'Show All') {
      setDisplayedDeliveryLocations(deliveryLocations);
    } else {
      let filteredLocations = deliveryLocations;

      if (cityFilter !== 'Show All') {
        filteredLocations = filteredLocations.filter((record) => record.city === cityFilter);
      }

      if (stateFilter !== 'Show All') {
        filteredLocations = filteredLocations.filter((record) => record.state === stateFilter);
      }

      setDisplayedDeliveryLocations(filteredLocations);
    }
  };

  const returnToCallingPage = () => {
    if (userRole === 'Vendor') {
      if (returnTo === 'delivery-locations') {
        history.push(dashboardUrl + 'delivery-locations');
      } else {
        history.push(dashboardUrl + 'vendor-profile');
      }
    } else {
      history.push(dashboardUrl + 'restaurants/details/' + restaurantUuid);
    }
  };

  const displayTableHeader = () => {
    return (
      <tr>
        <th>Will Delivery To</th>
        <th>Name</th>
        <th>Address</th>
        <th>City</th>
        <th>State</th>
        <th>Leagues</th>
      </tr>
    );
  };

  const disableFiltering = (value) => {
    document.getElementById('filter-by-city').disabled = value;
    document.getElementById('filter-by-state').disabled = value;
    document.getElementById('clear-filters-button').disabled = value;
    if (value) {
      document.getElementById('clear-filters-button').classList.add('disabled');
    } else {
      document.getElementById('clear-filters-button').classList.remove('disabled');
    }
  };

  const disableSaving = (value) => {
    setIsSaveButtonDisabled(value);

    if (value) {
      document.getElementById('save-button').classList.add('disabled');
    } else {
      document.getElementById('save-button').classList.remove('disabled');
    }
  };

  const dataHasChanged = (value) => {
    disableFiltering(value);
    disableSaving(!value);
  };

  const toggleWillDeliveryTo = (deliveryLocationUuid) => {
    const yesButtonClass = 'size-a green';
    const noButtonClass = 'size-a red';

    const button = document.getElementById(deliveryLocationUuid);
    const buttonText = button.innerText;
    const action = buttonText === 'NO' ? 'add' : 'remove';

    button.className = buttonText === 'NO' ? yesButtonClass : noButtonClass;
    button.innerText = buttonText === 'NO' ? 'YES' : 'NO';

    dataHasChanged(true);

    const updatedArray = addOrRemove(willDeliverToLocations, deliveryLocationUuid, action);

    setWillDeliverToLocations(updatedArray);
  };

  const displayTableBody = () => {
    return displayedDeliveryLocations.map((record, index) => (
      <tr key={index}>
        <td className='text-centered'>
          <Box>
            <button
              type='button'
              id={record.deliveryLocationUuid}
              onClick={() => toggleWillDeliveryTo(`${record.deliveryLocationUuid}`)}
              className={
                'size-a ' +
                (willDeliverToLocations.includes(record.deliveryLocationUuid) ? 'green' : 'red')
              }
            >
              {willDeliverToLocations.includes(record.deliveryLocationUuid) ? 'YES' : 'NO'}
            </button>
          </Box>
        </td>
        <td>{record.name}</td>
        <td>
          {record.address1}
          {record.address2 ? ', ' + record.address2 : ''}
        </td>
        <td>{record.city}</td>
        <td>{record.state}</td>

        <td>{record.leagues}</td>
      </tr>
    ));
  };

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      <div className='content-container'>
        <Box className='title-and-button-holder'>
          <Typography variant='h1' component='h1'>
            Delivery Locations
          </Typography>
          {/* <Box className='button-holder-no-pb'>
            <Button
              variant='contained'
              status='custom'
              color='accent'
              onClick={returnToCallingPage}
            >
              {returnTo === 'delivery-locations' ? 'Delivery Locations' : 'Restaurant Details'}
            </Button>
          </Box> */}
        </Box>

        {!isLoading && !isError ? (
          <Box className='button-holder'>
            <Box>
              <Box>
                <label htmlFor='filter-by-city'>City</label>
              </Box>

              {displayListFilterSelect(
                'filter-by-city',
                cityFilterRef,
                filterDisplayedRecords,
                cities
              )}
            </Box>
            <Box>
              <Box>
                <label htmlFor='filter-by-state'>State</label>
              </Box>
              {displayListFilterSelect(
                'filter-by-state',
                stateFilterRef,
                filterDisplayedRecords,
                states,
                defaultState
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
                gap: '5px',
                width: '100%',
              }}
            >
              <Box>
                <button id='clear-filters-button' className='small' onClick={clearFilterHandler}>
                  Clear Filters
                </button>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: '5px',
                }}
              >
                <button
                  id='save-button'
                  disabled={isSaveButtonDisabled}
                  className='small green disabled'
                  onClick={saveDeliveryLocationsHandler}
                >
                  Save
                </button>

                <button className='small red' onClick={returnToCallingPage}>
                  Cancel
                </button>
              </Box>
            </Box>
          </Box>
        ) : null}

        {!isLoading && deliveryLocations.length > 0 && (
          <div className='table-holder'>
            <table className={`${classes['styled-table']} ${classes['full-width']}`}>
              <thead>{displayTableHeader()}</thead>
              <tbody>{displayTableBody()}</tbody>
            </table>
          </div>
        )}

        {!isLoading && !isError && deliveryLocations.length === 0 ? (
          <Typography variant='h2' component='h2' color='error'>
            No Delivery Locations Found
          </Typography>
        ) : null}

        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </>
  );
};

export default VendorDeliveryLocationsForm;
