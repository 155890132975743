import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import StandardPageWrapper from '../Layout/StandardPageWrapper';
import DeliveryInstructionsForm from './DeliveryInstructionsForm';
import classes from '../CSS/Checkout.module.css';

const DeliveryInstructions = () => {
  return (
    <StandardPageWrapper title='Delivery Instructions' usePaper='true'>
      <Box className={classes['content-holder']}>
        <List>
          <ListItem>
            <ListItemIcon>
              <InfoIcon color='primary' />
            </ListItemIcon>
            <ListItemText>
              Please enter any specific details related to the delivery of your order
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InfoIcon color='primary' />
            </ListItemIcon>
            <ListItemText>
              The more information you can supply the smoother your delivery will go
            </ListItemText>
          </ListItem>
        </List>
      </Box>

      <div className={classes['content-holder']}>
        <DeliveryInstructionsForm />
      </div>
    </StandardPageWrapper>
  );
};

export default DeliveryInstructions;
