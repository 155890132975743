import { useState, useRef, useEffect, useContext } from 'react';
import { Box, Paper } from '@mui/material';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
import LeaguesAutocompleteFormField from '../FormControls/LeaguesAutocompleteFormField';
import StatusSelectFormField from '../FormControls/StatusSelectFormField';
import classes from '../CSS/standard-mui-form.module.css';

// const foodTypeOptions = [
//   { id: 'Asian', title: 'Asian' },
//   { id: 'BBQ', title: 'BBQ' },
//   { id: 'Italian', title: 'Italian' },
//   { id: 'Soups, Salads and Subs', title: 'Soups, Salads and Subs' },
// ];

// const statusOptions = [
//   { id: 'New', title: 'New' },
//   { id: 'Active', title: 'Active' },
//   { id: 'Inactive', title: 'Inactive' },
//   { id: 'Deleted', title: 'Deleted' },
// ];

const TestRestaurantForm = (props) => {
  // console.log(' ######################################################');
  // console.log('-- Loading TestRestaurantForm --');
  // console.log(' ######################################################');

  const authCtx = useContext(AuthContext);

  // const userRole = authCtx.userRole;

  const { restaurantDetails, foodTypes, leagues, reloadData } = props;

  if (!restaurantDetails.name) {
    restaurantDetails.deliveryHoursStart = '11:00:00';
    restaurantDetails.deliveryHoursEnd = '23:00:00';
    restaurantDetails.orderLeadTime = 24;
    restaurantDetails.taxRate = 0.0;
  }

  // console.log('TestRestaurantForm - restaurantDetails.restaurantUuid = ' + restaurantDetails.restaurantUuid);

  // console.log('restaurantDetails');
  // console.table(restaurantDetails);

  // console.log('leagues');
  // console.log(leagues);

  // console.log('restaurantDetails.league = ' + restaurantDetails.league);

  // const [foodType, setFoodType] = useState('');
  const [foodType, setFoodType] = useState(
    restaurantDetails.foodType ? restaurantDetails.foodType : ''
  );
  const [selectedLeagues, setSelectedLeagues] = useState(null);
  const [restaurantStatus, setRestaurantStatus] = useState(
    restaurantDetails.status ? restaurantDetails.status : 'Active'
  );

  // const [league, setLeague] = useState(restaurantDetails.league ? restaurantDetails.league : 'NFL');
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  // console.log('league = ' + league);

  let currentRestaurantLeagues = [];
  if (restaurantDetails.leagueExperience) {
    // console.log('restaurantDetails.league_experience = ' + restaurantDetails.leagueExperience);

    const returnedLeagues = restaurantDetails.leagueExperience;
    const returnedLeaguesArray = returnedLeagues.split(', ');

    // console.log('returnedLeaguesArray');
    // console.log(returnedLeaguesArray);

    returnedLeaguesArray.sort();

    // console.log('returnedLeaguesArray');
    // console.log(returnedLeaguesArray);

    currentRestaurantLeagues = returnedLeaguesArray.map((league) => {
      return {
        name: league,
      };
    });
  }

  const nameRef = useRef();
  const descriptionRef = useRef();
  const address1Ref = useRef();
  const address2Ref = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const zipcodeRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  // const foodTypeRef = useRef();
  // const headerImageRef = useRef();
  // const listImageRef = useRef();
  // const leagueExperienceRef = useRef();
  // const deliveryHoursRef = useRef();
  const deliveryHoursStartRef = useRef();
  const deliveryHoursEndRef = useRef();
  const orderLeadTimeRef = useRef();
  const taxRateRef = useRef();
  const statusRef = useRef();

  useEffect(() => {
    nameRef.current.focus();
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();

    // setDisplayMessage(null);
    // setIsLoading(true);
    // setIsError(false);

    const enteredName = nameRef.current.value;
    const enteredDescription = descriptionRef.current.value;
    const enteredAddress1 = address1Ref.current.value;
    const enteredAddress2 = address2Ref.current.value;
    const enteredCity = cityRef.current.value;
    const enteredState = stateRef.current.value;
    const enteredZipcode = zipcodeRef.current.value;
    const enteredEmail = emailRef.current.value;
    const enteredPhone = phoneRef.current.value;
    const enteredDeliveryHoursStart = deliveryHoursStartRef.current.value;
    const enteredDeliveryHoursEnd = deliveryHoursEndRef.current.value;
    const enteredOrderLeadTime = orderLeadTimeRef.current.value;
    const enteredTaxRate = taxRateRef.current.value;
    const enteredFoodType = foodType;

    // const enteredStatus = statusRef.current ? statusRef.current.value : 'Active';
    const enteredStatus = restaurantStatus;

    // Todo:  Add Validation.

    // console.log(
    //   ' -- TestRestaurantForm - restaurantDetails.restaurantUuid = ' +
    //     restaurantDetails.restaurantUuid
    // );

    let apiURL = '';
    let apiMethod = '';

    if (restaurantDetails.restaurantUuid !== '' && restaurantDetails.restaurantUuid !== undefined) {
      apiURL = authCtx.baseApiUrl + 'restaurants/' + restaurantDetails.restaurantUuid;
      apiMethod = 'PATCH';
    } else {
      apiURL = authCtx.baseApiUrl + 'restaurants';
      apiMethod = 'POST';
    }

    // console.log('apiURL = ' + apiURL);
    // console.log('apiMethod = ' + apiMethod);

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        name: enteredName,
        description: enteredDescription,
        address1: enteredAddress1,
        address2: enteredAddress2,
        city: enteredCity,
        state: enteredState,
        zipcode: enteredZipcode,
        email: enteredEmail,
        phone: enteredPhone,
        food_type: enteredFoodType,
        league_experience: selectedLeagues,
        delivery_hours_start: enteredDeliveryHoursStart,
        delivery_hours_end: enteredDeliveryHoursEnd,
        order_lead_time: enteredOrderLeadTime,
        tax_rate: enteredTaxRate,
        status: enteredStatus,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      // setIsLoading(false);

      if (res.ok) {
        nameRef.current.value = '';
        descriptionRef.current.value = '';
        address1Ref.current.value = '';
        address2Ref.current.value = '';
        cityRef.current.value = '';
        stateRef.current.value = '';
        zipcodeRef.current.value = '';
        emailRef.current.value = '';
        phoneRef.current.value = '';
        deliveryHoursStartRef.current.value = '';
        deliveryHoursEndRef.current.value = '';
        orderLeadTimeRef.current.value = '';
        taxRateRef.current.value = '';
        if (statusRef.current) {
          statusRef.current.value = '';
        }

        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          // const returnedData = {
          //   restaurantUuid: responseData.data.restaurants[0].restaurantUuid,
          //   name: responseData.data.restaurants[0].name,
          //   description: responseData.data.restaurants[0].description,
          //   address1: responseData.data.restaurants[0].address1,
          //   address2: responseData.data.restaurants[0].address2,
          //   city: responseData.data.restaurants[0].city,
          //   state: responseData.data.restaurants[0].state,
          //   zipcode: responseData.data.restaurants[0].zipcode,
          //   email: responseData.data.restaurants[0].email,
          //   phone: responseData.data.restaurants[0].phone,
          //   foodType: responseData.data.restaurants[0].foodType,
          //   leagueExperience: responseData.data.restaurants[0].leagueExperience,
          //   deliveryHoursStart: responseData.data.restaurants[0].deliveryHoursStart,
          //   deliveryHoursEnd: responseData.data.restaurants[0].deliveryHoursEnd,
          //   orderLeadTime: responseData.data.restaurants[0].orderLeadTime,
          //   taxRate: responseData.data.restaurants[0].taxRate,
          //   status: responseData.data.restaurants[0].status,
          // };

          // if (userRole === 'Vendor') {
          //   authCtx.updateOrgValues(
          //     responseData.data.restaurants[0].restaurantUuid,
          //     responseData.data.restaurants[0].name
          //   );
          // }

          // props.onCancel(returnedData);
          // showList(returnedData);
          reloadData();
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          // console.log(responseData);
          // setIsError(true);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            // setDisplayMessage(responseData.messages);
            console.table(responseData.messages);
          } else {
            // setDisplayMessage([[textFailedApiCallMessage]]);
            console.log('*** SOMETHING HAPPENED ***');
          }
        });
      }
    });
  };

  // const handleSelectLeague = (event, value) => {
  //   console.log('handleSelectLeague - event.target.value = ' + event.target.value);
  //   // console.log('value');
  //   // console.log(value);
  //   if (value) {
  //     const leagueValue = value.name ? value.name : '';
  //     console.log('leagueValue = ' + leagueValue);
  //     setLeague(leagueValue);
  //   }
  // };

  const foodTypeChangeHandler = (event) => {
    // console.log('foodtypeChangeHandler - event.target.value = ' + event.target.value);
    setFoodType(event.target.value);
  };

  const setLeaguesHandler = (event, selectedLeagues) => {
    // console.log('setLeaguesHandler');
    // console.log('selectedLeagues');
    // console.log(selectedLeagues);

    let leaguesString = '';
    if (Array.isArray(selectedLeagues) && selectedLeagues.length > 0) {
      const leaguesArray = selectedLeagues.map((league) => {
        return league.name;
      });

      // console.log('leaguesArray');
      // console.log(leaguesArray);

      leaguesArray.sort();

      leaguesString = leaguesArray.join(', ');
    }

    // console.log('leaguesString');
    // console.log(leaguesString);

    setSelectedLeagues(leaguesString);
  };

  const statusChangeHandler = (event) => {
    // console.log('statusChangeHandler - event.target.value = ' + event.target.value);
    setRestaurantStatus(event.target.value);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Paper elevation={4} sx={{ maxWidth: '120rem' }}>
        <form onSubmit={submitHandler}>
          <Box className={classes['form-container']}>
            <FormControls.InputControl
              type='text'
              name='name'
              label='Restaurant Name'
              defaultValue={restaurantDetails.name}
              inputRef={nameRef}
              required
              inputProps={{ maxLength: 50 }}
              className={classes['mui-control']}
            />
            <FormControls.InputControl
              type='text'
              name='description'
              label='Description'
              inputRef={descriptionRef}
              defaultValue={restaurantDetails.description}
              className={classes['mui-control']}
              multiline
              minRows={3}
              maxRows={12}
            />

            <Box className={classes['grid-2']}>
              <FormControls.InputControl
                type='text'
                name='address1'
                label='Address 1'
                defaultValue={restaurantDetails.address1}
                inputRef={address1Ref}
                inputProps={{ maxLength: 255 }}
                className={classes['mui-control']}
              />
              <FormControls.InputControl
                type='text'
                name='address2'
                label='Address 2'
                defaultValue={restaurantDetails.address2}
                inputRef={address2Ref}
                inputProps={{ maxLength: 255 }}
                className={classes['mui-control']}
              />
            </Box>

            <Box className={classes['grid-2']}>
              <FormControls.InputControl
                type='text'
                name='city'
                label='City'
                defaultValue={restaurantDetails.city}
                inputRef={cityRef}
                inputProps={{ maxLength: 50 }}
                className={`${classes['mui-control']}`}
              />

              <Box className={classes['grid-2']}>
                <FormControls.InputControl
                  type='text'
                  name='state'
                  label='State'
                  defaultValue={restaurantDetails.state}
                  inputRef={stateRef}
                  inputProps={{ maxLength: 2 }}
                  className={`${classes['mui-control']}`}
                />
                <FormControls.InputControl
                  type='text'
                  name='zipcode'
                  label='Zipcode'
                  defaultValue={restaurantDetails.zipcode}
                  inputRef={zipcodeRef}
                  inputProps={{ maxLength: 10 }}
                  className={`${classes['mui-control']}`}
                />
              </Box>
            </Box>

            <Box className={classes['grid-2']}>
              <FormControls.InputControl
                type='email'
                name='email'
                label='Email'
                defaultValue={restaurantDetails.email}
                inputRef={emailRef}
                inputProps={{ maxLength: 50 }}
                className={classes['mui-control']}
              />
              <FormControls.InputControl
                type='text'
                name='phone'
                label='Phone'
                defaultValue={restaurantDetails.phone}
                inputRef={phoneRef}
                inputProps={{ maxLength: 15 }}
                className={classes['mui-control']}
              />
            </Box>

            <Box className={classes['grid-2']}>
              <FormControls.SelectControl
                name='food_type'
                label='Food Type'
                value={foodType}
                onChange={foodTypeChangeHandler}
                options={foodTypes}
              />
              <LeaguesAutocompleteFormField
                leagues={leagues}
                currentLeagues={currentRestaurantLeagues}
                onChange={setLeaguesHandler}
                label='League Experience'
              />
            </Box>

            <Box className={classes['grid-2']}>
              <Box className={classes['grid-2']}>
                <FormControls.InputControl
                  type='time'
                  name='delivery_hours_start'
                  label='Delivery Hours Start'
                  defaultValue={restaurantDetails.deliveryHoursStart}
                  inputRef={deliveryHoursStartRef}
                  className={classes['mui-control']}
                />
                <FormControls.InputControl
                  type='time'
                  name='delivery_hours_end'
                  label='Delivery Hours End'
                  defaultValue={restaurantDetails.deliveryHoursEnd}
                  inputRef={deliveryHoursEndRef}
                  className={classes['mui-control']}
                />
              </Box>

              <Box className={classes['grid-2']}>
                <FormControls.InputControl
                  type='number'
                  min='1'
                  max='120'
                  step='1'
                  name='order_lead_time'
                  label='Order Lead Time (Hours)'
                  defaultValue={restaurantDetails.orderLeadTime}
                  inputRef={orderLeadTimeRef}
                  inputProps={{ type: 'number', min: '1', max: '120', step: '1', maxLength: 3 }}
                  className={classes['mui-control']}
                />
                <FormControls.InputControl
                  type='text'
                  required
                  name='tax_rate'
                  label='Tax Rate (%)'
                  defaultValue={restaurantDetails.taxRate}
                  inputRef={taxRateRef}
                  inputProps={{ type: 'number', min: '0.0', max: '20.0', step: '0.01' }}
                  className={classes['mui-control']}
                />
              </Box>
            </Box>

            <StatusSelectFormField
              currentStatus={restaurantStatus}
              onChange={statusChangeHandler}
            />

            {/* <Box sx={{ width: '15rem' }}>
              <FormControls.SelectControl
                name='status'
                label='Status'
                value={restaurantStatus}
                onChange={statusChangeHandler}
                options={statusOptions}
              />
            </Box> */}

            <Box component='div' className={classes['actions-container']}>
              <FormControls.ButtonControl text='Save' type='submit' />
              <FormControls.ButtonControl text='Cancel' type='button' onClick={reloadData} />
            </Box>
          </Box>
        </form>
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default TestRestaurantForm;
