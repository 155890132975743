import React, { useContext, useState } from 'react';
import { Box, Button } from '@mui/material';

import { servingSizeLabel, priceDetailsLabel } from '../../constants/global';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import ConfirmationDialog from '../MUI/UI/ConfirmationDialog';
import MenuItemDetailMiniCard from './MenuItemDetailMiniCard';
import classes from './MenuItem.module.css';

const ChildMenuItemDetailsCard = (props) => {
  const { menuItemDetails, onEditChild, userRole, itemType = 'child', onDataUpdated } = props;

  const authCtx = useContext(AuthContext);

  // console.log('ChildMenuItemDetailsCard - itemType = ' + itemType);

  // console.log(menuItemDetails);

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const deleteHandler = (menuItemUuid) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    console.log(' - handleDelete - menuItemUuid = ' + menuItemUuid);
    // onDeleteChild(menuItemUuid);
    deleteMenuItemHandler(menuItemUuid);
    // setNotify({ isOpen: true, message: 'Deleted Successfully', type: 'error' });
  };

  const deleteMenuItemHandler = (menuItemUuid) => {
    const deleteData = async () => {
      const response = await fetch(authCtx.baseApiUrl + 'menuitems/' + menuItemUuid, {
        method: 'DELETE',
        headers: {
          Authorization: authCtx.token,
        },
      });

      if (response.ok) {
        // const responseData = await response.json();
        await response.json();
        // console.log(responseData);
        onDataUpdated();
      } else {
        console.log(' -- setIsError(true) -- ');
        // setIsError(true);
      }
      // setIsLoading(false);
    };

    deleteData().catch(console.error);
  };

  // const confirmationSubtitle = menuItemDetails.parentUuid
  //   ? 'It will be permanently deleted.'
  //   : 'All related Menu Items will be deleted as well.';

  return (
    <Box className={classes['menuitem-card-holder-grid']}>
      <MenuItemDetailMiniCard
        label='Price'
        data={menuItemDetails.priceFormatted}
        itemType={itemType}
      />

      <MenuItemDetailMiniCard
        label={servingSizeLabel}
        data={menuItemDetails.servingSize}
        itemType={itemType}
      />
      <MenuItemDetailMiniCard label='Feeds' data={menuItemDetails.feeds} itemType={itemType} />
      <MenuItemDetailMiniCard
        label={priceDetailsLabel}
        data={menuItemDetails.priceDetails}
        itemType={itemType}
      />
      {userRole === 'Admin' ? (
        <Box className='flex-container-side-by-side-small-gap' sx={{ pt: '5px' }}>
          {itemType === 'child' ? (
            <Button
              variant='contained'
              size='small'
              onClick={() => onEditChild(menuItemDetails)}
              sx={{ height: '2.6rem' }}
            >
              Edit
            </Button>
          ) : null}

          {itemType === 'child' ? (
            <Button
              variant='contained'
              // onClick={() => onEditChild(menuItemDetails)}
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: 'Are you sure you want to delete this menu item.',
                  // subTitle: 'It will be permanently deleted.',
                  onConfirm: () => {
                    // deleteHandler(user.userUuid);
                    deleteHandler(menuItemDetails.menuItemUuid);
                  },
                });
              }}
              color='error'
              sx={{ height: '2.6rem' }}
            >
              Delete
            </Button>
          ) : null}
        </Box>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </Box>
  );
};

export default ChildMenuItemDetailsCard;
