import React, { useContext, useState, useRef } from 'react';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';

const FileUpload = () => {
  const authCtx = useContext(AuthContext);

  const [selectedFile, setSelectedFile] = useState(null);
  const [imageLink, setImageLink] = useState(null);
  const [fileLink, setFileLink] = useState(null);
  const [validationError, setValidationError] = useState(null);

  const fileInputRef = useRef(null);
  const nameRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileExtensionsAllowed = 'doc, docx, jpg, jpeg, pdf, png, txt, xls, xlsx';
      const arrayFileExtensionsAllowed = [
        '.doc',
        '.docx',
        '.jpg',
        '.jpeg',
        '.pdf',
        '.png',
        '.txt',
        '.xls',
        '.xlsx',
      ];
      const selectedFileExtension = file.name.split('.').pop().toLowerCase();

      if (arrayFileExtensionsAllowed.includes('.' + selectedFileExtension)) {
        setSelectedFile(file);
        setValidationError(null);
      } else {
        setSelectedFile(null);
        setValidationError(
          'Invalid file extension.  Please select a file with one of the following extensions: ' +
            fileExtensionsAllowed
        );

        fileInputRef.current.value = '';
      }
    } else {
    }
  };

  const handleUpload = async () => {
    setFileLink(null);
    setImageLink(null);

    if (selectedFile) {
      const apiURL = authCtx.baseApiUrl + 'testingfileupload';
      const apiMethod = 'POST';

      const orderUuid = '666-order-uuid-666';
      const enteredName = nameRef.current.value;
      const enteredDescription = 'This is just a Test Description';

      const jsonObj = { order_uuid: orderUuid, name: enteredName, description: enteredDescription };

      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('json', JSON.stringify(jsonObj));

      // This doesn't work.
      // formData.append('name', enteredName);

      const response = await fetch(apiURL, {
        method: apiMethod,
        headers: {
          Authorization: authCtx.signupToken,
          // 'Content-Type': 'application/json',
        },
        body: formData,
        // body: JSON.stringify(formData),
        // body: { file: selectedFile },
        // body: JSON.stringify({
        //   name: enteredName,
        //   file: selectedFile,
        // }),
        // body: {
        //   name: enteredName,
        //   file: selectedFile,
        // },
      });

      // Notes:
      // using JSON.stringify means we don't get the $_FILES

      const responseData = await response.json();

      console.log('##### responseData #####');
      console.log(responseData);

      setImageLink(
        responseData.data && responseData.data.imageLink ? responseData.data.imageLink : null
      );
      setFileLink(
        responseData.data && responseData.data.fileLink ? responseData.data.fileLink : null
      );

      fileInputRef.current.value = '';
    } else {
      setValidationError('Please select a file before uploading.');
    }
  };

  const openFileInNewTab = (pathAndFilename) => {
    // console.log('### openFileInNewTab - pathAndFilename = ' + pathAndFilename + ' ###');
    window.open(pathAndFilename, '_blank');
  };

  const handleChooseFile = () => {
    setFileLink(null);
    setImageLink(null);
  };

  return (
    <Box className='p-20'>
      <h1>File Upload Testing</h1>

      <Box>
        <Box>
          {/* <form onSubmit={handleUpload} method='POST' encType='multipart/form-data'> */}
          <form encType='multipart/form-data'>
            <Typography component='span' className='pr-10'>
              Select File
            </Typography>
            {/* <input
              type='file'
              ref={fileInputRef}
              onChange={handleFileChange}
              onClick={handleChooseFile}
            /> */}

            <Button variant='contained' component='label' size='small' sx={{ width: '140px' }}>
              Select File
              <input
                hidden
                accept='.xls,.xlsx,.pdf,.txt,.doc'
                multiple
                type='file'
                name='selected_file'
                ref={fileInputRef}
                onChange={handleFileChange}
                onClick={handleChooseFile}
              />
            </Button>
          </form>
          <Box className='pt-20'>
            <input type='text' name='name' ref={nameRef} />
          </Box>
          <Box className='pt-20'>
            <button onClick={handleUpload}>Upload File</button>
            {/* <button type='submit'>Upload File</button> */}
            {/* <input type='submit' value='Upload File' /> */}
          </Box>
        </Box>

        {validationError ? (
          <Box className='pt-20'>
            <Typography color='error'>{validationError}</Typography>
          </Box>
        ) : null}

        {imageLink ? (
          <Box className='pt-20'>
            <Typography>Uploaded Image</Typography>
            <img src={imageLink} alt='Uploaded' />
          </Box>
        ) : null}

        {fileLink ? (
          <Box className='pt-20'>
            <Typography>Uploaded File</Typography>
            <button
              onClick={() => {
                openFileInNewTab(fileLink);
              }}
            >
              Open/Download File
            </button>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default FileUpload;
