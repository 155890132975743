import React, { useCallback, useContext, useEffect, useState } from 'react';

import AuthContext from '../../store/auth-context';

const GetDataTest1 = () => {
  console.log('***** GetDataTest1.jsx *****');

  const authCtx = useContext(AuthContext);

  const [user, setUser] = useState([]);
  const [userLoaded, setUserLoaded] = useState(false);

  const fetchUser = useCallback(async () => {
    try {
      const response = await fetch(authCtx.baseApiUrl + 'users/' + authCtx.userUuid, {
        headers: {
          Authorization: authCtx.token,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log('responseData');
        // console.log(responseData);

        return { success: true, data: responseData.data };
      } else {
      }
    } catch (error) {
      console.log('*** ERROR - fetchUser ***');
      return { success: false };
    }
  }, [authCtx.baseApiUrl, authCtx.token, authCtx.userUuid]);

  useEffect(() => {
    (async () => {
      setUserLoaded(false);
      const response = await fetchUser();
      // console.log('response.data');
      // console.log(response.data);
      const userData = response.data.users[0];
      // console.log('userData');
      // console.table(userData);
      if (response.success) {
        setUser(userData);
        setUserLoaded(true);
      }
    })();
  }, [fetchUser]);

  return (
    <div>
      <h3>Get Data Test 1</h3>
      {userLoaded ? <div>{user.firstName}</div> : null}
    </div>
  );
};

export default GetDataTest1;
