import React from 'react';

const TestingMenu = ({ displayContent }) => {
  return (
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              displayContent('fileUploadComplex');
            }}
          >
            File Upload
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              displayContent('fileUploadTesting');
            }}
          >
            File Upload (simple version)
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              displayContent('orderEmailTesting');
            }}
          >
            Email Testing
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              displayContent('componentDataTest');
            }}
          >
            Component and Data Test
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              displayContent('reactPdfTestPage');
            }}
          >
            React PDF / Renderer
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              displayContent('stripePaymentForm');
            }}
          >
            Stripe Payment Form
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              displayContent('imageUpload');
            }}
          >
            Image Upload
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              displayContent('additionalCharge');
            }}
          >
            Additional Charge
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              displayContent('getDataTest1');
            }}
          >
            Get Data Test 1
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              displayContent('globalEndpoints');
            }}
          >
            Global Endpoints
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default TestingMenu;
