import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const ListItemWithIcon = ({ textContent }) => {
  return (
    <ListItem>
      <ListItemIcon>
        <InfoIcon color='primary' />
      </ListItemIcon>
      <ListItemText>{textContent}</ListItemText>
    </ListItem>
  );
};

export default ListItemWithIcon;
