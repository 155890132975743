import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import StandardPageWrapper from '../Layout/StandardPageWrapper';
import DeliveryLocationForm from './DeliveryLocationForm';

const DeliveryLocationAdd = (props) => {
  const history = useHistory();

  const backToListHandler = () => {
    history.push(props.dashboardUrl + 'delivery-locations');
  };

  return (
    <StandardPageWrapper>
      <Box className='content-container'>
        <Box className='title-and-button-holder'>
          <Typography variant='h1' component='h1'>
            Add Delivery Location
          </Typography>
          <Box className='button-holder-no-pb'>
            <Button variant='contained' size='custom' color='accent' onClick={backToListHandler}>
              Delivery Locations
            </Button>
          </Box>
        </Box>
        <div>
          <DeliveryLocationForm onCancel={backToListHandler} />
        </div>
      </Box>
    </StandardPageWrapper>
  );
};

export default DeliveryLocationAdd;
