import { useCallback, useContext, useEffect, useState } from 'react';

import AuthContext from '../../store/auth-context';

const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const orderUuid = 'b0e5c9ac-0d0f-48ae-b00d-8c96f8efc180';

// const defaultAdditionalCharges = [
//   {
//     name: 'Additional Charge 1',
//     description: 'Description of Additional Charge 1',
//     price: 123.45,
//   },
//   {
//     name: 'Additional Charge 2',
//     description: 'Description of Additional Charge 2',
//     price: 543.21,
//   },
// ];

const useAdditionalCharge = () => {
  const authCtx = useContext(AuthContext);

  const [additionalCharges, setAdditionalCharges] = useState([]);

  const getAdditionalCharges = useCallback(async () => {
    // console.log(' ** getAdditionalCharges ** ');

    // setIsLoading(true);
    // setIsError(false);

    const response = await fetch(authCtx.baseApiUrl + 'additionalcharges/order/' + orderUuid, {
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    });

    // console.log('response');
    // console.log(response);

    if (response.ok) {
      const responseData = await response.json();

      const transformedRecords = responseData.data.additional_charges.map((record) => {
        return {
          name: record.name,
          description: record.description,
          price: record.price,
          priceFormatted: record.price ? priceFormatter.format(record.price) : 0.0,
          quantity: 1,
          status: record.status,
          subtotal: record.price * 1,
        };
      });

      // console.log('getAdditionalCharges');
      // console.log('transformedRecords');
      // console.log(transformedRecords);

      setAdditionalCharges(transformedRecords);
    } else {
      console.log(' ***** ERROR ** getAdditionalCharges ** ERROR ***** ');
    }

    // setIsLoading(false);
  }, [authCtx.baseApiUrl, authCtx.token]);

  useEffect(() => {
    getAdditionalCharges();
  }, [getAdditionalCharges]);

  // _second_try
  const saveAdditionalCharge = useCallback(
    async (props) => {
      const { additionalChargeUuid, name, description, price, status } = props;

      let apiURL = '';
      let apiMethod = '';

      if (additionalChargeUuid !== '' && additionalChargeUuid !== undefined) {
        apiURL = authCtx.baseApiUrl + 'additionalcharges/' + additionalChargeUuid;
        apiMethod = 'PATCH';
      } else {
        apiURL = authCtx.baseApiUrl + 'additionalcharges';
        apiMethod = 'POST';
      }

      console.log('saveAdditionalCharge - second_try');
      // console.log('props');
      // console.table(props);
      // console.log('props');
      console.log('additionalChargeUuid = ' + additionalChargeUuid);
      console.log('name = ' + name);
      console.log('description = ' + description);
      console.log('price = ' + price);
      console.log('status = ' + status);

      // setLoading(true);
      // setError(null);
      try {
        const response = await fetch(apiURL, {
          method: apiMethod,
          body: JSON.stringify({
            order_uuid: orderUuid,
            name: name,
            description: description,
            price: price,
            status: status,
          }),
          headers: {
            Authorization: authCtx.token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (response.ok) {
          const responseData = await response.json();
          console.log('** SUCCESS **');
          console.log('responseData');
          console.log(responseData.data);

          // return responseData;

          setAdditionalCharges(responseData.data.additional_charges);

          return responseData.data.additional_charges;
        } else {
          console.log(' ***** ERROR ** saveAdditionalCharge ** ERROR ***** ');
        }

        // .then((response) => response.json())
        // .then((response) => {
        //   if (response.ok) {
        //     console.log('-- SUCCESS --');
        //     return response.json();
        //   } else {
        //     console.log('-- NOT Successful --');
        //   }
        // })
        // // .then((response) => response.json())
        // .then((data) => {
        //   console.log('-- data -- ');
        //   console.table(data);

        //   return data;
        // })
        // .catch((error) => {
        //   console.log(' - ERROR saving Additional Charge - ');
        //   console.log('error');
        //   console.log(error);
        // });

        // console.log('name 2 = ' + name);

        // if (!response.ok) {
        //   throw new Error('Request failed!');
        // }

        // console.log('name 3 = ' + name);

        // const data = response.json();

        // console.log('name 4 = ' + name);

        // console.log('data');
        // console.log(data);

        // return data;

        // applyData(data);
      } catch (err) {
        // setError(err.message || 'Something went wrong!');
      }
      // setLoading(false);
    },
    [authCtx.baseApiUrl, authCtx.token]
  );

  // const saveAdditionalCharge_first_try = (props) => {
  //   const { additionalChargeUuid, name, description, price, status } = props;

  //   let isLoading = true;
  //   let isError = false;

  //   console.log('saveAdditionalCharge');
  //   // console.log('props');
  //   // console.table(props);
  //   // console.log('props');
  //   console.log('additionalChargeUuid = ' + additionalChargeUuid);
  //   console.log('name = ' + name);
  //   console.log('description = ' + description);
  //   console.log('price = ' + price);
  //   console.log('status = ' + status);

  //   // const keepGoing = true;

  //   // if (!keepGoing) {
  //   //   console.log('saveAdditionalCharge - keepGoing = ' + keepGoing);
  //   //   return;
  //   // }

  //   let apiURL = '';
  //   let apiMethod = '';

  //   if (additionalChargeUuid !== '' && additionalChargeUuid !== undefined) {
  //     apiURL = authCtx.baseApiUrl + 'additionalcharges/' + additionalChargeUuid;
  //     apiMethod = 'PATCH';
  //   } else {
  //     apiURL = authCtx.baseApiUrl + 'additionalcharges';
  //     apiMethod = 'POST';
  //   }

  //   // console.log(' - apiURL = ' + apiURL);
  //   // console.log(' - apiMethod = ' + apiMethod);

  //   fetch(apiURL, {
  //     method: apiMethod,
  //     body: JSON.stringify({
  //       order_uuid: orderUuid,
  //       name: name,
  //       description: description,
  //       price: price,
  //       status: status,
  //     }),
  //     headers: {
  //       Authorization: authCtx.token,
  //       'Content-Type': 'application/json',
  //     },
  //   }).then((res) => {
  //     // setIsLoading(false);

  //     // if (!res.ok) {
  //     //   throw new Error('Request failed!');
  //     // }

  //     // const data = await response.json();

  //     // console.log(' - are we good to here ???? - ');

  //     if (res.ok) {
  //       // console.log(' - res.ok - but after it ????? ');

  //       return res.json().then((responseData) => {
  //         console.log(' ** SUCCESS ** ');
  //         console.log(responseData);

  //         isLoading = false;
  //         isError = false;
  //       });
  //     } else {
  //       return res.json().then((responseData) => {
  //         // setIsError(true);
  //         if (
  //           responseData &&
  //           Array.isArray(responseData.messages) &&
  //           responseData.messages.length
  //         ) {
  //           const messageText = responseData.messages.map((message) => `${message}`).join(', ');
  //           // setNotify({ isOpen: true, message: messageText, type: 'error' });
  //           console.log(messageText);
  //         } else {
  //           // setNotify({
  //           //   isOpen: true,
  //           //   message: 'Additional Charge Not Saved',
  //           //   type: 'error',
  //           // });
  //           console.log('Additional Charge Not Saved');
  //         }
  //       });
  //     }
  //   });
  // };

  return { additionalCharges, saveAdditionalCharge };
};

export default useAdditionalCharge;
