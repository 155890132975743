import { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Paper } from '@mui/material';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
import StatusSelectFormField from '../FormControls/StatusSelectFormField';
import classes from '../CSS/standard-mui-form.module.css';

const DeliveryHoursForm = (props) => {
  // const { deliveryHours, restaurantUuid, onUpdated, onCancel, onDataUpdated } = props;
  const { deliveryHours, restaurantUuid } = props;

  const authCtx = useContext(AuthContext);
  const userRole = authCtx.userRole;
  const dashboardUrl = authCtx.dashboardUrl;
  const displayStatus = false;

  const history = useHistory();

  const [deliveryHoursStatus, setDeliveryHoursStatus] = useState(
    deliveryHours.status ? deliveryHours.status : 'Active'
  );
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const [mondayClosedChecked, setMondayClosedChecked] = useState(
    deliveryHours.mondayClosed ? true : false
  );
  const [tuesdayClosedChecked, setTuesdayClosedChecked] = useState(
    deliveryHours.tuesdayClosed ? true : false
  );
  const [wednesdayClosedChecked, setWednesdayClosedChecked] = useState(
    deliveryHours.wednesdayClosed ? true : false
  );
  const [thursdayClosedChecked, setThursdayClosedChecked] = useState(
    deliveryHours.thursdayClosed ? true : false
  );
  const [fridayClosedChecked, setFridayClosedChecked] = useState(
    deliveryHours.fridayClosed ? true : false
  );
  const [saturdayClosedChecked, setSaturdayClosedChecked] = useState(
    deliveryHours.saturdayClosed ? true : false
  );
  const [sundayClosedChecked, setSundayClosedChecked] = useState(
    deliveryHours.sundayClosed ? true : false
  );

  // const nameRef = useRef();
  const mondayStartRef = useRef();
  const mondayEndRef = useRef();
  const tuesdayStartRef = useRef();
  const tuesdayEndRef = useRef();
  const wednesdayStartRef = useRef();
  const wednesdayEndRef = useRef();
  const thursdayStartRef = useRef();
  const thursdayEndRef = useRef();
  const fridayStartRef = useRef();
  const fridayEndRef = useRef();
  const saturdayStartRef = useRef();
  const saturdayEndRef = useRef();
  const sundayStartRef = useRef();
  const sundayEndRef = useRef();
  const statusRef = useRef();

  const submitHandler = (event) => {
    event.preventDefault();

    const enteredMondayStart = mondayStartRef.current ? mondayStartRef.current.value : null;
    const enteredMondayEnd = mondayEndRef.current ? mondayEndRef.current.value : null;
    const enteredTuesdayStart = tuesdayStartRef.current ? tuesdayStartRef.current.value : null;
    const enteredTuesdayEnd = tuesdayEndRef.current ? tuesdayEndRef.current.value : null;
    const enteredWednesdayStart = wednesdayStartRef.current
      ? wednesdayStartRef.current.value
      : null;
    const enteredWednesdayEnd = wednesdayEndRef.current ? wednesdayEndRef.current.value : null;
    const enteredThursdayStart = thursdayStartRef.current ? thursdayStartRef.current.value : null;
    const enteredThursdayEnd = thursdayEndRef.current ? thursdayEndRef.current.value : null;
    const enteredFridayStart = fridayStartRef.current ? fridayStartRef.current.value : null;
    const enteredFridayEnd = fridayEndRef.current ? fridayEndRef.current.value : null;
    const enteredSaturdayStart = saturdayStartRef.current ? saturdayStartRef.current.value : null;
    const enteredSaturdayEnd = saturdayEndRef.current ? saturdayEndRef.current.value : null;
    const enteredSundayStart = sundayStartRef.current ? sundayStartRef.current.value : null;
    const enteredSundayEnd = sundayEndRef.current ? sundayEndRef.current.value : null;
    const enteredStatus = statusRef.current ? statusRef.current.value : 'Active';

    let apiURL = '';
    let apiMethod = '';

    if (deliveryHours.deliveryHoursUuid !== '' && deliveryHours.deliveryHoursUuid !== undefined) {
      apiURL = authCtx.baseApiUrl + 'deliveryhours/' + deliveryHours.deliveryHoursUuid;
      apiMethod = 'PATCH';
    } else {
      apiURL = authCtx.baseApiUrl + 'deliveryhours';
      apiMethod = 'POST';
    }

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        restaurant_uuid: restaurantUuid,
        monday_start: enteredMondayStart,
        monday_end: enteredMondayEnd,
        monday_closed: mondayClosedChecked,
        tuesday_start: enteredTuesdayStart,
        tuesday_end: enteredTuesdayEnd,
        tuesday_closed: tuesdayClosedChecked,
        wednesday_start: enteredWednesdayStart,
        wednesday_end: enteredWednesdayEnd,
        wednesday_closed: wednesdayClosedChecked,
        thursday_start: enteredThursdayStart,
        thursday_end: enteredThursdayEnd,
        thursday_closed: thursdayClosedChecked,
        friday_start: enteredFridayStart,
        friday_end: enteredFridayEnd,
        friday_closed: fridayClosedChecked,
        saturday_start: enteredSaturdayStart,
        saturday_end: enteredSaturdayEnd,
        saturday_closed: saturdayClosedChecked,
        sunday_start: enteredSundayStart,
        sunday_end: enteredSundayEnd,
        sunday_closed: sundayClosedChecked,
        status: enteredStatus,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      // setIsLoading(false);

      if (res.ok) {
        // nameRef.current.value = '';

        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          history.push(dashboardUrl + 'restaurants/details/' + restaurantUuid);
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          // console.log(responseData);
          // setIsError(true);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            console.table(responseData.messages);
            setNotify({
              isOpen: true,
              message: responseData.messages,
              type: 'error',
            });
          } else {
            console.log('*** SOMETHING HAPPENED ***');
            setNotify({
              isOpen: true,
              message: 'Something Happened',
              type: 'error',
            });
          }
        });
      }
    });
  };

  const statusChangeHandler = (event) => {
    // console.log('statusChangeHandler - event.target.value = ' + event.target.value);
    setDeliveryHoursStatus(event.target.value);
  };

  if (!deliveryHours.deliveryHoursUuid) {
    deliveryHours.mondayStart = '10:00';
    deliveryHours.mondayEnd = '20:00';
    deliveryHours.tuesdayStart = '10:00';
    deliveryHours.tuesdayEnd = '20:00';
    deliveryHours.wednesdayStart = '10:00';
    deliveryHours.wednesdayEnd = '20:00';
    deliveryHours.thursdayStart = '10:00';
    deliveryHours.thursdayEnd = '20:00';
    deliveryHours.fridayStart = '10:00';
    deliveryHours.fridayEnd = '20:00';
    deliveryHours.saturdayStart = '10:00';
    deliveryHours.saturdayEnd = '23:00';
    deliveryHours.sundayStart = '10:00';
    deliveryHours.sundayEnd = '20:00';
  }

  const cancelHandler = () => {
    console.log('cancelHandler');
    history.push(dashboardUrl + 'restaurants/details/' + restaurantUuid);
    // onCancel();
  };

  const handleClosedCheckboxHandler = (event) => {
    console.log('handleClosedCheckboxHandler');
    console.log('event');
    console.log(event);

    const checkboxName = event.target.name;
    const checkboxChecked = event.target.value;

    // console.log('checkboxName = ' + checkboxName);
    // console.log('checkboxChecked = ' + checkboxChecked);

    switch (checkboxName) {
      case 'monday_closed':
        setMondayClosedChecked(checkboxChecked);
        break;
      case 'tuesday_closed':
        setTuesdayClosedChecked(checkboxChecked);
        break;
      case 'wednesday_closed':
        setWednesdayClosedChecked(checkboxChecked);
        break;
      case 'thursday_closed':
        setThursdayClosedChecked(checkboxChecked);
        break;
      case 'friday_closed':
        setFridayClosedChecked(checkboxChecked);
        break;
      case 'saturday_closed':
        setSaturdayClosedChecked(checkboxChecked);
        break;
      case 'sunday_closed':
        setSundayClosedChecked(checkboxChecked);
        break;
      default:
        console.log('No Matching Day Selected');
    }

    // console.log('mondayClosedChecked = ' + mondayClosedChecked);
    // console.log('tuesdayClosedChecked = ' + tuesdayClosedChecked);
    // console.log('wednesdayClosedChecked = ' + wednesdayClosedChecked);
    // console.log('thursdayClosedChecked = ' + thursdayClosedChecked);
    // console.log('fridayClosedChecked = ' + fridayClosedChecked);
    // console.log('saturdayClosedChecked = ' + saturdayClosedChecked);
    // console.log('sundayClosedChecked = ' + sundayClosedChecked);
  };

  return (
    <Box>
      <Paper elevation={4} sx={{ maxWidth: '120rem' }}>
        <form onSubmit={submitHandler}>
          <Box className={classes['form-container']}>
            <Box align='center'>
              <table className='table-simple table-simple-padded'>
                <tbody>
                  <tr>
                    <td className='table-td-label'>Monday</td>
                    <td className='table-td-form-field pl-10'>
                      <FormControls.InputControl
                        type='time'
                        name='monday_start'
                        label='Start'
                        defaultValue={deliveryHours.mondayStart}
                        inputRef={mondayStartRef}
                        className={classes['mui-control']}
                      />
                    </td>
                    <td className='table-td-form-field pl-10'>
                      <FormControls.InputControl
                        name='monday_end'
                        label='End (max)'
                        inputProps={{ type: 'time', min: '12:00', max: '23:30' }}
                        defaultValue={deliveryHours.mondayEnd}
                        inputRef={mondayEndRef}
                        className={classes['mui-control']}
                      />
                    </td>
                    <td className='table-td-form-field pl-10'>
                      <Box>
                        <FormControls.CheckboxControl
                          name='monday_closed'
                          label='Closed'
                          // value={mondayClosedChecked ? 'checked' : null}
                          value={mondayClosedChecked}
                          onChange={handleClosedCheckboxHandler}
                        />
                      </Box>
                    </td>
                    <td className='spacer'></td>
                    <td className='table-td-label'>Tuesday</td>
                    <td className='table-td-form-field pl-10'>
                      <FormControls.InputControl
                        type='time'
                        name='tuesday_start'
                        label='Start'
                        defaultValue={deliveryHours.tuesdayStart}
                        inputRef={tuesdayStartRef}
                        className={classes['mui-control']}
                      />
                    </td>
                    <td className='table-td-form-field pl-10'>
                      <FormControls.InputControl
                        name='tuesday_end'
                        label='End'
                        inputProps={{ type: 'time', min: '12:00', max: '23:30' }}
                        defaultValue={deliveryHours.tuesdayEnd}
                        inputRef={tuesdayEndRef}
                        className={classes['mui-control']}
                      />
                    </td>
                    <td className='table-td-form-field pl-10'>
                      <Box>
                        <FormControls.CheckboxControl
                          name='tuesday_closed'
                          label='Closed'
                          value={tuesdayClosedChecked}
                          onChange={handleClosedCheckboxHandler}
                        />
                      </Box>
                    </td>
                  </tr>
                  <tr>
                    <td className='table-td-label'>Wednesday</td>
                    <td className='table-td-form-field pl-10'>
                      <FormControls.InputControl
                        type='time'
                        name='wednesday_start'
                        label='Start'
                        defaultValue={deliveryHours.wednesdayStart}
                        inputRef={wednesdayStartRef}
                        className={classes['mui-control']}
                      />
                    </td>
                    <td className='table-td-form-field pl-10'>
                      <FormControls.InputControl
                        name='wednesday_end'
                        label='End'
                        inputProps={{ type: 'time', min: '12:00', max: '23:30' }}
                        defaultValue={deliveryHours.wednesdayEnd}
                        inputRef={wednesdayEndRef}
                        className={classes['mui-control']}
                      />
                    </td>
                    <td className='table-td-form-field pl-10'>
                      <Box>
                        <FormControls.CheckboxControl
                          name='wednesday_closed'
                          label='Closed'
                          value={wednesdayClosedChecked}
                          onChange={handleClosedCheckboxHandler}
                        />
                      </Box>
                    </td>
                    <td className='spacer'></td>
                    <td className='table-td-label'>Thursday</td>
                    <td className='table-td-form-field pl-10'>
                      <FormControls.InputControl
                        type='time'
                        name='thursday_start'
                        label='Start'
                        defaultValue={deliveryHours.thursdayStart}
                        inputRef={thursdayStartRef}
                        className={classes['mui-control']}
                      />
                    </td>
                    <td className='table-td-form-field pl-10'>
                      <FormControls.InputControl
                        name='thursday_end'
                        label='End'
                        inputProps={{ type: 'time', min: '12:00', max: '23:30' }}
                        defaultValue={deliveryHours.thursdayEnd}
                        inputRef={thursdayEndRef}
                        className={classes['mui-control']}
                      />
                    </td>
                    <td className='table-td-form-field pl-10'>
                      <Box>
                        <FormControls.CheckboxControl
                          name='thursday_closed'
                          label='Closed'
                          value={thursdayClosedChecked}
                          onChange={handleClosedCheckboxHandler}
                        />
                      </Box>
                    </td>
                  </tr>
                  <tr>
                    <td className='table-td-label'>Friday</td>
                    <td className='table-td-form-field pl-10'>
                      <FormControls.InputControl
                        type='time'
                        name='friday_start'
                        label='Start'
                        defaultValue={deliveryHours.fridayStart}
                        inputRef={fridayStartRef}
                        className={classes['mui-control']}
                      />
                    </td>
                    <td className='table-td-form-field pl-10'>
                      <FormControls.InputControl
                        name='friday_end'
                        label='End'
                        inputProps={{ type: 'time', min: '12:00', max: '23:30' }}
                        defaultValue={deliveryHours.fridayEnd}
                        inputRef={fridayEndRef}
                        className={classes['mui-control']}
                      />
                    </td>
                    <td className='table-td-form-field pl-10'>
                      <Box>
                        <FormControls.CheckboxControl
                          name='friday_closed'
                          label='Closed'
                          value={fridayClosedChecked}
                          onChange={handleClosedCheckboxHandler}
                        />
                      </Box>
                    </td>
                    <td className='spacer'></td>
                    <td className='table-td-label'>Saturday</td>
                    <td className='table-td-form-field pl-10'>
                      <FormControls.InputControl
                        type='time'
                        name='saturday_start'
                        label='Start'
                        defaultValue={deliveryHours.saturdayStart}
                        inputRef={saturdayStartRef}
                        className={classes['mui-control']}
                      />
                    </td>
                    <td className='table-td-form-field pl-10'>
                      <FormControls.InputControl
                        name='saturday_end'
                        label='End'
                        inputProps={{ type: 'time', min: '12:00', max: '23:30' }}
                        defaultValue={deliveryHours.saturdayEnd}
                        inputRef={saturdayEndRef}
                        className={classes['mui-control']}
                      />
                    </td>
                    <td className='table-td-form-field pl-10'>
                      <Box>
                        <FormControls.CheckboxControl
                          name='saturday_closed'
                          label='Closed'
                          value={saturdayClosedChecked}
                          onChange={handleClosedCheckboxHandler}
                        />
                      </Box>
                    </td>
                  </tr>
                  <tr>
                    <td className='table-td-label'>Sunday</td>
                    <td className='table-td-form-field pl-10'>
                      <FormControls.InputControl
                        type='time'
                        name='sunday_start'
                        label='Start'
                        defaultValue={deliveryHours.sundayStart}
                        inputRef={sundayStartRef}
                        className={classes['mui-control']}
                      />
                    </td>
                    <td className='table-td-form-field pl-10'>
                      <FormControls.InputControl
                        name='sunday_end'
                        label='End'
                        inputProps={{ type: 'time', min: '12:00', max: '23:30' }}
                        defaultValue={deliveryHours.sundayEnd}
                        inputRef={sundayEndRef}
                        className={classes['mui-control']}
                      />
                    </td>
                    <td className='table-td-form-field pl-10'>
                      <Box>
                        <FormControls.CheckboxControl
                          name='sunday_closed'
                          label='Closed'
                          value={sundayClosedChecked}
                          onChange={handleClosedCheckboxHandler}
                        />
                      </Box>
                    </td>
                    <td className='spacer'></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className='table-td-form-field pl-10'>
                      {displayStatus && (deliveryHoursStatus !== 'New' || userRole === 'Admin') ? (
                        <Box>
                          <StatusSelectFormField
                            currentStatus={deliveryHoursStatus}
                            onChange={statusChangeHandler}
                          />
                        </Box>
                      ) : null}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>

            <Box component='div' className={classes['actions-container']}>
              <FormControls.ButtonControl text='Save' type='submit' />
              <FormControls.ButtonControl text='Cancel' type='button' onClick={cancelHandler} />
            </Box>
          </Box>
        </form>
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default DeliveryHoursForm;
