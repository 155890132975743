import React, { useContext, useEffect } from 'react';
import { Route, Redirect, Switch, useHistory } from 'react-router-dom';
import { Box } from '@mui/material';

import OrderContext from '../store/order-context';
import OrderDetailsHeader from '../components/Order/OrderDetailsHeader';
// import OrderRestaurantList from '../components/Order/OrderRestaurantList';
import SelectRestaurant from '../components/Order/SelectRestaurant';
import RestaurantMenu from '../components/Order/RestaurantMenu';
// import MenuCategoriesHeader from '../components/Order/MenuCategoriesHeader';
import OrderCart from '../components/Order/OrderCart';
import classes from '../components/CSS/Order.module.css';

const OrderPage = () => {
  const orderCtx = useContext(OrderContext);
  const history = useHistory();

  // const deliveryCity = orderCtx.orderDetails.deliveryCity;
  const deliveryLocationUuid = orderCtx.orderDetails.deliveryLocationUuid;
  const restaurantUuid = orderCtx.orderDetails.restaurantUuid;

  const pathStart = '/order/';

  // console.log('** OrderPage **');
  // console.log('orderCtx.orderDetails');
  // console.table(orderCtx.orderDetails);

  useEffect(() => {
    // console.log('- useEffect Test - ');
    // console.log(' - useEffect Test - deliveryCity = ' + deliveryCity);
    if (!deliveryLocationUuid && restaurantUuid) {
      console.log('- useEffect Test - NO VALUE - should redirect');
      history.replace('/team-dashboard/new-order');
    } else {
      // console.log('- useEffect Test - There is a Value - deliveryLocationUuid = ' + deliveryCity);
    }
  }, [deliveryLocationUuid, restaurantUuid, history]);

  // const editCartItemHandler = (item) => {
  //   console.log('editCartItemHandler');
  //   console.log('item');
  //   console.table(item);
  // };

  const displayCart = false;

  return (
    <>
      <OrderDetailsHeader />
      <div className='content-container'>
        <Box>
          <Switch>
            <Route path={pathStart + 'select-restaurant'} exact>
              <SelectRestaurant />
            </Route>
            <Route path={pathStart + 'restaurant-menu'} exact>
              <RestaurantMenu />
            </Route>
            <Route path={pathStart + 'reorder-set-delivery'} exact>
              <SelectRestaurant />
            </Route>
            <Route path='*'>
              <Redirect to={pathStart + 'select-restaurant'} />
            </Route>
          </Switch>
        </Box>
      </div>

      {displayCart ? (
        <div className='content-container'>
          <Box component='div' className={classes['order-container']}>
            <Box>
              <Switch>
                <Route path={pathStart + 'select-restaurant'} exact>
                  <SelectRestaurant />
                </Route>
                <Route path={pathStart + 'restaurant-menu'} exact>
                  <RestaurantMenu />
                </Route>
                <Route path='*'>
                  <Redirect to={pathStart + 'select-restaurant'} />
                </Route>
              </Switch>
            </Box>
            <Box component='div' className={classes['cart-section']}>
              <Box component='div' className={classes['cart-holder-fixed']}>
                <Box component='div' className={classes['cart']}>
                  <OrderCart />
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      ) : null}
    </>
  );
};

export default OrderPage;
