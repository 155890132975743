import React, { useState, useEffect, useContext, useRef } from 'react';

import AuthContext from '../../store/auth-context';
import * as Constants from '../../helpers/Constants';
import { displayListFilterSelect } from '../../helpers/FilterListHelperFunctions';
import Notification from '../MUI/UI/Notification';
import ConfirmationDialog from '../MUI/UI/ConfirmationDialog';
import classes from '../CSS/styled-table.module.css';

const LeagueList = (props) => {
  const authCtx = useContext(AuthContext);

  const [leagues, setLeagues] = useState([]);
  const [displayedLeagues, setDisplayedLeagues] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const statusFilterRef = useRef();
  // const defaultStatusFilter = 'Show All';
  const defaultStatusFilter = 'Active';

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);

      const response = await fetch(authCtx.baseApiUrl + 'globalleagues', {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(' -- response.ok -- ');
        // console.log(responseData.data.global_leagues);

        const transformedLeagues = responseData.data.global_leagues.map((league) => {
          return {
            leagueUuid: league.globalLeagueUuid,
            name: league.name,
            description: league.description,
            sortOrder: league.sortOrder,
            status: league.status,
          };
        });

        setLeagues(transformedLeagues);

        if (defaultStatusFilter === 'Show All') {
          setDisplayedLeagues(transformedLeagues);
        } else {
          const filteredRecords = transformedLeagues.filter(
            (record) => record.status === defaultStatusFilter
          );
          setDisplayedLeagues(filteredRecords);
        }
      } else {
        setIsError(true);
      }
      setIsLoading(false);
      setRefreshData(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, refreshData]);

  const displayTableHeader = () => {
    return (
      <tr>
        <th>Name</th>
        <th>Description</th>
        <th>Sort Order</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
    );
  };

  const displayTableBody = () => {
    return displayedLeagues.map((league, index) => (
      <tr key={index}>
        <td>{league.name}</td>
        <td>{league.description}</td>
        <td>{league.sortOrder}</td>
        <td>{league.status}</td>
        <td>
          <div className={classes['table-actions-container']}>
            <button onClick={(event) => props.onEdit(`${league.leagueUuid}`)} className='small'>
              Edit
            </button>
            <button
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: 'Are you sure you want to delete this record.',
                  subTitle: 'It will be permanently deleted.',
                  onConfirm: () => {
                    deleteHandler(league.leagueUuid);
                  },
                });
              }}
              className='small'
            >
              Delete
            </button>
          </div>
        </td>
      </tr>
    ));
  };

  const clearFilterHandler = () => {
    statusFilterRef.current.value = defaultStatusFilter;

    filterDisplayedRecords();
  };

  const filterDisplayedRecords = () => {
    let statusFilter = statusFilterRef.current.value;

    let filteredRecords = leagues;

    if (statusFilter !== 'Show All') {
      filteredRecords = filteredRecords.filter((record) => record.status === statusFilter);
    }

    setDisplayedLeagues(filteredRecords);
  };

  const deleteHandler = (leagueUuid) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    // console.log(' - handleDelete - leagueUuid = ' + leagueUuid);
    deleteLeagueHandler(leagueUuid);
    // setNotify({ isOpen: true, message: 'Deleted Successfully', type: 'error' });
  };

  const deleteLeagueHandler = (leagueUuid) => {
    // console.log(' -- deleteLeagueHandler -- ');
    // console.log('leagueUuid = ' + leagueUuid);

    const deleteData = async () => {
      const response = await fetch(authCtx.baseApiUrl + 'globalleagues/' + leagueUuid, {
        method: 'DELETE',
        headers: {
          Authorization: authCtx.token,
        },
      });

      if (response.ok) {
        // const responseData = await response.json();
        // console.log(responseData);

        setNotify({ isOpen: true, message: 'Deleted Successfully', type: 'success' });

        setRefreshData(true);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    deleteData().catch(console.error);
  };

  return (
    <>
      {!isLoading && !isError && (
        <div className='button-holder'>
          <label htmlFor='filter-by-status'>Status</label>
          {displayListFilterSelect(
            'filter-by-status',
            statusFilterRef,
            filterDisplayedRecords,
            Constants.STATUS_ARRAY,
            defaultStatusFilter
          )}
          <button className='small' onClick={clearFilterHandler}>
            Clear Filters
          </button>
        </div>
      )}
      {isLoading && (
        <div className='message-holder'>
          <p className='loading'>Loading...</p>
        </div>
      )}
      {isError && (
        <div className='message-holder'>
          <p className='error'>Error...</p>
        </div>
      )}
      {!isLoading && leagues.length === 0 && (
        <div className='message-holder'>
          <p className='error'>No Leagues Found...</p>
        </div>
      )}
      {!isLoading && leagues.length > 0 && (
        <div className='table-holder'>
          <table className={`${classes['styled-table']} ${classes['full-width']}`}>
            <thead>{displayTableHeader()}</thead>
            <tbody>{displayTableBody()}</tbody>
          </table>
        </div>
      )}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default LeagueList;
