import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import MenuItemForm from './MenuItemForm';

const MenuItemAdd = () => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const restaurantUuid = authCtx.selectedRestaurantUuid;
  const dashboardUrl = authCtx.dashboardUrl;

  const backHandler = () => {
    // history.push(dashboardUrl + 'menu-items/' + restaurantUuid);
    history.push(dashboardUrl + 'restaurants/menu-items/' + restaurantUuid);
  };

  return (
    <StandardPageWrapper>
      <Box>
        <Box className='title-and-button-holder'>
          <Typography variant='h1' component='h1'>
            Add Menu Item
          </Typography>
          <Box className='button-holder-no-pb'>
            <Button variant='contained' size='custom' color='accent' onClick={backHandler}>
              Back to Menu
            </Button>
          </Box>
        </Box>

        <MenuItemForm />
      </Box>
    </StandardPageWrapper>
  );
};

export default MenuItemAdd;
