import StandardPageWrapper from '../Layout/StandardPageWrapper';

const Dashboard = () => {
  return (
    <StandardPageWrapper title='Vendor Dashboard'>
      <section>
        <p>This will be the main page for all Vendors (Restaurants/Caterers) after they login.</p>
        <p>Some things that I think might make sense to be here would be:</p>
        <ul>
          <li>Display and edit Account Data.</li>
          <li>Display and edit Restaurant/Caterer Data.</li>
          <li>The last 10 or so Orders that were placed.</li>
          <li>
            When we add the ability for Users to communicate back and forth this would be a good
            place for that data as well.
          </li>
        </ul>
      </section>
    </StandardPageWrapper>
  );
};

export default Dashboard;
