import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// import * as Constants from '../../helpers/Constants';

const styles = StyleSheet.create({
  page: {
    fontSize: '11px',
    fontFamily: 'Times-Roman',
    // color: '#333333',
    color: '#666666',
    paddingTop: '10px',
    paddingBottom: '40px',
  },

  header: {
    flexDirection: 'row',
    gap: '20px',
    justifyContent: 'space-between',
    padding: '0 10px 30px 10px',
  },

  section: {
    padding: '0 10px 10px 10px',
  },

  footer: {
    textAlign: 'center',
    fontSize: '8px',
  },

  pageNumber: {
    position: 'absolute',
    fontSize: '10px',
    bottom: '10px',
    left: 0,
    right: 0,
    textAlign: 'center',
  },

  //

  extraPaddingBottom: {
    paddingBottom: '10px',
  },

  rowColumnHeaders: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '5px',
    // paddingBottom: '5px',
    color: '#000000',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '5px',
    paddingBottom: '5px',
    borderTop: '1px solid #EEE',
    // borderBottom: '1px solid #EEE',
  },

  rowTotals: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '5px',
    paddingBottom: '10px',
    color: '#000000',
    borderTop: '3px solid #EEE',
  },

  stackedContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    // border: '1px solid #EEE',
  },

  largetext: {
    fontSize: '14px',
    color: '#000000',
    paddingBottom: '5px',
  },
  smalltext: {
    paddingTop: '2px',
    fontSize: '10px',
    // verticalAlign: 'bottom',
  },
  boldText: {
    color: '#000000',
  },

  columnPaymentDate: {
    width: '13%',
    paddingRight: '10px',
  },
  columnOrderNumber: {
    width: '14%',
    paddingRight: '10px',
  },
  columnVendor: {
    width: '25%',
    paddingRight: '10px',
  },
  columnVendorAddress: {
    width: '25%',
    paddingRight: '10px',
  },
  columnTax: {
    width: '10%',
    textAlign: 'right',
    paddingRight: '10px',
  },
  columnTotal: {
    width: '13%',
    textAlign: 'right',
  },

  sectionTotals: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  columnTotalsLabel: {
    width: '77%',
  },

  headerDateRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingBottom: '2px',
  },
  headerDateTitle: {
    textAlign: 'right',
    paddingRight: '5px',
  },

  // If vendor name and address are displayed in the same column.
  columnVendorAndAddress: {
    width: '50%',
    paddingRight: '10px',
  },
  // columnTax: {
  //   width: '10%',
  //   textAlign: 'right',
  // },
  // columnTotal: {
  //   width: '15%',
  //   textAlign: 'right',
  // },
});

const PdfExpenseReport = ({
  orders,
  team,
  startDate,
  endDate,
  ordersTotalLabel,
  ordersTaxTotal,
  ordersTotal,
}) => {
  // console.log('##### PdfExpenseReport #####');
  // console.log('--- orders ---');
  // console.log(orders);
  // console.log('startDate = ' + startDate + ' --- endDate = ' + endDate);

  const displayVendorAddressUnderName = true;
  const displayContentTwiceForTesting = false;

  return (
    <Document>
      <Page style={styles.page}>
        <View fixed>
          <View style={styles.header}>
            <View style={[styles.stackedContent]}>
              {/* <Image
                src={Constants.IMAGE_FOLDER_PATH + 'logos/elite_eats_e_100.png'}
                style={styles.logo}
              /> */}
              <Text style={styles.largetext}>Elite Eats Inc.</Text>
              <Text>12070 Glenridge Lane</Text>
              <Text>Colorado springs CO, 80908</Text>
              <Text>www.elite-eats.com</Text>
            </View>

            <View style={[styles.stackedContent]}>
              <Text style={[styles.largetext, styles.extraPaddingBottom]}>
                Expense Summary Report
              </Text>

              <View style={styles.headerDateRow}>
                <Text style={[styles.boldText]}>{team}</Text>
              </View>
              <View style={styles.headerDateRow}>
                <Text style={styles.headerDateTitle}>Start Date:</Text>
                <Text>{startDate}</Text>
              </View>
              <View style={styles.headerDateRow}>
                <Text style={styles.headerDateTitle}>End Date:</Text>
                <Text>{endDate}</Text>
              </View>
            </View>
          </View>

          <View style={styles.section}>
            {Array.isArray(orders) && orders.length > 0 ? (
              <View style={styles.rowColumnHeaders} wrap={false}>
                <Text style={[styles.columnPaymentDate, styles.smalltext]}>Payment Date</Text>
                <Text style={[styles.columnOrderNumber, styles.smalltext]}>Order Number</Text>
                {displayVendorAddressUnderName ? (
                  <Text style={[styles.columnVendorAndAddress, styles.smalltext]}>Vendor</Text>
                ) : (
                  <>
                    <Text style={[styles.columnVendor, styles.smalltext]}>Vendor</Text>
                    <Text style={[styles.columnVendorAddress, styles.smalltext]}>
                      Vendor Address
                    </Text>
                  </>
                )}
                <Text style={[styles.columnTax, styles.smalltext]}>Order Tax</Text>
                <Text style={[styles.columnTotal, styles.smalltext]}>Order Total</Text>
              </View>
            ) : null}
          </View>
        </View>

        <View style={styles.section}>
          {Array.isArray(orders) && orders.length > 0 ? (
            <>
              {orders.map((row, i) => (
                <View key={i} style={styles.row} wrap={false}>
                  <Text style={styles.columnPaymentDate}>{row.paymentDateDateOnly}</Text>
                  <Text style={styles.columnOrderNumber}>{row.orderNumber}</Text>
                  {displayVendorAddressUnderName ? (
                    <View style={[styles.columnVendorAndAddress, styles.stackedContent]}>
                      <Text>{row.restaurantName}</Text>
                      <Text style={styles.smalltext}>{row.restaurantAddress}</Text>
                    </View>
                  ) : (
                    <>
                      <Text style={styles.columnVendor}>{row.restaurantName}</Text>
                      <Text style={styles.columnVendorAddress}>{row.restaurantAddress}</Text>
                    </>
                  )}

                  <Text style={styles.columnTax}>{row.taxFormatted}</Text>
                  <Text style={styles.columnTotal}>{row.totalFormatted}</Text>
                </View>
              ))}
            </>
          ) : null}
        </View>

        {/* Duplicate the content for testing purposes */}
        {displayContentTwiceForTesting ? (
          <View style={styles.section}>
            {Array.isArray(orders) && orders.length > 0 ? (
              <>
                {orders.map((row, i) => (
                  <View key={i} style={styles.row} wrap={false}>
                    <Text style={styles.columnPaymentDate}>{row.paymentDateDateOnly}</Text>
                    <Text style={styles.columnOrderNumber}>{row.orderNumber}</Text>
                    {displayVendorAddressUnderName ? (
                      <View style={[styles.columnVendorAndAddress, styles.stackedContent]}>
                        <Text>{row.restaurantName}</Text>
                        <Text style={styles.smalltext}>{row.restaurantAddress}</Text>
                      </View>
                    ) : (
                      <>
                        <Text style={styles.columnVendor}>{row.restaurantName}</Text>
                        <Text style={styles.columnVendorAddress}>{row.restaurantAddress}</Text>
                      </>
                    )}

                    <Text style={styles.columnTax}>{row.taxFormatted}</Text>
                    <Text style={styles.columnTotal}>{row.totalFormatted}</Text>
                  </View>
                ))}
              </>
            ) : null}
          </View>
        ) : null}

        <View style={styles.sectionTotals}>
          <View style={styles.rowTotals} wrap={false}>
            <Text style={styles.columnTotalsLabel}>{ordersTotalLabel}</Text>
            <Text style={styles.columnTax}>{ordersTaxTotal}</Text>
            <Text style={styles.columnTotal}>{ordersTotal}</Text>
          </View>
        </View>

        <View
          style={styles.pageNumber}
          render={({ totalPages }) =>
            totalPages > 1 && (
              <Text
                style={styles.footer}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              />
            )
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default PdfExpenseReport;
