import StandardPageWrapper from '../Layout/StandardPageWrapper';

import TestRestaurants from './TestRestaurants';
// import TestRestaurantContacts from './TestRestaurantContacts';
import RestaurantContacts from '../RestaurantContacts/RestaurantContacts';

const TestVendorProfile = () => {
  return (
    <StandardPageWrapper>
      <TestRestaurants />
      {/* <TestRestaurantContacts /> */}
      <RestaurantContacts />
    </StandardPageWrapper>
  );
};

export default TestVendorProfile;
