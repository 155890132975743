import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Box, Button, InputAdornment, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import AuthContext from '../../store/auth-context';
import * as Constants from '../../helpers/Constants';
import { displayListFilterSelect } from '../../helpers/FilterListHelperFunctions';
import WorkflowMessage from '../UI/WorkflowMessage';
import Notification from '../MUI/UI/Notification';
import ConfirmationDialog from '../MUI/UI/ConfirmationDialog';
import FormControls from '../MUI/FormControls/FormControls';
import classes from '../CSS/styled-table.module.css';

const DeliveryLocationList = (props) => {
  // console.log(' ***** DeliveryLocationList ***** ');
  const authCtx = useContext(AuthContext);

  // const { urlStart = authCtx.dashboardUrl } = props;

  // Todo:  See if this actually works or not.
  // console.log(' - localStorage - eeon = ' + localStorage.getItem('eeon'));
  // authCtx.checkIfShouldLogout();

  const userRole = authCtx.userRole;
  const dashboardUrl = authCtx.dashboardUrl;

  const [deliveryLocations, setDeliveryLocations] = useState([]);
  const [displayedDeliveryLocations, setDisplayedDeliveryLocations] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    type: 'General',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const cityFilterRef = useRef();
  const stateFilterRef = useRef();
  const statusFilterRef = useRef();
  const searchRef = useRef();

  const history = useHistory();

  const canAddDeliveryLocation = userRole === 'Admin' ? true : false;

  // console.log('apiUrl', apiUrl);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const apiUrl = authCtx.baseApiUrl + 'deliverylocations';
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.log('responseData.data.delivery_locations');
        // console.log(responseData.data.delivery_locations);

        const transformedLocations = responseData.data.delivery_locations.map((location) => {
          return {
            deliveryLocationUuid: location.deliveryLocationUuid,
            name: location.name,
            description: location.description,
            leagues: location.leagues,
            address:
              location.city !== '' && location.state !== ''
                ? location.city + ', ' + location.state
                : location.city !== ''
                ? location.city
                : location.state !== ''
                ? location.state
                : '',
            address1: location.address1,
            address2: location.address2,
            city: location.city,
            state: location.state,
            email: location.email,
            phone: location.phone,
            deliveryInstructions: location.delivery_instructions,
            status: location.status,
            isGlobal: location.isGlobal,
          };
        });

        setDeliveryLocations(transformedLocations);

        // console.log('transformedLocations');
        // console.log(transformedLocations);

        const filteredLocations = transformedLocations.filter(
          (location) => location.status === 'Active'
        );
        setDisplayedDeliveryLocations(filteredLocations);

        const uniqueCities = responseData.data.delivery_location_cities;
        const uniqueStates = responseData.data.delivery_location_states;

        setCities(uniqueCities);
        setStates(uniqueStates);
      } else {
        // If a 401 is returned then there is an issue with the session (most likely).
        if (responseData.statusCode === 401) {
          history.push(dashboardUrl + 'sessionexpired');
        }

        setIsError(true);
      }
      setIsLoading(false);
      setRefreshData(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, history, dashboardUrl, refreshData]);

  const displayTableHeader = () => {
    return (
      <tr>
        <th>Name</th>
        <th>Address</th>
        {/* <th>Address 2</th> */}
        <th>City</th>
        <th>State</th>
        {/* {userRole !== 'Admin' ? <th>Phone</th> : null} */}
        {userRole === 'Admin' ? <th>Leagues</th> : null}
        {userRole === 'Admin' ? <th>Status</th> : null}
        {/* <th>Select</th> */}
        <th>Actions</th>
      </tr>
    );
  };

  const displayTableBody = () => {
    return displayedDeliveryLocations.map((record, index) => (
      <tr key={index}>
        <td>{record.name}</td>
        <td>
          {record.address1} {record.address2 ? ', ' + record.address2 : ''}
        </td>
        <td>{record.city}</td>
        <td>{record.state}</td>
        {userRole === 'Admin' ? <td>{record.leagues}</td> : null}
        {userRole === 'Admin' ? <td>{record.status}</td> : null}
        <td>
          <div className={classes['table-actions-container']}>
            <Link
              to={`${dashboardUrl}delivery-locations/details/${record.deliveryLocationUuid}`}
              className='button small'
            >
              Details
            </Link>
            {canAddDeliveryLocation ? (
              <Link
                to={`${dashboardUrl}delivery-locations/edit/${record.deliveryLocationUuid}`}
                className='button small'
              >
                Edit
              </Link>
            ) : null}

            {!record.isGlobal ||
              (record.isGlobal && userRole === 'Admin' && record.status !== 'Deleted' && (
                <button
                  onClick={() => {
                    setConfirmDialog({
                      isOpen: true,
                      title: 'Are you sure you want to delete this Delivery Location.',
                      subTitle: 'It will be "soft" deleted.',
                      type: 'General',
                      onConfirm: () => {
                        deleteLocationHandler(record.deliveryLocationUuid);
                      },
                    });
                  }}
                  className='small'
                >
                  Delete
                </button>
              ))}
          </div>
        </td>
      </tr>
    ));
  };

  const deleteLocationHandler = (deliveryLocationUuid) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    deleteData(deliveryLocationUuid);
    setNotify({ isOpen: true, message: 'Delivery Location Successfully Deleted', type: 'error' });
  };

  const deleteData = async (deliveryLocationUuid) => {
    const response = await fetch(authCtx.baseApiUrl + 'deliverylocations/' + deliveryLocationUuid, {
      method: 'DELETE',
      headers: {
        Authorization: authCtx.token,
      },
    });

    if (response.ok) {
      // const responseData = await response.json();
      // console.log('##### responseData #####');
      // console.log(responseData);
      await response.json();

      history.replace(dashboardUrl + 'delivery-locations');
    } else {
      setIsError(true);
    }
    setIsLoading(false);
    setRefreshData(true);
  };

  const addNewHandler = () => {
    history.push(dashboardUrl + 'delivery-locations/add');
  };

  const clearFilterHandler = () => {
    cityFilterRef.current.value = 'Show All';
    stateFilterRef.current.value = 'Show All';
    if (statusFilterRef.current) {
      statusFilterRef.current.value = 'Active';
    }

    filterDisplayedRecords();
  };

  const filterDisplayedRecords = () => {
    let cityFilter = cityFilterRef.current.value;
    let stateFilter = stateFilterRef.current.value;
    let statusFilter = statusFilterRef.current ? statusFilterRef.current.value : 'Active';
    let searchValue = searchRef.current ? searchRef.current.value.toLowerCase() : '';

    // console.log(' -- filterDisplayedLocations -- ');
    // console.log({ cityFilter: cityFilter, stateFilter: stateFilter, statusFilter: statusFilter });

    if (
      cityFilter === 'Show All' &&
      stateFilter === 'Show All' &&
      statusFilter === 'Show All' &&
      searchValue === ''
    ) {
      setDisplayedDeliveryLocations(deliveryLocations);
    } else {
      let filteredLocations = deliveryLocations;

      if (cityFilter !== 'Show All') {
        filteredLocations = filteredLocations.filter((record) => record.city === cityFilter);
      }

      if (stateFilter !== 'Show All') {
        filteredLocations = filteredLocations.filter((record) => record.state === stateFilter);
      }

      if (statusFilter !== 'Show All') {
        filteredLocations = filteredLocations.filter((record) => record.status === statusFilter);
      }

      if (searchValue !== '') {
        filteredLocations = filteredLocations.filter(
          (record) =>
            (record.name !== null && record.name.toLowerCase().includes(searchValue)) ||
            (record.city !== null && record.city.toLowerCase().includes(searchValue)) ||
            (record.address1 !== null && record.address1.toLowerCase().includes(searchValue)) ||
            (record.address2 !== null && record.address2.toLowerCase().includes(searchValue)) ||
            (record.leagues !== null && record.leagues.toLowerCase().includes(searchValue))
        );
      }

      setDisplayedDeliveryLocations(filteredLocations);
    }
  };

  const clearSearchHandler = () => {
    if (searchRef.current) {
      searchRef.current.value = '';
    }
    filterDisplayedRecords();
  };

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}
      {!isLoading && !isError && deliveryLocations.length === 0 ? (
        <WorkflowMessage type='error' message={'No Delivery Locations Found  $$$$$'} />
      ) : null}

      <div className='content-container'>
        <Box className='title-and-button-holder'>
          <Typography variant='h1' component='h1'>
            Delivery Locations
          </Typography>
          {!isLoading && !isError && canAddDeliveryLocation ? (
            <Box className='button-holder-no-pb'>
              <Button
                variant='contained'
                size='custom'
                color='accent'
                onClick={addNewHandler}
                autoFocus
              >
                Add New Delivery Location
              </Button>
            </Box>
          ) : null}
        </Box>

        {!isLoading && !isError ? (
          <Box className='mb-20'>
            <Box>
              <label htmlFor='search'>
                Search <span className='small-text'>(by Name, Address, City, League)</span>
              </label>
            </Box>

            <Box
              component='div'
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                minWidth: '300px',
                height: '40px',
                // ml: '10px',
                // border: '1px solid red',
              }}
            >
              <FormControls.InputControl
                type='text'
                name='search'
                id='search'
                // label='Search'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ flexGrow: '1', mr: '2rem' }}
                onChange={filterDisplayedRecords}
                inputRef={searchRef}
              />
              <FormControls.ButtonControl
                name='clearSearch'
                text='Clear'
                variant='contained'
                size='small'
                endIcon={<SearchIcon />}
                onClick={clearSearchHandler}
                sx={{ p: '2rem', fontWeight: 'bold', height: '4rem' }}
              />
            </Box>
          </Box>
        ) : null}

        {!isLoading && !isError && (
          <Box className='button-holder'>
            {/* <label>Filter By: </label> */}
            <Box>
              <Box>
                <label htmlFor='filter-by-city'>City</label>
              </Box>
              {displayListFilterSelect(
                'filter-by-city',
                cityFilterRef,
                filterDisplayedRecords,
                cities
              )}
            </Box>
            <Box>
              <Box>
                <label htmlFor='filter-by-state'>State</label>
              </Box>
              {displayListFilterSelect(
                'filter-by-state',
                stateFilterRef,
                filterDisplayedRecords,
                states
              )}
            </Box>

            {userRole === 'Admin' ? (
              <Box>
                <Box>
                  <label htmlFor='filter-by-status'>Status</label>
                </Box>
                {displayListFilterSelect(
                  'filter-by-status',
                  statusFilterRef,
                  filterDisplayedRecords,
                  Constants.STATUS_ARRAY,
                  'Active'
                )}
              </Box>
            ) : null}

            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <button className='small' onClick={clearFilterHandler}>
                Clear Filters
              </button>
            </Box>
          </Box>
        )}

        {!isLoading && deliveryLocations.length > 0 && (
          <div className='table-holder'>
            <table className={`${classes['styled-table']} ${classes['full-width']}`}>
              <thead>{displayTableHeader()}</thead>
              <tbody>{displayTableBody()}</tbody>
            </table>
          </div>
        )}
      </div>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default DeliveryLocationList;
