import StandardPageWrapper from '../Layout/StandardPageWrapper';
import VendorReferrals from '../Admin/VendorReferrals';

const Referrals = () => {
  return (
    <StandardPageWrapper title='Restaurant Referrals'>
      <VendorReferrals />
    </StandardPageWrapper>
  );
};

export default Referrals;
