import { useContext } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Switch, Route, Redirect } from 'react-router-dom';

import AuthContext from './store/auth-context';
import EECustomTheme from './components/CSS/EECustomTheme';
import ScrollToTop2 from './components/Layout/ScrollToTop2';
import Layout from './components/Layout/Layout';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import NewAccountPage from './pages/NewAccountPage';
import AdminDashboardPage from './pages/AdminDashboardPage';
import TeamSignupPage from './pages/TeamSignupPage';
import TeamDashboardPage from './pages/TeamDashboardPage';
import VendorDashboardPage from './pages/VendorDashboardPage';
import VendorSignupPage from './pages/VendorSignupPage';
import OrderPage from './pages/OrderPage';
import CheckoutPage from './pages/CheckoutPage';
import TermsPage from './pages/TermsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import SessionExpired from './pages/SessionExpired';

function App() {
  const authCtx = useContext(AuthContext);

  const isLoggedIn = authCtx.isLoggedIn;
  const userRole = authCtx.userRole;
  const userStatus = authCtx.userStatus;
  const orgStatus = authCtx.orgStatus;

  const homePage = 'Login';

  const dashboardPath =
    userRole === 'User'
      ? '/team-dashboard'
      : userRole === 'Vendor'
      ? '/vendor-dashboard'
      : '/admin-dashboard';

  const mainPage =
    userRole === 'User' ? '/new-order' : userRole === 'Vendor' ? '/orders' : '/dashboard';

  return (
    <ThemeProvider theme={EECustomTheme}>
      <CssBaseline />
      <ScrollToTop2 />
      <Layout>
        <Switch>
          {/* <Route path='/' exact>
            {homePage === 'Login' ? <LoginPage /> : <HomePage />}
          </Route> */}
          <Route path='/' exact>
            {!isLoggedIn && homePage === 'Login' ? <LoginPage /> : <HomePage />}
            {isLoggedIn && dashboardPath ? <Redirect to={dashboardPath + mainPage} /> : null}
            {/* {isLoggedIn && dashboardPath ? <Redirect to={dashboardPath + '/orders'} /> : null} */}
          </Route>
          <Route path='/home' exact>
            <HomePage />
          </Route>
          <Route path='/terms' exact>
            <TermsPage />
          </Route>
          <Route path='/privacy-policy' exact>
            <PrivacyPolicyPage />
          </Route>
          <Route path='/new-account' exact>
            <NewAccountPage />
          </Route>
          {orgStatus === 'New' ? (
            <Route>
              <Redirect to='/new-account' />
            </Route>
          ) : null}
          {!isLoggedIn ? (
            <Route path='/team-main' exact>
              <TeamSignupPage />
            </Route>
          ) : null}
          {!isLoggedIn ? (
            <Route path='/vendor-main' exact>
              <VendorSignupPage />
            </Route>
          ) : null}
          <Route path='/login/:orderUuid/:restaurantUuid'>
            <LoginPage />
          </Route>
          mainPage
          <Route path='/login' exact>
            {!isLoggedIn ? <LoginPage /> : null}
            {isLoggedIn && dashboardPath ? <Redirect to={dashboardPath + mainPage} /> : null}
            {/* {isLoggedIn && dashboardPath && userRole === 'Admin' ? (
              <Redirect to={dashboardPath + '/dashboard'} />
            ) : null} */}
            {/* {isLoggedIn && dashboardPath && userRole === 'User' ? (
              <Redirect to={dashboardPath + '/new-order'} />
            ) : null} */}
            {/* {isLoggedIn && dashboardPath && userRole === 'Vendor' ? (
              <Redirect to={dashboardPath + '/orders'} />
            ) : null} */}
            {/* {isLoggedIn && dashboardPath ? <Redirect to={dashboardPath + '/orders'} /> : null} */}
          </Route>
          <Route path='/login/unauth' exact>
            <LoginPage code='unauth' />
          </Route>
          <Route path='/sessionexpired' exact>
            <SessionExpired />
          </Route>
          {isLoggedIn && userRole === 'Admin' && userStatus === 'Active' ? (
            <Route path='/admin-dashboard'>
              <AdminDashboardPage />
            </Route>
          ) : null}
          {isLoggedIn && userRole === 'Vendor' && userStatus === 'Active' ? (
            <Route path='/vendor-dashboard'>
              <VendorDashboardPage />
            </Route>
          ) : null}
          {isLoggedIn && userRole === 'User' && userStatus === 'Active' ? (
            <Route path='/team-dashboard'>
              <TeamDashboardPage />
            </Route>
          ) : null}
          {!isLoggedIn ? <Redirect to='/login' /> : null}
          <Route path='/order'>
            {isLoggedIn && userRole === 'User' && userStatus === 'Active' ? <OrderPage /> : null}
            {!isLoggedIn ? <Redirect to='/login' /> : null}
          </Route>
          <Route pat='/checkout'>
            {isLoggedIn && userRole === 'User' && userStatus === 'Active' ? <CheckoutPage /> : null}
            {!isLoggedIn ? <Redirect to='/login' /> : null}
          </Route>
          <Route path='*'>
            <Redirect to='/' />
          </Route>
        </Switch>
      </Layout>
    </ThemeProvider>
  );
}

export default App;
