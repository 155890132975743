import React, { useContext } from 'react';

import AuthContext from '../../store/auth-context';
import RestaurantDetailsTable from '../Restaurant/RestaurantDetailsTable';

const TestRestaurantDetails = (props) => {
  const authCtx = useContext(AuthContext);

  const userRole = authCtx.userRole;

  const { restaurantDetails } = props;

  return (
    <>
      {restaurantDetails ? (
        <RestaurantDetailsTable userRole={userRole} details={restaurantDetails} />
      ) : null}
    </>
  );
};

export default TestRestaurantDetails;
