import React from 'react';
import { Box, Paper } from '@mui/material';
// import { Link } from 'react-router-dom';
// import { Box, Typography } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Box className='content-container'>
      <h1>Elite Eats Privacy Policy</h1>
      <h5>Last Updated: May 22, 2023</h5>

      <Paper elevation={4} sx={{ maxWidth: '120rem', p: '2rem' }}>
        <p>
          This Privacy Policy describes the types of information Elite Eats, Inc. ("Elite Eats,"
          "we," "us," or "our") collects from and about you, how we use and disclose your
          information, and your choices regarding your information.
        </p>
        <p>
          This Privacy Policy applies to the collection of information from visitors and users of
          our website{' '}
          <a href='https://www.elite-eats.com' target='_blank' rel='noreferrer'>
            www.elite-eats.com
          </a>{' '}
          (our "Platform") .
        </p>
        <p>
          Your use of our Platform is subject to this Privacy Policy and the Elite Eats Terms of
          Use, which are incorporated herein by reference. Capitalized terms used but not defined in
          this Privacy Policy have the definitions given to them in the Elite Eats Terms of Use.
        </p>

        <div className='official-document'>
          <h3>1. THE INFORMATION WE COLLECT</h3>
          <h4>a. Information You Provide to Us</h4>

          <p>
            We collect information directly from you through your use of our Platform. The following
            describes common types of information you may provide to us. Please note that some of
            the information we collect from and about you may be considered "sensitive personal
            information" under applicable law. We collect and process sensitive personal information
            in accordance with applicable legal requirements
          </p>

          <h5>Information from Food-Ordering Users (Professional Sport Teams)</h5>

          <ul>
            <li>
              <b>Sign Up Information.</b> When you sign up to place food orders through our
              Platform, you provide us with information such as your organization's name, your
              position within the organization, and your name, address, email address, phone number,
              and login information.
            </li>
            <li>
              <b>Order Information.</b> When you place a food order through our Platform, you
              provide us details about your order, including the Fueling Partner from whom you are
              ordering food, the type and amount of food you are ordering, any dietary restrictions
              and preferences, the delivery time, location, and recipient name, and any other
              instructions. You also provide us with payment information, such as your credit card
              details. However, we never store your payment information on our servers; that
              information is stored by a third-party payments processor company (Stripe Inc.) where
              it is inaccessible to us.
            </li>
            <li>
              <b>User Content.</b> You may voluntarily choose to submit or otherwise make available
              certain content through our Platform. For example, you may post reviews, photos, and
              ratings, and recommend Fueling Partners to us. This Content may be publicly viewable
              by other Platform users. For example, if you post a rating or review, then that rating
              or review may be displayed along with your name and/or the city and state associated
              with your account.
            </li>
            <li>
              <b>Communications.</b> If you contact us, we may receive information such as your
              name, email address, and/or phone number. We also will receive the contents of any
              communications you have with us. This might include requests, questions, and/or issues
              regarding your food orders, current, past, or prospective Fueling Partners on our
              Platform, your account, our Platform, or other topics you might raise.
            </li>
          </ul>

          <h5>Information from Fueling Partners</h5>

          <ul>
            <li>
              <b>Contact and Business Information.</b> If you are a food provider interested in
              being a Fueling Provider on our Platform, the information you provide us through the
              Platform might include your name, business name, number of locations, menu
              information, hours of operation, address(es), website URL(s), contact information, and
              any other information you choose to provide.
            </li>
          </ul>

          <h5>Other Information</h5>

          <ul>
            <li>
              In addition to the information described above, we collect all other information any
              visitor or user of our Platform chooses to provide to us through the Platform.
            </li>
          </ul>

          <h4>b. Information We Collect Through Automated Means</h4>

          <p>
            We collect certain information about you automatically as described in this section.{' '}
          </p>

          <ul>
            <li>
              <b>Usage Data.</b> We collect and analyze information about how you use our Platform.
              We collect and analyze information about your transactions through our Platform,
              including the type of food orders you placed, the date and time that an order was
              placed, and the amount charged.
            </li>
            <li>
              <b>Information from Cookies and Other Technologies.</b> We and our service providers
              collect information through the use of "cookies" and similar technologies, such as
              tracking pixels, to understand how you navigate through and interact with our
              Platform, to learn what content is popular, to enhance the Platform and provide you
              with a more relevant and personalized experience, for authentication and advertising
              purposes, to save your preferences, and to collect certain usage, device and location
              information as described above. Cookies are small text files that web servers place on
              your device; they are designed to store basic information and to help websites and
              apps recognize your browser. We may use both session cookies and persistent cookies. A
              session cookie disappears after you close your browser. A persistent cookie remains
              after you close your browser and may be accessed every time you use our Platform.
              Tracking pixels (sometimes referred to as web beacons or clear GIFs) are tiny
              electronic tags with a unique identifier embedded in websites, online ads, and/or
              email; they are designed to collect usage information like ad impressions or clicks
              and email open rates, measure the popularity of our Platform and associated
              advertising, and access cookies. We and our vendors (including Google Analytics) also
              use these technologies and the information collected by them for data analytics
              purposes. You can find more information about Google's analytics practices at{' '}
              <a
                href='https://google.com/policies/privacy/partners'
                target='_blank'
                rel='noreferrer'
              >
                google.com/policies/privacy/partners
              </a>
              . To prevent Google Analytics from using your information for analytics, you may
              install the{' '}
              <a href='https://tools.google.com/dlpage/gaoptout' target='_blank' rel='noreferrer'>
                Google Analytics Opt-Out Broswer Add-on
              </a>{' '}
              to generally update your cookies settings to notify you when a cookie is being set or
              updated or to disable cookies altogether, please consult your web browser settings.
              Please note that if you delete or choose not to accept cookies from us, you may miss
              out on certain features of our Platform. We may also use third-party service providers
              to collect on a real-time basis information on how you use and navigate our Platform.
              This may include mouse movements and how you scroll through our Platform, but we do
              not collect keystroke data. The information (including your IP address) collected by
              the technology will be disclosed to or collected directly by these service providers,
              who use the information to evaluate your use of the Platform.
            </li>
          </ul>

          <h4>c. Information We Collect from Others</h4>

          <p>We may collect information about you from third parties, including the following:</p>

          <ul>
            <li>
              <b>Food and Delivery Service Providers.</b> If you place an order with a Fueling
              Partner, the Fueling Partner or Deliverer for the order may provide us information
              relating to your purchase or delivery, including your name, phone number, order
              details, and delivery information.
            </li>
          </ul>

          <h3>2. HOW WE USE THE INFORMATION WE COLLECT</h3>

          <p>
            We and our service providers use the information we collect from and about you for the
            following business and operational purposes:
          </p>

          <ul>
            <li>Provide, improve, enhance, and promote our Platform</li>
            <li>Analyze use of our Platform</li>
            <li>Communicate with you, including for marketing and promotional purposes</li>
            <li>Facilitate transactions, deliveries, and payments</li>
            <li>
              Provide customer support, including responding to questions, requests, and issues
            </li>
            <li>Conduct research</li>
            <li>Find and prevent fraud</li>
            <li>
              Resolve disputes, protect ourselves and other Platform users and others, and enforce
              any legal terms that govern use of our Platform
            </li>
            <li>Comply with laws, regulations, and other legal process and procedures</li>
          </ul>

          <p>
            We may aggregate and/or de-identify information collected through our Platform in such a
            way that the information cannot reasonably be linked to you or your device. We may use
            de-identified or aggregated data for any purpose, including for research and marketing
            purposes, and we may disclose such de-identified or aggregated data to any third
            parties, including advertisers, promotional partners, sponsors, and others.
          </p>

          <h3>3. ONLINE ADVERTISING</h3>

          <h4>a. Online Advertising</h4>

          <p>
            We may allow third-party advertising technologies (e.g., ad networks and ad servers such
            as Google's ad services, and others) on our Platform that use cookies and similar
            technologies to deliver relevant and targeted content and advertising to you on the
            Platform and other websites you visit and applications you use. The ads may be based on
            various factors such as the content of the page you are visiting, information you
            provide, your searches, demographic data, and other information we collect about you.
            These ads may be based on your current activity or your activity over time and across
            other websites and online services and may be tailored to your interests.
          </p>
          <p>
            We do not have access to, and this Privacy Policy does not govern, the use of cookies or
            other tracking technologies that may be placed on devices you use to access our Platform
            by non-affiliated third parties. If you are interested in more information about
            tailored browser advertising and how you can generally control cookies from being put on
            your devices to deliver tailored advertising, you may visit the{' '}
            <a href='https://optout.networkadvertising.org/?c=1' target='_blank' rel='noreferrer'>
              Network Advertising Initiative's Consumer Opt-Out link
            </a>{' '}
            or the{' '}
            <a href='https://optout.aboutads.info/?c=2&lang=EN' target='_blank' rel='noreferrer'>
              Digital Advertising Alliance's Consumer Opt-Out
            </a>{' '}
            link to opt out of receiving tailored advertising from companies that participate in
            those programs. To opt out of Google Analytics for display advertising or customize
            Google display network ads, visit the{' '}
            <a
              href='https://accounts.google.com/InteractiveLogin/signinchooser?continue=https%3A%2F%2Fadssettings.google.com%2Fauthenticated&ffgf=1&followup=https%3A%2F%2Fadssettings.google.com%2Fauthenticated&passive=1209600&ifkv=Af_xneEI1COQvZlDPxOJfZFSChASUlkC76MxBKV3rnf6sOzD4QvpbhVoXzLbl_HuBzrXENysz7SU&flowName=GlifWebSignIn&flowEntry=ServiceLogin'
              target='_blank'
              rel='noreferrer'
            >
              Google Ads Settings
            </a>{' '}
            page.
          </p>
          <p>
            Please note that these opt-outs apply per browser and per device, so you will have to
            opt out for each device—and each browser on each device—through which you access our
            Platform. In addition, the opt-outs do not apply to advertisements in mobile
            applications, if any.
          </p>

          <h4>b. Notice Concerning Do Not Track. </h4>

          <p>
            Do Not Track ("DNT") is a privacy preference that is available in certain web browsers.
            We are committed to providing you with meaningful choices about the information
            collected on our website for third party purposes, which is why we describe a variety of
            opt-out mechanisms above. However, we do not currently recognize or respond to browser
            initiated DNT signals. Learn more about{' '}
            <a href='https://allaboutdnt.com' target='_blank' rel='noreferrer'>
              Do Not Track
            </a>{' '}
            . Please note that Do Not Track is a different privacy mechanism than the legally
            required browser-based "preference signal" referenced in the "Your Rights and Choices"
            section below.
          </p>

          <h3>4. HOW WE DISCLOSE THE INFORMATION WE COLLECT</h3>
          <p>
            We and our service providers disclose the information we collect from and about you for
            the following business and operational purposes:
          </p>
          <ul>
            <li>
              <b>Fueling Partners.</b> You will disclose your name, contact information, any dietary
              preferences and restrictions you have provided, and purchase information with Fueling
              Partners with whom you have placed orders. In addition, Fueling Partners will disclose
              information relating to your delivery to Deliverers, including your name, phone
              number, and delivery information. The information we disclose to Fueling Partners, and
              that Fueling Partners disclose to Deliverers, is not subject to this Privacy Policy,
              but instead is subject to the Fueling Partner's own privacy policy, which may allow
              the Fueling Partner, among other things, to send you marketing communications and
              other promotional content.
            </li>
            <li>
              <b>Service Providers.</b> We provide access to or disclose your information to third
              parties that perform services on our behalf, such as billing, payment processing,
              advertising, web and other analytics, data storage and processing, customer support,
              security, fraud prevention, and other services.
            </li>
            <li>
              <b>For Legal Reasons or the Protection of Elite Eats and Others.</b> We will disclose
              the information we collect about you if required by law or legal process or if we
              believe in good faith that disclosure is reasonably necessary to: (i) enforce our
              Terms of Use, this Privacy Policy, or other contracts with you, including
              investigation of potential violations thereof; (ii) respond to claims that any content
              violates the rights of third parties; and/or (iii) protect the rights, property or
              personal safety of Elite Eats, our Platform users, and/or others. This includes
              exchanging information with other companies and organizations for fraud protection,
              spam/malware prevention, and similar purposes.
            </li>
            <li>
              <b>Business Transfers.</b> In the event of sale (of some or all of our assets),
              transfer, merger, reorganization, dissolution, or similar event involving our business
              (including in contemplation of such transactions), your information may be among the
              transferred business assets. If such transfer is subject to any mandatory restrictions
              under applicable laws, we will comply with those restrictions.
            </li>
            <li>
              <b>Your Consent.</b> If you consent to our disclosure of your information, we will
              disclose your information consistent with your consent.
            </li>
            <p>
              We may disclose aggregate or de-identified information to any third party, including
              advertisers, promotional partners, and sponsors, at our discretion.
            </p>
          </ul>

          <h3>5. YOUR RIGHTS AND CHOICES</h3>

          <p>
            In addition to the other rights and choices described in this Privacy Policy, you have
            these rights and choices regarding your information:
          </p>

          <h4>a. Rights Regarding Your Information</h4>

          <p>
            Depending on your jurisdiction, you may have the right to make certain requests
            regarding your "personal information" or "personal data" (as such terms are defined
            under applicable law, and collectively referred to herein as "personal information"). We
            extend these rights to all individuals, regardless of residency, subject to this Privacy
            Policy. Specifically, you have the right to ask us to:
          </p>
          <ul>
            <li>
              Inform you about the categories of personal information we collect or disclose about
              you; the categories of sources of such information; the business or commercial purpose
              for collecting your personal information; and the categories of third parties with
              whom we disclose personal information.
            </li>
            <li>
              Provide you access to and/or a copy of certain personal information we hold about you.
            </li>
            <li>Correct or update personal information we hold about you. </li>
            <li>Delete certain personal information we have about you. </li>
            <li>
              Provide you with information about the financial incentives that we offer to you, if
              any.
            </li>
            <li>
              Opt out of the processing of your personal information for purposes of profiling in
              furtherance of decisions that produce legal or similarly significant effects, if
              applicable.
            </li>
          </ul>

          <p>
            As provided in applicable law, you also have the right to not be discriminated against
            for exercising your rights. Please note that certain information may be exempt from such
            requests under applicable law. For example, we need to retain certain information in
            order to provide our Platform to you. We also need to take reasonable steps to verify
            your identity before responding to a request, which may include, at a minimum, depending
            on the sensitivity of the information you are requesting and the type of request you are
            making, verifying your name and email address. You are also permitted to designate an
            authorized agent to submit certain requests on your behalf. In order for an authorized
            agent to be verified, you must provide the authorized agent with signed, written
            permission to make such requests or a power of attorney. We may also follow up with you
            to verify your identity before processing the authorized agent's request. If you would
            like to correct or update your personal information, please log into your account and
            make changes through your account settings.
          </p>
          <p>
            Depending on applicable law, you may have the right to appeal our decision to deny your
            request, if applicable. If we deny your request, we will provide you with information on
            how to appeal the decision, if applicable, in our communications with you.
          </p>

          <h4>b. Opting Out of Marketing Emails</h4>

          <p>
            You can unsubscribe from our marketing emails via the unsubscribe link provided in the
            emails and at www.elite-eats.com. Please note that it may take us some time, consistent
            with our legal obligations, to process your request. Even if you opt out from receiving
            marketing messages from us, you will continue to receive administrative messages from
            us, such as order confirmations, updates to our policies and practices, or other
            communications regarding our relationship or transactions with you.
          </p>

          <h4>c. Notice of Financial Incentives</h4>

          <p>
            We may provide certain reward or incentive programs in connection with certain marketing
            campaigns. You can opt into the rewards or incentives by signing up as these programs
            arise. Your participation is completely voluntary, and you have a right to withdraw from
            any program at any time. If you decide you don't want to participate, you can opt out
            from these programs at{' '}
            <a href='https://www.elite-eats.com' target='_blank' rel='noreferrer'>
              www.elite-eats.com
            </a>
            .
          </p>

          <h3>6. THIRD-PARTY LINKS AND FEATURES</h3>
          <p>
            Our Platform may contain links to third-party websites and third-party plug-ins, such as
            Facebook, LinkedIn, and Instagram. If you choose to use these sites or features, please
            note that we do not have control over the third parties that operate these websites and
            services and are not responsible for their content or privacy practices. We encourage
            you to read their privacy policies before using their websites or services or giving
            them your information.
          </p>

          <h3>7. HOW WE PROTECT YOUR INFORMATION</h3>
          <p>
            We take measures to protect your information against accidental or unlawful destruction,
            loss, alteration, disclosure, or access. However, no method of transmission over the
            internet, and no means of electronic or physical storage, is absolutely secure. By using
            our Platform, you acknowledge and accept that we cannot guarantee the security of your
            information transmitted to, through, or on our Platform, and that any such transmission
            is at your own risk.
          </p>

          <h3>8. CHANGES TO OUR PRIVACY POLICY</h3>
          <p>
            We reserve the right to make changes to this Privacy Policy at any time. If we make a
            change, we will make the revised Privacy Policy accessible through our Platform. You can
            know if the Privacy Policy has changed since the last time you reviewed it by checking
            the "Last Updated" date at the top of this Privacy Policy. If we make a material change
            to this Privacy Policy, we will notify you through the Platform, by email, and/or by
            other legally permissible communication. By continuing to use the Platform, you are
            confirming that you have read and understood the latest version of this Privacy Policy.
          </p>

          <h3>9. CONTACT INFORMATION</h3>
          <p>
            If you have any questions or comments about this Privacy Policy, please contact us at{' '}
            <a href='https://www.elite-eats.com' target='_blank' rel='noreferrer'>
              www.elite-eats.com
            </a>
            .
          </p>
        </div>
      </Paper>
    </Box>
  );
};

export default PrivacyPolicy;
