import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Paper, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import { useFormatDateAndTime } from '../Hooks/useFormatDateAndTime';
import RestaurantReviewListCard from './RestaurantReviewListCard';

const RestaurantReviewsList = (props) => {
  const authCtx = useContext(AuthContext);
  const dashboardUrl = authCtx.dashboardUrl;
  // const userRole = authCtx.userRole;
  const baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath;

  const { propRestaurantUuid = '', mode = 'main' } = props;

  const history = useHistory();

  const { FormatDateForDisplay } = useFormatDateAndTime();

  let restaurantUuid = '';
  // if (userRole === 'Vendor') {
  //   restaurantUuid = authCtx.selectedRestaurantUuid;
  // } else {
  //   restaurantUuid = propRestaurantUuid;
  // }
  // New version of the code.
  if (propRestaurantUuid) {
    restaurantUuid = propRestaurantUuid;
  } else {
    restaurantUuid = authCtx.selectedRestaurantUuid;
  }

  // console.log('userRole = ' + userRole);
  // console.log('propRestaurantUuid = ' + propRestaurantUuid);
  // console.log('authCtx.selectedRestaurantUuid = ' + authCtx.selectedRestaurantUuid);
  // console.log('restaurantUuid = ' + restaurantUuid);

  const [records, setRecords] = useState([]);
  const [displayedRecords, setDisplayedRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      let apiURL = '';

      if (restaurantUuid !== '' && restaurantUuid !== undefined) {
        apiURL = authCtx.baseApiUrl + 'restaurantreviews/restaurant/' + restaurantUuid;
      } else {
        apiURL = authCtx.baseApiUrl + 'restaurantreviews';
      }
      // console.log('apiURL = ' + apiURL);

      const response = await fetch(apiURL, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.log(' -- response.ok -- ');
        // console.log(responseData.data.restaurant_reviews);

        const transformedRecords = responseData.data.restaurant_reviews.map((record) => {
          return {
            restaurantReviewUuid: record.restaurantReviewUuid,
            rating: +record.rating,
            review: record.review,
            dateAdded: record.dateAdded,
            dateAddedFormatted: record.dateAdded ? FormatDateForDisplay(record.dateAdded) : '',
            status: record.status,
            restaurantName: record.restaurantName,
            teamName: record.teamName,
            reviewImages: record.reviewImages,
          };
        });

        // console.log('transformedRecords');
        // console.table(transformedRecords);

        setRecords(transformedRecords);
        setDisplayedRecords(transformedRecords);
      } else {
        // If a 401 is returned then there is an issue with the session (most likely).
        if (responseData.statusCode === 401) {
          history.push(dashboardUrl + 'sessionexpired');
        }

        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [
    authCtx.baseApiUrl,
    authCtx.token,
    restaurantUuid,
    FormatDateForDisplay,
    dashboardUrl,
    history,
  ]);

  const displayRestaurantReviews = () => {
    return displayedRecords.map((record, index) => (
      <Box key={index}>
        <RestaurantReviewListCard
          review={record}
          baseApiUploadedImagesPath={baseApiUploadedImagesPath}
        />
      </Box>
    ));
  };

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {!isLoading && !isError ? (
        <Box>
          {mode === 'main' ? (
            <>
              <Box className='title-and-buttons-holder'>
                <Typography variant='h1' component='h1'>
                  Reviews
                </Typography>
              </Box>

              {records.length === 0 ? (
                <Paper elevation={4} sx={{ p: '20px' }}>
                  <Typography>There are no Reviews for this Restaurant at this time...</Typography>
                </Paper>
              ) : (
                <Box className='flex-side-by-side-stretch'>{displayRestaurantReviews()}</Box>
              )}
            </>
          ) : (
            <>
              {records.length > 0 ? (
                <>
                  <Typography variant='h1' component='h1'>
                    Reviews
                  </Typography>
                  <Box>{displayRestaurantReviews()}</Box>
                </>
              ) : null}
            </>
          )}
        </Box>
      ) : null}
    </>
  );
};

export default RestaurantReviewsList;
