import { useState } from 'react';
import { Box } from '@mui/material';

// import VendorSignupForm from '../components/Signup/VendorSignupForm';
// import VendorSignupChecklist from '../components/Signup/VendorSignupChecklist';
import VendorSignupMessage from '../components/Signup/VendorSignupMessage';
import VendorSignupForm from '../components/MUI/Signup/VendorSignupForm';
import TermsOfUse from '../components/GeneralContent/TermsOfUse';
import PrivacyPolicy from '../components/GeneralContent/PrivacyPolicy';

import Popup from '../components/MUI/UI/Popup';

let popupTitle = 'Popup Title';

const VendorSignupPage = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('none');

  const showTermsOfUseHandler = () => {
    popupTitle = 'Terms of Use';
    setPopupContent('terms');
    setOpenPopup(true);
  };

  const showPrivacyPolicyHandler = () => {
    popupTitle = 'Privacy Policy';
    setPopupContent('privacy');
    setOpenPopup(true);
  };

  return (
    <Box className='page-container'>
      <Box className='content-container normal-content-area' sx={{ maxWidth: '80rem' }}>
        <VendorSignupMessage />
        <div>
          {/* <VendorSignupForm setFocus='true' /> */}
          <VendorSignupForm
            showTermsOfUse={showTermsOfUseHandler}
            showPrivacyPolicy={showPrivacyPolicyHandler}
          />
          {/* <Box>
            <VendorSignupChecklist />
          </Box> */}
        </div>
      </Box>

      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} maxWidth='md' title={popupTitle}>
        {popupContent === 'terms' ? <TermsOfUse /> : null}
        {popupContent === 'privacy' ? <PrivacyPolicy /> : null}
      </Popup>
    </Box>
  );
};

export default VendorSignupPage;
