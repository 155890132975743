import React, { useContext } from 'react';

import AuthContext from '../../store/auth-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import RestaurantDetails from '../Restaurant/RestaurantDetails';

const VendorProfile = () => {
  const authCtx = useContext(AuthContext);
  const orgDetails = authCtx.orgDetails;
  const restaurantUuid = authCtx.selectedRestaurantUuid
    ? authCtx.selectedRestaurantUuid
    : orgDetails.orgUuid;

  // console.log('orgDetails');
  // console.log(orgDetails);
  // console.log('authCtx.selectedRestaurantUuid = ' + authCtx.selectedRestaurantUuid);

  return (
    <StandardPageWrapper>
      <RestaurantDetails pRestaurantUuid={restaurantUuid} mode='details' />
    </StandardPageWrapper>
  );
};

export default VendorProfile;
