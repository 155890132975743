import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Box } from '@mui/material';

import VendorMenu from '../components/VendorDashboard/VendorMenu';
import Orders from '../components/Orders/Orders';
import Receipts from '../components/VendorDashboard/Receipts';
import Reviews from '../components/VendorDashboard/Reviews';
import Dashboard from '../components/VendorDashboard/Dashboard';
import Account from '../components/VendorDashboard/Account';
import VendorProfile from '../components/VendorDashboard/VendorProfile';
import UserProfile from '../components/VendorDashboard/UserProfile';
// import Restaurants from '../components/Restaurant/Restaurants';
import Restaurants from '../components/VendorDashboard/Restaurants';
import MenuItemAdd from '../components/Menu/MenuItemAdd';
import MenuItemList from '../components/Menu/MenuItemList';
import MenuItemDetails from '../components/Menu/MenuItemDetails';
import MenuItemEdit from '../components/Menu/MenuItemEdit';
import DeliveryLocations from '../components/VendorDashboard/DeliveryLocations';
import VendorDeliveryLocationsForm from '../components/DeliveryLocations/VendorDeliveryLocationsForm';
import DeliveryFees from '../components/DeliveryFees/DeliveryFees';
import DeliveryFee from '../components/DeliveryFees/DeliveryFee';
import PaymentAccount from '../components/Stripe/PaymentAccount';
import AccountLinkRefresh from '../components/Stripe/AccountLinkRefresh';
import AccountLinkReturn from '../components/Stripe/AccountLinkReturn';
import SessionExpired from './SessionExpired';

import TestVendorProfile from '../components/TestProfile/TestVendorProfile';

const VendorDashboardPage = () => {
  const pathStart = '/vendor-dashboard/';

  return (
    <Box>
      <VendorMenu />
      <Box component='div' className='page-container-after-top-menu'>
        <Switch>
          <Route path={pathStart + 'sessionexpired'} exact>
            <SessionExpired />
          </Route>
          <Route path={pathStart + 'orders'} exact>
            <Orders />
          </Route>
          <Route path={pathStart + 'orders/:orderUuid'}>
            <Orders />
          </Route>
          <Route path={pathStart + 'receipts'} exact>
            <Receipts />
          </Route>
          <Route path={pathStart + 'reviews'} exact>
            <Reviews />
          </Route>
          <Route path={pathStart + 'dashboard'} exact>
            <Dashboard />
          </Route>
          <Route path={pathStart + 'restaurants'} exact>
            <Restaurants />
          </Route>
          <Route path={pathStart + 'restaurants/details/:restaurantUuid'} exact>
            <Restaurants mode='details' urlStart={pathStart} />
          </Route>
          <Route path={pathStart + 'restaurants/menu-items/:restaurantUuid'} exact>
            <MenuItemList />
          </Route>
          <Route path={pathStart + 'restaurants/delivery-fees/:restaurantUuid'} exact>
            <DeliveryFees />
          </Route>
          <Route path={pathStart + 'restaurants/delivery-fees/:restaurantUuid'} exact>
            <DeliveryFees mode='list' />
          </Route>
          {/* <Route path={pathStart + 'restaurants/delivery-fee/:restaurantUuid'} exact>
            <DeliveryFees mode='form' />
          </Route> */}
          <Route
            path={pathStart + 'restaurants/delivery-fee/:restaurantUuid/:deliveryFeeUuid'}
            exact
          >
            <DeliveryFee />
          </Route>

          <Route
            path={
              pathStart +
              'restaurants/delivery-locations-form/:restaurantUuid/:restaurantState/:returnTo'
            }
            exact
          >
            <VendorDeliveryLocationsForm />
          </Route>

          <Route path={pathStart + 'menu-items'} exact>
            <MenuItemList />
          </Route>
          <Route path={pathStart + 'menu-items/:restaurantUuid'} exact>
            <MenuItemList />
          </Route>
          <Route path={pathStart + 'menu-item/add'} exact>
            <MenuItemAdd dashboardUrl={pathStart} />
          </Route>
          <Route path={pathStart + 'menu-item/edit/:menuItemUuid'}>
            <MenuItemEdit />
          </Route>
          <Route path={pathStart + 'menu-items/details/:menuItemUuid'}>
            <MenuItemDetails />
          </Route>
          <Route path={pathStart + 'delivery-locations'} exact>
            <DeliveryLocations />
          </Route>
          <Route path={pathStart + 'account'}>
            <Account />
          </Route>
          {/* <Route path={pathStart + 'payment-account/:restaurantUuid'}>
            <PaymentAccount />
          </Route> */}
          <Route path={pathStart + 'payment-account'}>
            <PaymentAccount />
          </Route>
          <Route path={pathStart + 'vendor-profile/payment-account'}>
            <PaymentAccount />
          </Route>
          <Route path={pathStart + 'account-link-refresh'}>
            <AccountLinkRefresh />
          </Route>
          <Route path={pathStart + 'account-link-return'}>
            <AccountLinkReturn />
          </Route>
          <Route path={pathStart + 'vendor-profile'}>
            <VendorProfile />
          </Route>
          <Route path={pathStart + 'test-profile'}>
            <TestVendorProfile />
          </Route>
          <Route path={pathStart + 'user-profile'}>
            <UserProfile />
          </Route>
          <Route path='*'>
            <Redirect to={pathStart + 'dashboard'} />
          </Route>
        </Switch>
      </Box>
    </Box>
  );
};

export default VendorDashboardPage;
