import React from 'react';
import { Box } from '@mui/material';

import TermsOfUse from '../components/GeneralContent/TermsOfUse';

const TermsPage = () => {
  return (
    <Box className='page-container'>
      <TermsOfUse />
    </Box>
  );
};

export default TermsPage;
