import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import { useFormatDateAndTime } from '../Hooks/useFormatDateAndTime';
import WorkflowMessage from '../UI/WorkflowMessage';
import RestaurantDetailsCard from './RestaurantDetailsCard';
import RestaurantForm from './RestaurantForm';
import RestaurantImagesForm from './RestaurantImagesForm';
// import DeliveryHoursForm from './DeliveryHoursForm';
import VendorDeliveryLocationsList from '../DeliveryLocations/VendorDeliveryLocationsList';
import DeliveryLocationListForRestaurant from '../DeliveryLocations/DeliveryLocationListForRestaurant';
import RestaurantReviewsList from '../RestaurantReviews/RestaurantReviewsList';

import RestaurantContacts from '../RestaurantContacts/RestaurantContacts';

const RestaurantDetails = (props) => {
  // console.log('######## Restaurant/RestaurantDetails ########');
  const { pRestaurantUuid, mode } = props;

  const authCtx = useContext(AuthContext);
  const userRole = authCtx.userRole;
  const dashboardUrl = authCtx.dashboardUrl;
  const multipleRestaurants = authCtx.multipleRestaurants;

  const history = useHistory();

  // console.log('***** RestaurantDetails - mode = ' + mode + ' ******');
  // console.log('***** RestaurantDetails - multipleRestaurants = ' + multipleRestaurants + ' ******');

  const [restaurantUuid, setRestaurantUuid] = useState(pRestaurantUuid);
  const [restaurantDetails, setRestaurantDetails] = useState(null);
  const [displayedComponent, setDisplayedComponent] = useState(
    // mode === 'details' ? 'Card' : 'Form'
    mode === 'details' ? 'Card' : mode === 'editImages' ? 'ImagesForm' : 'Form'
  );

  // console.log('displayedComponent = ' + displayedComponent);
  // console.log('pRestaurantUuid = ' + pRestaurantUuid);
  // console.log('restaurantUuid = ' + restaurantUuid);
  // console.log('### restaurantDetails ###');
  // console.log(restaurantDetails);

  const [globalFoodTypesForForm, setGlobalFoodTypesForForm] = useState([]);
  const [globalLeaguesForForm, setGlobalLeaguesForForm] = useState([]);

  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const { FormatDateAndTimeForDisplay, FormatTimeForDisplay } = useFormatDateAndTime();

  let baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath;
  const orgFolder = restaurantUuid;
  baseApiUploadedImagesPath += orgFolder + '/';

  // userRole === 'Vendor' ? 'Profile' : 'Restaurant Details'
  let pageTitle = userRole === 'Vendor' ? 'Profile' : 'Restaurant Details';
  if (displayedComponent === 'Form') {
    pageTitle = 'Edit Restaurant';
  }
  if (displayedComponent === 'ImagesForm') {
    pageTitle = 'Edit Restaurant Images';
  }
  // if (mode === 'details') {
  //   if (userRole === 'Vendor') {
  //     pageTitle = 'Profile';
  //   }
  // } else {
  //   if (userRole === 'Admin') {
  //     pageTitle = 'Edit Restaurant';
  //   }
  // }

  // console.log('mode = ' + mode);
  // console.log('userRole = ' + userRole);
  // console.log('displayedComponent = ' + displayedComponent);
  // console.log('pageTitle = ' + pageTitle);

  useEffect(() => {
    const fetchData = async () => {
      // console.log('### RestaurantDetails - useEffect - fetchData ###');
      setIsLoading(true);

      const response = await fetch(authCtx.baseApiUrl + 'restaurants/' + restaurantUuid, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.log(' -- response.ok -- ');
        // console.log('responseData.data.restaurants');
        // console.log(responseData.data.restaurants);
        // console.log(responseData.data);

        const transformedRestaurants = responseData.data.restaurants.map((restaurant) => {
          return {
            restaurantUuid: restaurant.restaurantUuid,
            name: restaurant.name,
            description: restaurant.description,
            address: restaurant.address1,
            address1: restaurant.address1,
            address2: restaurant.address2,
            city: restaurant.city,
            state: restaurant.state,
            zipcode: restaurant.zipcode,
            email: restaurant.email,
            phone: restaurant.phone,
            foodType: restaurant.foodType,
            rating: restaurant.rating,
            numberOfRatings: restaurant.numberOfRatings,
            leagueExperience: restaurant.leagueExperience,
            orderLeadTime: restaurant.orderLeadTime,
            taxRate: restaurant.taxRate,
            paymentAccountStatus: restaurant.paymentAccountStatus,
            paymentAccountId: restaurant.paymentAccountId,
            paymentTaxRateId: restaurant.paymentTaxRateId,
            notificationEmailAddresses: restaurant.notificationEmailAddresses,
            status: restaurant.status,
            dateAdded: restaurant.dateAdded,
            dateUpdated: restaurant.dateUpdated,
            dateAddedFormatted: restaurant.dateAdded
              ? FormatDateAndTimeForDisplay(restaurant.dateAdded)
              : '',
            dateUpdatedFormatted: restaurant.dateUpdated
              ? FormatDateAndTimeForDisplay(restaurant.dateUpdated)
              : '',
            headerImage: restaurant.headerImage,
            listImage: restaurant.listImage,
            uploadsFolder: restaurant.uploadsFolder,
            isParent: restaurant.isParent,
            globalFoodTypes: restaurant.globalFoodTypes ? restaurant.globalFoodTypes : [],
            globalLeagues: restaurant.globalLeagues ? restaurant.globalLeagues : [],
            contacts: restaurant.contacts ? restaurant.contacts : [],
            deliveryHours: restaurant.deliveryHours ? restaurant.deliveryHours : [],
            deliveryFees: restaurant.deliveryFees ? restaurant.deliveryFees : [],
          };
        });

        // console.log(' transformedRestaurants ');
        // console.log(transformedRestaurants);

        setRestaurantDetails(transformedRestaurants[0]);

        const globalFoodTypesForForm = transformedRestaurants[0].globalFoodTypes.map((record) => {
          return {
            id: record,
            title: record,
          };
        });

        setGlobalFoodTypesForForm(globalFoodTypesForForm);
        // console.log('globalFoodTypesForForm');
        // console.log(globalFoodTypesForForm);

        const globalLeaguesForm = transformedRestaurants[0].globalLeagues.map((record) => {
          return {
            name: record,
          };
        });

        setGlobalLeaguesForForm(globalLeaguesForm);
        // console.log(' --- globalLeaguesForm --- ');
        // console.log(globalLeaguesForm);
      } else {
        // If a 401 is returned then there is an issue with the session (most likely).
        if (responseData.statusCode === 401) {
          history.push(dashboardUrl + 'sessionexpired');
        }

        setIsError(true);
      }
      setIsLoading(false);
      // setRefreshData(false);
    };

    // console.log(' FETCHING DATA ');
    fetchData().catch(console.error);
  }, [
    authCtx.baseApiUrl,
    authCtx.token,
    FormatDateAndTimeForDisplay,
    FormatTimeForDisplay,
    restaurantUuid,
    refreshData,
    dashboardUrl,
    history,
  ]);

  const displayMenuHandler = () => {
    if (userRole === 'Vendor') {
      history.push(dashboardUrl + 'menu-items');
    } else {
      history.push(dashboardUrl + 'restaurants/menu-items/' + restaurantUuid);
    }
  };

  const backToListHandler = () => {
    history.push(dashboardUrl + 'restaurants');
  };

  const displayDetailsHandler = () => {
    console.log('displayDetailsHandler');
    setIsLoading(true);
    // setRefreshData(true);
    // Test Code.
    setRefreshData(Date.now());
    setDisplayedComponent('Card');
  };

  const addRestaurantHandler = () => {
    // console.log('addRestaurantHandler');
    setDisplayedComponent('AddNewForm');
  };

  const editDetailsHandler = () => {
    // setRefreshData(true);
    // Test Code.
    setRefreshData(Date.now());
    setDisplayedComponent('Form');
  };

  const editImagesHandler = () => {
    // console.log('editImagesHandler');
    setDisplayedComponent('ImagesForm');
  };

  const showListHandler = () => {
    console.log('showListHandler');
  };

  const reloadDataHandler = () => {
    // console.log('reloadDataHandler - data should get reloaded');
    // setRefreshData(true);
    // Test Code.
    setRefreshData(Date.now());
    setDisplayedComponent('Card');

    // setSelectedRestaurant({});
    // console.log('reloadDataHandler - selectedRestaurant');
    // console.table(selectedRestaurant);
    // setRefreshData(true);
    // console.log('reloadDataHandler - refreshData = ' + refreshData);
    // showListHandler();
  };

  const displayRestaurantDetailsHandler = (restaurantUuid) => {
    // console.log(
    //   '### displayRestaurantDetailsHandler - restaurantUuid = ' + restaurantUuid + ' ###'
    // );
    setDisplayedComponent('Card');
    setRestaurantUuid(restaurantUuid);
  };

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' message='Something did not work...' /> : null}

      {!isLoading && !isError ? (
        <Box className='title-and-button-holder'>
          <Typography variant='h1' component='h1'>
            {/* {userRole === 'Vendor' ? 'Profile' : 'Restaurant Details'} */}
            {pageTitle}
          </Typography>
          <Box className='button-holder-no-pb'>
            {userRole !== 'User' &&
            (displayedComponent === 'Card' || displayedComponent === 'ImagesForm') ? (
              <Button
                variant='contained'
                status='custom'
                color='accent'
                // onClick={() => editDetailsHandler(restaurantUuid)}
                onClick={editDetailsHandler}
              >
                Edit
              </Button>
            ) : null}

            {userRole !== 'User' && displayedComponent === 'Form' ? (
              <Button
                variant='contained'
                status='custom'
                color='accent'
                // onClick={() => displayDetailsHandler(restaurantUuid)}
                onClick={displayDetailsHandler}
              >
                Details
              </Button>
            ) : null}

            <Button
              variant='contained'
              size='custom'
              color='accent'
              onClick={displayMenuHandler}
              autoFocus
            >
              Menu
            </Button>

            {multipleRestaurants ? (
              <Button
                variant='contained'
                size='custom'
                color='accent'
                onClick={backToListHandler}
                autoFocus
              >
                Restaurants
              </Button>
            ) : null}

            {userRole === 'Admin' && restaurantDetails.isParent ? (
              <Button
                variant='contained'
                size='custom'
                color='accent'
                onClick={addRestaurantHandler}
                autoFocus
              >
                Add Linked Restaurant
              </Button>
            ) : null}
          </Box>
        </Box>
      ) : null}

      {!isLoading && !isError && restaurantDetails ? (
        <>
          {displayedComponent === 'Card' ? (
            <RestaurantDetailsCard
              mode='details'
              restaurantDetails={restaurantDetails}
              baseApiUploadedImagesPath={baseApiUploadedImagesPath}
              reloadData={reloadDataHandler}
            />
          ) : null}

          {displayedComponent === 'Form' ? (
            <Box>
              <RestaurantForm
                restaurantDetails={restaurantDetails}
                showList={showListHandler}
                onCancel={displayDetailsHandler}
                onEditImages={editImagesHandler}
                reloadData={reloadDataHandler}
                onDataUpdated={displayRestaurantDetailsHandler}
                globalFoodTypesForForm={globalFoodTypesForForm}
                globalLeaguesForForm={globalLeaguesForForm}
              />
            </Box>
          ) : null}

          {displayedComponent === 'AddNewForm' ? (
            <Box>
              <RestaurantForm
                parentRestaurantUuid={restaurantUuid}
                parentRestaurantName={restaurantDetails.name}
                showList={showListHandler}
                onCancel={displayDetailsHandler}
                onEditImages={editImagesHandler}
                reloadData={reloadDataHandler}
                onDataUpdated={displayRestaurantDetailsHandler}
                globalFoodTypesForForm={globalFoodTypesForForm}
                globalLeaguesForForm={globalLeaguesForForm}
              />
            </Box>
          ) : null}

          {displayedComponent === 'ImagesForm' ? (
            <Box>
              <RestaurantImagesForm
                restaurantDetails={restaurantDetails}
                onEditImages={editImagesHandler}
                onCancel={editDetailsHandler}
              />
            </Box>
          ) : null}

          {/* <Box sx={{ pt: '4rem' }}>
            <DeliveryHoursForm
              restaurantUuid={restaurantDetails.restaurantUuid}
              deliveryHours={restaurantDetails.deliveryHours}
            />
          </Box> */}

          {displayedComponent === 'Card' ? (
            <>
              <Box sx={{ pt: '4rem' }}>
                {/* <Typography variant='h1' component='h1'>
              Delivery Locations
            </Typography> */}

                {!isLoading && !isError && userRole !== 'User' ? (
                  <VendorDeliveryLocationsList
                    restaurantUuid={restaurantDetails.restaurantUuid}
                    restaurantState={restaurantDetails.state}
                    returnTo='restaurant-details'
                  />
                ) : null}

                {!isLoading && !isError && userRole === 'User' ? (
                  <DeliveryLocationListForRestaurant
                    restaurantUuid={restaurantDetails.restaurantUuid}
                    restaurantDetails={restaurantDetails}
                  />
                ) : null}
              </Box>

              {!isLoading && !isError && userRole !== 'User' ? (
                <Box>
                  <RestaurantContacts
                    contacts={restaurantDetails.contacts}
                    restaurantUuid={restaurantDetails.restaurantUuid}
                  />
                </Box>
              ) : null}

              <Box className='pt-10'>
                <RestaurantReviewsList
                  propRestaurantUuid={restaurantDetails.restaurantUuid}
                  mode='component'
                />
              </Box>
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default RestaurantDetails;
