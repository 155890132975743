import { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Divider, Paper, Typography } from '@mui/material';

import AuthContext from '../../../store/auth-context';
import Notification from '../UI/Notification';
import FormControls from '../FormControls/FormControls';
// import classes from '../../CSS/standard-form.module.css';
import classes from '../../CSS/standard-mui-form.module.css';

const VendorSignupForm = (props) => {
  const authCtx = useContext(AuthContext);

  const notificationPreferenceItems = authCtx.notificationPreferencesArray;

  const { showTermsOfUse, showPrivacyPolicy } = props;

  const [notificationPreference, setNotificationPreference] = useState('Email');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const restaurantNameRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const positionRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();

  const roleType = 'Vendor';

  // const signupToken = 'EliteEatsSignup';

  useEffect(() => {
    restaurantNameRef.current.focus();
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();

    setNotify({
      isOpen: true,
      message: 'Sending Request...',
      type: 'info',
    });

    const enteredRestaurantName = restaurantNameRef.current.value;
    const enteredFirstName = firstNameRef.current.value;
    const enteredLastName = lastNameRef.current.value;
    const enteredPosition = positionRef.current.value;
    const enteredPhone = phoneRef.current.value;
    const enteredEmail = emailRef.current.value;
    const enteredPassword = passwordRef.current.value;
    const enteredPasswordConfirm = passwordConfirmRef.current.value;

    // Todo:  Add Validation.
    // Make sure that the Passwords Match.
    if (enteredPassword !== enteredPasswordConfirm) {
      setNotify({
        isOpen: true,
        message: 'The Passwords entered do not match.',
        type: 'warning',
      });
      return;
    }

    fetch(authCtx.baseApiUrl + 'users/signup', {
      method: 'POST',
      body: JSON.stringify({
        restaurant_name: enteredRestaurantName,
        first_name: enteredFirstName,
        last_name: enteredLastName,
        position: enteredPosition,
        phone: enteredPhone,
        email: enteredEmail,
        password: enteredPassword,
        notification_preference: notificationPreference,
        role_type: roleType,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        setNotify({
          isOpen: true,
          message: 'Successful Signup',
          type: 'success',
        });

        restaurantNameRef.current.value = '';
        firstNameRef.current.value = '';
        lastNameRef.current.value = '';
        positionRef.current.value = '';
        phoneRef.current.value = '';
        emailRef.current.value = '';
        passwordRef.current.value = '';
        passwordConfirmRef.current.value = '';

        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          // Newly added to log the user in after signing up.
          // console.log(responseData);
          const accessTokenExpiresInMilliseconds =
            +responseData.data.access_token_expires_in * 1000;
          const accessTokenExpiryDateTime = new Date(
            new Date().getTime() + accessTokenExpiresInMilliseconds
          );

          const refreshTokenExpiresInMilliseconds =
            +responseData.data.refresh_token_expires_in * 1000;
          const refreshTokenExpiryDateTime = new Date(
            new Date().getTime() + refreshTokenExpiresInMilliseconds
          );

          authCtx.login(
            responseData.data.user_uuid,
            responseData.data.user_name,
            responseData.data.user_status,
            responseData.data.user_role_type,
            responseData.data.session_id,
            responseData.data.access_token,
            accessTokenExpiryDateTime,
            responseData.data.refresh_token,
            refreshTokenExpiryDateTime,
            responseData.data.org_uuid,
            responseData.data.org_name,
            responseData.data.org_type,
            responseData.data.org_status,
            responseData.data.location_uuid,
            responseData.data.location_name,
            responseData.data.location_city,
            responseData.data.location_state,
            responseData.data.location_status,
            responseData.data.user_details,
            responseData.data.org_details
          );
        });
      } else {
        return res.json().then((responseData) => {
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: 'Account Creation Failed',
              type: 'error',
            });
          }
        });
      }
    });
  };

  const notificationPreferenceChangeHandler = (event) => {
    // console.log('notificationPreferenceChangeHandler = event.target.value = ' + event.target.value);
    setNotificationPreference(event.target.value);
  };

  const termsAcceptedHandler = (event) => {
    setTermsAccepted(event.target.value);
  };

  const showTermsOfUseHandler = (event) => {
    event.preventDefault();
    showTermsOfUse();
  };

  const showPrivacyPolicyHandler = (event) => {
    event.preventDefault();
    showPrivacyPolicy();
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Paper elevation={4} sx={{ width: '120rem' }}>
        <form onSubmit={submitHandler}>
          <Box className={classes['form-container']}>
            <FormControls.InputControl
              type='text'
              name='restaurant_name'
              label='Restaurant Name'
              inputRef={restaurantNameRef}
              required
              className={classes['mui-control']}
            />

            <Box className={classes['grid-2']}>
              <FormControls.InputControl
                type='text'
                name='first_name'
                label='First Name'
                inputRef={firstNameRef}
                required
                className={classes['mui-control']}
              />
              <FormControls.InputControl
                type='text'
                name='last_name'
                label='Last Name'
                inputRef={lastNameRef}
                required
                className={classes['mui-control']}
              />
            </Box>

            <Box className={classes['grid-2']}>
              <FormControls.InputControl
                type='text'
                name='position'
                label='Position'
                inputRef={positionRef}
                required
                className={classes['mui-control']}
              />
            </Box>

            <Box className={classes['grid-2']}>
              <FormControls.InputControl
                type='text'
                name='phone'
                label='Phone'
                inputRef={phoneRef}
                required
                className={classes['mui-control']}
              />
              <FormControls.InputControl
                type='email'
                name='email'
                label='Email'
                inputRef={emailRef}
                required
                autoComplete='username'
                className={classes['mui-control']}
              />
            </Box>

            <Box className={classes['grid-2']}>
              <FormControls.InputControl
                type='password'
                name='password'
                label='Password'
                inputRef={passwordRef}
                required
                autoComplete='new-password'
                className={classes['mui-control']}
              />
              <FormControls.InputControl
                type='password'
                name='password_confirm'
                label='Confirm Password'
                inputRef={passwordConfirmRef}
                required
                autoComplete='new-password'
                className={classes['mui-control']}
              />
            </Box>
            <FormControls.RadioGroupControl
              name='notification_preference'
              label='Notification Preference'
              value={notificationPreference}
              onChange={notificationPreferenceChangeHandler}
              items={notificationPreferenceItems}
              required
            />

            <Box>
              <FormControls.CheckboxControl
                name='accept_terms_and_privacy_policy'
                label={
                  <div>
                    Please confirm that you have read and agree to the
                    <Button variant='text' color='primary' onClick={showTermsOfUseHandler}>
                      Terms of Use
                    </Button>
                    and
                    <Button variant='text' color='primary' onClick={showPrivacyPolicyHandler}>
                      Privacy Policy
                    </Button>
                  </div>
                }
                onChange={termsAcceptedHandler}
              />
            </Box>

            <Box component='div' className={classes['actions-container']}>
              <FormControls.ButtonControl
                text='Create Account'
                type='submit'
                disabled={!termsAccepted}
              />
            </Box>
          </Box>
        </form>

        <Divider sx={{ mb: '3rem' }} />

        <Box
          component='div'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            justifyContent: 'center',
            pb: '2rem',
          }}
        >
          <Typography align='center' component='div'>
            Already have an Account?
          </Typography>
          <Link to='/login'>
            <Typography component='div' textAlign='center'>
              Login
            </Typography>
          </Link>
        </Box>
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default VendorSignupForm;
