import { useState, useRef, useEffect, useContext } from 'react';
// import { useHistory } from 'react-router-dom';
import { Box, Paper, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
import classes from '../CSS/standard-mui-form.module.css';

const StripePaymentForm = (props) => {
  const { orderUuid = 'test_order_uuid' } = props;
  const authCtx = useContext(AuthContext);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  // const [checkoutUrl, setCheckoutUrl] = useState(null);
  const subtotalRef = useRef();
  const testSubTotal = '2468.75';

  useEffect(() => {
    subtotalRef.current.focus();
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();

    const enteredSubtotal = subtotalRef.current.value;

    console.log(' -- enteredSubtotal = ' + enteredSubtotal);

    const processPayment = true;

    if (!processPayment) {
      return;
    }

    const apiURL = authCtx.baseApiUrl + 'tests/OrderPayment';
    // const apiURL = authCtx.baseApiUrl + 'tests';
    const apiMethod = 'POST';

    const userUuid = 'test_user_uuid';

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        // action: 'OrderPayment',
        user_uuid: userUuid,
        order_uuid: orderUuid,
        subtotal: enteredSubtotal,
      }),
      headers: {
        Authorization: authCtx.signupToken,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      // setIsLoading(false);

      if (res.ok) {
        subtotalRef.current.value = '';

        return res.json().then((responseData) => {
          console.log(' ** SUCCESS ** ');
          console.log(responseData);

          if (responseData.data.checkout_url) {
            // setCheckoutUrl(responseData.data.checkout_url);
            // window.location.href = responseData.data.checkout_url;
            window.open(responseData.data.checkout_url, '_blank', 'noreferrer');
          }

          // if (userRole === 'User') {
          //   authCtx.updateOrgValues(
          //     responseData.data.teams[0].teamUuid,
          //     responseData.data.teams[0].name
          //   );
          // }

          // props.onCancel(returnedData);
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          // console.log(responseData);
          // setIsError(true);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            // setDisplayMessage(responseData.messages);
            console.table(responseData.messages);
            setNotify({
              isOpen: true,
              message: responseData.messages,
              type: 'error',
            });
          } else {
            // setDisplayMessage([[textFailedApiCallMessage]]);
            console.log('*** SOMETHING HAPPENED ***');
            setNotify({
              isOpen: true,
              message: 'Something Happened',
              type: 'error',
            });
          }
        });
      }
    });
  };

  return (
    <Box sx={{ p: '2rem' }}>
      <Paper elevation={4}>
        <Typography variant='h5' sx={{ p: '1rem' }} className={classes['mui-form-header']}>
          Stripe Payment Form
        </Typography>
        <form onSubmit={submitHandler}>
          <Box className={classes['form-container']}>
            <Box className={classes['side-by-side-form-controls-holder']}>
              <FormControls.InputControl
                type='text'
                name='subtotal'
                label='Order Subtotal'
                defaultValue={testSubTotal}
                inputRef={subtotalRef}
                required
                inputProps={{ maxLength: 10 }}
                className={classes['mui-control']}
              />
            </Box>
            <Box component='div' className={classes['actions-container']}>
              <FormControls.ButtonControl text='Pay the Bill' type='submit' />
              {/* <FormControls.ButtonControl text='Cancel' type='button' onClick={onCancel} /> */}
            </Box>
          </Box>
        </form>
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default StripePaymentForm;
