import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, List, Paper, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import OrderContext from '../../store/order-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import OrderDetails from './OrderDetails';
import DeliveryLocationDetails from './DeliveryLocationDetails';
import RestaurantDetails from './RestaurantDetails';
// import OrderContactDetails from './OrderContactDetails';
// import OrderMessagesList from '../OrderMessages/OrderMessagesList';
import OrderMessages from '../OrderMessages/OrderMessages';
import FileAttachmentList from '../FileAttachments/FileAttachmentList';
import InstructionDetails from './InstructionDetails';
import ListItemWithIcon from '../UI/ListItemWithIcon';
import Notification from '../MUI/UI/Notification';
import ConfirmationDialog from '../MUI/UI/ConfirmationDialog';
import classes from '../CSS/Checkout.module.css';

const ReviewOrder = () => {
  const authCtx = useContext(AuthContext);
  const orderCtx = useContext(OrderContext);

  const history = useHistory();

  const userRole = authCtx.userRole;
  const teamUuid = authCtx.orgDetails.orgUuid;
  const orderDetails = orderCtx.orderDetails;
  const orderUuid = orderCtx.orderDetails.orderUuid;
  const orderContacts = orderCtx.orderContacts;
  const restaurantContacts = orderCtx.restaurantContacts;

  const orderDeliveryInstructions = orderCtx.orderDetails.deliveryInstructions
    ? orderCtx.orderDetails.deliveryInstructions
    : '';
  const orderTeamInstructions = orderCtx.orderDetails.teamInstructions
    ? orderCtx.orderDetails.teamInstructions
    : '';

  if (!orderUuid) {
    history.replace('/team-dashboard/orders');
  }

  const [placeOrderButtonDisabled, setPlaceOrderButtonDisabled] = useState(false);
  const [teamInstructions, setTeamInstructions] = useState(orderTeamInstructions);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      // setIsLoading(true);

      const response = await fetch(authCtx.baseApiUrl + 'teams/' + teamUuid, {
        headers: {
          Authorization: authCtx.token,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(responseData);

        const teamInstructions = responseData.data.team.teamInstructions
          ? responseData.data.team.teamInstructions
          : null;

        setTeamInstructions(teamInstructions);
      } else {
        console.log('$$$$$ ERROR in Team Details $$$$$');

        // setIsError(true);
      }
      // setIsLoading(false);
    };

    if (!orderTeamInstructions) {
      fetchData().catch(console.error);
    } else {
      // setIsLoading(false);
    }
  }, [authCtx.baseApiUrl, authCtx.token, teamUuid, orderTeamInstructions]);

  // Test Function to send out order notifications after data has been updated and returned.
  // This is being done to speed things up.
  const sendOrderNotifications = async () => {
    // console.log('sendOrderNotifications - orderUuid = ' + orderUuid);

    // setNotify({ isOpen: true, message: 'Updating order information.', type: 'info' });

    const apiURL = authCtx.baseApiUrl + 'orders/ordernotifications/' + orderUuid;
    const apiMethod = 'GET';

    const response = await fetch(apiURL, {
      method: apiMethod,
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      await response.json();
      // const responseData = await response.json();
      // console.log(responseData);
    } else {
      console.log(' ** ERROR ** ');
      setNotify({
        isOpen: true,
        message: 'An Error occurred while sending Order Notifications.',
        type: 'error',
      });
    }
  };

  const placeOrder = () => {
    // console.log('Place Order - orderUuid = ' + orderUuid);

    setPlaceOrderButtonDisabled(true);

    const teamOrderStatus = 'Placed';

    let restaurantOrderStatus = 'New';
    if (
      orderDetails.restaurantStatus === 'New' ||
      orderDetails.restaurantStatus === 'Accepted' ||
      orderDetails.restaurantStatus === 'Modified'
    ) {
      restaurantOrderStatus = 'Modified';
    }

    // console.log(
    //   '##### placeOrder -- orderDetails.restaurantStatus = ' +
    //     orderDetails.restaurantStatus +
    //     ' -- restaurantOrderStatus = ' +
    //     restaurantOrderStatus
    // );

    const apiURL = authCtx.baseApiUrl + 'orders/' + orderUuid;
    const apiMethod = 'PATCH';

    // console.log(' - apiURL = ' + apiURL);
    // console.log(' - apiMethod = ' + apiMethod);

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        status: teamOrderStatus,
        restaurant_status: restaurantOrderStatus,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        setNotify({
          isOpen: true,
          message: 'Order Successfully Placed.',
          type: 'success',
        });

        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          // const deliveryLocationUuid = responseData.data.delivery_locations[0].deliveryLocationUuid;
          const orderDetails = responseData.data.orders[0];

          // console.log('orderDetails');
          // console.table(orderDetails);

          orderCtx.setOrderDetails(orderDetails);
          orderCtx.clearOrderItems();

          sendOrderNotifications();

          // history.replace('/checkout/order-confirmation');
          history.replace('/team-dashboard/orders/' + orderUuid);
        });
      } else {
        return res.json().then((responseData) => {
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: 'Order Not Placed',
              type: 'error',
            });
          }
        });
      }
    });
  };

  const submitOrderHandler = () => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });

    placeOrder();
  };

  // const continueCheckoutHandler = () => {
  //   const redirect = false;

  //   placeOrder();

  //   if (redirect) {
  //     history.replace('/checkout/order-confirmation');
  //   }
  // };

  return (
    <>
      <StandardPageWrapper title='Review Order' usePaper='true'>
        <Box className={classes['content-holder']}>
          <List>
            <ListItemWithIcon textContent='Please review your Order details carefully before placing the Order.' />
            <ListItemWithIcon textContent='Payment for Orders takes place after your Fueling Partner has reviewed and Confirmed the Order.' />
            <ListItemWithIcon textContent='You will receive an email alerting you to log into your Elite Eats account once your order has been accepted so that you can remit payment and confirm your athlete catering order!' />
            <ListItemWithIcon textContent='This ensures that your Fueling Partner will be able to meet your specific needs before payment is required.' />
          </List>
          <Box className={classes['details-section']}>
            <Paper elevation={4} sx={{ p: '20px' }}>
              <Box className='flex-wrap-container'>
                <div>
                  <OrderDetails orderDetails={orderDetails} displayDeliveryAddress={false} />
                  <DeliveryLocationDetails
                    deliveryLocation={orderCtx.deliveryLocation}
                    orderContacts={orderContacts}
                  />
                </div>

                <RestaurantDetails
                  restaurant={orderCtx.restaurant}
                  restaurantContacts={restaurantContacts}
                />
              </Box>
            </Paper>

            <FileAttachmentList
              orderUuid={orderUuid}
              displayHeaderText={true}
              includePaperContainer={true}
            />

            <Paper elevation={4} className='p-10'>
              <OrderMessages
                orderUuid={orderUuid}
                userRole={userRole}
                status={orderDetails.status}
                mode='review-order'
              />
            </Paper>

            <Paper elevation={4}>
              <InstructionDetails
                orderDetails={orderDetails}
                orderDeliveryInstructions={orderDeliveryInstructions}
                orderTeamInstructions={teamInstructions}
              />
            </Paper>
          </Box>
        </Box>

        <Box component='div' className='button-holder' sx={{ pt: '4rem' }}>
          <Button
            variant='contained'
            color='accent'
            size='custom'
            type='button'
            // onClick={submitOrderHandler}
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: 'Submit Order?',
                yesButton: 'Submit Order',
                noButton: 'Cancel',
                subTitle:
                  'Please Note: Payment is not required at this step.  Your order will be sent to the fueling partner for joint review and approval before payment is requested.',
                onConfirm: () => {
                  submitOrderHandler();
                },
              });
            }}
            disabled={placeOrderButtonDisabled}
          >
            Submit Order
          </Button>
        </Box>

        {placeOrderButtonDisabled ? (
          <Box component='div' sx={{ pt: '4rem' }}>
            <Typography align='center'>Sending Order Information...</Typography>
          </Box>
        ) : null}
      </StandardPageWrapper>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default ReviewOrder;
