import React from 'react';
import { Box } from '@mui/material';

import classes from '../CSS/styled-table.module.css';

import { teamChargeLabel, deliveryFeeLabel } from '../../constants/global';

const ExpenseReport = ({
  orders,
  displayOptions,
  startDate,
  endDate,
  ordersTaxTotal,
  ordersTotal,
  ordersTipTotal,
  ordersDeliveryFeeTotal,
  ordersTeamChargeTotal,
}) => {
  // console.log('##### ExpenseReport #####');
  // console.log('orders');
  // console.log(orders);
  // console.log('displayOptions.length = ' + displayOptions.length);
  // console.log('startDate = ' + startDate);
  // console.log('endDate = ' + endDate);

  const displayVendorAddressUnderName = true;

  // const standardColSpan = 4;
  const standardColSpan = displayVendorAddressUnderName ? 3 : 4;
  // const totalsColSpan = displayOptions ? standardColSpan + displayOptions.length : standardColSpan;
  // const totalsColSpan = additionalInfoCols ? standardColSpan + additionalInfoCols : standardColSpan;
  let totalsColSpan = standardColSpan;
  //

  const setTotalColSpan = () => {
    // console.log('### setTotalColSpan ###');

    if (displayOptions.includes('displayDeliveryDateAndTime')) {
      totalsColSpan++;
    }
    if (displayOptions.includes('displayDeliveryLocation')) {
      totalsColSpan++;
    }
  };
  //

  setTotalColSpan();

  // console.log('totalsColSpan = ' + totalsColSpan);

  const displayTableHeader = () => {
    return (
      <tr>
        <th>Payment Date</th>
        <th>Order Number</th>
        <th>Vendor</th>
        {!displayVendorAddressUnderName ? <th>Vendor Address</th> : null}

        {displayOptions.includes('displayDeliveryDateAndTime') ? (
          <th>Delivery Date & Time</th>
        ) : null}
        {displayOptions.includes('displayDeliveryDate') ? <th>Delivery Date</th> : null}
        {displayOptions.includes('displayDeliveryTime') ? <th>Delivery Time</th> : null}
        {displayOptions.includes('displayDeliveryLocation') ? <th>Delivery Location</th> : null}
        {displayOptions.includes('displayStatus') ? <th>Status</th> : null}
        {displayOptions.includes('displayDeliveryFee') ? (
          <th style={{ maxWidth: '80px' }} className='text-right'>
            {deliveryFeeLabel}
          </th>
        ) : null}
        {displayOptions.includes('displayTip') ? <th className='text-right'>Tip</th> : null}
        {displayOptions.includes('displayTeamCharge') ? (
          <th style={{ maxWidth: '100px' }} className='text-right'>
            {teamChargeLabel}
          </th>
        ) : null}
        {/* {displayOptions.includes('displayTax') ? <th className='text-right'>Order Tax</th> : null} */}
        <th className='text-right'>Order Tax</th>
        <th className='text-right'>Order Total</th>
      </tr>
    );
  };

  const displayTableBody = () => {
    return orders.map((order, index) => (
      <tr key={index}>
        <td>{order.paymentDateDateOnly}</td>
        <td>{order.orderNumber}</td>
        <td>
          {order.restaurantName}
          {displayVendorAddressUnderName ? (
            <div className='small-text'>{order.restaurantAddress}</div>
          ) : null}
        </td>
        {!displayVendorAddressUnderName ? <td>{order.restaurantAddress}</td> : null}

        {displayOptions.includes('displayDeliveryDateAndTime') ? (
          <td>{order.deliveryDate + ' @ ' + order.deliveryTime}</td>
        ) : null}
        {displayOptions.includes('displayDeliveryDate') ? <td>{order.deliveryDate}</td> : null}
        {displayOptions.includes('displayDeliveryTime') ? <td>{order.deliveryTime}</td> : null}
        {displayOptions.includes('displayDeliveryLocation') ? (
          <td>
            {order.deliveryLocation} <span className='small-text'>({order.deliveryAddress})</span>
          </td>
        ) : null}
        {displayOptions.includes('displayStatus') ? <td>{order.status}</td> : null}
        {displayOptions.includes('displayDeliveryFee') ? (
          <td className={`${classes['text-right']}`}>{order.deliveryFeeFormatted}</td>
        ) : null}
        {displayOptions.includes('displayTip') ? (
          <td className={`${classes['text-right']}`}>{order.tipFormatted}</td>
        ) : null}
        {displayOptions.includes('displayTeamCharge') ? (
          <td className={`${classes['text-right']}`}>{order.teamChargeFormatted}</td>
        ) : null}
        {/* {displayOptions.includes('displayTax') ? (
          <td className={`${classes['text-right']}`}>{order.taxFormatted}</td>
        ) : null} */}
        <td className={`${classes['text-right']}`}>{order.taxFormatted}</td>
        <td className={`${classes['text-right']}`}>{order.totalFormatted}</td>
      </tr>
    ));
  };

  const displayTableFooter = () => {
    return (
      <tr>
        <td
          colSpan={totalsColSpan}
          className={`${classes['text-bold']} ${classes['extra-padding']}`}
        >
          Expense Report Totals ({startDate + ' - ' + endDate})
        </td>

        {displayOptions.includes('displayDeliveryFee') ? (
          <td
            className={`${classes['text-bold']} ${classes['text-right']} ${classes['extra-padding']}`}
          >
            {ordersDeliveryFeeTotal}
          </td>
        ) : null}
        {displayOptions.includes('displayTip') ? (
          <td
            className={`${classes['text-bold']} ${classes['text-right']} ${classes['extra-padding']}`}
          >
            {ordersTipTotal}
          </td>
        ) : null}
        {displayOptions.includes('displayTeamCharge') ? (
          <td
            className={`${classes['text-bold']} ${classes['text-right']} ${classes['extra-padding']}`}
          >
            {ordersTeamChargeTotal}
          </td>
        ) : null}

        <td
          className={`${classes['text-bold']} ${classes['text-right']} ${classes['extra-padding']}`}
        >
          {ordersTaxTotal}
        </td>
        <td
          className={`${classes['text-bold']} ${classes['text-right']} ${classes['extra-padding']}`}
        >
          {ordersTotal}
        </td>
      </tr>
    );
  };

  return (
    <Box className='mt-40'>
      <Box className='table-holder'>
        <table className={`${classes['styled-table']} ${classes['full-width']}`}>
          <thead>{displayTableHeader()}</thead>
          <tbody>
            {displayTableBody()}
            {displayTableFooter()}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

export default ExpenseReport;
