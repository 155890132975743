import React from 'react';

import StandardPageWrapper from '../Layout/StandardPageWrapper';
import ReferVendorInstructions from '../ReferVendor/ReferVendorInstructions';
import ReferVendorForm from '../ReferVendor/ReferVendorForm';

const ReferVendor = () => {
  return (
    <StandardPageWrapper title='Refer A Fueling Partner'>
      <ReferVendorInstructions />
      <ReferVendorForm />
    </StandardPageWrapper>
  );
};

export default ReferVendor;
