import React from 'react';
import { Box } from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';

import PdfExpenseReport from './PdfExpenseReport';

const PrintExpenseReport = ({
  orders,
  team,
  startDate,
  endDate,
  ordersTotalLabel,
  ordersTaxTotal,
  ordersTotal,
}) => {
  // console.log('##### PrintExpenseReport #####');
  // console.log('--- orders ---');
  // console.table(orders);

  return (
    <Box>
      <PDFViewer className='pdf-viewer'>
        <PdfExpenseReport
          orders={orders}
          team={team}
          startDate={startDate}
          endDate={endDate}
          ordersTotalLabel={ordersTotalLabel}
          ordersTaxTotal={ordersTaxTotal}
          ordersTotal={ordersTotal}
        />
      </PDFViewer>
    </Box>
  );
};

export default PrintExpenseReport;
