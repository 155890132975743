import React from 'react';
import { Box, Typography } from '@mui/material';

import { useFormatData } from '../Hooks/useFormatData';

import { deliveryFeesLabel } from '../../constants/global';

const DeliveryFeesSimpleCard = ({ deliveryFees = [] }) => {
  const { FormatCurrencyForDisplay } = useFormatData();

  const displayTableRowsHeader = () => {
    return (
      <tr>
        <th className='label-right'>Order Amount</th>
        <th className='label-right'>Fee</th>
      </tr>
    );
  };

  const displayTableRows = () => {
    return deliveryFees.map((record, index) => (
      <tr key={index}>
        <td className='table-td-number'>
          {FormatCurrencyForDisplay(record.rangeStart) +
            ' - ' +
            FormatCurrencyForDisplay(record.rangeEnd)}
        </td>
        <td className='table-td-number'>
          {record.percentFormatted ? record.percentFormatted : record.feeFormatted}
        </td>
      </tr>
    ));
  };

  return (
    <Box sx={{ width: '100%' }}>
      {deliveryFees.length === 0 ? (
        <Typography color='error'>No {deliveryFeesLabel}</Typography>
      ) : null}

      {deliveryFees.length > 0 ? (
        <Box>
          <Typography variant='labelSmall' align='center'>
            {deliveryFeesLabel}
          </Typography>
          <table className='table-simple full-width table-simple-nowrap table-simple-with-padding small-text'>
            <thead>{displayTableRowsHeader()}</thead>
            <tbody>{displayTableRows()}</tbody>
          </table>
        </Box>
      ) : null}
    </Box>
  );
};

export default DeliveryFeesSimpleCard;
