import StandardPageWrapper from '../Layout/StandardPageWrapper';
import NewRestaurants from '../Admin/NewRestaurants';
import NewTeams from '../Admin/NewTeams';
import classes from './Admin.module.css';

const Dashboard = () => {
  return (
    <StandardPageWrapper title='Dashboard'>
      <div className={classes['widget-grid-container']}>
        <div className={classes['widget-container']}>
          <NewTeams />
        </div>
        <div className={classes['widget-container']}>
          <NewRestaurants />
        </div>
      </div>
    </StandardPageWrapper>
  );
};

export default Dashboard;
