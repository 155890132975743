import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import VendorDeliveryLocationsList from '../DeliveryLocations/VendorDeliveryLocationsList';

const DeliveryLocations = () => {
  const authCtx = useContext(AuthContext);

  const restaurantUuid = authCtx.selectedRestaurantUuid;
  const restaurantName = authCtx.selectedRestaurantName;
  const restaurantCity = authCtx.selectedRestaurantCity;
  const restaurantState = authCtx.selectedRestaurantState;
  // console.log('&& restaurantUuid = ' + restaurantUuid + ' &&');
  // console.log('&& restaurantName = ' + restaurantName + ' &&');
  // console.log('&& restaurantCity = ' + restaurantCity + ' &&');
  // console.log('&& restaurantState = ' + restaurantState + ' &&');

  const currentRestaurant = {
    restaurantUuid: restaurantUuid,
    name: restaurantName,
    city: restaurantCity,
    state: restaurantState,
  };

  // console.log('currentRestaurant');
  // console.table(currentRestaurant);

  const displayRetaurantName = false;

  return (
    <StandardPageWrapper>
      {displayRetaurantName && currentRestaurant ? (
        <Box
          sx={{
            pb: '2rem',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <Typography variant='h2' component='h2'>
            {currentRestaurant.name}
          </Typography>
        </Box>
      ) : null}

      <VendorDeliveryLocationsList
        restaurantUuid={restaurantUuid}
        restaurantState={restaurantState}
        returnTo='delivery-locations'
      />
    </StandardPageWrapper>
  );
};

export default DeliveryLocations;
