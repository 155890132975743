import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import OrderContext from '../../store/order-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import DeliveryLocationForm from './DeliveryLocationForm';
import DeliveryLocationDetails from './DeliveryLocationDetails';
import classes from '../CSS/Checkout.module.css';

const DeliveryLocation = () => {
  const authCtx = useContext(AuthContext);
  const orderCtx = useContext(OrderContext);

  // const orderUuid = orderCtx.orderDetails.orderUuid;
  // const orderDeliveryLocationUuid = orderCtx.orderDetails.deliveryLocationUuid;
  const orderDeliveryLocation = orderCtx.deliveryLocation;
  let deliveryLocationUuid = '';
  if (orderDeliveryLocation) {
    deliveryLocationUuid = orderDeliveryLocation.deliveryLocationUuid;
  }
  const [deliveryLocation, setDeliveryLocation] = useState(orderDeliveryLocation);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  // console.log('orderUuid');
  // console.log(orderUuid);
  // console.log('orderDeliveryLocationUuid');
  // console.log(orderDeliveryLocationUuid);
  // console.log('deliveryLocationUuid');
  // console.log(deliveryLocationUuid);
  // console.log('deliveryLocation');
  // console.log(deliveryLocation);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const response = await fetch(
        authCtx.baseApiUrl + 'deliverylocations/' + deliveryLocationUuid,
        {
          headers: {
            Authorization: authCtx.token,
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        // console.log(responseData);

        const returnedLocationDetails = {
          deliveryLocationUuid: responseData.data.delivery_locations[0].deliveryLocationUuid,
          name: responseData.data.delivery_locations[0].name,
          description: responseData.data.delivery_locations[0].description,
          address1: responseData.data.delivery_locations[0].address1,
          address2: responseData.data.delivery_locations[0].address2,
          city: responseData.data.delivery_locations[0].city,
          state: responseData.data.delivery_locations[0].state,
          zipcode: responseData.data.delivery_locations[0].zipcode,
          email: responseData.data.delivery_locations[0].email,
          phone: responseData.data.delivery_locations[0].phone,
          deliveryInstructions: responseData.data.delivery_locations[0].deliveryInstructions,
          status: responseData.data.delivery_locations[0].status,
        };
        // console.log(' -- returnedLocationDetails -- ');
        // console.log(returnedLocationDetails);

        setDeliveryLocation(returnedLocationDetails);
      } else {
        console.log('$$$$$ ERROR in DeliveryLocation $$$$$');

        setIsError(true);
      }
      setIsLoading(false);
    };

    if (!deliveryLocation) {
      fetchData().catch(console.error);
    } else {
      setIsLoading(false);
    }
  }, [authCtx.baseApiUrl, authCtx.token, deliveryLocation, deliveryLocationUuid]);

  return (
    <>
      <div className='page-container-after-top-menu'>
        <StandardPageWrapper title='Delivery Location'>
          <Box className={classes['content-holder']} sx={{ width: '70rem' }}>
            <Typography variant='body1' component='p'>
              We need all of the Delivery Location Information you can give to us before you
              Checkout. Please make sure all of Address Information for the Delivery Location is
              filled out correctly.
            </Typography>
            <Typography variant='body1' component='p'>
              The next Step in the Checkout Process will allow you to enter/select some specific
              Dellivery Instructions.
            </Typography>

            <Box sx={{ display: 'block' }} className={classes['details-section']}>
              <DeliveryLocationDetails deliveryLocation={orderCtx.deliveryLocation} />
            </Box>
          </Box>
          {isLoading && (
            <div className={classes['message-holder']}>
              <p className={classes['loading']}>Loading...</p>
            </div>
          )}
          {isError && (
            <div className={classes['message-holder']}>
              <p className={classes['error']}>Error...</p>
            </div>
          )}
          <DeliveryLocationForm
            // deliveryLocationUuid={deliveryLocationUuid}
            deliveryLocation={deliveryLocation}
          />
        </StandardPageWrapper>
      </div>
    </>
  );
};

export default DeliveryLocation;
