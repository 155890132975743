import React, { useState } from 'react';
import { Box, Button, Divider, Menu, MenuItem, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import classes from '../CSS/MenuCategoriesHeader.module.css';

const MenuCategoriesHeader = (props) => {
  const { menuItemCategories } = props;
  // console.log('menuItemCategories');
  // console.log(menuItemCategories);

  // const testCheck = menuItemCategories.includes('Appetizers');
  const appetizersIncluded = menuItemCategories.find(({ name }) => name === 'Appetizers');
  // console.log('appetizersIncluded', appetizersIncluded);

  const displayAppetizerMenuItem = appetizersIncluded ? true : false;
  const displayBoxedMeals = false;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleBuildYourOwnClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBuildYourOwnClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes['menu-categories-header-holder']}>
      <Typography variant='h2' component='h2' sx={{ pl: '10px', pt: '10px' }}>
        Catering Menu
      </Typography>
      <Box className={classes['menu-categories-header']}>
        <Button variant='text' size='large' href='#Sport Catering Packages' className='bold-text'>
          Sport Catering Packages
        </Button>
        {displayBoxedMeals ? (
          <Button variant='text' size='large' href='#Boxed Meals' className='bold-text'>
            Boxed Meals
          </Button>
        ) : null}
        <Button
          variant='text'
          size='large'
          id='build-your-own-menu-button'
          onClick={handleBuildYourOwnClick}
          className='bold-text'
        >
          Build Your Own
          <ArrowDropDownIcon />
        </Button>
        <Button variant='text' size='large' href='#Beverages' className='bold-text'>
          Beverages
        </Button>
        <Button variant='text' size='large' href='#Service Items' className='bold-text'>
          Service Items
        </Button>

        {/* <div>Menu Categories Go Here</div> */}
        {/* {categories.map((cat, index) => (
        <a key={index} href={`#${cat}`} className={classes['menu-link']}>
          {cat}
        </a>
      ))} */}

        {/* {menuItemCategories.map((cat, index) => (
        // <Button variant='text' color='neutral1000' key={index}>
        //   {cat}
        // </Button>
        <a key={index} href={`#${cat.name}`} className={classes['menu-link']}>
          {cat.name}
        </a>
      ))} */}
      </Box>
      <Menu
        id='build-your-own-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleBuildYourOwnClose}
        className={classes['menu']}
      >
        <MenuItem onClick={handleBuildYourOwnClose}>
          <a href='#Breakfast'>Breakfast</a>
        </MenuItem>

        {displayAppetizerMenuItem ? (
          <MenuItem onClick={handleBuildYourOwnClose}>
            <a href='#Appetizers'>Appetizers</a>
          </MenuItem>
        ) : null}

        <MenuItem onClick={handleBuildYourOwnClose}>
          <a href='#Entrees'>Entrees</a>
        </MenuItem>

        <MenuItem onClick={handleBuildYourOwnClose}>
          <a href='#Sandwiches & Wraps'>Sandwiches & Wraps</a>
        </MenuItem>

        <MenuItem onClick={handleBuildYourOwnClose}>
          <a href='#Salads & Bowls'>Salads & Bowls</a>
        </MenuItem>

        {/* <MenuItem onClick={handleBuildYourOwnClose}>
          <a href='#Salads'>Salads</a>
        </MenuItem> */}

        <MenuItem onClick={handleBuildYourOwnClose}>
          <a href='#Sides'>Sides</a>
        </MenuItem>
        <MenuItem onClick={handleBuildYourOwnClose}>
          <a href='#Desserts'>Desserts</a>
        </MenuItem>
      </Menu>

      <Box>
        <Divider />
      </Box>
    </Box>
  );
};

export default MenuCategoriesHeader;
