import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Alert, Box, Paper, Typography } from '@mui/material';

import {
  noRestaurantsExplanationLine1,
  noRestaurantsExplanationLine2,
} from '../../constants/global';

import AuthContext from '../../store/auth-context';
import OrderContext from '../../store/order-context';
import * as Constants from '../../helpers/Constants';
// import { displayListFilterSelect } from '../../helpers/FilterListHelperFunctions';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import WorkflowMessage from '../UI/WorkflowMessage';
import SelectRestaurantCard from './SelectRestaurantCard';
import OrderForm from './OrderForm';
import ConfirmationDialog from '../MUI/UI/ConfirmationDialog';
import classes from '../CSS/Order.module.css';

const SelectRestaurant = () => {
  const authCtx = useContext(AuthContext);
  const orderCtx = useContext(OrderContext);

  // console.log('***** SelectRestaurant ***** ');
  // console.log('orderCtx.deliveryLocation');
  // console.log(orderCtx.deliveryLocation);

  const history = useHistory();

  const dashboardUrl = authCtx.dashboardUrl;
  const orderUuid = orderCtx.orderDetails.orderUuid;
  const selectedDeliveryCity = orderCtx.orderDetails.deliveryCity;
  const selectedDeliveryLocation = orderCtx.deliveryLocation;
  const currentSelectedRestaurant = orderCtx.restaurant;
  const isReorder = orderCtx.isReorder;

  const deliveryDateAndTimeForDisplay = orderCtx.orderDetails.deliveryDateAndTimeForDisplay;

  const pageTitle =
    deliveryDateAndTimeForDisplay === 'NOT SET' || isReorder
      ? 'Set Delivery Date and Time'
      : 'Select A Restaurant';

  // console.log('##### SelectRestaurant #####');
  // console.log('orderUuid = ' + orderUuid);
  // console.log('orderCtx.orderDetails');
  // console.log(orderCtx.orderDetails);
  // console.log(orderCtx.orderDetails.restaurantName);
  // console.log('selectedDeliveryLocation');
  // console.log(selectedDeliveryLocation);
  // console.log('currentSelectedRestaurant');
  // console.log(currentSelectedRestaurant);
  // console.log('deliveryDateAndTimeForDisplay', deliveryDateAndTimeForDisplay);
  // console.log('pageTitle', pageTitle);
  // console.log('isReorder = ' + isReorder);

  const baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath;

  const [restaurants, setRestaurants] = useState([]);
  const [displayedRestaurants, setDisplayedRestaurants] = useState([]);
  const [backToOrderButtonActive, setBackToOrderButtonActive] = useState(true);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  // const apiUrl =
  //   authCtx.baseApiUrl +
  //   'restaurants/deliverylocation/' +
  //   selectedDeliveryLocation.deliveryLocationUuid;

  useEffect(() => {
    if (!orderUuid || orderUuid === undefined) {
      history.replace(dashboardUrl + 'orders');
      return;
    }

    const apiUrl = authCtx.baseApiUrl + 'restaurants/order/' + orderUuid;

    const fetchData = async () => {
      setIsLoading(true);

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.log(' -- response.ok -- ');
        // console.log('responseData.data');
        // console.log(responseData.data);

        const imageFolder = Constants.IMAGE_FOLDER_PATH;
        const defaultListImage = imageFolder + 'restaurants/default_list_image.jpg';
        const defaultHeaderImage = imageFolder + 'restaurants/default_header_image.jpg';

        const transformedRestaurants = responseData.data.restaurants.map((restaurant) => {
          return {
            restaurantUuid: restaurant.restaurantUuid,
            name: restaurant.name,
            description: restaurant.description,
            // address:
            //   restaurant.address2 === ''
            //     ? restaurant.address1
            //     : restaurant.address1 + ', ' + restaurant.address2,
            address:
              restaurant.address2 === '' || restaurant.address2 === null
                ? restaurant.address1
                : restaurant.address1 + ', ' + restaurant.address2,
            address1: restaurant.address1,
            address2: restaurant.address2,
            city: restaurant.city,
            state: restaurant.state,
            zipcode: restaurant.zipcode,
            email: restaurant.email,
            phone: restaurant.phone,
            foodType: restaurant.foodType,
            listImage:
              restaurant.listImage === '' || restaurant.listImage === null
                ? defaultListImage
                : baseApiUploadedImagesPath +
                  restaurant.restaurantUuid +
                  '/' +
                  restaurant.listImage,
            headerImage:
              restaurant.headerImage === '' || restaurant.headerImage === null
                ? defaultHeaderImage
                : baseApiUploadedImagesPath +
                  restaurant.restaurantUuid +
                  '/' +
                  restaurant.headerImage,
            status: restaurant.status,
            leagueExperience: restaurant.leagueExperience,
            deliveryFees: restaurant.deliveryFees,
            deliveryHours: restaurant.deliveryHours,
            orderLeadTime: restaurant.orderLeadTime,
            taxRate: restaurant.taxRate,
            rating: restaurant.rating,
            numberOfRatings: restaurant.numberOfRatings,
            contacts: restaurant.contacts,
          };
        });

        setRestaurants(transformedRestaurants);
        // setDisplayedRestaurants(transformedRestaurants);

        // console.log('transformedRestaurants');
        // console.log(transformedRestaurants);

        // Check to see if there is a selected restaurant and if there is, is it one of the available restaurants.
        // If it the current restaunt isn't one of the available restaurants then we need disable the Back to Order button.
        const duplicate = transformedRestaurants.some(
          (restaurant) => restaurant.restaurantUuid === currentSelectedRestaurant.restaurantUuid
        );
        // console.log('duplicate = ' + duplicate);
        setBackToOrderButtonActive(duplicate);

        let filteredRecords = transformedRestaurants;

        // console.log('selectedDeliveryCity = ' + selectedDeliveryCity);
        // console.log('filteredRecords');
        // console.log(filteredRecords);

        // if (selectedDeliveryCity) {
        //   // filteredRecords = filteredRecords.filter(
        //   //   (record) => record.city === selectedDeliveryCity
        //   // );
        //   filteredRecords = filteredRecords.filter((record) =>
        //     record.city.includes(selectedDeliveryCity)
        //   );
        //   // setRestaurants(filteredRecords);
        // }
        setDisplayedRestaurants(filteredRecords);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    if (orderUuid) {
      fetchData().catch(console.error);
    } else {
      console.log(' ----- NO orderUuid ----- ');
      setIsLoading(false);
    }
  }, [
    authCtx.baseApiUrl,
    authCtx.token,
    selectedDeliveryCity,
    baseApiUploadedImagesPath,
    selectedDeliveryLocation.deliveryLocationUuid,
    orderUuid,
    dashboardUrl,
    history,
    currentSelectedRestaurant.restaurantUuid,
  ]);

  // const updateSelectedRestaurantHandler = (uuid, name, city, state) => {
  //   authCtx.updateSelectedRestaurantValues(uuid, name, city, state);
  // };

  const getSelectedRestaurantData = (restaurantUuid) => {
    const selectedRestaurant = restaurants.filter(
      (restaurant) => restaurant.restaurantUuid === restaurantUuid
    );
    // console.log(' - getSelectedRestaurantData - ');
    // console.log(selectedRestaurant);
    return selectedRestaurant[0];
  };

  const updateSelectedRestuarant = (restaurantUuid) => {
    const selectedRestaurant = getSelectedRestaurantData(restaurantUuid);
    // console.log('updateSelectedRestuarant');
    // console.log(' - selectedRestaurantHandler - selectedRestaurant = ');
    // console.log(selectedRestaurant);

    // Update Order DB Record to include the Restaurant.
    updateOrderToIncludeRestaurant(selectedRestaurant);

    orderCtx.setRestaurant(selectedRestaurant);
    orderCtx.setRestaurantContacts(selectedRestaurant.contacts);
  };

  const confirmCartItemsWillBeRemoved = (restaurantUuid) => {
    setConfirmDialog({
      isOpen: true,
      title: 'Are you sure you want to change Restaurants?',
      subTitle: 'All Cart Items will be removed.',
      onConfirm: () => {
        updateSelectedRestuarant(restaurantUuid);
      },
    });
  };

  const selectedRestaurantHandler = (restaurantUuid, name) => {
    // console.log('selectedRestaurantHandler');
    // console.log('restaurantUuid = ' + restaurantUuid);
    // console.log('currentSelectedRestaurant');
    // console.table(currentSelectedRestaurant);

    // Check to see if the restaurant was actually changed.
    if (currentSelectedRestaurant && currentSelectedRestaurant.restaurantUuid === restaurantUuid) {
      // console.log(' -- GOING TO restaurant-menu');
      history.replace('/order/restaurant-menu');
      return;
    } else {
      if (!currentSelectedRestaurant.restaurantUuid && restaurantUuid) {
        updateSelectedRestuarant(restaurantUuid);
      } else {
        confirmCartItemsWillBeRemoved(restaurantUuid);
      }
    }
  };

  const deleteAllOrderItems = async (orderUuid) => {
    const response = await fetch(authCtx.baseApiUrl + 'orderitems/order/' + orderUuid, {
      method: 'DELETE',
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      // const responseData = await response.json();
      // console.log(responseData);
      await response.json();
    } else {
      // setIsError(true);
      console.log(' ******** ERROR - deleting an Order Item - ERROR ******** ');
    }
    // setIsLoading(false);
  };

  const updateOrderToIncludeRestaurant = (selectedRestaurant) => {
    setIsLoading(true);

    const apiURL = authCtx.baseApiUrl + 'orders/' + orderUuid;
    const apiMethod = 'PATCH';

    // console.log(' - apiURL = ' + apiURL);
    // console.log(' - apiMethod = ' + apiMethod);
    // console.log(' - enteredStatus = ' + enteredStatus);

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        restaurant_uuid: selectedRestaurant.restaurantUuid,
        tax_rate: selectedRestaurant.taxRate,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          orderCtx.setOrderDetails(responseData.data.orders[0]);
          orderCtx.clearOrderItems();

          deleteAllOrderItems(orderUuid).catch(console.error);

          history.replace('/order/restaurant-menu');
          setIsLoading(false);
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          console.log(' ** ERROR ** ');
          console.log(responseData);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            // Todo: figure out what to do here.
            // setDisplayMessage(responseData.messages);
          } else {
            // Todo: figure out what to do here.
            // setDisplayMessage(['Failed to save Order Data.']);
          }
        });
      }
    });
  };

  return (
    <Box className='page-container-after-top-menu'>
      <StandardPageWrapper title={pageTitle}>
        {isLoading ? <WorkflowMessage type='loading' /> : null}
        {isError ? <WorkflowMessage type='error' /> : null}

        {!isLoading && !isError ? (
          <>
            <Box className='mb-20'>
              <OrderForm mode='selectrestaurant' />
            </Box>

            {/* {orderCtx.orderDetails.restaurantName ? ( */}
            {currentSelectedRestaurant.name ? (
              <Paper elevation={4} sx={{ p: '20px', mb: '20px' }}>
                <Box className={classes['current-restaurant-container']}>
                  <div className={classes['current-restuarnt-name']}>
                    <Typography variant='labelInline' component='div'>
                      Current Restaurant:
                    </Typography>
                    <Typography component='div'>{currentSelectedRestaurant.name}</Typography>
                  </div>
                  <div>
                    {backToOrderButtonActive ? (
                      <Link
                        to='/order/restaurant-menu'
                        className='button green'
                        id='backbutton'
                        // style={{ pointerEvents: 'none' }}
                      >
                        Back to Order
                      </Link>
                    ) : (
                      <span className='button green disabled'>Back to Order</span>
                    )}
                  </div>
                </Box>
              </Paper>
            ) : null}

            {displayedRestaurants.length === 0 && !isReorder ? (
              <>
                <WorkflowMessage type='nodata' message='No Restaurants Found....' />

                <Box align='center'>
                  <Box sx={{ maxWidth: '600px' }}>
                    <Alert severity='error'>
                      <Typography align='left'>{noRestaurantsExplanationLine1}</Typography>
                      <Typography align='left'>{noRestaurantsExplanationLine2}</Typography>
                    </Alert>
                  </Box>
                </Box>
              </>
            ) : null}

            {displayedRestaurants.length === 0 &&
            isReorder &&
            deliveryDateAndTimeForDisplay !== 'NOT SET' ? (
              <Box align='center'>
                <Box sx={{ maxWidth: '600px', pt: '40px' }}>
                  <Alert severity='error'>
                    The Restaurant cannot delivery your order on the date and time selected.
                  </Alert>
                </Box>
              </Box>
            ) : null}

            {displayedRestaurants.length === 0 &&
            isReorder &&
            deliveryDateAndTimeForDisplay === 'NOT SET' ? (
              <Box align='center'>
                <Box sx={{ maxWidth: '600px', py: '40px' }}>
                  <Alert severity='info'>Set a Delivery Date and Time.</Alert>
                </Box>
              </Box>
            ) : null}

            {displayedRestaurants.length > 0 && isReorder ? (
              <Box align='center'>
                <Box sx={{ maxWidth: '600px', py: '40px' }}>
                  <Alert severity='success'>
                    The Restaurant is able to deliver your order on the date and time selected.
                  </Alert>
                </Box>
              </Box>
            ) : null}

            {/* {(displayedRestaurants.length > 0 && !isReorder) ||
            (displayedRestaurants.length === 1 && isReorder) ? ( */}
            {displayedRestaurants.length > 0 && !isReorder ? (
              <Box className='card-list-holder'>
                {displayedRestaurants.map((restaurant, index) => (
                  <SelectRestaurantCard
                    key={index}
                    restaurant={restaurant}
                    selectRestaurant={selectedRestaurantHandler}
                    authCtx={authCtx}
                  />
                ))}
              </Box>
            ) : null}
          </>
        ) : null}
      </StandardPageWrapper>

      <ConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </Box>
  );
};

export default SelectRestaurant;
