import { Box } from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
// import PdfTestPageBasic from './PdfTestPageBasic';
import PdfOrderExample from './PdfOrderExample';
import PdfDeliveryDetails from './PdfDeliveryDetails';

const ReactPdfTestPage = () => {
  return (
    <Box>
      <h1>ReactPdfTestPage</h1>
      <Box sx={{ pb: '20px', display: 'flex', gap: '20px' }}>
        <PDFDownloadLink document={<PdfOrderExample />} fileName='EliteEatsOrderDetails'>
          {({ loading }) =>
            loading ? (
              <button>Loading Order Details...</button>
            ) : (
              <button>Download Order Details</button>
            )
          }
        </PDFDownloadLink>

        <PDFDownloadLink
          document={<PdfDeliveryDetails />}
          fileName='EliteEatsOrderDeliveryInstructions'
        >
          {({ loading }) =>
            loading ? (
              <button>Loading Delivery Instructions...</button>
            ) : (
              <button>Download Delivery Instructions</button>
            )
          }
        </PDFDownloadLink>
      </Box>
      <Box>
        <PDFViewer className='pdf-viewer'>
          <PdfOrderExample />
        </PDFViewer>
      </Box>
    </Box>
  );
};

export default ReactPdfTestPage;
