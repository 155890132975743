import React, { useContext, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import UserList from '../User/UserList';
import RestaurantContact from './RestaurantContact';

// const scrollToTopOfPage = () => {
//   document.body.scrollTop = 0;
//   document.documentElement.scrollTop = 0;
// };

const RestaurantContacts = ({ contacts, restaurantUuid }) => {
  // console.log('######## RestaurantContacts ########');
  // console.log('restaurantUuid', restaurantUuid);
  // console.log('contacts');
  // console.log(contacts);

  const authCtx = useContext(AuthContext);
  const userRole = authCtx.userRole;

  const [showFormOrDetails, setShowFormOrDetails] = useState('Details');
  const [showContact, setShowContact] = useState(false);
  const [showList, setShowList] = useState(true);
  const [userUuid, setUserUuid] = useState('');
  const [pageTitle, setPageTitle] = useState('Contacts');

  const displayPageTitleAndButtons = false;

  const showContactHandler = (userUuid) => {
    setUserUuid(userUuid);
    setShowContact(true);
    setShowFormOrDetails('Details');
    setShowList(false);
    setPageTitle('Contact Details');
  };

  const addUserHandler = () => {
    setUserUuid('');
    setShowContact(true);
    setShowFormOrDetails('Form');
    setShowList(false);
    setPageTitle('Add New Contact');
  };

  const editUserHandler = (userUuid) => {
    setUserUuid(userUuid);
    setShowContact(true);
    setShowFormOrDetails('Form');
    setShowList(false);
    setPageTitle('Edit Contact Details');
  };

  const showListHandler = () => {
    setShowContact(false);
    setShowFormOrDetails('Details');
    setShowList(true);
    setPageTitle('Contacts');
    // scrollToTopOfPage();

    // console.log(' ** showListHandler ** ');
  };

  return (
    <Box sx={{ py: '40px' }}>
      {displayPageTitleAndButtons ? (
        <Box className='title-and-button-holder'>
          <Typography variant='h1' component='h1'>
            {pageTitle}
          </Typography>

          <Box className='button-holder-no-pb'>
            {userRole === 'Vendor' ? (
              <Button variant='contained' size='small' color='accent' onClick={addUserHandler}>
                Add Contact #####
              </Button>
            ) : null}

            <Button variant='contained' size='small' color='accent' onClick={showListHandler}>
              Contacts
            </Button>
          </Box>
        </Box>
      ) : null}

      {showList ? (
        <UserList
          onAddNew={addUserHandler}
          onEdit={editUserHandler}
          onShowContact={showContactHandler}
          pageTitle={pageTitle}
          orgType='Vendor'
          orgUuid={restaurantUuid}
        />
      ) : null}

      {showContact ? (
        <RestaurantContact
          userUuid={userUuid}
          onShowList={showListHandler}
          showFormOrDetails={showFormOrDetails}
          pageTitle={pageTitle}
        />
      ) : null}
    </Box>
  );
};

export default RestaurantContacts;
