import classes from '../CSS/styled-table.module.css';

const RestaurantDetailsTable = (props) => {
  const { userRole, purpose = 'Details', details } = props;

  // details.address2 = 'TESTING';
  // details.zipcode = '2323323ddwse';

  let addressFormatted = details.address1;
  addressFormatted += details.address2 ? ', ' + details.address2 : '';
  addressFormatted += details.city
    ? '\n' +
      details.city +
      (details.state ? ', ' + details.state : '') +
      (details.zipcode ? ' ' + details.zipcode : '')
    : '';

  return (
    <div className='table-holder'>
      <table className={classes['styled-table']}>
        <tbody>
          <tr>
            <th>Name</th>
            <td>{details.name}</td>
          </tr>
          {purpose !== 'Approval' ? (
            <tr>
              <th>Description</th>
              <td>
                <pre>{details.description}</pre>
              </td>
            </tr>
          ) : null}
          {purpose === 'Approval' ? (
            <tr>
              <th>Address</th>
              <td>
                <pre>{addressFormatted}</pre>
              </td>
            </tr>
          ) : (
            <>
              <tr>
                <th>Address 1</th>
                <td>{details.address1}</td>
              </tr>
              <tr>
                <th>Address 2</th>
                <td>{details.address2}</td>
              </tr>
              <tr>
                <th>City</th>
                <td>{details.city}</td>
              </tr>
              <tr>
                <th>State</th>
                <td>{details.state}</td>
              </tr>
              <tr>
                <th>Zipcode</th>
                <td>{details.zipcode}</td>
              </tr>
            </>
          )}

          <tr>
            <th>Email</th>
            <td>{details.email}</td>
          </tr>
          <tr>
            <th>Phone Number</th>
            <td>{details.phone}</td>
          </tr>
          <tr>
            <th>Type of Food</th>
            <td>{details.foodType}</td>
          </tr>
          <tr>
            <th>League Experience</th>
            <td>{details.leagueExperience}</td>
          </tr>
          {/* <tr>
            <th>Delivery Hours</th>
            <td>{details.deliveryHours}</td>
          </tr> */}
          <tr>
            <th>Delivery Hours Start</th>
            <td>{details.deliveryHoursStartFormatted}</td>
          </tr>
          <tr>
            <th>Delivery Hours End</th>
            <td>{details.deliveryHoursEndFormatted}</td>
          </tr>
          <tr>
            <th>Order Lead Time</th>
            <td>{details.orderLeadTime ? details.orderLeadTime + ' Hours' : ''}</td>
          </tr>

          <tr>
            <th>Additional Information</th>
            <td>{details.description}</td>
          </tr>

          {purpose !== 'Approval' ? (
            <>
              <tr>
                <th>Tax Rate</th>
                <td>{details.taxRate ? details.taxRate + '%' : ''}</td>
              </tr>
              <tr>
                <th>Rating</th>
                <td>{details.rating ? details.rating + ' Stars' : ''}</td>
              </tr>
              <tr>
                <th>Number of Reviews</th>
                <td>{details.numberOfRatings ? details.numberOfRatings : ''}</td>
              </tr>
            </>
          ) : null}

          {userRole !== 'User' && purpose !== 'Approval' ? (
            <>
              <tr>
                <th>Status</th>
                <td>{details.status}</td>
              </tr>
              <tr>
                <th>Date Added</th>
                <td>{details.dateAddedFormatted}</td>
              </tr>
              <tr>
                <th>Date Updated</th>
                <td>{details.dateUpdatedFormatted}</td>
              </tr>
            </>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export default RestaurantDetailsTable;
