import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import DeliveryLocationCard from './DeliveryLocationCard';

const DeliveryLocationListForRestaurant = (props) => {
  // console.log(' ***** DeliveryLocationListForRestaurant ***** ');
  const { restaurantUuid = null } = props;

  const authCtx = useContext(AuthContext);
  const userRole = authCtx.userRole;

  const [deliveryLocations, setDeliveryLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const apiUrl = authCtx.baseApiUrl + 'restaurantdeliverylocations/' + restaurantUuid;

  // console.log('apiUrl', apiUrl);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.log('responseData.data.delivery_locations');
        // console.log(responseData.data.delivery_locations);

        const transformedLocations = responseData.data.delivery_locations.map((location) => {
          return {
            deliveryLocationUuid: location.deliveryLocationUuid,
            name: location.name,
            description: location.description,
            leagues: location.leagues,
            address:
              location.city !== '' && location.state !== ''
                ? location.city + ', ' + location.state
                : location.city !== ''
                ? location.city
                : location.state !== ''
                ? location.state
                : '',
            address1: location.address1,
            address2: location.address2,
            city: location.city,
            state: location.state,
            zipcode: location.zipcode,
            email: location.email,
            phone: location.phone,
            deliveryInstructions: location.delivery_instructions,
            status: location.status,
            isGlobal: location.isGlobal,
          };
        });

        setDeliveryLocations(transformedLocations);
      } else {
        setIsError(true);

        // console.log('responseData');
        // console.log(responseData);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [apiUrl, authCtx.token]);

  const displayDeliveryLocationCards = () => {
    return deliveryLocations.map((record, index) => (
      <DeliveryLocationCard key={index} details={record} userRole={userRole} />
    ));
  };

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}
      {/* {!isLoading && !isError && deliveryLocations.length === 0 ? (
        <WorkflowMessage type='error' message={'No Delivery Locations Found'} />
      ) : null} */}

      <Box className='content-container'>
        <Box className='title-and-button-holder'>
          <Typography variant='h1' component='h1'>
            Delivery Locations
          </Typography>
        </Box>

        {!isLoading && deliveryLocations.length > 0 && <Box>{displayDeliveryLocationCards()}</Box>}

        {!isLoading && !isError && deliveryLocations.length === 0 ? (
          <Typography color='error'>No Delivery Locations found for this Restaurant.</Typography>
        ) : null}
      </Box>
    </>
  );
};

export default DeliveryLocationListForRestaurant;
