import { useState } from 'react';
import GlobalMenuItemCategoryList from './GlobalMenuItemCategoryList';
import GlobalMenuItemCategoriesDetails from './GlobalMenuItemCategoryDetails';
import GlobalMenuItemCategoryForm from './GlobalMenuItemCategoryForm';

const GlobalMenuItemCategories = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [showList, setShowList] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [uuid, setUuid] = useState('');

  const addNewHandler = () => {
    // console.log(' -- addNewHandler -- ');
    setUuid('');
    setShowList(false);
    setShowDetails(false);
    setShowForm(true);
  };

  const showListHandler = () => {
    setShowList(true);
    setShowDetails(false);
    setShowForm(false);
  };

  const showDetailsHandler = (uuid) => {
    // console.log(' -- showDetailsHandler -- uuid = ' + uuid);
    setUuid(uuid);
    setShowList(false);
    setShowDetails(true);
    setShowForm(false);
  };

  const onEditRecordHandler = (uuid) => {
    // console.log(' -- onEditRecordHandler -- uuid = ' + uuid);
    setUuid(uuid);
    setShowList(false);
    setShowDetails(false);
    setShowForm(true);
  };

  return (
    <>
      <div className='button-holder'>
        <button className='small' onClick={addNewHandler}>
          Add Menu Item Category
        </button>
        <button className='small' onClick={showListHandler}>
          Menu Item Category List
        </button>
      </div>
      {showList && (
        <GlobalMenuItemCategoryList
          onShowDetails={showDetailsHandler}
          onEditRecord={onEditRecordHandler}
        />
      )}
      {showDetails && uuid && (
        <GlobalMenuItemCategoriesDetails uuid={uuid} onEditRecord={onEditRecordHandler} />
      )}
      {showForm && <GlobalMenuItemCategoryForm uuid={uuid} onCancel={showListHandler} />}
    </>
  );
};

export default GlobalMenuItemCategories;
