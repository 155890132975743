import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import WorkflowMessage from '../UI/WorkflowMessage';
import MenuItemForm from './MenuItemForm';

const MenuItemEdit = () => {
  const authCtx = useContext(AuthContext);

  const dashboardUrl = authCtx.dashboardUrl;
  const restaurantUuid = authCtx.selectedRestaurantUuid;
  // const restaurantUuid = authCtx.locationUuid;

  const [menuItemDetails, setMenuItemDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const history = useHistory();
  const params = useParams();
  const menuItemUuid = params.menuItemUuid;

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      // get the data from the api
      const response = await fetch(authCtx.baseApiUrl + 'menuitems/' + menuItemUuid, {
        headers: {
          Authorization: authCtx.token,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log('$$$$$ responseData $$$$$');
        // console.log(responseData);

        const returnedMenuItemDetails = {
          menuItemUuid: responseData.data.menu_items[0].menuItemUuid,
          parentUuid: responseData.data.menu_items[0].parentUuid,
          restaurantUuid: responseData.data.menu_items[0].restaurantUuid,
          name: responseData.data.menu_items[0].name,
          description: responseData.data.menu_items[0].description,
          details: responseData.data.menu_items[0].details,
          servingSize: responseData.data.menu_items[0].servingSize,
          feeds: responseData.data.menu_items[0].feeds,
          price: responseData.data.menu_items[0].price,
          priceDetails: responseData.data.menu_items[0].priceDetails,
          menuItemCategoryId: responseData.data.menu_items[0].menuItemCategoryId,
          menuItemCategory: responseData.data.menu_items[0].menuItemCategory,
          dietaryOptions: responseData.data.menu_items[0].dietaryOptions,
          status: responseData.data.menu_items[0].status,
          image: responseData.data.menu_items[0].image,
        };
        // console.log(' -- returnedMenuItemDetails -- ');
        // console.log(returnedMenuItemDetails);

        setMenuItemDetails(returnedMenuItemDetails);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    // call the function
    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, menuItemUuid]);

  const detailsHandler = () => {
    //   history.push(dashboardUrl + 'menu-items/details/' + menuItemUuid);
    history.push(dashboardUrl + 'restaurants/menu-items/details/' + menuItemUuid);
  };
  // };

  const backToListHandler = () => {
    //   history.push(dashboardUrl + 'menu-items/' + restaurantUuid);
    history.push(dashboardUrl + 'restaurants/menu-items/' + restaurantUuid);
  };
  // };

  // const backHandler = () => {
  //   history.push(dashboardUrl + 'menu-items');
  // };

  return (
    <StandardPageWrapper>
      {!isLoading && !isError && (
        <Box className='title-and-button-holder'>
          <Typography variant='h1' component='h1'>
            Edit Menu Item
          </Typography>
          <Box className='button-holder-no-pb'>
            <Button variant='contained' size='custom' color='accent' onClick={detailsHandler}>
              Details
            </Button>
            <Button variant='contained' size='custom' color='accent' onClick={backToListHandler}>
              Back to Menu
            </Button>
          </Box>
        </Box>
      )}

      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {!isLoading && !isError ? (
        <MenuItemForm menuItemUuid={menuItemUuid} menuItemDetails={menuItemDetails} />
      ) : null}
    </StandardPageWrapper>
  );
};

export default MenuItemEdit;
