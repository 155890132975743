import React from 'react';
import { Box, Paper } from '@mui/material';

const TermsOfUse = () => {
  return (
    <Box className='content-container'>
      <h1>Elite Eats Terms of Use</h1>

      <h5>Last Modified: May 22, 2023</h5>

      <Paper elevation={4} sx={{ maxWidth: '120rem', p: '2rem' }}>
        <p>
          PLEASE NOTE THAT DISPUTES ABOUT THESE TERMS OF USE AND THE ELITE EATS PLATFORM ARE SUBJECT
          TO BINDING ARBITRATION AND A WAIVER OF CLASS ACTION RIGHTS, AS DETAILED IN SECTION 18
          BELOW.
        </p>

        <ol className='sub-list'>
          <li className='header'>
            ACCEPTANCE OF THIS AGREEMENT
            <p>
              These Terms of Use (the "Terms") apply to the website www.elite-eats.com provided by
              Elite Eats Inc. ("Elite Eats," "we," "us," or "our"), (collectively, our "Platform").
              These Terms are a binding contract between you and Elite Eats. This means that if you
              access or use our Platform, you acknowledge and agree that you (as well as any of your
              heirs or persons to whom you have assigned your legal rights) (collectively "you") are
              telling us that you have read, understand, and agree to be bound by these Terms and to
              comply with all applicable laws and regulations relating to your access and use of the
              Platform.
            </p>
            <p>
              If you use the Platform on behalf of an entity or organization, you represent and
              warrant that you have the authority to bind that entity to these Terms, your
              acceptance of the Terms will be deemed an acceptance by that entity or organization,
              and that references to "you" and "your" in these Terms (except for in this sentence
              and in Section 3 below) refer to that entity or organization.
            </p>
            <p>
              You also agree to any additional terms applicable to specific services and/or features
              that are a part of, or may be from time to time, made a part of, the Platform
              (collectively, the "Additional Terms"). Subject to applicable law, the Additional
              Terms are a part of these Terms and are expressly incorporated herein by this
              reference. If you do not agree to these Terms, you may not use the Platform.
            </p>
          </li>
          <li className='header'>
            DESCRIPTION AND LIMITATIONS OF OUR PLATFORM; FUELING PARTNERS AND DELIVERERS ARE
            INDEPENDENT
            <p>
              We provide a technology platform and associated support services that make it easier
              for you to search, select and order athlete catering for delivery.
            </p>
            <p>
              We are not a caterer, restaurant, or other food preparer (each a "Fueling Partner").
              The Fueling Partners available through our Platform are independent of Elite Eats and
              are subject to laws, rules, regulations, and standards regarding their businesses and
              services.
            </p>
            <p>
              We also are not a delivery service and instead rely upon the Fueling Partners and
              other providers of delivery services to deliver food ordered through our Platform
              (each a "Deliverer"). The Deliverers are independent of Elite Eats and are subject to
              laws, rules, regulations and standards regarding their businesses and services.
            </p>
            <p>
              We do not verify, and are not responsible for, any Fueling Partner's or Deliverer's
              compliance with the laws, rules, regulations, and standards regarding their businesses
              and services. We do not assess or guarantee the quality or suitability of any Fueling
              Partner's food, products, services, menus, descriptions or other disclosures or of any
              Deliverer's services, and we are not responsible for any Fueling Partner's or
              Deliverer's acts or omissions.
            </p>
            <p>
              You understand and agree that Elite Eats is not liable for any losses, damages,
              liabilities, lawyers' fees, or other expenses caused by the acts or omissions of a
              Fueling Partner or Deliverer. That said, we strive to work with Fueling Partners and
              Deliverers that our customers value, so if you have any questions, comments, or
              concerns about a Fueling Partner or Deliverer, we encourage you to email us at
              info@elite-eats.com.
            </p>
          </li>

          <li className='header'>
            ELIGIBILITY
            <p>
              To use the Platform, you must be—and you represent and warrant that - (i) you are at
              least 18 years of age, (ii) neither you nor any organization on whose behalf you are
              using the Platform has previously been banned or removed from the Platform, and (iii)
              you have the ability and authority to agree to these Terms.
            </p>
          </li>

          <li className='header'>
            ACCOUNT REGISTRATION AND USE
            <ol className='sub-list'>
              <li className='sub-item'>
                Account Registration and Confidentiality. You are required to create an account to
                use parts of the Platform. During the registration process for your account, you
                must provide us with your email address and a password (that together will serve as
                your login credentials) and you may be required to provide additional information,
                such as your name, organization name, league, position, address, phone number and/or
                a credit card number You understand and agree that it is your responsibility to
                ensure that your password remains confidential and secure. You also agree to keep up
                to date all of the information you provide to us.
              </li>
              <li className='sub-item'>
                Unauthorized Account Use. You are responsible for notifying us promptly if you
                become aware of any unauthorized use of or access to your account. To notify us,
                please contact us at
                <a href='https://www.elite-eats.com' target='_blank' rel='noreferrer'>
                  www.elite-eats.com
                </a>
                . You understand and agree that we may require you to provide information that may
                be used to confirm your identity and help ensure the security of your account. By
                creating an account with us, you agree that you are fully responsible for all
                activities that occur under your login credentials and that we may assume that any
                communications we receive through your account have been made by you
              </li>
            </ol>
          </li>

          <li className='header'>
            INTELLECTUAL PROPERTY OWNERSHIP
            <p>
              The Platform, and all materials contained in and on the Platform, including all
              intellectual property rights in the Platform, are the sole and exclusive property of
              us and our licensors. Except for the limited license expressly granted by us to you
              under these Terms, no other rights or licenses are granted to you under these Terms,
              either expressly, by implication or otherwise. All rights not expressly granted by us
              in these Terms are expressly reserved.
            </p>
          </li>

          <li className='header'>
            USER CONTENT AND SUBMISSIONS
            <ol className='sub-list'>
              <li className='sub-item'>
                User Content and Posts. The Platform may enable you to submit, post, upload, or
                otherwise make available (collectively, "Post") content, such as reviews, photos,
                ratings, and vendor referrals (collectively, "User Content"). User Content may be
                viewable by other users and may include information about you, which shall be
                governed by the Elite Eats Privacy Policy.
              </li>
              <li className='sub-item'>
                Our License to User Content. When you Post User Content on or through the Platform
                (including ratings and reviews), you grant us a world-wide, perpetual, irrevocable,
                royalty-free, non-exclusive, and sub-licensable license to use, copy, distribute,
                reproduce, modify, edit, adapt, publicly perform or display, create derivative works
                from, sell, lease, and transmit such User Content, in whole or in part, in any
                format or medium, including for promoting and redistributing part or all of the
                Platform in any media formats and through any media channels. Notwithstanding the
                foregoing, our use of your information shall be governed by the Elite Eats Privacy
                Policy.
              </li>
              <li className='sub-item'>
                User Content Representations. You acknowledge and agree that all User Content that
                you Post is your responsibility. You represent that you have all required rights to
                Post or transmit such User Content without violation of any third-party rights. You
                understand that we do not control, and are not responsible for, User Content, and
                that by using the Platform, you may be exposed to User Content from other users that
                is offensive, indecent, inaccurate, misleading, or otherwise objectionable. Please
                also note that User Content may contain typographical errors or other errors. You
                agree that you will indemnify, defend, and hold harmless us for all claims resulting
                from User Content you Post. We reserve the right, at our own expense, to assume the
                exclusive defense and control of such disputes, and in any event, you will cooperate
                with us in asserting any available defenses.
              </li>
              <li className='sub-item'>
                Ratings and Reviews. When you Post a rating or review about a Fueling Partner or
                Deliverer, you shall ensure that (i) your rating or review is based on your
                first-hand experience with the Fueling Partner or Deliverer; (ii) you do not have a
                proprietary or other affiliation with the Fueling Partner, Deliverer, or any of
                their competitors; (iii) you do not draw any legal conclusions regarding the Fueling
                Partner's or Deliverer's products, services, or conduct; and (iv) your review
                complies with these Terms. We may remove any rating or review if we determine, in
                our sole discretion, that it violates these Terms or diminishes the integrity of the
                ratings and reviews.
              </li>
            </ol>
          </li>

          <li className='header'>
            COMMUNICATIONS
            <p>
              You consent to accept and receive communications from us, including e-mail, calls,
              push notifications and text messages to the cellular telephone number you provide to
              us. . You may opt-out of receiving certain communications by following the unsubscribe
              options we provide to you. You acknowledge that opting out of receiving communications
              may impact your use of the Platform.
            </p>
          </li>

          <li className='header'>
            PAYMENTS
            <ol className='sub-list'>
              <li className='sub-item'>
                Payment Authorization. You authorize us to charge all amounts owed for orders you
                place to the payment method you specify at the time of purchase. If you pay any
                amounts with a credit card, we may seek pre-authorization of your credit card
                account prior to your purchase to verify that the credit card is valid and has
                credit available for your intended purchase.
              </li>
              <li className='sub-item'>
                Cancellations; No Shows. If you cancel your order, you may be charged some or all of
                the amount of the order, depending on the policy of the Fueling Partner. We also
                reserve the right to charge you the full order amount, if you, or the person you
                designate, is not at the delivery location you specify when the order is delivered.
              </li>
              <li className='sub-item'>
                Refunds. Any payments you make are final and non-refundable.
              </li>
              <li className='sub-item'>
                Other Fees. We reserve the right to establish, remove, and/or revise fees, or other
                amounts for any or all services or features we provide through the Platform.
              </li>
              <li className='sub-item'>
                Courtesy/Promotional Credits and Other Discounts, Promotions and Offers. From time
                to time, we may offer courtesy/promotional credits or other discounts, promotions or
                offers. Any courtesy/promotional credits that you receive may only be used to
                purchase goods or services, are non-transferrable and are not redeemable for cash.
                Other discounts, promotions or offers are also non-transferrable and are not
                redeemable for cash. We reserve the right to expire, limit, or modify any
                courtesy/promotional credits or other discounts, promotions or offers at any time.
                If your access to the Platform is terminated under Section 16.2 below, you may
                forfeit any pending, current, or future credits and any other forms of unredeemed
                value in or associated with your account without prior notice to you.
              </li>
            </ol>
          </li>

          <li className='header'>
            LICENSE AND PROHIBITED CONDUCT
            <ol className='sub-list'>
              <li className='sub-item'>
                Your License. As long as you comply with these Terms and we have not otherwise
                revoked this right, we grant you a limited, non-exclusive, non-sublicensable,
                non-transferable, and revocable right to access and use the Platform only in a
                manner that complies with all legal requirements that apply to you or your use of
                the Platform, including the Elite Eats Privacy Policy and these Terms. We may revoke
                this license at any time, in our sole discretion, for any or no reason. Upon any
                such revocation, you must immediately cease all access and use of the Platform. In
                addition, at our request you must promptly destroy all materials downloaded or
                otherwise obtained from the Platform, as well as all copies of such materials,
                whether made in accordance with these Terms or otherwise.
              </li>
              <li className='sub-item'>
                Prohibited Uses. Use of the Platform for any illegal purpose—or for any other
                purpose not expressly permitted in these Terms—is strictly prohibited. Without
                limitation, you will not use the Platform to:
                <ol className='sub-list'>
                  <li className='sub-item'>
                    Post User Content that: (i) harasses, abuses, or threatens any other person, or
                    that contains obscene content; (ii) is false, misleading, or inaccurate; (iii)
                    degrades or discriminates against others on the basis of gender, race, class,
                    ethnicity, national origin, religion, sexual preference, disability, or any
                    other classification; (iv) is indecent, offensive, harassing, violent, hateful,
                    inflammatory, unlawful, harmful, tortious, defamatory, libelous, or invasive of
                    another's privacy; (v) promotes sexually explicit or pornographic material,
                    violence, or any illegal acts; (vi) infringes the legal rights of any person or
                    contains any material that could give rise to any civil or criminal liability
                    under applicable laws or regulations or that otherwise may be in conflict with
                    these Terms; or (vii) gives the impression that it emanates from or is endorsed
                    by us or any other person or entity, if this is not the case;
                  </li>
                  <li className='sub-item'>
                    Promote any commercial purpose, such as to benchmark or compile information for
                    a product or service;
                  </li>
                  <li className='sub-item'>
                    Copy, download (other than through page caching for personal use, or as
                    otherwise expressly permitted by these Terms), modify, distribute, post,
                    transmit, display, perform, reproduce, broadcast, "mirror," duplicate, publish,
                    republish, upload, license, reverse engineer, create derivative works from, or
                    offer for sale any content or other information contained on or obtained from or
                    through the Platform, by any means except as provided for in these Terms or with
                    our prior written consent;
                  </li>
                  <li className='sub-item'>
                    Scrape, access, monitor, index, frame, link, or copy any content or information
                    by accessing the Platform in an automated way, using any robot, spider, scraper,
                    web crawler, or any other method of access other than manually accessing the
                    publicly available portions of the Platform through a browser or accessing the
                    Platform through any Application Programming Interface (API) provided or
                    approved by us;
                  </li>
                  <li className='sub-item'>
                    Breach the restrictions in any robot exclusion headers of the Platform, if any,
                    or bypass or circumvent other measures employed to prevent or limit access to
                    the Platform;
                  </li>
                  <li className='sub-item'>
                    Advocate illegal activity or discuss illegal activities with the intent to
                    commit them;
                  </li>
                  <li className='sub-item'>
                    Engage in money laundering or any other fraudulent activity;
                  </li>
                  <li className='sub-item'>
                    Make available any material that contains any software, device, instructions,
                    computer code, files, programs, and/or other content or features designed to
                    interrupt, destroy, harm, or limit the functionality of any computer software or
                    hardware or telecommunications equipment (including without limitation any time
                    bomb, virus, malware, software lock, worm, self-destruction, drop-device,
                    malicious logic, Trojan horse, trap door, "disabling," "lock out," "metering"
                    device or any malicious code);
                  </li>
                  <li className='sub-item'>
                    Do anything that could disable, overburden, or impair the proper working of the
                    Platform;
                  </li>
                  <li className='sub-item'>
                    Impede or otherwise prohibit communication or disrupt user discussion;
                  </li>
                  <li className='sub-item'>
                    Post, utilize, or otherwise make available any other party's intellectual
                    property, unless you have the right to do so, or remove or alter any copyright,
                    trademark, or other proprietary notice contained on the Platform;
                  </li>
                  <li className='sub-item'>
                    Post any trade secrets or other confidential information, or any material that
                    you do not have a right to make available under any law or contractual
                    relationships;
                  </li>
                  <li className='sub-item'>
                    Falsely state or otherwise misrepresent your affiliation with a person or
                    entity;
                  </li>
                  <li className='sub-item'>
                    Impersonate any person or entity in a manner that does not constitute parody;
                  </li>
                  <li className='sub-item'>
                    Solicit personal or sensitive information from other users;
                  </li>
                  <li className='sub-item'>
                    Send spam, commercial electronic messages, advertisements, solicitations,
                    surveys, or investment pitches; promote commercial entities; or otherwise engage
                    in commercial activity on or through the Platform;
                  </li>
                  <li className='sub-item'>
                    Violate, or facilitate violations of, these Terms, the Elite Eats Privacy
                    Policy, or any guidelines or policies posted by us; and/or
                  </li>
                  <li className='sub-item'>
                    Interfere with any other party's use and enjoyment of the Platform.
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li className='header'>
            LEGAL COMPLIANCE
            <p>
              You acknowledge, consent, and agree that we may access, preserve, and disclose your
              information and/or any User Content you submit or make available for inclusion on the
              Platform, if required to do so by law or in a good faith belief that such access,
              preservation, or disclosure is permitted by law or by our Privacy Policy.
            </p>
          </li>

          <li className='header'>
            PRIVACY
            <p>
              For information about how we collect, use, and share the data we collect from and
              about you, please see our Privacy Policy, which is incorporated by reference into
              these Terms.
            </p>
          </li>

          <li className='header'>
            EXCLUSIONS AND LIMITATIONS
            <p>
              Some jurisdictions do not allow the exclusion of certain warranties or the limitation
              or exclusion of liability for damages such as below in the section entitled
              "Warranties, Disclaimers, and Limitation of Liability." Accordingly, some of the below
              limitations may not apply to you. If you are a resident of a U.S. state that does not
              permit the exclusion of certain warranties and liabilities, then the limitations in
              the "Warranties, Disclaimers, and Limitation of Liability" section below will apply to
              you to the extent permitted by law.
            </p>
            <p>
              If you are a resident of New Jersey, the provisions in subsection 13.2 below apply to
              you to the extent permitted by law.
            </p>
          </li>

          <li className='header'>
            WARRANTIES, DISCLAIMERS, AND LIMITATION OF LIABILITY
            <ol className='sub-list'>
              <li className='sub-item'>
                Disclaimer of Warranties. THE PLATFORM IS PROVIDED "AS IS" AND "AS AVAILABLE." TO
                THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, ELITE EATS DISCLAIMS ALL
                REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, NOT EXPRESSLY SET
                OUT IN THESE TERMS, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
                A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN ADDITION, ELITE EATS MAKES NO
                REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS,
                QUALITY, SUITABILITY, AVAILABILITY, ACCURACY OR COMPLETENESS OF THE PLATFORM OR ANY
                SERVICES OR PRODUCTS REQUESTED THROUGH THE USE OF THE PLATFORM, OR THAT YOUR USE OF
                THE PLATFORM WILL BE SECURE, UNINTERRUPTED OR ERROR-FREE (INCLUDING THAT THE
                PLATFORM OR THE SERVER(S) THAT MAKE THE PLATFORM AVAILABLE ARE FREE OF VIRUSES OR
                OTHER HARMFUL COMPONENTS).
                <p>
                  ELITE EATS DOES NOT ASSESS OR GUARANTEE THE QUALITY, SUITABILITY, LEGALITY, SAFETY
                  OR ABILITY OF FUELING PARTNERS, DELIVERERS OR OTHER THIRD-PARTY PROVIDERS. ELITE
                  EATS RELIES UPON FUELING PARTNERS TO PROVIDE ACCURATE CALORIC, ALLERGEN AND
                  DIETARY INFORMATION AND GENERAL PRODUCT SAFETY. ELITE EATS RELIES UPON DELIVERERS
                  TO PACKAGE, TRANSPORT AND DELIVER FOOD SAFELY AND IN ADHERENCE TO FOOD SAFETY
                  CODES. IN ADDITION, ELITE EATS DOES NOT REPRESENT OR WARRANT THAT THE INFORMATION
                  ACCESSIBLE THROUGH THE PLATFORM IS ACCURATE, COMPLETE, RELIABLE, CURRENT OR
                  ERROR-FREE, INCLUDING WITHOUT LIMITATION MENUS, NUTRITIONAL, CALORIC, AND ALLERGEN
                  INFORMATION, PHOTOS, FOOD QUALITY OR DESCRIPTIONS, PRICING, HOURS OF OPERATION,
                  DELIVERY AVAILABILITY OR DELIVERY AREAS, OR REVIEWS. ALL CONTENT IS PROVIDED FOR
                  INFORMATIONAL PURPOSES ONLY. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR
                  USE OF THE PLATFORM, INFORMATION PROVIDED ON THE PLATFORM, AND ANY SERVICE OR
                  PRODUCT REQUESTED THROUGH THE PLATFORM, INCLUDING FOOD PRODUCT AND DELIVERY
                  SERVICES, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER
                  APPLICABLE LAW.
                </p>
              </li>
              <li className='sub-item'>
                Limitation of Liability. TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT
                (INCLUDING NEGLIGENCE) SHALL ELITE EATS OR ITS AFFILIATES, OR ANY OF THEIR
                RESPECTIVE STOCKHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, OR OTHER
                REPRESENTATIVES, BE LIABLE TO YOU OR ANY OTHER PERSON OR ENTITY FOR ANY DAMAGES,
                WHETHER DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, OR OTHERWISE
                (INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY, LOSS OF PROFITS, DATA, USE, REVENUE
                OR REPUTATION OR COSTS OF OBTAINING SUBSTITUTE GOODS OR SERVICES), ARISING OUT OF OR
                IN CONNECTION WITH YOUR USE OF OR RELIANCE ON THE SERVICES PROVIDED ON OR THROUGH
                THE PLATFORM, YOUR INABILITY TO ACCESS OR USE THE PLATFORM, ANY CONTENT,
                INFORMATION, OR RECOMMENDATIONS APPEARING ON THE PLATFORM, OR ANY LINK PROVIDED ON
                THE PLATFORM, WHETHER OR NOT ELITE EATS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES AND WHETHER BASED UPON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE),
                CIVIL LIABILITY, STRICT LIABILITY, VIOLATION OF STATUTE, OR OTHERWISE. IN ANY EVENT,
                OUR AGGREGATE LIABILITY WILL NOT EXCEED THE AMOUNT PAID BY YOU FOR THE FOOD SERVICES
                YOU HAVE PURCHASED THROUGH THE PLATFORM TO WHICH THE CLAIM RELATES OR, IF THE CLAIM
                DOES NOT RELATE TO A FOOD SERVICE, $100.
              </li>
              <li className='sub-item'>
                Reasonability of Disclaimers and Limitations. YOU AND WE AGREE THAT THE DISCLAIMER
                OF WARRANTIES AND LIMITATIONS OF LIABILITY IN THESE TERMS ARE MATERIAL,
                BARGAINED-FOR BASES OF THESE TERMS, AND THAT THEY HAVE BEEN TAKEN INTO ACCOUNT IN
                DETERMINING THE CONSIDERATION TO BE GIVEN BY EACH PARTY UNDER THESE TERMS AND IN THE
                DECISION BY EACH PARTY TO ENTER INTO THESE TERMS. YOU AND WE AGREE THAT THE
                DISCLAIMER OF WARRANTIES AND LIMITATIONS OF LIABILITY IN THESE TERMS ARE FAIR AND
                REASONABLE. IF YOU ARE DISSATISFIED WITH THE PLATFORM OR DO NOT AGREE TO ANY
                PROVISIONS OF THESE TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING
                THE PLATFORM, EXCEPT AS MAY BE OTHERWISE PROVIDED FOR IN THESE TERMS.
              </li>
            </ol>
          </li>

          <li className='header'>
            INDEMNITY
            <p>
              TO THE FULL EXTENT PERMITTED BY LAW, YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD ELITE
              EATS AND ITS RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, SHAREHOLDERS, AFFILIATES, AND
              REPRESENTATIVES (AND ALL SUCCESSORS AND ASSIGNS OF ANY OF THE FOREGOING), HARMLESS
              FROM AND AGAINST ANY CLAIM OR DEMAND, INCLUDING WITHOUT LIMITATION REASONABLE LAWYERS'
              FEES AND DISBURSEMENTS, MADE BY ANY THIRD PARTY IN CONNECTION WITH OR ARISING OUT OF
              (i) YOUR USE OF THE PLATFORM; (ii) YOUR CONNECTION TO THE PLATFORM; (iii) YOUR
              VIOLATION OF THESE TERMS OR THE ELITE EATS PRIVACY POLICY; (iv) YOUR VIOLATION OF AN
              APPLICABLE LAW; (v) YOUR SUBMISSION, POSTING, OR TRANSMISSION OF USER CONTENT TO THE
              PLATFORM; AND/OR (vi) YOUR VIOLATION OF ANY RIGHTS OF ANOTHER.
            </p>
            <p>
              WE RESERVE THE RIGHT, AT OUR OWN EXPENSE, TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL
              OF SUCH DISPUTES, AND IN ANY EVENT, YOU WILL COOPERATE WITH US IN ASSERTING ANY
              AVAILABLE DEFENSES.
            </p>
          </li>

          <li className='header'>
            THIRD-PARTY LINKS AND SERVICES
            <p>
              The Platform may provide (i) information and content provided by third parties (for
              example, Fueling Partners); (ii) links to third-party websites or resources (for
              example, links to our or other's social media pages); and (iii) third-party products
              and services for sale directly to you (for example, food prepared by Fueling
              Partners). Elite Eats is not responsible for the availability of such external sites
              or resources and does not endorse and is not responsible or liable for (x) any
              content, advertising, products, or other materials on or available from such third
              parties; (y) any errors or omissions by such third parties; or (z) any information
              handling practices or other business practices of such third parties. You further
              acknowledge and agree that Elite Eats shall not be responsible or liable, directly or
              indirectly, for any damage or loss caused or alleged to be caused by or in connection
              with use of or reliance on any third-party websites, products, services, or other
              resources. Your interactions with such third parties will be governed by the third
              parties' own terms of service, privacy policy, and any other similar terms.
            </p>
          </li>

          <li className='header'>
            MODIFICATION AND TERMINATION
            <ol className='sub-list'>
              <li className='sub-item'>
                Modification of Platform. We reserve the right at any time to modify or discontinue,
                temporarily or permanently, the Platform or any part thereof. We may do this with or
                without providing notice to you of the modification or discontinuance. We will not
                be liable to you or any third party for any modification, suspension, or
                discontinuance of the Platform.
              </li>
              <li className='sub-item'>
                Termination. We may, in our sole and absolute discretion, terminate, suspend, or
                otherwise deny your access to all or part of the Platform at any time. If we
                terminate your right to access the Platform, these Terms will terminate, and all
                rights you have to access the Platform will immediately terminate. Even if these
                Terms terminate, certain provisions of these Terms will still apply post
                termination, including without limitation the "Mandatory Arbitration and Class
                Action Waiver" provisions. Termination of your account may also include, at our sole
                discretion, the deletion of your account and/or User Content. If we delete your
                account or User Content, you will not be able to retrieve any information related to
                your account except as required by applicable law.
              </li>
            </ol>
          </li>

          <li className='header'>
            NOTICE OF INTELLECTUAL PROPERTY INFRINGEMENT
            <p>
              We respect the intellectual property of others and require you to do the same. For
              this reason, it is our policy to terminate the account of a user who is deemed to
              infringe third-party intellectual property rights and/or to remove User Content that
              is deemed to be infringing. If you believe that your work has been copied in a way
              that constitutes copyright infringement and is displayed on the Platform, please send
              a written notice to us at the address below that includes all of the following
              information:
            </p>
            <ol className='sub-list'>
              <li className='sub-item'>
                An electronic or physical signature of the person authorized to act on behalf of the
                owner of the copyright or other intellectual property interests. For example, if you
                are the owner of a copyright, your notice to us must include your signature.
              </li>
              <li className='sub-item'>
                A description of your copyrighted work or other intellectual property that you claim
                has been infringed. For example, this means that you could include a written
                description of the work you believe to be infringed or, for images, you could also
                include in or attach to your notice to us the image of the work you believe to be
                infringed.
              </li>
              <li className='sub-item'>
                A description of where the material you claim is infringing is located on the
                Platform. For example, providing us with a website URL or screenshot of the relevant
                page of our mobile app is the quickest way to help us locate content quickly.
              </li>
              <li className='sub-item'>Your address, telephone number, and email address.</li>
              <li className='sub-item'>
                A statement by you that you have a good faith belief that the disputed use is not
                authorized by the copyright owner, its agent, or the law.
              </li>
              <li className='sub-item'>
                A statement by you made under penalty of perjury that the above information in your
                notice is accurate and that you are the copyright or intellectual property owner or
                authorized to act on the copyright or intellectual property owner's behalf.
              </li>
            </ol>
            <p>
              We include the information in this section solely to comply with our rights and
              obligations under the Digital Millennium Copyright Act ("DMCA") and other applicable
              law. However, you should not construe this section as legal advice to you. You should
              only send your notice to us after consulting with your legal counsel. Please also note
              that, pursuant to 17 U.S.C. § 512(c), any person who knowingly materially
              misrepresents that material or activity is infringing may be subject to liability.
            </p>
            <p>Please send your written notice to us at the following address:</p>
            <p>
              Elite Eats, Inc.
              <br />
              12070 Glenridge Lane
              <br />
              Colorado Springs CO 80908
            </p>
          </li>

          <li className='header'>
            MANDATORY ARBITRATION AND CLASS ACTION WAIVER FOR RESIDENTS OF THE UNITED STATES
            <p>
              PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT
              TO FILE A LAWSUIT IN COURT.
            </p>
            <ol className='sub-list'>
              <li className='sub-item'>
                Application. You and Elite Eats agree that these Terms affect interstate commerce
                and that the U.S. Federal Arbitration Act governs the interpretation and enforcement
                of these arbitration provisions. This section entitled "Mandatory Arbitration and
                Class Action Waiver for Residents of the United States" is intended to be
                interpreted broadly and governs any and all disputes between you and Elite Eats. Any
                and all disputes may include, but are not limited to (i) claims arising out of or
                relating to any aspect of the relationship between you and Elite Eats, whether based
                in contract, tort, statute, fraud, misrepresentation or any other legal theory; (ii)
                claims that arose before these Terms or any prior agreement (including, but not
                limited to, claims related to advertising); and (iii) claims that may arise after
                the termination of these Terms. The only disputes excluded from the broad
                prohibition in this subsection entitled "Application" are the litigation of certain
                intellectual property and small claims court claims, as provided in the subsection
                entitled "Exception" below.
              </li>
              <li className='sub-item'>
                Initial Dispute Resolution. Most disputes can be resolved without resorting to
                arbitration. If you have any dispute with us, you agree that you will try to resolve
                your dispute directly with us before taking any formal action, by contacting us at
                the address set forth in Section 22 below. When you contact us, you must provide a
                brief, written description of the dispute and your contact information. If you have
                an account with us, you must include the email address associated with your account.
                Except for intellectual property and small claims court claims (see the subsection
                entitled "Exception" below), you and Elite Eats agree to use good faith efforts to
                resolve any dispute, claim, question, or disagreement directly through consultation
                with each other. Each of you and Elite Eats agrees to engage in good faith
                discussions before initiating a lawsuit or arbitration and understands that good
                faith discussions are a precondition of initiating a lawsuit or arbitration.
              </li>
              <li className='sub-item'>
                Binding Arbitration. If the parties do not reach an agreed-upon solution within a
                period of sixty (60) days from the time informal dispute resolution is initiated
                under the Initial Dispute Resolution provision above, then either party may initiate
                binding arbitration as the sole means to resolve claims (except as provided in the
                subsection entitled "Exception" below), as long as the party agrees with the terms
                set forth below. Specifically, all claims arising out of or relating to these Terms
                (including the Terms' formation, performance, and breach), the parties' relationship
                with each other, and/or your use of the Platform shall be finally settled by binding
                arbitration administered by JAMS in accordance with either (i) the JAMS Streamlined
                Arbitration Procedure Rules, for claims that do not exceed $250,000; or (ii) the
                JAMS Comprehensive Arbitration Rules and Procedures, for claims exceeding $250,000.
                The JAMS rules and procedures just identified shall be those in effect at the time
                the arbitration is initiated (not the Last Modified date of these Terms), excluding
                any rules or procedures governing or permitting class actions.
              </li>
              <li className='sub-item'>
                Arbitrator's Powers. The arbitrator (and not any federal, state, or local court or
                agency) shall have exclusive authority to resolve all disputes arising out of or
                relating to the interpretation, applicability, enforceability, or formation of these
                Terms. Such disputes may include, but are not limited to, any claim that all or any
                part of these Terms is void or voidable, whether a claim is subject to arbitration,
                or the question of waiver by litigation conduct. The arbitrator shall be empowered
                to grant whatever relief would be available in a court under law or in equity. The
                arbitrator's award shall be written and shall be binding on the parties and may be
                entered as a judgment in any court of competent jurisdiction.
              </li>
              <li className='sub-item'>
                Filing a Demand. To start an arbitration, you must do all three of the following:
                (i) Write a Demand for Arbitration that includes a description of the claim and the
                amount of damages you seek to recover (you may find a copy of a Demand for
                Arbitration at{' '}
                <a href='https//:www.jamsadr.com' target='_blank' rel='noreferrer'>
                  https//:www.jamsadr.com
                </a>
                ); (ii) send three copies of the Demand for Arbitration, plus the appropriate filing
                fee, to JAMS, Two Embarcadero Center, Suite 1500, San Francisco, California 94111;
                and (iii) send one copy of the Demand for Arbitration to us at: Elite Eats Inc.,
                12070 Glenridge Lane, Colorado Springs CO 80908, Attn: Jennifer Gibson.
                <p>
                  To the extent the filing fee for the arbitration exceeds the cost of filing a
                  lawsuit Elite Eats will pay the additional cost. If the arbitrator finds the
                  arbitration to be non-frivolous, Elite Eats will pay the fees invoiced by JAMS,
                  including filing fees and arbitrator and hearing expenses. You are responsible for
                  your own attorneys' fees unless the arbitration rules and/or applicable law
                  provide otherwise.
                </p>
                <p>
                  Each of the parties understands that, absent this mandatory arbitration provision,
                  they would have the right to sue in court and have a jury trial. Each of the
                  parties further understands that, in some instances, the costs of arbitration
                  could exceed the costs of litigation and the right to discovery may be more
                  limited in arbitration than in court. If you are a resident of the United States,
                  arbitration may take place in the county where you reside at the time of filing,
                  unless you and we both agree to another location or telephonic arbitration. For
                  individuals residing outside the United States, arbitration shall be initiated in
                  Lewes, Delaware, United States, and each of you and Elite Eats agrees to submit to
                  the personal jurisdiction of any federal or state court in Delaware in order to
                  compel arbitration, stay proceedings pending arbitration, or to confirm, modify,
                  vacate, or enter judgment on the award entered by the arbitrator.
                </p>
              </li>
              <li className='sub-item'>
                Class Action Waiver. EACH OF YOU AND ELITE EATS AGREES THAT EACH MAY BRING CLAIMS
                AGAINST THE OTHER ONLY IN YOUR OR OUR INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
                CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. This means that
                each of you and Elite Eats expressly waives any rights to file a class action or
                seek relief on a class basis.
                <p>
                  If any court or arbitrator determines that the class action waiver set forth in
                  this paragraph is void or unenforceable for any reason or that an arbitration can
                  proceed on a class basis, then the arbitration provisions set forth above shall be
                  deemed null and void in their entirety and the parties shall be deemed to have not
                  agreed to arbitrate disputes.
                </p>
              </li>
              <li className='sub-item'>
                Exception. Notwithstanding the parties' agreement to resolve all disputes through
                arbitration, either party may bring enforcement actions, validity determinations or
                claims arising from or relating to theft, piracy, or unauthorized use of
                intellectual property in state or federal court with jurisdiction or in the U.S.
                Patent and Trademark Office to protect its intellectual property rights.
                "Intellectual property rights" means patents, copyrights, moral rights, trademarks,
                and trade secrets, but does not include privacy or publicity rights. Either party
                may also seek relief in a small claims court for disputes or claims within the scope
                of that court's jurisdiction.
              </li>
              <li className='sub-item'>
                30-Day Right to Opt Out. You have the right to opt out and not be bound by the
                arbitration and class action waiver provisions set forth above by sending written
                notice of your decision to opt out to Elite Eats at 12070 Glenridge Lane, Colorado
                Springs CO 80908 Attn: Jennifer Gibson. Your written notice must have the subject
                line, "ARBITRATION AND CLASS ACTION WAIVER OPT-OUT." The notice must be sent within
                thirty (30) days of (i) January 1, 2023; or (ii) your first date that you used the
                Platform that contained any versions of the Terms that included this version of the
                Mandatory Arbitration and Class Action Waiver, whichever is later. Otherwise, you
                shall be bound to arbitrate disputes in accordance with this section entitled
                "Mandatory Arbitration and Class Action Waiver". If you opt out of these arbitration
                provisions, then Elite Eats also will not be bound by them.
              </li>
              <li className='sub-item'>
                Changes to This Section. Elite Eats will provide thirty (30) days' notice of any
                material changes to this section by posting notice on the Platform or informing you
                via email and complying with any other applicable legal notice or consent
                requirements. Amendments will become effective thirty (30) days after they are
                posted on the Platform or sent to you by email. Changes to this section will
                otherwise apply prospectively only to claims arising after the thirtieth (30th)
                day.*** INSERT P HERE ***
              </li>
              <li className='sub-item'>
                Survival. This Mandatory Arbitration and Class Action Waiver section shall survive
                any termination of your use of the Platform.
              </li>
            </ol>
          </li>

          <li className='header'>
            CONTROLLING LAW
            <p>
              These Terms shall be construed in accordance with and governed by the laws of the
              State of Delaware consistent with the Federal Arbitration Act, notwithstanding its
              conflicts of law principles. If a lawsuit or court proceeding is permitted under these
              Terms, each of you and we agrees that the dispute must be initiated and conducted
              exclusively in the state or federal courts of Delaware, and each of you and we
              consents to the exclusive jurisdiction of such courts.
            </p>
          </li>

          <li className='header'>
            CHANGES TO THESE TERMS
            <p>
              We may revise these Terms at any time. If we revise these Terms, we will give you
              notice of any revisions in accordance with legal requirements. If you do not agree to,
              or cannot comply with, any modified Terms, you must stop using the Platform. Your
              continued use of the Platform after any such update constitutes your binding
              acceptance of such changes. The Terms were most recently updated on the Last Modified
              date at the top of these Terms.
            </p>
          </li>

          <li className='header'>
            GENERAL TERMS
            <ol className='sub-list'>
              <li className='sub-item'>
                Force Majeure. Under no circumstances shall Elite Eats or its licensors or suppliers
                be held liable for any delay or failure in performance resulting directly or
                indirectly from an event beyond its reasonable control.
              </li>
              <li className='sub-item'>
                Waiver. If we fail to insist that you perform any obligations under these Terms,
                that will not mean that you do not have to comply with your obligations. If we do
                not enforce our rights against you, or if we delay in doing so, that will not mean
                that we have waived such rights. If we do waive a failure or breach by you, we will
                only do so in writing and that will not mean that we automatically waive any future
                failure of breach by you.
              </li>
              <li className='sub-item'>
                Severability. Each provision of these Terms operates separately. If any court or
                relevant authority decides that any provision is unlawful or unenforceable, the
                remaining provisions will remain in full force and effect.
              </li>
              <li className='sub-item'>
                Survival. Any provision of these Terms that by its nature is reasonably intended to
                survive beyond termination of these Terms shall survive.
              </li>
              <li className='sub-item'>
                Third-Party Beneficiaries. You agree that, except as otherwise expressly provided in
                these Terms, there shall be no third-party beneficiaries to these Terms. This means
                that, unless we say otherwise in these Terms, no one other than you can benefit from
                the contract set forth between you and us in these Terms.
              </li>
              <li className='sub-item'>
                Entire Agreement. These Terms (and all terms and conditions incorporated herein)
                constitute the entire agreement between you and Elite Eats and take precedence over
                any prior agreements between you and Elite Eats relating to the Platform, except as
                expressly provided herein.
              </li>
              <li className='sub-item'>
                Assignment and Succession. You may not assign or delegate any rights or licenses
                granted to you by these Terms. Elite Eats may assign or delegate any rights or
                licenses granted to us by these Terms without restriction. These Terms bind and
                inure to the benefit of your and our successors and those to which you or we have
                assigned legal responsibilities.
              </li>
              <li className='sub-item'>
                Relationship Between You and Us. No agency, partnership, joint venture, or
                employee-employer relationship is intended or created by these Terms. Put another
                way, Elite Eats is an independent contractor, and you agree that Elite Eats is not
                responsible for any information you convey, in any manner, and has no control over
                any actions you take or statements that you make.
              </li>
              <li className='sub-item'>
                Electronic Agreement. You agree that these Terms, and any other agreements made by
                and between you and us, in electronic form are as legally binding as if made in
                physical written form.
              </li>
              <li className='sub-item'>
                U.S. Government Users. If you are using the Platform for or on behalf of the U.S.
                government, your license rights do not exceed those granted to non-government
                consumers.
              </li>
              <li className='sub-item'>
                Section Titles. The section titles in these Terms are for convenience only and have
                no legal or contractual effect.
              </li>
              <li className='sub-item'>
                Notices. We may deliver notice to you by email, posting a notice on the Platform, or
                by any other method we choose. Any such notice will be effective when we send, post,
                or otherwise dispatch it. If you give notice to us, it will be effective when we
                receive it by mail at the address set forth in Section 22 below.
              </li>
            </ol>
          </li>

          <li className='header'>
            CONTACT US
            <p>
              If you wish to contact us or have any questions about these Terms, contact us at{' '}
              <a href='https://www.elite-eats.com' target='_blank' rel='noreferrer'>
                www.elite-eats.com
              </a>
              . You may also contact us by mail at:
            </p>
            <p>
              Elite Eats, Inc.
              <br />
              12070 Glenridge Lane Colorado
              <br />
              Springs CO 80908
            </p>
          </li>
        </ol>
      </Paper>
    </Box>
  );
};

export default TermsOfUse;
