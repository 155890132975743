import React from 'react';
import { Box, Typography } from '@mui/material';

import { useFormatDateAndTime } from '../Hooks/useFormatDateAndTime';

const OrderDetails = (props) => {
  const { orderDetails, displayDeliveryAddress = true } = props;

  const { FormatTimeForDisplay, FormatLongDateForDisplay } = useFormatDateAndTime();

  // Format Delivery Date and Time (if set).
  const deliveryDateFormatted = orderDetails.deliveryDate
    ? FormatLongDateForDisplay(orderDetails.deliveryDate)
    : '';

  const deliveryTimeFormatted = orderDetails.deliveryTime
    ? FormatTimeForDisplay(orderDetails.deliveryTime)
    : '';

  const statusToDisplay =
    orderDetails.status === 'Placed' && orderDetails.restaurantStatus === 'Modified'
      ? 'Placed / Modified'
      : orderDetails.status;

  // console.log('displayDeliveryAddress = ' + displayDeliveryAddress);

  return (
    <div className='condensed-content'>
      <Box>
        <Typography variant='h3' component='h3' sx={{ pb: '20px' }}>
          Order Number: {orderDetails.orderNumber}
        </Typography>
        {displayDeliveryAddress ? (
          <>
            <Typography>Delivery City: {orderDetails.deliveryCity}</Typography>
            {orderDetails.deliveryState ? (
              <Typography>Delivery State: {orderDetails.deliveryState}</Typography>
            ) : null}
            {orderDetails.deliveryZipcode ? (
              <Typography>Delivery Zipcode: {orderDetails.deliveryZipcode}</Typography>
            ) : null}
          </>
        ) : null}

        {orderDetails.status ? <Typography>Status: {statusToDisplay}</Typography> : null}
        {orderDetails.headcount ? (
          <Typography>Headcount: {orderDetails.headcount}</Typography>
        ) : null}

        {/* {orderDetails.deliveryDate ? (
          <Typography>Delivery Date: {orderDetails.deliveryDate}</Typography>
        ) : null}
        {orderDetails.deliveryTime ? (
          <Typography>Delivery Time: {FormatTimeForDisplay(orderDetails.deliveryTime)}</Typography>
        ) : null} */}

        <Box sx={{ pt: '20px' }}>
          {deliveryDateFormatted ? (
            <>
              <Typography variant='h3' component='h3'>
                Delivery Date
              </Typography>
              <Typography>{deliveryDateFormatted}</Typography>
              {/* <Typography>Delivery Date: {deliveryDateFormatted}</Typography> */}
            </>
          ) : null}
          {deliveryTimeFormatted ? (
            <>
              <Typography variant='h3' component='h3' sx={{ pt: '10px' }}>
                Delivery Time
              </Typography>
              <Typography>{deliveryTimeFormatted}</Typography>
            </>
          ) : // <Typography>Delivery Time: {FormatTimeForDisplay(deliveryTimeFormatted)}</Typography>
          null}
        </Box>
      </Box>
    </div>
  );
};

export default OrderDetails;
