import React from 'react';
import { Box, Typography } from '@mui/material';

import classes from './MenuItem.module.css';

const MenuItemMainDetails = (props) => {
  const { menuItem } = props;
  // const { menuItem, userRole, onDisplayDetails } = props;

  // console.log('menuItem');
  // console.log(menuItem);

  // onDisplayDetails
  // const detailsButtonText = userRole === 'Admin' ? 'Details / Edit' : 'Details';

  return (
    <Box sx={{ py: '2rem' }}>
      <Box className={classes['title-category-holder']}>
        <Box>
          <Typography variant='h5' component='p'>
            {menuItem.name}
          </Typography>

          {menuItem.dietaryOptions ? (
            <Box>
              <Typography variant='label'>Dietary Flags</Typography>
              <Typography variant='body1'>{menuItem.dietaryOptions}</Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
      {menuItem.description ? (
        <Box>
          <Typography variant='label'>Description</Typography>
          <Typography variant='bodyContent' component='pre'>
            {menuItem.description}
          </Typography>
        </Box>
      ) : null}

      {menuItem.details ? (
        <Box>
          <Typography variant='label'>Details</Typography>
          <Typography variant='bodyContent' component='pre'>
            {menuItem.details}
          </Typography>
        </Box>
      ) : null}

      <Box className='pt-10'>
        <Typography variant='label'>Category</Typography>
        <Typography variant='body1'>{menuItem.menuItemCategory}</Typography>
      </Box>
    </Box>
  );
};

export default MenuItemMainDetails;
