import { Box, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import StandardPageWrapper from '../Layout/StandardPageWrapper';
import DeliveryDetailsForm from './DeliveryDetailsForm';
import classes from '../CSS/Checkout.module.css';

const DeliveryDetails = () => {
  return (
    <StandardPageWrapper title='Delivery Details' usePaper='true'>
      <Box className={classes['content-holder']}>
        <List>
          <ListItem>
            <ListItemIcon>
              <InfoIcon color='primary' />
            </ListItemIcon>
            <ListItemText>
              To ensure a precise delivery, please review & confirm the date & time for your athlete
              catering order
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InfoIcon color='primary' />
            </ListItemIcon>
            <ListItemText>
              Please note: most Fueling Partners will require all changes to be made within their
              lead times
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InfoIcon color='primary' />
            </ListItemIcon>
            <ListItemText>
              You will have the opportunity to message your fueling partner with questions and
              special requests before your order is placed
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InfoIcon color='primary' />
            </ListItemIcon>
            <ListItemText>
              For a faster response please call the contact listed for your fueling partner directly
            </ListItemText>
          </ListItem>
        </List>
      </Box>

      <Divider sx={{ mb: '30px' }} />

      <div className={classes['content-holder']}>
        <DeliveryDetailsForm />
      </div>
    </StandardPageWrapper>
  );
};

export default DeliveryDetails;
