import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import Notification from '../MUI/UI/Notification';
import ConfirmationDialog from '../MUI/UI/ConfirmationDialog';
import VendorReferralCard from '../Referrals/VendorReferralCard';

const VendorReferrals = () => {
  const authCtx = useContext(AuthContext);
  const dashboardUrl = authCtx.dashboardUrl;

  const history = useHistory();

  const [records, setRecords] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const getVendorReferralRecords = useCallback(async () => {
    // console.log(' ***** getVendorReferralRecords ***** ');

    setIsLoading(true);
    setIsError(false);

    const response = await fetch(authCtx.baseApiUrl + 'restaurantreferrals', {
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    });

    const responseData = await response.json();

    if (response.ok) {
      // console.log('responseData.data.orders');
      // console.log(responseData.data.orders);

      const transformedRecords = responseData.data.restaurant_referrals.map((record) => {
        return {
          vendorReferralUuid: record.restaurantReferralUuid,
          name: record.name,
          contactName: record.contactName,
          contactPosition: record.contactPosition,
          email: record.email,
          phone: record.phone,
          website: record.website,
          location: record.location,
          notes: record.notes,
          status: record.status,
          dateAdded: record.dateAdded,
        };
      });

      // console.log('transformedRecords');
      // console.log(transformedRecords);

      let filteredRecords = transformedRecords;

      filteredRecords = filteredRecords.filter(
        // (record) => record.status.toLowerCase() === statusFilter
        (record) => record.status !== 'Deleted'
      );

      // console.log('filteredRecords');
      // console.log(filteredRecords);

      // setRecords(transformedRecords);
      setRecords(filteredRecords);
    } else {
      // If a 401 is returned then there is an issue with the session (most likely).
      if (responseData.statusCode === 401) {
        history.push(dashboardUrl + 'sessionexpired');
      }
      setIsError(true);
      console.log(' ***** ERROR ** getVendorReferralRecords ** ERROR ***** ');
    }

    setIsLoading(false);
    setRefreshData(false);
  }, [authCtx.baseApiUrl, authCtx.token, dashboardUrl, history]);

  useEffect(() => {
    getVendorReferralRecords();
  }, [getVendorReferralRecords, refreshData]);

  const deleteDataHandler = (vendorReferralUuid) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    // console.log(' - deleteDataHandler - vendorReferralUuid = ' + vendorReferralUuid);
    deleteData(vendorReferralUuid);
    // setNotify({ isOpen: true, message: 'Deleted Successfully', type: 'error' });
  };

  const deleteData = async (vendorReferralUuid) => {
    const response = await fetch(authCtx.baseApiUrl + 'restaurantreferrals/' + vendorReferralUuid, {
      method: 'DELETE',
      headers: {
        Authorization: authCtx.token,
      },
    });

    if (response.ok) {
      // const responseData = await response.json();
      await response.json();
      // console.log(responseData);

      setNotify({ isOpen: true, message: 'Deleted Successfully', type: 'error' });

      getVendorReferralRecords();
    } else {
      setIsError(true);
    }

    setIsLoading(false);
    setRefreshData(true);
    getVendorReferralRecords();
  };

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' message='Something did not work...' /> : null}

      {!isLoading && !isError && records.length > 0 ? (
        <Box className='grid-50-50'>
          {records.map((record, index) => (
            <VendorReferralCard
              key={index}
              record={record}
              setConfirmDialog={setConfirmDialog}
              deleteDataHandler={deleteDataHandler}
            />
          ))}
        </Box>
      ) : null}

      {!isLoading && records.length === 0 && (
        <Box sx={{ pt: '1rem' }}>
          <Typography color='error' align='center'>
            No New Restaurant Referrals
          </Typography>
        </Box>
      )}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default VendorReferrals;
