import React, { useState } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';

import Popup from '../MUI/UI/Popup';
import RestaurantRatingWidget from '../UI/RestaurantRatingWidget';
import RestaurantReviewsList from '../RestaurantReviews/RestaurantReviewsList';
// import DeliveryHours from '../DeliveryHours/DeliveryHours';
// import DeliveryFeesListCard from '../DeliveryFees/DeliveryFeesListCard';
import DeliveryHoursTable from '../DeliveryHours/DeliveryHoursTable';
import DeliveryFeesSimpleCard from '../DeliveryFees/DeliveryFeesSimpleCard';
import classes from '../CSS/SelectRestaurantCard.module.css';

const SelectRestaurantCard = (props) => {
  // console.log('##### SelectRestaurantCard #####');
  // console.log('props');
  // console.log(props);

  const { restaurant, selectRestaurant } = props;

  const {
    restaurantUuid,
    name,
    description,
    address,
    city,
    state,
    foodType,
    listImage,
    leagueExperience,
    rating,
    numberOfRatings,
    deliveryFees,
    deliveryHours,
    orderLeadTime,
  } = restaurant;

  const [openPopupReviews, setOpenPopupReviews] = useState(false);

  const displayOrderLeadTime = false;

  const showRestaurantReviewssHandler = () => {
    setOpenPopupReviews(true);
  };

  return (
    <Paper elevation={4} className={classes['select-restaurant-card']}>
      <Box className={classes['card-layout']}>
        <Box className={classes['image-section']}>
          <img src={listImage} alt='Restaurant List Side Header' />
        </Box>

        <Box className={classes['info-section']}>
          <Box className={classes['info-section-layout']}>
            <Box className={classes['info-section-inner-layout']}>
              <Box className={classes['main-info-holder']}>
                <Typography variant='h2' component='h2'>
                  {name}
                </Typography>

                {address ? (
                  <Box className='pb-10'>
                    <Typography>
                      {address} {city ? city : ''} {state ? state : ''}
                    </Typography>
                  </Box>
                ) : null}

                {description ? (
                  <Box className={classes['additional-information-holder']}>
                    <Typography component='pre'>{description}</Typography>
                  </Box>
                ) : null}

                <Box className='pb-5'>
                  <Typography variant='labelInline'>Food Type: </Typography>
                  <Typography component='span'>{foodType}</Typography>
                </Box>

                <Box className='pb-5'>
                  <Typography variant='labelInline'>League Experience:</Typography>
                  <Typography component='span'>{leagueExperience}</Typography>
                </Box>

                {displayOrderLeadTime ? (
                  <Box className='pb-5'>
                    <Typography variant='labelInline'>Order Lead Time:</Typography>
                    <Typography component='span'>{orderLeadTime + ' hours'}</Typography>
                  </Box>
                ) : null}
              </Box>

              <Box className={classes['delivery-info-layout']}>
                <Box>
                  <DeliveryHoursTable deliveryHours={deliveryHours} textSize='small' />
                </Box>

                <Box className='content-right-justified'>
                  <DeliveryFeesSimpleCard deliveryFees={deliveryFees} />
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '10px' }}>
              {rating && numberOfRatings ? (
                <Box onClick={showRestaurantReviewssHandler} sx={{ cursor: 'pointer' }}>
                  <RestaurantRatingWidget rating={rating} numberOfRatings={numberOfRatings} />
                </Box>
              ) : (
                <div></div>
              )}
              <Button
                variant='contained'
                color='accent'
                size='small'
                onClick={() => {
                  selectRestaurant(restaurantUuid, name);
                }}
              >
                Select
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Popup
        openPopup={openPopupReviews}
        setOpenPopup={setOpenPopupReviews}
        title={'Reviews for ' + name}
        maxWidth='md'
      >
        <RestaurantReviewsList propRestaurantUuid={restaurantUuid} mode='popup' />
      </Popup>
    </Paper>
  );
};

export default SelectRestaurantCard;
