import React from 'react';
import { Box, Typography } from '@mui/material';

const InstructionDetails = (props) => {
  const { orderDeliveryInstructions, orderTeamInstructions } = props;

  // console.log(orderDetails);
  // console.log(orderDeliveryInstructions);
  // console.log(orderTeamInstructions);

  // const deliveryInstructions = orderDetails.deliveryInstructions;
  // const teamInstructions = orderDetails.teamInstructions;

  return (
    <div className='condensed-content'>
      {orderDeliveryInstructions ? (
        <Box sx={{ p: 2 }}>
          <Typography variant='h7' component='h6'>
            Delivery Instructions
          </Typography>
          <Typography style={{ whiteSpace: 'pre-line' }}>{orderDeliveryInstructions}</Typography>
        </Box>
      ) : null}
      {orderTeamInstructions ? (
        <Box sx={{ p: 2 }}>
          <Typography variant='h7' component='h6'>
            Team Instructions
          </Typography>
          <Typography style={{ whiteSpace: 'pre-line' }}>{orderTeamInstructions}</Typography>
        </Box>
      ) : null}
    </div>
  );
};

export default InstructionDetails;
