import classes from '../CSS/styled-table.module.css';

const GlobalMenuItemCategoryDetailsTable = (props) => {
  return (
    <div className='table-holder'>
      <table className={classes['styled-table']}>
        <tbody>
          <tr>
            <th>Name</th>
            <td>{props.details.name}</td>
          </tr>
          <tr>
            <th>Description</th>
            <td>{props.details.description}</td>
          </tr>

          <tr>
            <th>Sort Order</th>
            <td>{props.details.sortOrder}</td>
          </tr>
          <tr>
            <th>Status</th>
            <td>{props.details.status}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default GlobalMenuItemCategoryDetailsTable;
