import React, { useContext, useRef, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
import WorkflowMessage from '../UI/WorkflowMessage';
import classes from '../CSS/standard-mui-form.module.css';

const FileAttachmentForm = ({ orderUuid, fileUpload = null, onRefreshData, onClearForm }) => {
  const authCtx = useContext(AuthContext);

  // console.log('###### FileAttachmentForm #####');
  // console.log('### fileUpload ###');
  // console.log(fileUpload);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFilename, setSelectedFileName] = useState('');
  const [validationError, setValidationError] = useState(null);

  const selectedFilenameRef = useRef();
  const nameRef = useRef();
  const descriptionRef = useRef();

  const fileUploadUuid = fileUpload ? fileUpload.fileUploadUuid : '';
  const filename = fileUpload ? fileUpload.name : '';
  const description = fileUpload ? fileUpload.description : '';

  const submitHandler = (event) => {
    event.preventDefault();

    setIsError(false);

    setNotify({
      isOpen: true,
      message: 'Sending Request...',
      type: 'info',
    });

    const enteredName = nameRef.current.value;
    const enteredDescription = descriptionRef.current.value;
    // console.log(' - enteredName = ' + enteredName);
    // console.log(' - enteredDescription = ' + enteredDescription);

    const keepGoing = true;

    if (!keepGoing) {
      console.log('keepGoing = ' + keepGoing);
      return;
    }

    let apiURL = '';
    let apiMethod = '';

    if (fileUploadUuid !== '' && fileUploadUuid !== undefined) {
      // apiURL = authCtx.baseApiUrl + 'fileupload/' + fileUploadUuid;
      // apiMethod = 'PATCH';
      // Ding a POST here so data and file gets to the API.
      apiURL = authCtx.baseApiUrl + 'fileupload/update';
      apiMethod = 'POST';
    } else {
      apiURL = authCtx.baseApiUrl + 'fileupload';
      apiMethod = 'POST';
    }

    // console.log(' - apiURL = ' + apiURL);
    // console.log(' - apiMethod = ' + apiMethod);

    const jsonObj = {
      file_upload_uuid: fileUploadUuid,
      order_uuid: orderUuid,
      name: enteredName,
      description: enteredDescription,
    };

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('json', JSON.stringify(jsonObj));

    fetch(apiURL, {
      method: apiMethod,
      body: formData,
      // body: JSON.stringify({
      //   order_uuid: orderUuid,
      //   name: enteredName,
      //   description: enteredDescription,
      //   selected_file: selectedFile,
      // }),
      headers: {
        Authorization: authCtx.token,
        // 'Content-Type': 'application/json',
      },
    }).then((res) => {
      setIsLoading(false);

      if (res.ok) {
        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          clearFormHandler();
          onRefreshData();
          onClearForm();
        });
      } else {
        return res.json().then((responseData) => {
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: 'File Attachment Not Uploaded or Saved',
              type: 'error',
            });
          }
        });
      }
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileExtensionsAllowed = 'doc, docx, jpg, jpeg, pdf, png, txt, xls, xlsx';
      const arrayFileExtensionsAllowed = [
        '.doc',
        '.docx',
        '.jpg',
        '.jpeg',
        '.pdf',
        '.png',
        '.txt',
        '.xls',
        '.xlsx',
      ];
      const selectedFileExtension = file.name.split('.').pop().toLowerCase();

      if (arrayFileExtensionsAllowed.includes('.' + selectedFileExtension)) {
        setSelectedFile(file);
        setValidationError(null);

        const filename = file.name;

        setSelectedFileName(filename);

        selectedFilenameRef.current.value = filename;
      } else {
        setSelectedFile(null);
        setValidationError(
          'Invalid file extension.  Approved file extensions: ' + fileExtensionsAllowed
        );
      }
    } else {
    }
  };

  const clearFormHandler = () => {
    // console.log('### clearFormHandler ###');
    selectedFilenameRef.current.value = '';
    nameRef.current.value = '';
    descriptionRef.current.value = '';
  };

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {/* {isError ? <WorkflowMessage type='error' /> : null} */}

      {!isLoading && !isError ? (
        <Box>
          <form onSubmit={submitHandler}>
            <Box className={classes['form-container']}>
              <Box className={classes['side-by-side-form-controls-holder']}>
                <Button variant='contained' component='label' size='small' sx={{ width: '140px' }}>
                  Select File
                  <input
                    hidden
                    accept='.txt,.doc,.docx,.jpg,.jpeg,.pdf,.png,.xls,.xlsx'
                    multiple
                    type='file'
                    name='selected_file'
                    // onChange={onFileChange}
                    onChange={handleFileChange}
                  />
                </Button>

                <FormControls.InputControl
                  type='text'
                  name='filename'
                  label='Selected Filename'
                  inputRef={selectedFilenameRef}
                  defaultValue={selectedFilename}
                  disabled
                  inputProps={{ maxLength: 200 }}
                  className={classes['mui-control']}
                />
              </Box>

              <FormControls.InputControl
                type='text'
                name='name'
                label='Name'
                inputRef={nameRef}
                defaultValue={filename}
                required
                inputProps={{ maxLength: 200 }}
                className={classes['mui-control']}
              />

              <FormControls.InputControl
                type='text'
                name='description'
                label='Description'
                inputRef={descriptionRef}
                defaultValue={description}
                className={classes['mui-control']}
                multiline
                minRows={3}
                maxRows={12}
              />
            </Box>

            <Box component='div' className={classes['actions-container']}>
              <FormControls.ButtonControl
                text='Save File Attachment'
                type='submit'
                color='primary'
                size='small'
                id='submit-button'
              />

              <FormControls.ButtonControl
                text='Clear'
                onClick={clearFormHandler}
                type='button'
                color='neutral200'
                size='small'
              />
            </Box>
          </form>

          {validationError ? (
            <Box className='pt-20'>
              <Typography color='error'>{validationError}</Typography>
            </Box>
          ) : null}
        </Box>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default FileAttachmentForm;
