import { useState } from 'react';

import LeagueList from '../Admin/LeagueList';
import LeagueForm from '../Admin/LeagueForm';

const GlobalLeagues = () => {
  const [showForm, setShowForm] = useState(false);
  const [showList, setShowList] = useState(true);
  const [leagueUuid, setLeagueUuid] = useState('');

  const addLeagueHandler = () => {
    setLeagueUuid('');
    setShowList(false);
    setShowForm(true);
  };

  const showListHandler = () => {
    setShowList(true);
    setShowForm(false);
  };

  const editLeagueHandler = (leagueUuid) => {
    setLeagueUuid(leagueUuid);
    setShowList(false);
    setShowForm(true);
  };

  return (
    <>
      <div className='button-holder'>
        <button className='small' onClick={addLeagueHandler}>
          Add League
        </button>
        <button className='small' onClick={showListHandler}>
          League List
        </button>
      </div>
      {showList && <LeagueList onEdit={editLeagueHandler} />}
      {showForm && <LeagueForm onCancel={showListHandler} leagueUuid={leagueUuid} />}
    </>
  );
};

export default GlobalLeagues;
