import { useState } from 'react';
import { Box, Button, Collapse } from '@mui/material';

import StandardPageWrapper from '../Layout/StandardPageWrapper';
import UserDetails from '../User/UserDetails';
import ChangePasswordForm from '../MUI/User/ChangePasswordForm';

const Account = () => {
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);

  const toggleShowPasswordForm = () => {
    setShowChangePasswordForm((current) => !current);
  };

  return (
    <StandardPageWrapper title='Account'>
      <UserDetails />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pb: 2 }}>
        <Button variant='text' onClick={toggleShowPasswordForm} className='text-button'>
          Change Your Password
        </Button>
      </Box>
      <Collapse in={showChangePasswordForm}>
        <ChangePasswordForm />
      </Collapse>
    </StandardPageWrapper>
  );
};

export default Account;
