import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AuthContext from '../../store/auth-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import classes from '../CSS/styled-table.module.css';

const UserMenuItemDetails = (props) => {
  const authCtx = useContext(AuthContext);

  const dashboardUrl = authCtx.dashboardUrl;

  const [menuItemDetails, setMenuItemDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const history = useHistory();
  const params = useParams();
  const menuItemUuid = params.menuItemUuid;

  const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const response = await fetch(authCtx.baseApiUrl + 'menuitems/' + menuItemUuid, {
        headers: {
          Authorization: authCtx.token,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);

        const restaurantUuid = responseData.data.menu_items[0].restaurantUuid;

        console.log('**** restaurantUuid = ' + restaurantUuid);

        const returnedMenuItemDetails = {
          uuid: responseData.data.menu_items[0].menuItemUuid,
          restaurantUuid: responseData.data.menu_items[0].restaurantUuid,
          name: responseData.data.menu_items[0].name,
          description: responseData.data.menu_items[0].description,
          details: responseData.data.menu_items[0].details,
          feeds: responseData.data.menu_items[0].feeds,
          price: responseData.data.menu_items[0].price,
          category: responseData.data.menu_items[0].menuItemCategory,
          status: responseData.data.menu_items[0].menuItemStatus,
        };
        console.log(' -- returnedMenuItemDetails -- ');
        console.log(returnedMenuItemDetails);

        setMenuItemDetails(returnedMenuItemDetails);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    // call the function
    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, menuItemUuid]);

  const backToListHandler = (event) => {
    const restaurantUuid = event.target.dataset.restaurantuuid;
    history.push(dashboardUrl + 'menu-items/' + restaurantUuid);
  };

  return (
    <StandardPageWrapper title='Menu Item Details ----- Users - Teams'>
      {!isLoading && !isError && (
        <div className='table-holder'>
          <table className={classes['styled-table']}>
            <tbody>
              <tr>
                <th>Name</th>
                <td>{menuItemDetails.name}</td>
              </tr>
              <tr>
                <th>Description</th>
                <td>{menuItemDetails.description}</td>
              </tr>

              <tr>
                <th>Details</th>
                <td>{menuItemDetails.details}</td>
              </tr>
              <tr>
                <th>Feeds</th>
                <td>{menuItemDetails.feeds}</td>
              </tr>
              <tr>
                <th>Price</th>
                <td>{priceFormatter.format(menuItemDetails.price)}</td>
              </tr>
              <tr>
                <th>Type</th>
                <td>{menuItemDetails.category}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{menuItemDetails.status}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {!isLoading && !isError && (
        <div className='button-holder'>
          <button
            data-restaurantuuid={menuItemDetails.restaurantUuid}
            onClick={backToListHandler}
            className='small'
          >
            Back to Menu
          </button>
        </div>
      )}
      {isLoading && (
        <div className={classes['message-holder']}>
          <p className={classes['loading']}>Loading...</p>
        </div>
      )}
      {isError && (
        <div className={classes['message-holder']}>
          <p className={classes['error']}>Error...</p>
        </div>
      )}
    </StandardPageWrapper>
  );
};

export default UserMenuItemDetails;
