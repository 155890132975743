import { useState, useRef, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';

import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
import useAdditionalCharge from '../Hooks/useAdditionalCharge';
import classes from './../CSS/AdditionalCharges.module.css';

const statusOptions = [
  { id: 'Active', title: 'Active' },
  { id: 'Inactive', title: 'Inactive' },
  { id: 'Deleted', title: 'Deleted' },
];

const AdditionalChargesForm = (props) => {
  const { additionalCharges, saveAdditionalCharge } = useAdditionalCharge();

  // const history = useHistory();

  let { additionalChargeDetails } = props;
  // let { additionalChargeUuid, additionalChargeDetails, orderUuid } = props;

  if (!additionalChargeDetails) {
    additionalChargeDetails = {
      additionalChargeUuid: '',
      orderUuid: '',
      name: '',
      description: '',
      price: '',
      status: '',
    };
  }

  // console.log('** AdditionalChargesForm ** additionalChargeDetails');
  // console.table(additionalChargeDetails);

  // console.log('additionalChargeDetails.status = ' + additionalChargeDetails.status);

  const [additionalChargeStatus, setAdditionalChargeStatus] = useState(
    additionalChargeDetails.status ? additionalChargeDetails.status : 'Active'
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const nameRef = useRef();
  const descriptionRef = useRef();
  const priceRef = useRef();

  let submitButtonText = 'Save';

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, [isLoading]);

  const submitHandler = (event) => {
    event.preventDefault();

    setIsLoading(true);
    setIsError(false);

    setNotify({
      isOpen: true,
      message: 'Sending Request...',
      type: 'info',
    });

    const enteredName = nameRef.current.value;
    const enteredDescription = descriptionRef.current.value;
    const enteredPrice = priceRef.current.value;

    const additionalCharge = {
      name: enteredName,
      description: enteredDescription,
      price: enteredPrice,
      status: additionalChargeStatus,
    };

    // console.log('additionalCharge');
    // console.table(additionalCharge);

    const data = saveAdditionalCharge(additionalCharge);

    console.log('-- submitHandler - data --');
    console.log(data);

    console.log('additionalCharges');
    console.table(additionalCharges);

    // const keepGoing = true;

    // if (!keepGoing) {
    //   console.log('keepGoing = ' + keepGoing);
    //   return;
    // }

    // setNotify({ isOpen: true, message: messageText, type: 'error' });
  };

  const statusChangeHandler = (event) => {
    setAdditionalChargeStatus(event.target.value);
  };

  return (
    <>
      {isLoading ? (
        <div className='message-holder'>
          <p className='loading'>Loading...</p>
        </div>
      ) : null}

      {isError ? (
        <div className='message-holder'>
          <p className='error'>Error...</p>
        </div>
      ) : null}

      {!isLoading ? (
        // <Paper elevation={4} className='standard-form'>
        <Box>
          {/* <Typography variant='h7' component='h6' align='center'>
            Additional Charge
          </Typography> */}
          <form onSubmit={submitHandler}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
              <FormControls.InputControl
                type='text'
                name='name'
                label='Name'
                inputRef={nameRef}
                defaultValue={additionalChargeDetails.name}
                required
                inputProps={{ maxLength: 50 }}
                className={classes['mui-control']}
              />
              <FormControls.InputControl
                type='text'
                name='description'
                label='Description'
                inputRef={descriptionRef}
                defaultValue={additionalChargeDetails.description}
                className={classes['mui-control']}
                multiline
                minRows={3}
                maxRows={12}
              />
              <FormControls.InputControl
                type='text'
                name='price'
                label='Price'
                inputRef={priceRef}
                defaultValue={additionalChargeDetails.price}
                required
                inputProps={{ type: 'number', min: '0', max: '19999.99', step: '0.01' }}
                className={classes['mui-control']}
              />
            </Box>
            <Box sx={{ width: '15rem', pt: '0.5rem' }}>
              <FormControls.SelectControl
                name='status'
                label='Status'
                value={additionalChargeStatus}
                onChange={statusChangeHandler}
                options={statusOptions}
              />
            </Box>
            <Box component='div' className='button-holder' sx={{ pt: '1rem' }}>
              <FormControls.ButtonControl
                text={submitButtonText}
                type='submit'
                color='primary'
                size='small'
              />
              {/* <FormControls.ButtonControl
                text='Cancel'
                onClick={onCancel}
                type='button'
                color='neutral200'
              /> */}
            </Box>
          </form>
        </Box>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default AdditionalChargesForm;
