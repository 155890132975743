import React, { useState, useEffect, useContext, useRef } from 'react';
// import { useHistory } from 'react-router-dom';
import { Alert, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
// import GlobalDataContext from '../../store/global-data-context';
import * as Constants from '../../helpers/Constants';
import { displayListFilterSelect } from '../../helpers/FilterListHelperFunctions';
import classes from '../CSS/styled-table.module.css';

const GlobalMenuItemCategoryList = (props) => {
  const authCtx = useContext(AuthContext);
  // const globalDataCtx = useContext(GlobalDataContext);
  // const globalMenuItemCategories = globalDataCtx.globalMenuItemCategories;
  // console.log(' ** globalMenuItemCategories ** ');
  // console.log(globalMenuItemCategories);

  // const dashboardUrl = authCtx.dashboardUrl;

  const [menuItemCategories, setMenuItemCategories] = useState([]);
  const [displayedMenuItemCategories, setDisplayedMenuItemCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const statusFilterRef = useRef();

  // const history = useHistory();

  // const updateGlobalMenuCategoriesHandler = (menuCategories) => {
  //   if (menuCategories.length > 0 && globalMenuItemCategories.length === 0) {
  //     // console.log(' @@@@@@@@@@ updateGlobalMenuCategoriesHandler - called @@@@@@@@@@ ');
  //     globalDataCtx.setGlobalMenuItemCategories(menuCategories);
  //   }
  // };

  useEffect(() => {
    // console.log(' ** GlobalMenuItemCagegoryList -- useEffect ** ');

    setIsLoading(true);
    const fetchData = async () => {
      // console.log(' ^^^ calling fetchData ^^^^ ');
      const response = await fetch(authCtx.baseApiUrl + 'globalmenuitemcategories', {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(responseData);

        const transformedRecords = responseData.data.global_menu_item_categories.map((record) => {
          return {
            uuid: record.globalMenuItemCategoryUuid,
            name: record.name,
            parentName: record.parentName,
            description: record.description,
            sortOrder: record.sortOrder,
            status: record.status,
          };
        });

        setMenuItemCategories(transformedRecords);
        // setDisplayedMenuItemCategories(transformedRecords);

        const filteredRecords = transformedRecords.filter((record) => record.status === 'Active');
        setDisplayedMenuItemCategories(filteredRecords);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);

    // If there are global values then use them, if not fetch the data.
    // if (!globalMenuItemCategories.length > 0) {
    //   // console.log(' - useEffect - calling fetchData - ');
    //   fetchData().catch(console.error);
    // } else {
    //   // console.log(' - useEffect - going to set Statue - ');
    //   console.log(' - USING GLOBAL DATA - ');
    //   setMenuItemCategories(globalMenuItemCategories);
    //   setDisplayedMenuItemCategories(globalMenuItemCategories);
    //   setIsLoading(false);
    // }
  }, [authCtx.baseApiUrl, authCtx.token]);

  // Todo:  Not sure why I added this.  It was causing an error after going to the restaurants -> menu page and then back.
  // None of this is working correctly.
  // useEffect(() => {
  //   console.log(' -- new useEffect -- ');
  //   if (menuItemCategories.length > 0) {
  //     console.log(' -- new useEffect -- updateGlobalMenuCategoriesHandler -- ');
  //     updateGlobalMenuCategoriesHandler(menuItemCategories);
  //   }
  // }, [menuItemCategories, updateGlobalMenuCategoriesHandler]);

  const displayTableHeader = () => {
    return (
      <tr>
        <th>Name</th>
        {/* <th>Description</th> */}
        <th>Parent</th>
        <th>Details (for information only - not displayed)</th>
        <th className='nowrap'>Sort Order</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
    );
  };

  const displayTableBody = () => {
    return displayedMenuItemCategories.map((record, index) => (
      <tr key={index}>
        {/* <td>{record.name}</td> */}
        <td className='nowrap'>{record.parentName ? '   -- ' + record.name : record.name}</td>
        {/* <td>{record.description}</td> */}
        <td className='nowrap'>{record.parentName}</td>
        <td>{record.description}</td>
        <td>{record.sortOrder}</td>
        <td>{record.status}</td>
        <td>
          <div className={classes['table-actions-container']}>
            {/* <button onClick={() => props.onShowDetails(`${record.uuid}`)} className='small'>
              Details
            </button> */}
            <button onClick={() => props.onEditRecord(`${record.uuid}`)} className='small'>
              Edit
            </button>
            {/* {record.status !== 'Deleted' && (
              <button data-uuid={record.uuid} onClick={deleteRecordHandler} className='small'>
                Delete
              </button>
            )} */}
          </div>
        </td>
      </tr>
    ));
  };

  // const deleteRecordHandler = (event) => {
  //   const uuid = event.target.getAttribute('data-uuid');

  //   console.log(' -- deleteRecordHandler -- ');
  //   console.log('uuid = ' + uuid);

  //   // const deleteData = async () => {
  //   //   const response = await fetch(authCtx.baseApiUrl + 'globalmenuitemcategories/' + uuid, {
  //   //     method: 'DELETE',
  //   //     headers: {
  //   //       Authorization: authCtx.token,
  //   //     },
  //   //   });

  //   //   if (response.ok) {
  //   //     const responseData = await response.json();
  //   //     console.log(responseData);
  //   //   } else {
  //   //     setIsError(true);
  //   //   }
  //   //   setIsLoading(false);
  //   // };

  //   // deleteData().catch(console.error);
  // };

  // const addNewHandler = () => {
  //   history.push(dashboardUrl + 'locations/add');
  // };

  const clearFilterHandler = () => {
    statusFilterRef.current.value = 'Show All'; // 'Active';

    filterDisplayedRecords();
  };

  const filterDisplayedRecords = () => {
    let statusFilter = statusFilterRef.current.value;

    // console.log(' -- filterDisplayedRecords -- ');
    // console.log({ statusFilter: statusFilter });

    if (statusFilter === 'Show All') {
      setDisplayedMenuItemCategories(menuItemCategories);
    } else {
      let filteredRecords = menuItemCategories;

      if (statusFilter !== 'Show All') {
        filteredRecords = filteredRecords.filter((record) => record.status === statusFilter);
      }

      setDisplayedMenuItemCategories(filteredRecords);
    }
  };

  return (
    <div>
      {!isLoading && (
        <Alert severity='info' sx={{ mb: '20px' }}>
          <Typography>
            These Menu Item Categores are used as available options for Menu Items and for filtering
            Results. These are not used for the Restaurant Menu Header. Code updates are needed to
            get these values synced up with the values in the code.
          </Typography>

          <Typography component='div'>
            Contact your Developer when you make changes here.
          </Typography>
        </Alert>
      )}

      {!isLoading && !isError && (
        <div className='button-holder'>
          <label>Filter By: </label>
          <label htmlFor='filter-by-status'>Status</label>
          {displayListFilterSelect(
            'filter-by-status',
            statusFilterRef,
            filterDisplayedRecords,
            Constants.STATUS_ARRAY,
            'Active'
            // 'Show All'
          )}
          <button className='small' onClick={clearFilterHandler}>
            Clear Filters
          </button>
        </div>
      )}
      {isLoading && (
        <div className='message-holder'>
          <p className='loading'>Loading...</p>
        </div>
      )}
      {isError && (
        <div className='message-holder'>
          <p className='error'>Error...</p>
        </div>
      )}
      {!isLoading && menuItemCategories.length === 0 && (
        <div className='message-holder'>
          <p className='error'>No Locations Found...</p>
        </div>
      )}
      {!isLoading && menuItemCategories.length > 0 && (
        <div className='table-holder'>
          <table className={`${classes['styled-table']} ${classes['full-width']}`}>
            <thead>{displayTableHeader()}</thead>
            <tbody>{displayTableBody()}</tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default GlobalMenuItemCategoryList;
