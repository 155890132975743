import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import AuthContext from '../../store/auth-context';
import classes from '../CSS/styled-table.module.css';

const TestRestaurantList = (props) => {
  const authCtx = useContext(AuthContext);

  const dashboardUrl = authCtx.dashboardUrl;
  const userRole = authCtx.userRole;

  const { restaurants, showDetails, editDetails } = props;

  const history = useHistory();

  const selectedRestaurantUuid = authCtx.selectedRestaurantUuid;

  // console.log(
  //   ' ** TestRestaurantList -- selectedRestaurantUuid = ' + selectedRestaurantUuid + ' ** '
  // );

  const displayTableHeader = () => {
    return (
      <tr>
        <th>Name</th>
        <th>Address</th>
        <th>City</th>
        <th>State</th>
        <th>Type of Food</th>
        <th>Status</th>
        <th>Select</th>
        <th>Actions</th>
      </tr>
    );
  };

  const displayTableBody = () => {
    return restaurants.map((restaurant, index) => (
      <tr key={index}>
        <td>{restaurant.name}</td>
        <td>{restaurant.address}</td>
        <td>{restaurant.city}</td>
        <td>{restaurant.state}</td>
        <td>{restaurant.foodType}</td>
        <td>{restaurant.status}</td>
        <td>
          {restaurant.restaurantUuid !== selectedRestaurantUuid && (
            <button
              data-location-uuid={restaurant.restaurantUuid}
              data-location-name={restaurant.name}
              data-location-city={restaurant.city}
              data-location-state={restaurant.state}
              onClick={selectLocationHandler}
              className='small'
            >
              Select
            </button>
          )}
          {restaurant.restaurantUuid === selectedRestaurantUuid && <span>Selected</span>}
        </td>
        <td>
          <div className={classes['table-actions-container']}>
            {/* <Link
              to={`${dashboardUrl}restaurant/details/${restaurant.restaurantUuid}`}
              className='button small'
            >
              Details
            </Link> */}
            <button
              onClick={() => {
                showDetailsHandler(restaurant.restaurantUuid);
              }}
              className='small'
            >
              Details
            </button>
            {userRole !== 'User' ? (
              <>
                {/* <Link
                to={`${dashboardUrl}restaurant/edit/${restaurant.restaurantUuid}`}
                className='button small'
              >
                Edit
              </Link> */}
                <button
                  onClick={() => {
                    editDetailsHandler(restaurant.restaurantUuid);
                  }}
                  className='small'
                >
                  Edit
                </button>
              </>
            ) : null}
            {/* {userRole !== 'User' && (
              <Link
                to={`${dashboardUrl}menu-items/${restaurant.restaurantUuid}`}
                className='button small'
              >
                Menu
              </Link>
            )} */}
            {userRole !== 'User' && (
              <button
                data-location-uuid={restaurant.restaurantUuid}
                data-location-name={restaurant.name}
                data-location-city={restaurant.city}
                data-location-state={restaurant.state}
                onClick={menuHandler}
                className='small'
              >
                Menu
              </button>
            )}
          </div>
        </td>
      </tr>
    ));
  };

  const selectLocationHandler = (event) => {
    // console.log(' -- selectLocationHandler -- ');
    const uuid = event.target.getAttribute('data-location-uuid');
    const name = event.target.getAttribute('data-location-name');
    const city = event.target.getAttribute('data-location-city');
    const state = event.target.getAttribute('data-location-state');
    // console.log(
    //   'uuid = ' + uuid + ' - name = ' + name + ' - city = ' + city + ' - state = ' + state
    // );

    // props.passedFunction(uuid, name, city, state);

    // authCtx.updateLocationValues(uuid, name, city, state);
    authCtx.updateSelectedRestaurantValues(uuid, name, city, state);
  };

  const menuHandler = (event) => {
    selectLocationHandler(event);
    // {`${dashboardUrl}menu-items/${restaurant.restaurantUuid}`}
    const uuid = event.target.getAttribute('data-location-uuid');
    history.replace(dashboardUrl + 'menu-items/' + uuid);
  };

  const showDetailsHandler = (restaurantUuid) => {
    console.log(' -- showDetailsHandler -- restaurantUuid = ' + restaurantUuid);
    showDetails(restaurantUuid);
  };

  const editDetailsHandler = (restaurantUuid) => {
    console.log(' -- editDetailsHandler -- restaurantUuid = ' + restaurantUuid);
    editDetails(restaurantUuid);
  };

  return (
    <>
      {/* <div className='button-holder'>
        <Link to={`${dashboardUrl}restaurant/add`} className='button small'>
          Add Location
        </Link>
      </div> */}

      {restaurants.length > 0 && (
        <div className='table-holder'>
          <table className={classes['styled-table']}>
            <thead>{displayTableHeader()}</thead>
            <tbody>{displayTableBody()}</tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default TestRestaurantList;
