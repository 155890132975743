import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';

import AuthContext from '../store/auth-context';

const SessionExpired = () => {
  const authCtx = useContext(AuthContext);

  const logoutHandler = () => {
    // console.log('--- logoutHandler ---');
    authCtx.logout();
  };

  return (
    <Box className='page-container'>
      <Box className='content-container' sx={{ width: '540px' }}>
        <Box className='message-holder-inline'>
          <Typography variant='h3' component='h3'>
            Your Session has expired. Please log back into Elite Eats.
          </Typography>
        </Box>
        <Box align='center'>
          <button onClick={logoutHandler}>Login</button>
        </Box>

        <Box sx={{ marginTop: '50px', padding: '0 50px' }}>
          <Typography variant='label' sx={{ paddingBottom: '10px' }}>
            Note:
          </Typography>
          <Typography>You can only be logged into Elite Eats once.</Typography>
          <Typography>
            If you log in more than once, your previous session will be deleted and a new session
            will be created.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SessionExpired;
