import React from 'react';
import { Typography } from '@mui/material';

import classes from '../CSS/MenuCategoryHeader.module.css';

const MenuCategoryHeader = (props) => {
  return (
    <div className={classes['menu-category-header-holder']}>
      <div className={classes['menu-category-link-offset']} id={props.category}></div>
      <Typography variant='h2' component='h2'>
        {props.category}
      </Typography>
    </div>
  );
};

export default MenuCategoryHeader;
