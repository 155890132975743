import React, { useReducer } from 'react';
import OrderContext from './order-context';

const runConsoleLogCalls = false;

const defaultOrderState = {
  orderMode: '',
  isReorder: false,
  // Below was commented out for some reason.
  // orderUuid: '',
  // orderNumber: '',
  // restaurantUuid: '',
  // restaurantName: '',
  // deliveryLocationUuid: '',
  // deliveryLocationName: '',
  // deliveryCity: '',
  // deliveryState: '',
  // deliveryZipcode: '',
  // deliveryDate: '',
  // deliveryTime: '',
  // headcount: 1,
  // status: 'Draft',
  // Above was commented out for some reason.
  orderDetails: {},
  orderContacts: [],
  deliveryLocation: {},
  restaurant: {},
  restaurantContacts: [],
  items: [],
  totalAmount: 0,
  subtotal: 0,
  tax: 0,
  total: 0,
  taxRate: 0.0,
  teamCharge: 0,
  orderMessages: [],
};

const formatCurrencyForDisplay = (value) => {
  let returnValue = parseFloat(value);

  returnValue = typeof returnValue === 'number' ? '$' + returnValue.toFixed(2) : value;

  return returnValue;
};

const calculateOrderSubtotal = (items) => {
  if (runConsoleLogCalls) {
    console.log(' ### calculateOrderSubtotal ### ');
  }
  let calculatedSubtotal = 0;

  items.forEach((item) => {
    calculatedSubtotal += item.quantity * item.price;
  });

  const returnCalculatedSubtotal =
    typeof calculatedSubtotal === 'number' ? +calculatedSubtotal.toFixed(2) : +calculatedSubtotal;

  return returnCalculatedSubtotal;
};

const orderReducer = (state, action) => {
  if (action.type === 'SET_ORDER_MODE') {
    if (runConsoleLogCalls) {
      console.log(' *********************************** ');
      console.log(' - SET_ORDER_MODE ');
      console.log(' - action.orderMode - ');
      console.table(action.orderMode);
    }

    return {
      ...state,
      orderMode: action.orderMode,
    };
  }

  if (action.type === 'SET_IS_REORDER') {
    if (runConsoleLogCalls) {
      console.log(' *********************************** ');
      console.log(' - SET_IS_REORDER ');
      console.log(' - action.isReorder - ');
      console.table(action.isReorder);
    }

    return {
      ...state,
      isReorder: action.isReorder,
    };
  }

  if (action.type === 'SET_ORDER_DETAILS') {
    if (runConsoleLogCalls) {
      console.log(' - SET_ORDER_DETAILS ');
      console.log(' - action.orderDetails - ');
      console.log(action.orderDetails);
      // console.log('state', state);
    }

    // Going back to the simple version since we don't want to do calculations here.
    return {
      ...state,
      orderDetails: action.orderDetails,
    };
  }

  if (action.type === 'SET_ORDER_CONTACTS') {
    // console.log(' *********************************** ');
    // console.log(' - SET_ORDER_CONTACTS ');
    // console.log(' - action.orderContacts - ');
    // console.table(action.orderContacts);
    return {
      ...state,
      orderContacts: action.orderContacts,
    };
  }

  if (action.type === 'SET_ORDER_MESSAGES') {
    // console.log(' - SET_ORDER_MESSAGES ');
    // console.log(' - action.orderMessages - ');
    // console.table(action.orderMessages);
    return {
      ...state,
      orderMessages: action.orderMessages,
    };
  }

  if (action.type === 'SET_DELIVERY_LOCATION') {
    // console.log(' - SET_DELIVERY_LOCATION ');
    // console.log(' - action.deliveryLocation - ');
    // console.table(action.deliveryLocation);
    return {
      ...state,
      deliveryLocation: action.deliveryLocation,
    };
  }

  if (action.type === 'SET_RESTAURANT') {
    // console.log(' - SET_RESTAURANT ');
    // console.log(' - action.restaurant - ');
    // console.log(action.restaurant);
    const taxRate = action.restaurant.taxRate;
    // console.log(' - taxRate = ' + taxRate + ' - ');
    // Check if taxRate is a number.
    // const isTaxRateANumber = typeof taxRate === 'number' ? true : false;
    // console.log(' - isTaxRateANumber = ' + isTaxRateANumber + ' - ');

    return {
      ...state,
      restaurant: action.restaurant,
      taxRate: taxRate,
    };
  }

  if (action.type === 'SET_RESTAURANT_CONTACTS') {
    // console.log(' *********************************** ');
    // console.log(' - SET_RESTAURANT_CONTACTS ');
    // console.log(' - action.restaurantContacts - ');
    // console.table(action.restaurantContacts);
    return {
      ...state,
      restaurantContacts: action.restaurantContacts,
    };
  }

  if (action.type === 'RESET_ORDER') {
    return defaultOrderState;
  }

  // **
  // Start of Cart Related Code.

  if (action.type === 'ADD_CART_ITEM') {
    if (runConsoleLogCalls) {
      console.log(' -- ADD_CART_ITEM -- ');
    }

    if (action.item.orderItemUuid === null) {
      console.log('OrderProvider - ADD_CART_ITEM - no orderItemUuid');
      return;
    }

    const existingCartItemIndex = state.items.findIndex(
      (item) => item.orderItemUuid === action.item.orderItemUuid
    );
    const existingCartItem = state.items[existingCartItemIndex];

    let updatedItems;

    if (existingCartItem) {
      const updatedItem = {
        ...existingCartItem,
        quantity: existingCartItem.quantity + action.item.quantity,
      };
      updatedItems = [...state.items];
      updatedItems[existingCartItemIndex] = updatedItem;
    } else {
      updatedItems = state.items.concat(action.item);
    }

    const calculatedSubtotal = calculateOrderSubtotal(updatedItems);
    const formattedSubtotal = formatCurrencyForDisplay(calculatedSubtotal);

    if (runConsoleLogCalls) {
      console.log('calculatedSubtotal = ' + calculatedSubtotal);
      console.log('formattedSubtotal = ' + formattedSubtotal);
    }

    const updatedOrderDetails = {
      ...state.orderDetails,
      subtotal: calculatedSubtotal,
      subtotalFormatted: formattedSubtotal,
    };

    return {
      ...state,
      items: updatedItems,
      orderDetails: updatedOrderDetails,
    };
  }

  if (action.type === 'REMOVE_CART_ITEM') {
    if (runConsoleLogCalls) {
      console.log(' -- REMOVE_CART_ITEM -- ');
    }
    const existingCartItemIndex = state.items.findIndex(
      (item) => item.orderItemUuid === action.orderItemUuid
    );
    const existingCartItem = state.items[existingCartItemIndex];

    let updatedItems;

    if (existingCartItem.quantity === 1 || action.mode === 'all') {
      updatedItems = state.items.filter((item) => item.orderItemUuid !== action.orderItemUuid);
    } else {
      const updatedItem = { ...existingCartItem, quantity: existingCartItem.quantity - 1 };
      updatedItems = [...state.items];
      updatedItems[existingCartItemIndex] = updatedItem;
    }

    const calculatedSubtotal = calculateOrderSubtotal(updatedItems);
    const formattedSubtotal = formatCurrencyForDisplay(calculatedSubtotal);

    if (runConsoleLogCalls) {
      console.log('calculatedSubtotal = ' + calculatedSubtotal);
      console.log('formattedSubtotal = ' + formattedSubtotal);
    }

    const updatedOrderDetails = {
      ...state.orderDetails,
      subtotal: calculatedSubtotal,
      subtotalFormatted: formattedSubtotal,
    };

    return {
      ...state,
      items: updatedItems,
      orderDetails: updatedOrderDetails,
    };
  }

  if (action.type === 'UPDATE_CART_ITEM') {
    if (runConsoleLogCalls) {
      console.log(' -- UPDATE_CART_ITEM -- ');
      console.log('action.item.orderItemUuid = ' + action.item.orderItemUuid);
    }

    const existingCartItemIndex = state.items.findIndex(
      (item) => item.orderItemUuid === action.item.orderItemUuid
    );
    const existingCartItem = state.items[existingCartItemIndex];

    let updatedItems;

    if (existingCartItem) {
      const updatedItem = {
        ...existingCartItem,
        quantity: action.item.quantity,
        specialInstructions: action.item.specialInstructions,
      };
      updatedItems = [...state.items];
      updatedItems[existingCartItemIndex] = updatedItem;
    } else {
      updatedItems = state.items.concat(action.item);
    }

    const calculatedSubtotal = calculateOrderSubtotal(updatedItems);
    const formattedSubtotal = formatCurrencyForDisplay(calculatedSubtotal);

    if (runConsoleLogCalls) {
      console.log('calculatedSubtotal = ' + calculatedSubtotal);
      console.log('formattedSubtotal = ' + formattedSubtotal);
    }

    const updatedOrderDetails = {
      ...state.orderDetails,
      subtotal: calculatedSubtotal,
      subtotalFormatted: formattedSubtotal,
    };

    return {
      ...state,
      items: updatedItems,
      orderDetails: updatedOrderDetails,
    };
  }

  if (action.type === 'EMPTY_CART') {
    if (runConsoleLogCalls) {
      console.log(' -- EMPTY_CART -- ');
    }

    const updatedItems = [];

    const calculatedSubtotal = 0;
    const formattedSubtotal = formatCurrencyForDisplay(calculatedSubtotal);

    if (runConsoleLogCalls) {
      console.log('calculatedSubtotal = ' + calculatedSubtotal);
      console.log('formattedSubtotal = ' + formattedSubtotal);
    }

    const updatedOrderDetails = {
      ...state.orderDetails,
      subtotal: calculatedSubtotal,
      subtotalFormatted: formattedSubtotal,
    };

    return {
      ...state,
      items: updatedItems,
      orderDetails: updatedOrderDetails,
    };
  }

  if (action.type === 'REPLACE_CART') {
    if (runConsoleLogCalls) {
      console.log(' -- REPLACE_CART -- ');
    }

    const calculatedSubtotal = calculateOrderSubtotal(action.items);
    const formattedSubtotal = formatCurrencyForDisplay(calculatedSubtotal);

    if (runConsoleLogCalls) {
      console.log('calculatedSubtotal = ' + calculatedSubtotal);
      console.log('formattedSubtotal = ' + formattedSubtotal);
    }

    const updatedOrderDetails = {
      ...state.orderDetails,
      subtotal: calculatedSubtotal,
      subtotalFormatted: formattedSubtotal,
    };

    return {
      ...state,
      items: action.items,
      orderDetails: updatedOrderDetails,
    };
  }

  // End of Cart Related Code.
  // **

  return defaultOrderState;
};

const OrderProvider = (props) => {
  const [orderState, dispatchOrderAction] = useReducer(orderReducer, defaultOrderState);

  const setOrderModeHandler = (orderMode) => {
    dispatchOrderAction({ type: 'SET_ORDER_MODE', orderMode: orderMode });
  };

  const setIsReorderHandler = (isReorder) => {
    dispatchOrderAction({ type: 'SET_IS_REORDER', isReorder: isReorder });
  };

  const setOrderDetailsHandler = (orderDetails) => {
    dispatchOrderAction({ type: 'SET_ORDER_DETAILS', orderDetails: orderDetails });
  };

  const setOrderContactsHandler = (orderContacts) => {
    dispatchOrderAction({ type: 'SET_ORDER_CONTACTS', orderContacts: orderContacts });
  };

  const setDeliveryLocationHandler = (deliveryLocation) => {
    dispatchOrderAction({ type: 'SET_DELIVERY_LOCATION', deliveryLocation: deliveryLocation });
  };

  const setRestaurantHandler = (restaurant) => {
    dispatchOrderAction({ type: 'SET_RESTAURANT', restaurant: restaurant });
  };

  const setRestaurantContactsHandler = (restaurantContacts) => {
    dispatchOrderAction({
      type: 'SET_RESTAURANT_CONTACTS',
      restaurantContacts: restaurantContacts,
    });
  };

  const resetOrderHandler = () => {
    dispatchOrderAction({ type: 'RESET_ORDER' });
  };

  const setOrderMessagesHandler = (orderMessages) => {
    dispatchOrderAction({ type: 'SET_ORDER_MESSAGES', orderMessages: orderMessages });
  };

  // **
  // Start of Cart Related Code.

  const addOrderItemHandler = (item) => {
    dispatchOrderAction({
      type: 'ADD_CART_ITEM',
      item: item,
    });
  };

  const removeOrderItemHandler = (orderItemUuid) => {
    console.log('removeOrderItemHandler');
    console.log('orderItemUuid = ' + orderItemUuid);

    dispatchOrderAction({
      type: 'REMOVE_CART_ITEM',
      orderItemUuid: orderItemUuid,
      mode: 'item',
    });
  };

  const updateOrderItemHandler = (item) => {
    dispatchOrderAction({
      type: 'UPDATE_CART_ITEM',
      item: item,
    });
  };

  const deleteOrderItemHandler = (orderItemUuid) => {
    // console.log('deleteOrderItemHandler');
    // console.log('orderItemUuid = ' + orderItemUuid);

    dispatchOrderAction({
      type: 'REMOVE_CART_ITEM',
      orderItemUuid: orderItemUuid,
      mode: 'all',
    });
  };

  const emptyOrderItemsHandler = () => {
    dispatchOrderAction({ type: 'EMPTY_CART' });
  };

  const replaceOrderItemsHandler = (items) => {
    dispatchOrderAction({
      type: 'REPLACE_CART',
      items: items,
    });
  };

  // End of Cart Related Code.
  // **

  const orderContext = {
    orderMode: orderState.orderMode,
    isReorder: orderState.isReorder,
    // orderUuid: orderState.orderUuid,
    // orderNumber: orderState.orderNumber,
    // restaurantUuid: orderState.restaurantUuid,
    // restaurantName: orderState.restaurantName,
    // deliveryLocationUuid: orderState.deliveryLocationUuid,
    // deliveryLocationName: orderState.deliveryLocationName,
    // deliveryCity: orderState.deliveryCity,
    // deliveryState: orderState.deliveryState,
    // deliveryZipcode: orderState.deliveryZipcode,
    // deliveryDate: orderState.deliveryDate,
    // deliveryTime: orderState.deliveryTime,
    // headcount: orderState.headcount,
    // status: orderState.status,
    //
    orderDetails: orderState.orderDetails,
    orderContacts: orderState.orderContacts,
    deliveryLocation: orderState.deliveryLocation,
    restaurant: orderState.restaurant,
    restaurantContacts: orderState.restaurantContacts,
    items: orderState.items,
    totalAmount: orderState.totalAmount,
    subtotal: orderState.subtotal,
    tax: orderState.tax,
    total: orderState.total,
    taxRate: orderState.taxRate,
    //
    setOrderMode: setOrderModeHandler,
    setIsReorder: setIsReorderHandler,
    setOrderDetails: setOrderDetailsHandler,
    setOrderContacts: setOrderContactsHandler,
    setDeliveryLocation: setDeliveryLocationHandler,
    setRestaurant: setRestaurantHandler,
    setRestaurantContacts: setRestaurantContactsHandler,
    resetOrder: resetOrderHandler,
    //
    addOrderItem: addOrderItemHandler,
    removeOrderItem: removeOrderItemHandler,
    updateOrderItem: updateOrderItemHandler,
    deleteOrderItem: deleteOrderItemHandler,
    clearOrderItems: emptyOrderItemsHandler,
    replaceOrderItems: replaceOrderItemsHandler,
    //
    orderMessages: orderState.orderMessages,
    setOrderMessages: setOrderMessagesHandler,
  };

  return <OrderContext.Provider value={orderContext}>{props.children}</OrderContext.Provider>;
};

export default OrderProvider;
