import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Collapse, Typography } from '@mui/material';

import {
  displayHelpPhoneOrEmail,
  helpPhoneNumber,
  helpEmailAddress,
  teamChargeLabel,
  deliveryFeeLabel,
  subtotalCartItemsLabel,
} from '../../constants/global';

import AuthContext from '../../store/auth-context';
import OrderContext from '../../store/order-context';
import Notification from '../MUI/UI/Notification';
import Popup from '../MUI/UI/Popup';
import CheckoutCartItem from './CheckoutCartItem';
import MenuItemDetails from './MenuItemDetails';
import OrderDetails from './OrderDetails';
import DeliveryLocationDetails from './DeliveryLocationDetails';
import RestaurantDetails from './RestaurantDetails';
import SalesTaxMessage from '../Widgets/SalesTaxMessage';
import classes from '../CSS/CheckoutCart.module.css';

const CheckoutCart = () => {
  // console.log('######## CheckoutCart ########');
  const authCtx = useContext(AuthContext);
  const orderCtx = useContext(OrderContext);

  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [showOrderDetailsLabel, setShowOrderDetailsLabel] = useState('Show Order Details');
  const [selectedMenuItem, setSelectedMenuItem] = useState({});

  const history = useHistory();

  // console.log('orderCtx.orderDetails');
  // console.log(orderCtx.orderDetails);
  // console.log('orderCtx.items');
  // console.log(orderCtx.items);

  const editCartItemHandler = (item) => {
    showMenuItemDetailsHandler(item);
  };

  const deleteCartItemHandler = (orderItemUuid) => {
    const deleteData = async () => {
      const response = await fetch(authCtx.baseApiUrl + 'orderitems/' + orderItemUuid, {
        method: 'DELETE',
        headers: {
          Authorization: authCtx.token,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);
      } else {
        // setIsError(true);
        console.log(' ******** ERROR - deleting an Order Item - ERROR ******** ');
      }
      // setIsLoading(false);
    };

    deleteData().catch(console.error);

    orderCtx.deleteOrderItem(orderItemUuid);
  };

  const backToMenuHandler = () => {
    // console.log('### backToMenuHandler ### ');
    orderCtx.setOrderMode('');
    history.replace('/order/restaurant-menu');
  };

  const editOrderHandler = () => {
    orderCtx.setOrderMode('');
    history.replace('/order/select-restaurant');
  };

  const showMenuItemDetailsHandler = (menuItem) => {
    setSelectedMenuItem(menuItem);
    setOpenPopup(true);
  };

  const toggleShowOrderDetails = () => {
    setShowOrderDetails((current) => !current);
    const newLabel =
      showOrderDetailsLabel === 'Show Order Details' ? 'Hide Order Details' : 'Show Order Details';
    setShowOrderDetailsLabel(newLabel);
  };

  const cartItems = orderCtx.items.map((item, index) => (
    <CheckoutCartItem
      key={index}
      item={item}
      name={item.name}
      onEditCartItem={editCartItemHandler.bind(null, item)}
      onDeleteCartItem={deleteCartItemHandler.bind(null, item.orderItemUuid)}
    />
  ));

  return (
    <Box>
      <Box>
        <Typography variant='h2' component='h2' align='left'>
          Cart
        </Typography>
      </Box>

      <Box className={classes['cart-items-holder']} sx={{ pt: '2rem' }}>
        {cartItems}
      </Box>

      <Box className={classes['total']}>
        <table>
          <tbody>
            <tr>
              <td className={classes['label-smaller']}>{subtotalCartItemsLabel}</td>
              <td className={classes['amount-smaller']}>
                {orderCtx.orderDetails.subtotalFormatted}
              </td>
            </tr>
            <tr>
              <td className={classes['label-smaller']}>{teamChargeLabel}</td>
              <td className={classes['amount-smaller']}>
                {orderCtx.orderDetails.teamChargeFormatted}
              </td>
            </tr>
            <tr>
              <td className={classes['label-smaller']}>{deliveryFeeLabel}</td>
              <td className={classes['amount-smaller']}>
                {orderCtx.orderDetails.deliveryFeeFormatted}
              </td>
            </tr>
            <tr>
              <td className={classes['label-smaller']}>Gratuity (for the Driver)</td>
              <td className={classes['amount-smaller']}>{orderCtx.orderDetails.tipFormatted}</td>
            </tr>

            {authCtx.displayTaxInfo ? (
              <tr>
                <td className={classes['label-smaller']}>Tax</td>
                <td className={classes['amount-smaller']}>{orderCtx.orderDetails.taxFormatted}</td>
              </tr>
            ) : null}

            <tr>
              <td colSpan='2' className='py-20'>
                <hr />
              </td>
            </tr>
            <tr>
              <td className={classes['label-smaller']}>Total</td>
              <td className={classes['amount-smaller']}>
                {orderCtx.orderDetails.calculatedTotalFormatted}
              </td>
            </tr>
          </tbody>
        </table>
      </Box>

      <SalesTaxMessage />

      {orderCtx.orderDetails.status &&
      (orderCtx.orderDetails.status === 'Draft' || orderCtx.orderDetails.status === 'Placed') ? (
        <Box className='button-holder-no-pb'>
          <Button variant='contained' color='accent' size='custom' onClick={backToMenuHandler}>
            Back to Menu
          </Button>
          <Button variant='contained' color='accent' size='custom' onClick={editOrderHandler}>
            Edit Order
          </Button>
        </Box>
      ) : null}

      <Box
        align='left'
        sx={{
          pt: '20px',
        }}
      >
        <Button
          variant='text'
          color='accent'
          onClick={toggleShowOrderDetails}
          className='text-button'
          sx={{ px: '0px' }}
        >
          {showOrderDetailsLabel}
        </Button>
      </Box>

      <Collapse in={showOrderDetails}>
        <Box align='left' sx={{ py: '20px' }}>
          <OrderDetails
            orderDetails={orderCtx.orderDetails}
            displayDeliveryAddress={orderCtx.deliveryLocation.city ? false : true}
          />
          <DeliveryLocationDetails deliveryLocation={orderCtx.deliveryLocation} />
          <RestaurantDetails restaurant={orderCtx.restaurant} />
        </Box>
      </Collapse>

      {displayHelpPhoneOrEmail && (helpPhoneNumber || helpEmailAddress) ? (
        <Box align='left' className={classes['cart-info-area']}>
          <Typography variant='h3' component='h3' sx={{ pb: '20px' }}>
            Need Help?
          </Typography>
          {(displayHelpPhoneOrEmail === 'Phone' || displayHelpPhoneOrEmail === 'Both') &&
          helpPhoneNumber ? (
            <Typography>Call: {helpPhoneNumber}</Typography>
          ) : null}
          {(displayHelpPhoneOrEmail === 'Email' || displayHelpPhoneOrEmail === 'Both') &&
          helpEmailAddress ? (
            <Typography>{helpEmailAddress}</Typography>
          ) : null}
        </Box>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title={selectedMenuItem.name}
        maxWidth='sm'
      >
        <MenuItemDetails
          selectedMenuItem={selectedMenuItem}
          setNotify={setNotify}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
    </Box>
  );
};

export default CheckoutCart;
