export const useCommonFunctions = () => {
  //
  // Add items to the array if they don't exist or remove them if they do.
  const addOrRemove = (array, item, action = 'check') => {
    const exists = array.includes(item);

    // console.log(
    //   '--- useCommonFunctions --- addOrRemove --- action = ' +
    //     action +
    //     ' --- exists = ' +
    //     exists +
    //     ' ---'
    // );

    if (exists && action !== 'add') {
      return array.filter((c) => {
        return c !== item;
      });
    } else {
      if (action !== 'remove') {
        const result = array;
        result.push(item);
        return result;
      } else {
        return array;
      }
    }
  };

  return { addOrRemove };
};
