import { Fragment, useState, useRef, useEffect, useContext } from 'react';
// import { useHistory } from 'react-router-dom';

import AuthContext from '../../store/auth-context';
import * as Constants from '../../helpers/Constants';
import classes from '../CSS/standard-form.module.css';

import GlobalDataContext from '../../store/global-data-context';

const GlobalMenuItemCategoryForm = (props) => {
  const authCtx = useContext(AuthContext);
  const globalDataCtx = useContext(GlobalDataContext);

  // const dashboardUrl = authCtx.dashboardUrl;

  const [recordDetails, setRecordDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(null);
  const [isError, setIsError] = useState(false);

  const nameRef = useRef();
  const descriptionRef = useRef();
  const sortOrderRef = useRef();
  const statusRef = useRef();

  let uuid = props.uuid;

  let textFormHeader = '';
  let textFormButton = '';
  let textFailedApiCallMessage = '';

  if (uuid !== '' && uuid !== undefined) {
    textFormHeader = 'Update Global Menu Item Category';
    textFormButton = 'Update Global Menu Item Category';
    textFailedApiCallMessage = 'Failed to update Global Menu Item Category';
  } else {
    textFormHeader = 'Add Global Menu Item Category';
    textFormButton = 'Add Global Menu Item Category';
    textFailedApiCallMessage = 'Failed to add the Global Menu Item Category';
  }

  useEffect(() => {
    nameRef.current.focus();

    // if (!recordDetails) {
    // const defaultDetails = {
    //   uuid: '',
    //   name: '',
    //   description: '',
    //   sortOrder: 1,
    //   status: '',
    // };
    // setRecordDetails(defaultDetails);
    // console.log(' -- setRecordDetails - with default values -- ');
    // }
  }, []);

  useEffect(() => {
    if (uuid !== '' && uuid !== undefined) {
      setIsLoading(true);
      const fetchData = async () => {
        const response = await fetch(authCtx.baseApiUrl + 'globalmenuitemcategories/' + uuid, {
          headers: {
            Authorization: authCtx.token,
          },
        });

        if (response.ok) {
          const responseData = await response.json();
          // console.log(responseData);

          const returnedRecords = {
            uuid: responseData.data.global_menu_item_categories[0].globalMenuItemCategoryUuid,
            name: responseData.data.global_menu_item_categories[0].name,
            description: responseData.data.global_menu_item_categories[0].description,
            sortOrder: responseData.data.global_menu_item_categories[0].sortOrder,
            status: responseData.data.global_menu_item_categories[0].status,
          };
          // console.log(' -- returnedRecords -- ');
          // console.log(returnedRecords);

          setRecordDetails(returnedRecords);

          statusRef.current.value = returnedRecords.status;
        } else {
          setIsError(true);
        }
        setIsLoading(false);
      };

      fetchData().catch(console.error);
    } else {
      const defaultDetails = {
        uuid: '',
        name: '',
        description: '',
        sortOrder: 1,
        status: '',
      };
      setRecordDetails(defaultDetails);
    }
  }, [authCtx.baseApiUrl, authCtx.token, uuid]);

  const submitHandler = (event) => {
    event.preventDefault();

    setDisplayMessage(null);
    setIsLoading(true);
    // setIsError(false);

    const enteredName = nameRef.current.value;
    const enteredDescription = descriptionRef.current.value;
    const enteredSortOrder = sortOrderRef.current.value;
    const enteredStatus = statusRef.current ? statusRef.current.value : 'Active';

    // Todo:  Add Validation.

    let apiURL = '';
    let apiMethod = '';

    if (uuid !== '' && uuid !== undefined) {
      apiURL = authCtx.baseApiUrl + 'globalmenuitemcategories/' + uuid;
      apiMethod = 'PATCH';
    } else {
      apiURL = authCtx.baseApiUrl + 'globalmenuitemcategories';
      apiMethod = 'POST';
    }

    // console.log(' - apiURL = ' + apiURL);
    // console.log(' - apiMethod = ' + apiMethod);
    // console.log(' - enteredStatus = ' + enteredStatus);

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        name: enteredName,
        description: enteredDescription,
        sort_order: enteredSortOrder,
        status: enteredStatus,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      setIsLoading(false);

      if (res.ok) {
        nameRef.current.value = '';
        descriptionRef.current.value = '';
        sortOrderRef.current.value = '';
        if (statusRef.current) {
          statusRef.current.value = '';
        }

        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          // Clear the Global Menu Item Categories so they will get loaded again.
          globalDataCtx.setGlobalMenuItemCategories([]);

          props.onCancel();
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          // console.log(responseData);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            setDisplayMessage(responseData.messages);
          } else {
            setDisplayMessage([[textFailedApiCallMessage]]);
          }
        });
      }
    });
  };

  const displayStatusFormSelect = () => {
    // console.log(' -- displayStatusFormSelect -- recordDetails.status = ' + recordDetails.status);
    return (
      <select id='status' defaultValue={recordDetails.status} ref={statusRef}>
        {Constants.STATUS_ARRAY.map((status, index) => (
          <option key={index} value={status}>
            {status}
          </option>
        ))}
      </select>
    );
  };

  return (
    <Fragment>
      <section className={classes['form-container']}>
        <h1>{textFormHeader}</h1>
        <form onSubmit={submitHandler}>
          <div className={classes.control}>
            <label htmlFor='name'>Name</label>
            <input
              type='text'
              id='name'
              defaultValue={recordDetails.name}
              ref={nameRef}
              required
              maxLength='50'
            />
          </div>
          <div className={classes.control}>
            <label htmlFor='description'>Description</label>
            <textarea
              id='description'
              defaultValue={recordDetails.description}
              ref={descriptionRef}
            />
          </div>

          <div className={classes.control}>
            <label htmlFor='sort_order'>Sort Order</label>
            <input
              id='sort_order'
              type='number'
              min='1'
              max='100'
              defaultValue={recordDetails.sortOrder}
              ref={sortOrderRef}
            />
          </div>
          {uuid !== '' && uuid !== undefined && (
            <div className={classes.control}>
              <label htmlFor='status'>Status</label>
              {displayStatusFormSelect()}
            </div>
          )}
          <div className={classes.actions}>
            <button>{textFormButton}</button>
            <button onClick={props.onCancel} className={classes['secondary']}>
              Cancel
            </button>
          </div>
        </form>
      </section>

      {displayMessage && (
        <div className='messages'>
          {displayMessage.map((message, index) => (
            <p className={classes.messages__error} key={index}>
              {message}
            </p>
          ))}
        </div>
      )}

      {isLoading && <div className='messages'>Sending Request...</div>}
      {isError && (
        <div className={classes['message-holder']}>
          <p className={classes['error']}>Error...</p>
        </div>
      )}
    </Fragment>
  );
};

export default GlobalMenuItemCategoryForm;
