import React, { useRef, useState, useEffect } from 'react';
import { Box, Button, Collapse, IconButton, TextField, Typography } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import classes from '../CSS/MenuItemForm.module.css';

const MenuItemForm = (props) => {
  const [showSpecialInstructions, setShowSpecialInstructions] = useState(false);

  const quantityRef = useRef();
  const specialInstructionsRef = useRef();

  const { orderItemUuid, selectedMenuItem, setNotify, onAddToCart, onUpdateCart, onDeleteItem } =
    props;

  // console.log('CHECKOUT - selectedMenuItem');
  // console.log(selectedMenuItem);

  const setSpecialInstructionsFocus = () => {
    specialInstructionsRef.current.focus();
  };

  const toggleShowSpecialInstructions = () => {
    if (!showSpecialInstructions) {
      setTimeout(setSpecialInstructionsFocus, 200);
    }
    setShowSpecialInstructions((current) => !current);
    specialInstructionsRef.current.focus();
  };

  // console.log('selectedMenuItem');
  // console.log(selectedMenuItem);

  const quantityOrdered = selectedMenuItem.quantity ? selectedMenuItem.quantity : 1;
  const specialInstructions = selectedMenuItem.specialInstructions
    ? selectedMenuItem.specialInstructions
    : '';
  const buttonText = orderItemUuid ? 'Update Item' : 'Add to Cart';

  useEffect(() => {
    if (specialInstructions) {
      setShowSpecialInstructions(true);
    }
  }, [specialInstructions, setShowSpecialInstructions]);

  const deleteCartItem = () => {
    // console.log(' deleteCartItem ');
    // console.log(' orderItemUuid = ' + orderItemUuid);
    onDeleteItem(orderItemUuid);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    const enteredQuantity = quantityRef.current.value;
    const enteredQuantityNumber = +enteredQuantity;
    const specialInstructions = specialInstructionsRef.current.value;

    // console.log('enteredQuantityNumber = ' + enteredQuantityNumber);

    if (
      enteredQuantity.trim().length === 0 ||
      enteredQuantityNumber < 1 ||
      enteredQuantityNumber > 10000
    ) {
      // setFormIsValid(false);
      setNotify({
        isOpen: true,
        message: 'Please enter a valid Quantity (1 - 10,000)',
        type: 'warning',
      });
      return;
    }
    // props.setNotify({
    //   isOpen: true,
    //   message: 'Quantity entered = ' + enteredQuantity,
    //   type: 'success',
    // });
    // console.log(' - enteredQuantity = ' + enteredQuantity);

    // console.log(' - MenuItemForm - submitHandler - orderItemUuid = ' + orderItemUuid + ' - ');

    // Todo:  Was using selectedMenuItem.menuItemUuid but changed to selectedMenuItem.orderItemUuid
    if (orderItemUuid) {
      onUpdateCart(selectedMenuItem.orderItemUuid, enteredQuantityNumber, specialInstructions);
    } else {
      onAddToCart(selectedMenuItem.orderItemUuid, enteredQuantityNumber, specialInstructions);
    }
  };

  return (
    <Box component='form' onSubmit={submitHandler} className={classes['add-to-cart-form']}>
      {/* <Typography>orderItemUuid = {orderItemUuid}</Typography> */}
      {/* <Typography>quantityOrdered = {quantityOrdered}</Typography> */}
      {/* <Typography>specialInstructions = {specialInstructions}</Typography> */}

      <Typography>Enter Quantity</Typography>
      <TextField
        id={selectedMenuItem.orderItemUuid}
        size='small'
        defaultValue={quantityOrdered}
        type='number'
        min='1'
        max='10000'
        step='1'
        sx={{ width: '10rem' }}
        inputRef={quantityRef}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right', pb: 2 }}>
        <Button variant='text' onClick={toggleShowSpecialInstructions} className='text-button'>
          Meal Selection / Special Instructions
        </Button>
      </Box>
      <Collapse in={showSpecialInstructions}>
        <TextField
          multiline
          minRows={3}
          maxRows={12}
          fullWidth={true}
          placeholder='Please enter your meal selections and any other special instructions for this menu item here.'
          defaultValue={specialInstructions}
          inputRef={specialInstructionsRef}
          sx={{ pb: '2rem' }}
        />
      </Collapse>
      <Box component='div' className={classes['button-holder']}>
        {orderItemUuid ? (
          <IconButton aria-label='delete' onClick={deleteCartItem}>
            <DeleteForeverIcon color='error' />
          </IconButton>
        ) : null}
        <Button variant='contained' color='primary' type='submit' className={classes['button']}>
          {/* <AddIcon className={classes['button-icon']} /> */}
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};

export default MenuItemForm;
