import React, { useContext, useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import Notification from '../MUI/UI/Notification';
import StandardPageWrapper from '../Layout/StandardPageWrapper';

import PaymentAccountForm from './PaymentAccountForm';
import PaymentAccountFormAdmin from './PaymentAccountFormAdmin';

const PaymentAccount = () => {
  const authCtx = useContext(AuthContext);
  const userRole = authCtx.userRole;

  const restaurantUuid = authCtx.selectedRestaurantUuid;
  // console.log('restaurantUuid = ' + restaurantUuid);

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [restaurantDetails, setRestaurantDetails] = useState([]);
  const [paymentAccountLink, setPaymentAccountLink] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const response = await fetch(authCtx.baseApiUrl + 'restaurants/' + restaurantUuid, {
        headers: {
          Authorization: authCtx.token,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log('responseData', responseData);

        const restaurant = responseData.data.restaurants[0];

        const returnedRestaurantDetails = {
          restaurantUuid: restaurant.restaurantUuid,
          name: restaurant.name,
          email: restaurant.email,
          phone: restaurant.phone,
          status: restaurant.status,
          paymentAccountStatus: restaurant.paymentAccountStatus,
          paymentAccountId: restaurant.paymentAccountId,
          paymentTaxRateId: restaurant.paymentTaxRateId,
        };
        // console.log(' -- returnedRestaurantDetails -- ');
        // console.log(returnedRestaurantDetails);

        setRestaurantDetails(returnedRestaurantDetails);
      } else {
        setIsError(true);
      }
      // setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, restaurantUuid]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const response = await fetch(authCtx.baseApiUrl + 'paymentaccount/' + restaurantUuid, {
        headers: {
          Authorization: authCtx.token,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log('responseData', responseData);

        const accountLink = responseData.data.stripe_login_link_url
          ? responseData.data.stripe_login_link_url
          : '';

        setPaymentAccountLink(accountLink);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, restaurantUuid]);

  return (
    <StandardPageWrapper title='Payment Account'>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {!isLoading && !isError ? (
        <Paper elevation={4} className='p-20'>
          {restaurantDetails ? (
            <Box className='pb-20'>
              {userRole === 'Admin' ? (
                <PaymentAccountFormAdmin pRestaurantDetails={restaurantDetails} />
              ) : null}

              {userRole === 'Vendor' && restaurantDetails.paymentAccountStatus !== 'Active' ? (
                <PaymentAccountForm restaurantDetails={restaurantDetails} />
              ) : null}

              {userRole === 'Vendor' && restaurantDetails.paymentAccountStatus === 'Active' ? (
                <Typography align='center' variant='h6'>
                  Your Stripe Payment Account is Active
                </Typography>
              ) : null}

              {paymentAccountLink ? (
                <Box className='pt-20'>
                  <Box align='center'>
                    <a href={paymentAccountLink} target='_blank' rel='noreferrer'>
                      Your Stripe Dashboard
                    </a>
                  </Box>
                </Box>
              ) : null}
            </Box>
          ) : null}
        </Paper>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
    </StandardPageWrapper>
  );
};

export default PaymentAccount;
