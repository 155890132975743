import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

import classes from '../CSS/OrderMenuItemCard.module.css';

const OrderMenuItemCard = (props) => {
  // console.log(' ######## OrderMenuItemCard ######## ');
  const { menuItem, onShowDetails } = props;

  const displayFeeds = true;
  const displayServingSize = true;

  // console.log('menuItem');
  // console.log(menuItem);

  // const menuItemImage = uploadsFolder + '/' + menuItem.image;

  // console.log('menuItemImage');
  // console.log(menuItemImage);

  const onMenuItemSelected = () => {
    onShowDetails(menuItem);
  };

  return (
    <Paper
      elevation={12}
      component='div'
      key={menuItem.menuItemUuid}
      className={classes['menu-item-card']}
      onClick={onMenuItemSelected}
    >
      <Box component='div' className={classes['content']}>
        {menuItem.image ? (
          <Box className={classes['menu-item-image-holder']}>
            <img src={menuItem.image} alt={menuItem.name} />
          </Box>
        ) : null}

        <Typography variant='h7' component='h5'>
          {menuItem.name}
        </Typography>

        {menuItem.description ? (
          <Typography variant='bodyContent' component='p'>
            {menuItem.description}
          </Typography>
        ) : null}

        <Box>
          <Typography className={classes['price']} sx={{ display: 'inline-block' }}>
            {menuItem.priceFormatted}
          </Typography>

          {displayFeeds || (displayServingSize && menuItem.servingSize) ? (
            <Typography variant='caption' component='span' sx={{ pl: '10px' }}>
              ({menuItem.feeds ? 'Feeds ' + menuItem.feeds : null}
              {displayFeeds && displayServingSize && menuItem.servingSize ? ' - ' : null}
              {menuItem.servingSize ? menuItem.servingSize : null})
            </Typography>
          ) : null}

          {/* {displayFeeds || (displayServingSize && menuItem.servingSize) ? (
            <Typography variant='caption' component='span' sx={{ pl: '10px' }}>
              ({menuItem.servingSize ? menuItem.servingSize : null}
              {displayFeeds && displayServingSize && menuItem.servingSize ? ' - ' : null}
              {menuItem.feeds ? 'Feeds ' + menuItem.feeds : null})
            </Typography>
          ) : null} */}

          {/* {displayFeeds ? (
            <Typography variant='body1' component='span' sx={{ pl: '2rem' }}>
              (Feeds {menuItem.feeds})
            </Typography>
          ) : null}

          {displayServingSize && menuItem.servingSize ? (
            <Typography variant='caption' component='span' sx={{ pl: '10px' }}>
              ({menuItem.servingSize})
            </Typography>
          ) : null} */}
        </Box>
        {/* <Typography>{menuItem.priceDetails}</Typography> */}
        <Typography>{menuItem.dietaryOptions}</Typography>
      </Box>
    </Paper>
  );
};

export default OrderMenuItemCard;
