import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import WorkflowMessage from '../UI/WorkflowMessage';
import DeliveryLocationForm from './DeliveryLocationForm';

const DeliveryLocationEdit = () => {
  const authCtx = useContext(AuthContext);

  const dashboardUrl = authCtx.dashboardUrl;

  const [deliveryLocationDetails, setDeliveryLocationDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const history = useHistory();
  const params = useParams();
  const deliveryLocationUuid = params.deliveryLocationUuid;

  // console.log('deliveryLocationUuid');
  // console.log(deliveryLocationUuid);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetch(
        authCtx.baseApiUrl + 'deliverylocations/' + deliveryLocationUuid,
        {
          headers: {
            Authorization: authCtx.token,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        // console.log(responseData);

        const returnedLocationDetails = {
          deliveryLocationUuid: responseData.data.delivery_locations[0].deliveryLocationUuid,
          name: responseData.data.delivery_locations[0].name,
          description: responseData.data.delivery_locations[0].description,
          leagues: responseData.data.delivery_locations[0].leagues,
          address1: responseData.data.delivery_locations[0].address1,
          address2: responseData.data.delivery_locations[0].address2,
          city: responseData.data.delivery_locations[0].city,
          state: responseData.data.delivery_locations[0].state,
          zipcode: responseData.data.delivery_locations[0].zipcode,
          email: responseData.data.delivery_locations[0].email,
          phone: responseData.data.delivery_locations[0].phone,
          deliveryInstructions: responseData.data.delivery_locations[0].deliveryInstructions,
          status: responseData.data.delivery_locations[0].status,
        };
        // console.log(' -- returnedLocationDetails -- ');
        // console.log(returnedLocationDetails);

        setDeliveryLocationDetails(returnedLocationDetails);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    // call the function
    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, deliveryLocationUuid]);

  const addNewHandler = () => {
    history.push(dashboardUrl + 'delivery-locations/add');
  };

  const detailsHandler = () => {
    history.push(dashboardUrl + 'delivery-locations/details/' + deliveryLocationUuid);
  };

  const backToListHandler = () => {
    history.push(dashboardUrl + 'delivery-locations');
  };

  return (
    <StandardPageWrapper>
      <div className='content-container'>
        {isLoading ? <WorkflowMessage type='loading' /> : null}
        {isError ? <WorkflowMessage type='error' /> : null}

        {!isLoading && !isError ? (
          <Box className='title-and-button-holder'>
            <Typography variant='h1' component='h1'>
              Edit Delivery Location
            </Typography>
            <Box className='button-holder-no-pb'>
              <Button variant='contained' size='custom' color='accent' onClick={addNewHandler}>
                Add New
              </Button>
              <Button variant='contained' size='custom' color='accent' onClick={detailsHandler}>
                Details
              </Button>
              <Button variant='contained' size='custom' color='accent' onClick={backToListHandler}>
                Delivery Locations
              </Button>
            </Box>
          </Box>
        ) : null}
        {!isLoading && !isError ? (
          <DeliveryLocationForm
            deliveryLocationUuid={deliveryLocationUuid}
            deliveryLocationDetails={deliveryLocationDetails}
            onCancel={backToListHandler}
          />
        ) : null}
      </div>
    </StandardPageWrapper>
  );
};

export default DeliveryLocationEdit;
