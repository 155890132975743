import React from 'react';
import { Typography } from '@mui/material';

import StandardPageWrapper from '../Layout/StandardPageWrapper';

const Receipts = () => {
  return (
    <StandardPageWrapper title='Receipts'>
      <Typography variant='h6' align='center' sx={{ py: '2rem' }}>
        Coming Soon...
      </Typography>
    </StandardPageWrapper>
  );
};

export default Receipts;
