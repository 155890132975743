import { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import { paymentNotConfirmedStatusValue } from '../../constants/global';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import Notification from '../MUI/UI/Notification';
import StandardPageWrapper from '../Layout/StandardPageWrapper';

const CheckoutSuccessful = () => {
  console.log('######## CheckoutSuccessful ########');
  const params = useParams();
  const orderUuid = params.orderUuid;

  const history = useHistory();

  const authCtx = useContext(AuthContext);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const gotoOrdersPage = () => {
    history.replace('/team-dashboard/orders/' + orderUuid);
  };

  // Commenting this out for now.
  // TODO:  Cleanup this code at some point.
  // useEffect(() => {
  //   // console.log(' ### useEffect - OrderDetails - authCtx.resetLogoutTimer ### ');
  //   authCtx.resetLogoutTimer();

  //   setIsLoading(false);
  //   setIsError(false);
  // }, [authCtx]);

  const updateOrderStatus = async (status) => {
    // console.log('updateOrderStatusValues - orderUuid = ' + orderUuid + ' - status = ' + status);

    // setIsLoading(true);

    // const apiURL = authCtx.baseApiUrl + 'orders/' + orderUuid;
    const apiURL = authCtx.baseApiUrl + 'orders/updatestatus/' + orderUuid;
    const apiMethod = 'PATCH';

    const response = await fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        status: status,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseData = await response.json();
      console.log(responseData);
    } else {
      setIsError(true);
      console.log(' ** ERROR ** ');
      setNotify({
        isOpen: true,
        message: 'An Error occurred while saving Order Information.  Please try again.',
        type: 'error',
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    updateOrderStatus(paymentNotConfirmedStatusValue);
  });

  return (
    <StandardPageWrapper title='Checkout Successful' usePaper='true'>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      <Box className='content-centered'>
        <Box>
          <Typography>Your Checkout Session with Stripe was successful.</Typography>
          <Typography>There could be a bit of a delay before your payment is processed.</Typography>
          <Typography>
            Once your payment has been confirmed you will receive an email and your Order will be
            scheduled for Delivery.
          </Typography>
          <Box className='button-holder-center'>
            <Button variant='contained' onClick={gotoOrdersPage}>
              Back to Order
            </Button>
          </Box>
        </Box>
      </Box>

      <Notification notify={notify} setNotify={setNotify} />
    </StandardPageWrapper>
  );
};

export default CheckoutSuccessful;
