import { useState, useRef, useEffect, useContext } from 'react';

import AuthContext from '../../store/auth-context';
import * as Constants from '../../helpers/Constants';
import classes from '../CSS/standard-form.module.css';

const LeagueForm = (props) => {
  const authCtx = useContext(AuthContext);

  // const userRole = authCtx.userRole;

  const [leagueDetails, setLeagueDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(null);
  const [isError, setIsError] = useState(false);

  // const history = useHistory();

  const nameRef = useRef();
  const descriptionRef = useRef();
  const sortOrderRef = useRef();
  const statusRef = useRef();

  const leagueUuid = props.leagueUuid;

  let textFormHeader = '';
  let textFormButton = '';
  let textFailedApiCallMessage = '';

  if (leagueUuid !== '' && leagueUuid !== undefined) {
    textFormHeader = 'Update League';
    textFormButton = 'Update League';
    textFailedApiCallMessage = 'Failed to update the League';
  } else {
    textFormHeader = 'Add League';
    textFormButton = 'Add League';
    textFailedApiCallMessage = 'Failed to add the League';
  }

  useEffect(() => {
    nameRef.current.focus();
  }, []);

  useEffect(() => {
    if (leagueUuid) {
      setIsLoading(true);

      const fetchData = async () => {
        const response = await fetch(authCtx.baseApiUrl + 'globalleagues/' + leagueUuid, {
          headers: {
            Authorization: authCtx.token,
          },
        });

        if (response.ok) {
          const responseData = await response.json();
          // console.log(responseData);

          const returnedLeagueDetails = {
            uuid: responseData.data.global_leagues[0].globalLeagueUuid,
            name: responseData.data.global_leagues[0].name,
            description: responseData.data.global_leagues[0].description,
            sortOrder: responseData.data.global_leagues[0].sortOrder,
            status: responseData.data.global_leagues[0].status,
          };
          // console.log(' -- returnedLeagueDetails -- ');
          // console.log(returnedLeagueDetails);

          setLeagueDetails(returnedLeagueDetails);

          statusRef.current.value = returnedLeagueDetails.status;
        } else {
          setIsError(true);
        }
        setIsLoading(false);
      };

      fetchData().catch(console.error);
    } else {
      const defaultLeagueDetails = {
        uuid: '',
        name: '',
        description: '',
        sortOrder: 1,
        status: '',
      };
      setLeagueDetails(defaultLeagueDetails);
    }
  }, [authCtx.baseApiUrl, authCtx.token, leagueUuid]);

  const submitHandler = (event) => {
    event.preventDefault();

    setDisplayMessage(null);
    setIsLoading(true);
    setIsError(false);

    const enteredName = nameRef.current.value;
    const enteredDescription = descriptionRef.current.value;
    const enteredSortOrder = sortOrderRef.current.value;
    const enteredStatus = statusRef.current ? statusRef.current.value : 'Active';

    // Todo:  Add Validation.

    let apiURL = '';
    let apiMethod = '';

    if (leagueUuid !== '' && leagueUuid !== undefined) {
      apiURL = authCtx.baseApiUrl + 'globalleagues/' + leagueUuid;
      apiMethod = 'PATCH';
    } else {
      apiURL = authCtx.baseApiUrl + 'globalleagues';
      apiMethod = 'POST';
    }

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        name: enteredName,
        description: enteredDescription,
        sort_order: enteredSortOrder,
        status: enteredStatus,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      setIsLoading(false);

      if (res.ok) {
        nameRef.current.value = '';
        descriptionRef.current.value = '';
        sortOrderRef.current.value = '';
        if (statusRef.current) {
          statusRef.current.value = '';
        }

        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          props.onCancel();
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          // console.log(responseData);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            setDisplayMessage(responseData.messages);
          } else {
            setDisplayMessage([[textFailedApiCallMessage]]);
          }
        });
      }
    });
  };

  const displayStatusFormSelect = () => {
    // console.log(' -- displayStatusFormSelect -- ');
    // console.log(' leagueDetails.status = ' + leagueDetails.status);
    return (
      <select id='status' defaultValue={leagueDetails.status} ref={statusRef}>
        {Constants.STATUS_ARRAY.map((status, index) => (
          <option key={index}>{status}</option>
        ))}
      </select>
    );
  };

  return (
    <>
      <section className={classes['form-container']}>
        <h1>{textFormHeader}</h1>
        <form onSubmit={submitHandler}>
          <div className={classes.control}>
            <label htmlFor='name'>Name</label>
            <input
              type='text'
              id='name'
              defaultValue={leagueDetails.name}
              maxLength='50'
              ref={nameRef}
              required
            />
          </div>
          <div className={classes.control}>
            <label htmlFor='description'>Description</label>
            <textarea
              id='description'
              defaultValue={leagueDetails.description}
              ref={descriptionRef}
            />
          </div>
          <div className={classes.control}>
            <label htmlFor='sort_order'>Sort Order</label>
            <input
              id='sort_order'
              type='number'
              min='1'
              max='100'
              defaultValue={leagueDetails.sortOrder}
              ref={sortOrderRef}
            />
          </div>
          <div className={classes.control}>
            <label htmlFor='status'>Status</label>
            {displayStatusFormSelect()}
          </div>

          <div className={classes.actions}>
            <button>{textFormButton}</button>
            <button onClick={props.onCancel} className={classes['secondary']}>
              Cancel
            </button>
          </div>
        </form>
      </section>

      {displayMessage && (
        <div className='messages'>
          {displayMessage.map((message, index) => (
            <p className={classes.messages__error} key={index}>
              {message}
            </p>
          ))}
        </div>
      )}

      {isLoading && <div className='messages'>Sending Request...</div>}

      {isError && (
        <div className='message-holder'>
          <p className='error'>Error...</p>
        </div>
      )}
    </>
  );
};

export default LeagueForm;
