import React from 'react';
import { Box, Typography } from '@mui/material';

const TeamSignupMessage = () => {
  return (
    <Box sx={{ maxWidth: '120rem' }}>
      <Typography variant='h1' component='h1'>
        Get started today by creating a Team Account.
      </Typography>
      <Typography>
        After you create an Account someone will be get in touch with you to get you fully onboarded
        with our Platform.
      </Typography>
    </Box>
  );
};

export default TeamSignupMessage;
