import React from 'react';
import { Box } from '@mui/material';

import StandardPageWrapper from '../components/Layout/StandardPageWrapper';
import ExpenseReportForm from '../components/ExpenseReports/ExpenseReportForm';

const ExpenseReports = () => {
  return (
    <StandardPageWrapper title='Expense Reports'>
      <Box>
        <ExpenseReportForm />
      </Box>
    </StandardPageWrapper>
  );
};

export default ExpenseReports;
