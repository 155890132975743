import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import OrderContext from '../../store/order-context';
import Notification from '../MUI/UI/Notification';
// import DeliveryLocationAutocompleteFormField from '../Order/DeliveryLocationAutocompleteFormField';
import TeamContactAutocompleteFormField from '../TeamContacts/TeamContactAutocompleteFormField';
import classes from '../CSS/standard-form.module.css';

const DeliveryDetailsForm = () => {
  const authCtx = useContext(AuthContext);
  const orderCtx = useContext(OrderContext);
  const history = useHistory();

  const orderUuid = orderCtx.orderDetails.orderUuid;

  if (!orderUuid) {
    history.replace('/team-dashboard/orders');
  }

  const orderLeadTime = parseInt(orderCtx.restaurant.orderLeadTime)
    ? parseInt(orderCtx.restaurant.orderLeadTime)
    : authCtx.defaultOrderLeadTime;

  // console.log('orderLeadTime = ' + orderLeadTime);

  const currentDeliveryDate = orderCtx.orderDetails.deliveryDate;
  const currentDeliveryTime = orderCtx.orderDetails.deliveryTime;
  const currentTip = orderCtx.orderDetails.tip;
  // const currentDeliveryLocation = orderCtx.deliveryLocation.name ? orderCtx.deliveryLocation : {};
  const currentContact = authCtx.userDetails;
  // const userRole = authCtx.userRole;

  // console.log('orderCtx.deliveryLocation.name = ' + orderCtx.deliveryLocation.name);
  // console.log('currentDeliveryLocation');
  // console.log(currentDeliveryLocation);

  currentContact.name = currentContact.firstName + ' ' + currentContact.lastName;

  let currentOrderContacts = orderCtx.orderContacts;

  if (!Array.isArray(currentOrderContacts) || !currentOrderContacts.length > 0) {
    currentOrderContacts = [currentContact];
  }

  // const [deliveryLocations, setDeliveryLocations] = useState([]);
  const [teamContacts, setTeamContacts] = useState([]);
  // const [selectedDeliveryLocation, setSelectedDeliveryLocation] = useState(currentDeliveryLocation);
  const [selectedTeamContacts, setSelectedTeamContacts] = useState(currentOrderContacts);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const deliveryDateRef = useRef();
  const deliveryTimeRef = useRef();
  const tipRef = useRef();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setIsLoading(true);
  //     const response = await fetch(authCtx.baseApiUrl + 'deliverylocations', {
  //       headers: {
  //         Authorization: authCtx.token,
  //         'Content-Type': 'application/json',
  //       },
  //     });

  //     if (response.ok) {
  //       const responseData = await response.json();

  //       const transformedLocations = responseData.data.delivery_locations;
  //       const filteredRecords = transformedLocations.filter((record) => record.status === 'Active');

  //       // console.log('transformedLocations');
  //       // console.log(transformedLocations);

  //       setDeliveryLocations(filteredRecords);
  //     } else {
  //       setIsError(true);
  //     }
  //     setIsLoading(false);
  //   };

  //   fetchData().catch(console.error);
  // }, [authCtx.baseApiUrl, authCtx.token]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'users/include/orginfo', {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(responseData.data.users);

        const transformedUsers = responseData.data.users.map((user) => {
          return {
            userUuid: user.userUuid,
            firstName: user.firstName,
            lastName: user.lastName,
            name: user.firstName + ' ' + user.lastName,
            position: user.position,
            phone: user.phone,
            email: user.email,
            notificationPreference: user.notificationPreference,
            status: user.status,
            roleType: user.roleType,
            orgName: user.orgName ? user.orgName : '',
          };
        });

        setTeamContacts(transformedUsers);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token]);

  const submitHandler = (event) => {
    event.preventDefault();

    const enteredDeliveryDate = deliveryDateRef.current.value;
    const enteredDeliveryTime = deliveryTimeRef.current.value;
    const enteredTip = tipRef.current.value;

    // console.log('enteredTip = ' + enteredTip);

    // Validate Delivery Date is in the Future.
    // const defaultOrderLeadTime = 24; // Padding just to be sure.
    let today = new Date();
    // today.setHours(today.getHours() + orderLeadTime + defaultOrderLeadTime);
    today.setHours(today.getHours() + orderLeadTime);
    const todaysDate = new Date(today.getTime() - today.getTimezoneOffset() * 60000)
      .toISOString()
      .split('T')[0];
    // console.log('enteredDeliveryDate = ' + enteredDeliveryDate);
    // console.log('todaysDate = ' + todaysDate);

    const dcToday = new Date(todaysDate).getTime();
    const dcEnteredDate = new Date(enteredDeliveryDate).getTime();

    if (dcToday > dcEnteredDate) {
      setNotify({
        isOpen: true,
        message:
          'This Vendor has an Order Lead Time of ' +
          orderLeadTime +
          ' Hours.  Please select another Delivery Date by clicking on the Edit Order button below.',
        type: 'warning',
      });

      return;
    }
    // console.log('Date is OK - Order Placed');

    let selectedOrderContactUuids = '';
    selectedTeamContacts.forEach((item) => {
      if (selectedOrderContactUuids === '') {
        selectedOrderContactUuids = item.userUuid;
      } else {
        selectedOrderContactUuids += ', ' + item.userUuid;
      }
    });

    if (!enteredDeliveryDate) {
      setNotify({
        isOpen: true,
        message: 'Please enter/select a Delivery Date.',
        type: 'warning',
      });
      return;
    }

    if (!enteredDeliveryTime) {
      setNotify({
        isOpen: true,
        message: 'Please enter/select a Delivery Time.',
        type: 'warning',
      });
      return;
    }

    if (!Array.isArray(selectedTeamContacts) || !selectedTeamContacts.length > 0) {
      setNotify({
        isOpen: true,
        message: 'Please select at least 1 Order Contact.',
        type: 'warning',
      });
      return;
    }

    // console.log('orderDetails');
    // console.table(orderDetails);
    // console.log('currentDeliveryLocation');
    // console.table(currentDeliveryLocation);
    // console.log('selectedDeliveryLocation');
    // console.table(selectedDeliveryLocation);

    // if ('a' === 'a') {
    //   return;
    // }

    if (
      currentDeliveryDate === enteredDeliveryDate &&
      currentDeliveryTime === enteredDeliveryTime &&
      // currentDeliveryLocation === selectedDeliveryLocation &&
      currentOrderContacts === selectedTeamContacts &&
      currentTip === enteredTip
    ) {
      setNotify({
        isOpen: true,
        message: 'No values have been changed - Noting to Save.',
        type: 'success',
      });
      history.replace('/checkout/delivery-instructions');
      return;
    }

    const saveToDB = true;

    if (!saveToDB) {
      return;
    }

    const apiURL = authCtx.baseApiUrl + 'orders/' + orderUuid;
    const apiMethod = 'PATCH';

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        delivery_date: enteredDeliveryDate,
        delivery_time: enteredDeliveryTime,
        contact_user_uuids: selectedOrderContactUuids,
        tip: enteredTip,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      // setIsLoading(false);

      if (res.ok) {
        deliveryDateRef.current.value = '';
        deliveryTimeRef.current.value = '';
        tipRef.current.value = '';

        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS - submitHandler - ** ');
          // console.log(responseData);

          const recordDetails = responseData.data.orders[0];

          orderCtx.setOrderDetails(recordDetails);

          let transformedContacts = [];
          if (
            Array.isArray(responseData.data.order_contacts) &&
            responseData.data.order_contacts.length > 0
          ) {
            transformedContacts = responseData.data.order_contacts.map((user) => {
              return {
                userUuid: user.userUuid,
                firstName: user.firstName,
                lastName: user.lastName,
                name: user.firstName + ' ' + user.lastName,
                position: user.position,
                phone: user.phone,
                email: user.email,
                notificationPreference: user.notificationPreference,
                status: user.status,
                roleType: user.roleType,
                orgName: user.orgName ? user.orgName : '',
              };
            });
          }

          orderCtx.setOrderContacts(transformedContacts);

          history.replace('/checkout/delivery-instructions');
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          console.log(' ** ERROR ** ');
          console.log(responseData);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            // setDisplayMessage(responseData.messages);
          } else {
            // setDisplayMessage(['Failed to save Order Data.']);
          }
        });
      }
    });
  };

  const setContactsHandler = (event, value) => {
    // console.log('setContactsHandler');
    // console.log('event');
    // console.log(event);
    // console.log('value');
    // console.log(value);

    setSelectedTeamContacts(value);
  };

  // const setDeliveryLocationHandler = (event, value) => {
  //   // console.log('setDeliveryLocationHandler');
  //   // console.log('event');
  //   // console.log(event);
  //   // console.log('value');
  //   // console.log(value);

  //   setSelectedDeliveryLocation(value);
  // };

  return (
    <>
      {isLoading ? (
        <div className={classes['message-holder']}>
          <p className={classes['loading']}>Loading...</p>
        </div>
      ) : null}

      {isError ? (
        <div className={classes['message-holder']}>
          <p className={classes['error']}>Error...</p>
        </div>
      ) : null}

      {!isLoading && !isError ? (
        <Box component='form' onSubmit={submitHandler} sx={{ width: '100%' }}>
          <Stack direction='row' alignItems='flex-end' spacing={2} sx={{ width: '100%' }}>
            <Box>
              <Typography variant='h3' component='h3' className='label'>
                Date
              </Typography>
              <TextField
                type='date'
                id='delivery_date'
                defaultValue={currentDeliveryDate}
                size='small'
                inputRef={deliveryDateRef}
                // required
                disabled
                sx={{ width: '200px' }}
              />
            </Box>
            <Box>
              <Typography variant='h3' component='h3' className='label'>
                Time
              </Typography>
              <TextField
                type='time'
                id='delivery_time'
                defaultValue={currentDeliveryTime}
                size='small'
                inputRef={deliveryTimeRef}
                // required
                disabled
                sx={{ width: '200px' }}
              />
            </Box>
            <Box>
              <Typography variant='h3' component='h3' className='label'>
                Gratuity (for the Driver)
              </Typography>
              <TextField
                type='number'
                id='tip'
                defaultValue={currentTip}
                size='small'
                inputRef={tipRef}
                inputProps={{ step: 1 }}
                sx={{ width: '200px' }}
              />
            </Box>
          </Stack>

          <Box sx={{ pt: '20px' }}>
            <TeamContactAutocompleteFormField
              teamContacts={teamContacts}
              currentTeamContacts={currentOrderContacts}
              onChange={setContactsHandler}
            />
          </Box>

          {/* <Box>
              <DeliveryLocationAutocompleteFormField
                deliveryLocations={deliveryLocations}
                currentDeliveryLocation={currentDeliveryLocation}
                onChange={setDeliveryLocationHandler}
              />
            </Box> */}

          <Box sx={{ pt: '2rem' }}>
            <Button variant='contained' color='accent' size='custom' type='submit'>
              Continue Checkout
            </Button>
          </Box>
        </Box>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default DeliveryDetailsForm;
