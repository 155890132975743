import React from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const ReferVendorInstructions = () => {
  return (
    <Paper elevation={4} className='content-card content-spacer'>
      <Box>
        <List>
          <ListItem>
            <ListItemIcon>
              <InfoIcon color='primary' />
            </ListItemIcon>
            <ListItemText>
              All of our Fueling Partners are peer-reviewed & experienced with athlete catering
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InfoIcon color='primary' />
            </ListItemIcon>
            <ListItemText>
              Please refer any Fueling Partners you recommend to be added to the Elite Eats platform
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    </Paper>
  );
};

export default ReferVendorInstructions;
