import StandardPageWrapper from '../Layout/StandardPageWrapper';
import GlobalMenuItemCategories from '../GlobalMenuItemCategories/GlobalMenuItemCategories';

const MenuItemCategories = () => {
  return (
    <StandardPageWrapper title='Menu Item Categories'>
      <GlobalMenuItemCategories />
    </StandardPageWrapper>
  );
};

export default MenuItemCategories;
