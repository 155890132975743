import React, { useContext } from 'react';
import { Route, Redirect, Switch, useHistory } from 'react-router-dom';
import { Box } from '@mui/material';

import OrderContext from '../store/order-context';
import CheckoutMenu from '../components/Checkout/CheckoutMenu';
import CheckoutQuestions from '../components/Checkout/CheckoutQuestions';
import DeliveryLocation from '../components/Checkout/DeliveryLocation';
import DeliveryDetails from '../components/Checkout/DeliveryDetails';
import DeliveryInstructions from '../components/Checkout/DeliveryInstructions';
import FileAttachments from '../components/Checkout/FileAttachments';
import Payment from '../components/Checkout/Payment';
import ReviewOrder from '../components/Checkout/ReviewOrder';
import SpecialRequests from '../components/Checkout/SpecialRequests';
import OrderConfirmation from '../components/Checkout/OrderConfirmation';
import CheckoutComplete from '../components/Checkout/CheckoutComplete';
import CheckoutCart from '../components/Checkout/CheckoutCart';
import classes from '../components/CSS/Checkout.module.css';

const CheckoutPage = () => {
  // console.log('######## CheckoutPage ########');
  const orderCtx = useContext(OrderContext);
  const history = useHistory();

  // console.log('##### CheckoutPage #####');
  // console.log('orderCtx.orderDetails.status = ' + orderCtx.orderDetails.status);
  // console.log(orderCtx.orderDetails);

  // New Code
  // if (orderCtx.orderDetails.status === 'Deleted' || orderCtx.orderDetails.status === 'Completed') {
  //   // history.replace('/team-dashboard/orders');
  //   console.log(' -- CHECKING === -- redirect to -- /team-dashboard/orders --');
  // }

  if (orderCtx.orderDetails.status !== 'Draft' && orderCtx.orderDetails.status !== 'Placed') {
    // console.log(' -- CHECKING !== -- redirect to -- /team-dashboard/orders --');
    history.replace('/team-dashboard/orders');
  }

  // Original Code
  // if (orderCtx.orderDetails.status !== 'Draft') {
  //   history.replace('/team-dashboard/orders');
  // }

  const pathStart = '/checkout/';

  return (
    <>
      <CheckoutMenu />
      <div className='content-container'>
        <Box className={classes['checkout-container']}>
          <Box>
            <Box>
              <Switch>
                <Route path={pathStart + 'delivery-location'} exact>
                  <DeliveryLocation />
                </Route>
                <Route path={pathStart + 'delivery-details'} exact>
                  <DeliveryDetails />
                </Route>
                <Route path={pathStart + 'delivery-instructions'} exact>
                  <DeliveryInstructions />
                </Route>
                <Route path={pathStart + 'review-order'}>
                  <ReviewOrder />
                </Route>
                <Route path={pathStart + 'file-attachments'}>
                  <FileAttachments />
                </Route>
                <Route path={pathStart + 'special-requests'}>
                  <SpecialRequests />
                </Route>
                <Route path={pathStart + 'order-confirmation'}>
                  <OrderConfirmation />
                </Route>
                <Route path={pathStart + 'payment'}>
                  <Payment />
                </Route>
                <Route path={pathStart + 'checkout-complete'}>
                  <CheckoutComplete />
                </Route>
                <Route path='*'>
                  <Redirect to={pathStart + 'delivery-details'} />
                </Route>
              </Switch>
            </Box>

            <Box sx={{ pt: '40px' }}>
              <CheckoutQuestions />
            </Box>
          </Box>
          <Box className={classes['order-cart-container']}>
            <Box className={classes['cart-holder-fixed']}>
              <Box className={classes['cart']}>
                <CheckoutCart />
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default CheckoutPage;
