import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Paper, Typography } from '@mui/material';

import RestaurantRatingWidget from '../UI/RestaurantRatingWidget';
import OrderDetailsWidget from './OrderDetailsWidget';
import classes from '../CSS/RestaurantMenu.module.css';

const RestaurantMenuHeader = ({
  selectedRestaurant,
  restaurantContacts,
  showRestaurantReviewssHandler,
  orderDetails,
}) => {
  const displayRestaurantContacts = false;

  return (
    <Box sx={{ pt: '40px', pb: '20px' }}>
      <Paper elevation={4}>
        <Box className={classes['header-image']}>
          <img src={selectedRestaurant.headerImage} alt='Restaurant Header' />
        </Box>
        <Box sx={{ p: '20px' }}>
          <Box className='flex-container-side-by-side-edges'>
            <Box>
              <Typography variant='h1' component='h1'>
                {selectedRestaurant.name}
              </Typography>

              <Typography component='div'>
                {selectedRestaurant.address1}
                {selectedRestaurant.address2 ? ', ' + selectedRestaurant.address2 : ''}

                {selectedRestaurant.city ? (
                  <>
                    {selectedRestaurant.city ? ', ' + selectedRestaurant.city : ''}
                    {selectedRestaurant.state ? ', ' + selectedRestaurant.state : ''}
                    {selectedRestaurant.zipcode ? ', ' + selectedRestaurant.zipcode : ''}
                  </>
                ) : null}
              </Typography>

              {selectedRestaurant.email || selectedRestaurant.phone ? (
                <Box className='pb-20'>
                  {selectedRestaurant.phone ? (
                    <Box sx={{ pt: '5px' }}>
                      <Typography component='div'>{selectedRestaurant.phone}</Typography>
                    </Box>
                  ) : null}

                  {selectedRestaurant.email ? (
                    <Box sx={{ pt: '5px' }}>
                      <Typography component='div'>{selectedRestaurant.email}</Typography>
                    </Box>
                  ) : null}

                  {selectedRestaurant.description ? (
                    <Box sx={{ pt: '20px' }}>
                      <Typography component='pre'>{selectedRestaurant.description}</Typography>
                    </Box>
                  ) : null}
                </Box>
              ) : null}

              <Box>
                <table className='table-simple table-simple-pb-10'>
                  <tbody>
                    {selectedRestaurant.foodType ? (
                      <tr>
                        <td className='table-td-label'>Food Type</td>
                        <td className='table-td-text'>{selectedRestaurant.foodType}</td>
                      </tr>
                    ) : null}
                    {selectedRestaurant.leagueExperience ? (
                      <tr>
                        <td className='table-td-label'>League Experience</td>
                        <td className='table-td-text'>{selectedRestaurant.leagueExperience}</td>
                      </tr>
                    ) : null}
                    {selectedRestaurant.orderLeadTime ? (
                      <tr>
                        <td className='table-td-label'>Order Lead Time</td>
                        <td className='table-td-text'>
                          {selectedRestaurant.orderLeadTime + ' Hours'}
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </Box>

              {displayRestaurantContacts &&
              Array.isArray(restaurantContacts) &&
              restaurantContacts.length > 0 ? (
                <Box sx={{ py: '1rem' }}>
                  {restaurantContacts.map((record, index) => (
                    <Box key={index}>
                      <Box>
                        <Typography variant='labelInline'>
                          {/* {record.firstName + ' ' + record.lastName + ': ' + record.position} */}
                          {record.position}
                        </Typography>
                      </Box>
                      <Box>
                        {record.firstName +
                          ' ' +
                          record.lastName +
                          ': ' +
                          record.phone +
                          ' - ' +
                          record.email}
                      </Box>
                    </Box>
                  ))}
                </Box>
              ) : null}
            </Box>

            <Box sx={{ minWidth: '350px' }}>
              <OrderDetailsWidget orderDetails={orderDetails} />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              onClick={showRestaurantReviewssHandler}
              sx={{
                cursor: 'pointer',
                display: 'inline-block',
                pr: '20px',
                pt: '10px',
              }}
            >
              <RestaurantRatingWidget
                rating={selectedRestaurant.rating}
                numberOfRatings={selectedRestaurant.numberOfRatings}
              />
            </Box>
            <Box>
              <Link to='/order' className='button large'>
                Back
              </Link>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default RestaurantMenuHeader;
