import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    // flexDirection: 'row',
    backgroundColor: '#e4e4e4',
  },
  sideBySideSection: {
    flexDirection: 'row',
    gap: '100px',
  },
  section: {
    margin: 10,
    padding: 10,
    // flexGrow: 1,
  },
  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 8,
  },
  header: {
    borderTop: 'none',
  },
  bold: {
    fontWeight: 'bold',
  },
  // So Declarative and unDRY 👌
  row1: {
    width: '15%',
  },
  row2: {
    width: '60%',
  },
  row3: {
    width: '25%',
  },
});

// const orderItems = [
//   ['qty' => 2, 'item' => 'Chicken Parmesan', (price) => 19.99],
//   [(qty) => 6, (item) => 'Chicken Club', (price) => 17.99],
// ];

// const orderItems = array("qty" => 2, "item" => "Chicken Club", "price" => 15.99);

const orderItems = [
  { qty: 2, item: 'Chicken Piccata', price: '$18.99' },
  { qty: 1, item: 'Chicken Parmesan', price: '$19.99' },
  { qty: 4, item: 'Chicken Club', price: '$15.99' },
];

const PdfOrderExample = () => {
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text>Delivery Date</Text>
          <Text>Friday, June 23, 2023 at 4:30:00 PM</Text>
        </View>

        <View style={styles.section}>
          <View style={styles.table}>
            <View style={[styles.row, styles.bold, styles.header]}>
              <Text style={styles.row1}>Qty</Text>
              <Text style={styles.row2}>Item</Text>
              <Text style={styles.row3}>Price</Text>
            </View>

            {/* <View style={styles.row} wrap={false}>
              <Text style={styles.row1}>1</Text>
              <Text style={styles.row2}>Chicken Piccata</Text>
              <Text style={styles.row3}>$18.99</Text>
            </View> */}

            {orderItems.map((row, i) => (
              <View key={i} style={styles.row} wrap={false}>
                <Text style={styles.row1}>{row.qty}</Text>
                <Text style={styles.row2}>{row.item}</Text>
                <Text style={styles.row3}>{row.price}</Text>
              </View>
            ))}

            {/* {data.map((row, i) => (
              <View key={i} style={styles.row} wrap={false}>
                <Text style={styles.row1}>
                  <Text style={styles.bold}>{row.lastName}</Text>, {row.firstName}
                </Text>
                <Text style={styles.row2}>{row.startDate}</Text>
                <Text style={styles.row3}>{row.endDate}</Text>
                <Text style={styles.row4}>
                  <Text style={styles.bold}>{row.days}</Text> of {maximumDays}
                </Text>
                <Text style={styles.row5}>{row.info}</Text>
              </View>
            ))} */}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfOrderExample;
