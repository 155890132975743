import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import DeliveryFeeForm from './DeliveryFeeForm';
import DeliveryFeesList from './DeliveryFeesList';

import { deliveryFeesLabel } from '../../constants/global';

const DeliveryFees = ({ mode = 'list' }) => {
  const authCtx = useContext(AuthContext);
  const dashboardUrl = authCtx.dashboardUrl;

  const history = useHistory();
  const params = useParams();
  const restaurantUuid = params.restaurantUuid ? params.restaurantUuid : null;

  const pageTitle = deliveryFeesLabel;

  const displayRestaurantDetailsHandler = () => {
    console.log('displayRestaurantDetailsHandler');
    history.push(dashboardUrl + 'restaurants/details/' + restaurantUuid);
  };

  return (
    <StandardPageWrapper>
      <Box className='title-and-button-holder'>
        <Typography variant='h1' component='h1'>
          {pageTitle}
        </Typography>
        <Box className='button-holder-no-pb'>
          <Button
            variant='contained'
            status='custom'
            color='accent'
            // onClick={() => editDetailsHandler(restaurantUuid)}
            onClick={displayRestaurantDetailsHandler}
          >
            Restaurant Details
          </Button>
        </Box>
      </Box>

      <Box>
        {mode === 'form' ? <DeliveryFeeForm /> : null}
        {mode === 'list' ? <DeliveryFeesList restaurantUuid={restaurantUuid} /> : null}
      </Box>
    </StandardPageWrapper>
  );
};

export default DeliveryFees;
