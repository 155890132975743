import React from 'react';
import { Box, Typography } from '@mui/material';

import classes from './MenuItem.module.css';

const MenuItemDetailMiniCard = (props) => {
  const { label, data, itemType } = props;

  // console.log('MenuItemDetailMiniCard - data = ' + data);
  // console.log('MenuItemDetailMiniCard - itemType = ' + itemType);

  return (
    <Box className={classes['mini-card']}>
      {itemType === 'parent' ? (
        <Box className={classes['menuitem-details-label-holder']}>
          <Typography variant='label'>{label}</Typography>
        </Box>
      ) : null}

      <Typography variant='body1' component='div'>
        {data}
      </Typography>
    </Box>
  );
};

export default MenuItemDetailMiniCard;
