import { useState, useRef, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Paper } from '@mui/material';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
import classes from '../CSS/standard-mui-form.module.css';

import { deliveryFeeLabel } from '../../constants/global';

const DeliveryFeeForm = (props) => {
  const { restaurantUuid, deliveryFee = null } = props;

  const authCtx = useContext(AuthContext);
  // const userRole = authCtx.userRole;
  const dashboardUrl = authCtx.dashboardUrl;

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const defaultDeliveryFeeType = deliveryFee && deliveryFee.percent ? 'Percent' : 'Fee';

  const [deliveryFeeType, setDeliveryFeeType] = useState(defaultDeliveryFeeType);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const rangeStartRef = useRef();
  const rangeEndRef = useRef();
  const feeRef = useRef();
  const percentRef = useRef();

  let submitButtonText = 'Save';

  const deliveryFeeTypesArray = [
    { value: 'Fee', label: 'Fee' },
    { value: 'Percent', label: 'Percent' },
  ];

  // const restaurantUuid = deliveryFee.restaurantUuid ? deliveryFee.restaurantUuid : null;
  const deliveryFeeUuid = deliveryFee.deliveryFeeUuid ? deliveryFee.deliveryFeeUuid : null;
  // const rangeStart = deliveryFee.rangeStart ? deliveryFee.rangeStart : 0;
  // const rangeEnd = deliveryFee.rangeEnd ? deliveryFee.rangeEnd : 0;
  // const fee = deliveryFee.fee ? deliveryFee.fee : 0;

  // const [restaurantUuid, setRestaurantUuid] = useState(deliveryFee.restaurantUuid);
  // const [deliveryFeeUuid, setDeliveryFeeUuid] = useState(deliveryFee.deliveryFeeUuid);
  // const [rangeStart, setRangeStart] = useState(deliveryFee.rangeStart);
  // const [rangeEnd, setRangeEnd] = useState(deliveryFee.rangeEnd);
  // const [fee, setFee] = useState(deliveryFee.fee);

  // console.log(' *** DeliveryFeeForm *** ');
  // console.log('deliveryFee');
  // console.log(deliveryFee);
  // console.log('restaurantUuid = ' + restaurantUuid);
  // console.log('deliveryFeeUuid = ' + deliveryFeeUuid);
  // console.log('rangeStart = ' + rangeStart);
  // console.log('rangeEnd = ' + rangeEnd);
  // console.log('fee = ' + fee);

  // const deliveryFeeDetails = {
  //   deliveryFeeUuid: '',
  //   rangeStart: '0',
  //   rangeEnd: '2000',
  //   fee: '120',
  // };

  useEffect(() => {
    rangeStartRef.current.focus();
  }, [deliveryFee]);

  const submitHandler = (event) => {
    event.preventDefault();

    const enteredRangeStart = rangeStartRef.current.value;
    const enteredRangeEnd = rangeEndRef.current.value;
    const enteredFee = feeRef.current ? feeRef.current.value : null;
    const enteredPercent = percentRef.current ? percentRef.current.value : null;

    if (+enteredRangeEnd < +enteredRangeStart) {
      setNotify({
        isOpen: true,
        message: 'The From Order Total must be less than the To Order Total.',
        type: 'error',
      });

      return false;
    }

    if (!enteredRangeStart) {
      setNotify({
        isOpen: true,
        message: 'Please enter a From Order Total value.',
        type: 'error',
      });

      return false;
    }

    if (!enteredRangeEnd) {
      setNotify({
        isOpen: true,
        message: 'Please enter a To Order Total value.',
        type: 'error',
      });

      return false;
    }

    if (!enteredFee && !enteredPercent) {
      setNotify({
        isOpen: true,
        message: 'Please enter a ' + deliveryFeeLabel,
        type: 'error',
      });

      return false;
    }

    setIsLoading(true);

    setNotify({
      isOpen: true,
      message: 'Sending Request...',
      type: 'info',
    });

    let apiURL = '';
    let apiMethod = '';
    // let deliveryFeeUuid = '';

    console.log('deliveryFeeUuid = ' + deliveryFeeUuid);

    if (
      deliveryFeeUuid !== '' &&
      deliveryFeeUuid !== undefined &&
      deliveryFeeUuid !== null &&
      deliveryFeeUuid !== 'null'
    ) {
      apiURL = authCtx.baseApiUrl + 'deliveryfees/' + deliveryFeeUuid;
      apiMethod = 'PATCH';
    } else {
      apiURL = authCtx.baseApiUrl + 'deliveryfees';
      apiMethod = 'POST';
    }

    // console.log(' - apiURL = ' + apiURL);
    // console.log(' - apiMethod = ' + apiMethod);

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        restaurant_uuid: restaurantUuid,
        range_start: enteredRangeStart,
        range_end: enteredRangeEnd,
        fee: enteredFee,
        percent: enteredPercent,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        setIsError(false);

        setNotify({
          isOpen: true,
          message: deliveryFeeLabel + ' Successfully Saved.',
          type: 'success',
        });

        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          rangeStartRef.current.value = '';
          rangeEndRef.current.value = '';

          if (feeRef.current) {
            feeRef.current.value = '';
          }
          if (percentRef.current) {
            percentRef.current.value = '';
          }

          // const deliveryFeeUuid = responseData.data.delivery_fee.deliveryFeeUuid;
          // console.log('deliveryFeeUuid = ' + deliveryFeeUuid);

          history.replace(dashboardUrl + 'restaurants/delivery-fees/' + restaurantUuid);
        });
      } else {
        return res.json().then((responseData) => {
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: deliveryFeeLabel + ' Not Saved',
              type: 'error',
            });
          }
        });
      }
    });

    setIsLoading(false);
  };

  const onCancel = () => {
    console.log('onCancel');
    // history.push(dashboardUrl + 'restaurants/details/' + restaurantUuid);
    history.push(dashboardUrl + 'restaurants/delivery-fees/' + restaurantUuid);
  };

  const deliveryFeeTypeChangeHandler = (event) => {
    console.log('deliveryFeeTypeChangeHandler = event.target.value = ' + event.target.value);
    setDeliveryFeeType(event.target.value);

    // if (event.target.value === 'Percent') {
    //   percentRef.current.focus();
    // } else {
    //   feeRef.current.focus();
    // }
  };

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {!isLoading && !isError ? (
        <Paper elevation={4} className={classes['form-container']}>
          <form onSubmit={submitHandler}>
            <Box
              sx={{
                display: 'flex',
                gap: '2rem',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              <Box sx={{ minWidth: '220px', flexGrow: '1' }}>
                <FormControls.InputControl
                  type='text'
                  name='range_start'
                  label='From Order Total $'
                  required
                  inputRef={rangeStartRef}
                  defaultValue={deliveryFee.rangeStart}
                  inputProps={{ type: 'number', min: '0', max: '100000', step: '0.01' }}
                  className={classes['mui-control']}
                />
              </Box>

              <Box sx={{ minWidth: '220px', flexGrow: '1' }}>
                <FormControls.InputControl
                  type='text'
                  name='range_end'
                  label='To Order Total $'
                  required
                  inputRef={rangeEndRef}
                  defaultValue={deliveryFee.rangeEnd}
                  inputProps={{
                    type: 'number',
                    maxLength: '6',
                    min: '0',
                    max: '100000',
                    step: '0.01',
                  }}
                  className={classes['mui-control']}
                />
              </Box>

              <Box sx={{ minWidth: '180px' }}>
                <FormControls.RadioGroupControl
                  name='delivery_fee_or_percent'
                  value={deliveryFeeType}
                  onChange={deliveryFeeTypeChangeHandler}
                  items={deliveryFeeTypesArray}
                />
              </Box>

              {deliveryFeeType === 'Fee' ? (
                <Box sx={{ minWidth: '220px' }}>
                  <FormControls.InputControl
                    type='text'
                    name='fee'
                    label={deliveryFeeLabel + ' in $'}
                    required
                    inputRef={feeRef}
                    defaultValue={deliveryFee.fee}
                    inputProps={{ type: 'number', min: '0', max: '9999.99', step: '0.01' }}
                    className={classes['mui-control']}
                  />
                </Box>
              ) : null}

              {deliveryFeeType === 'Percent' ? (
                <Box sx={{ minWidth: '220px' }}>
                  <FormControls.InputControl
                    type='text'
                    name='percent'
                    label={deliveryFeeLabel + ' as a %'}
                    required
                    inputRef={percentRef}
                    defaultValue={deliveryFee.percent}
                    inputProps={{ type: 'number', min: '0', max: '100', step: '0.1' }}
                    className={classes['mui-control']}
                  />
                </Box>
              ) : null}
            </Box>

            <Box component='div' className={classes['actions-container']} sx={{ pt: '20px' }}>
              <FormControls.ButtonControl text={submitButtonText} type='submit' color='primary' />
              <FormControls.ButtonControl
                text='Cancel'
                onClick={onCancel}
                type='button'
                color='neutral200'
              />
            </Box>
          </form>
        </Paper>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default DeliveryFeeForm;
