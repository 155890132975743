import React, { useContext, useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';

import AuthContext from '../../store/auth-context';
import GlobalMenuItemCategoryDetailsTable from './GlobalMenuItemCategoryDetailsTable';
import classes from '../CSS/styled-table.module.css';

const GlobalMenuItemCategoriesDetails = (props) => {
  const authCtx = useContext(AuthContext);

  // const dashboardUrl = authCtx.dashboardUrl;

  const [recordDetails, setRecordDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  // const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const response = await fetch(authCtx.baseApiUrl + 'globalmenuitemcategories/' + props.uuid, {
        headers: {
          Authorization: authCtx.token,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(responseData);

        const returnedRecords = {
          uuid: responseData.data.global_menu_item_categories[0].globalMenuItemCategoryUuid,
          name: responseData.data.global_menu_item_categories[0].name,
          description: responseData.data.global_menu_item_categories[0].description,
          phone: responseData.data.global_menu_item_categories[0].phone,
          status: responseData.data.global_menu_item_categories[0].status,
        };
        // console.log(' -- returnedRecords -- ');
        // console.log(returnedRecords);

        setRecordDetails(returnedRecords);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, props.uuid]);

  // const editHandler = () => {
  //   history.push(dashboardUrl + 'menu-item-categories/edit/' + props.uuid);
  // };

  // const backToListHandler = () => {
  //   history.push(dashboardUrl + 'menu-item-categories');
  // };

  return (
    <div className='container normal-content-area'>
      <h1>Delivery Location Details</h1>
      {!isLoading && !isError && <GlobalMenuItemCategoryDetailsTable details={recordDetails} />}
      {!isLoading && !isError && (
        <div className='button-holder'>
          {/* <button onClick={editHandler} className='small'>
            Edit
          </button> */}
          <button onClick={() => props.onEditRecord(`${recordDetails.uuid}`)} className='small'>
            Edit
          </button>
          {/* <button onClick={backToListHandler} className='small'>
            Back To List
          </button> */}
        </div>
      )}
      {isLoading && (
        <div className={classes['message-holder']}>
          <p className={classes['loading']}>Loading...</p>
        </div>
      )}
      {isError && (
        <div className={classes['message-holder']}>
          <p className={classes['error']}>Error...</p>
        </div>
      )}
    </div>
  );
};

export default GlobalMenuItemCategoriesDetails;
