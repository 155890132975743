import React from 'react';
import { useParams } from 'react-router-dom';

import StandardPageWrapper from '../Layout/StandardPageWrapper';
import DeliveryLocationList from '../DeliveryLocations/DeliveryLocationList';
import DeliveryLocationDetails from '../DeliveryLocations/DeliveryLocationDetails';
import DeliveryLocationAdd from '../DeliveryLocations/DeliveryLocationAdd';
import DeliveryLocationEdit from '../DeliveryLocations/DeliveryLocationEdit';

const DeliveryLocations = (props) => {
  const { urlStart, mode } = props;
  const params = useParams();
  const deliveryLocationUuid = params.deliveryLocationUuid ? params.deliveryLocationUuid : '';

  return (
    <StandardPageWrapper>
      {!deliveryLocationUuid ? <DeliveryLocationList urlStart={urlStart} /> : null}

      {deliveryLocationUuid && mode === 'details' ? (
        <DeliveryLocationDetails urlStart={urlStart} deliveryLocationUuid={deliveryLocationUuid} />
      ) : null}

      {deliveryLocationUuid && mode === 'add' ? (
        <DeliveryLocationAdd urlStart={urlStart} deliveryLocationUuid={deliveryLocationUuid} />
      ) : null}

      {deliveryLocationUuid && mode === 'edit' ? (
        <DeliveryLocationEdit urlStart={urlStart} deliveryLocationUuid={deliveryLocationUuid} />
      ) : null}
    </StandardPageWrapper>
  );
};

export default DeliveryLocations;
