import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
// import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
// import StickyBox from 'react-sticky-box';

import AuthContext from '../../store/auth-context';
import OrderContext from '../../store/order-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import WorkflowMessage from '../UI/WorkflowMessage';
import Notification from '../MUI/UI/Notification';
import Popup from '../MUI/UI/Popup';
import OrderMenuItemCard from './OrderMenuItemCard';
import MenuItemDetails from './MenuItemDetails';
// import OrderCart from './OrderCart';
import RestaurantMenuHeader from './RestaurantMenuHeader';
import MenuCategoriesHeader from './MenuCategoriesHeader';
// import RestaurantRatingWidget from '../UI/RestaurantRatingWidget';
import RestaurantReviewsList from '../RestaurantReviews/RestaurantReviewsList';
import OrderCart from '../Order/OrderCart';
import classes from '../CSS/RestaurantMenu.module.css';

import MenuCategoryHeader from './MenuCategoryHeader';

const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

let popupTitle = 'Menu Item Details';

export const MenuItemCardHolderWrapper = (props) => {
  return (
    <Box component='div' className={classes['menu-card-holder']}>
      {props.children}
    </Box>
  );
};

const RestaurantMenu = () => {
  const authCtx = useContext(AuthContext);
  const orderCtx = useContext(OrderContext);
  const history = useHistory();

  let baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath;
  const selectedRestaurant = orderCtx.restaurant;
  const restaurantContacts = orderCtx.restaurantContacts;
  const restaurantUuid = orderCtx.orderDetails.restaurantUuid;
  const orderStatus = orderCtx.orderDetails.status;
  const orderDetails = orderCtx.orderDetails;

  // console.log('##### RestaurantMenu #####');
  // console.log('selectedRestaurant');
  // console.log(selectedRestaurant);
  // console.log('orderDetails');
  // console.log(orderDetails);
  // console.log('restaurantContacts');
  // console.log(restaurantContacts);
  // console.log('orderCtx.orderDetails.status = ' + orderCtx.orderDetails.status);
  // console.log('orderStatus = ' + orderStatus);

  const [menuItems, setMenuItems] = useState([]);
  // const [displayedMenuItems, setDisplayedMenuItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupReviews, setOpenPopupReviews] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState({});
  const [menuItemCategories, setMenuItemCategories] = useState([]);
  const [globaMenuItemCategories, setGlobalMenuItemCategories] = useState([]);

  useEffect(() => {
    if (!restaurantUuid || restaurantUuid === undefined) {
      history.replace('/team-dashboard/orders');
      return;
    }

    // if (orderStatus !== 'Draft') {
    //   history.replace('/team-dashboard/orders');
    // }

    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);

      const response = await fetch(authCtx.baseApiUrl + 'menuitems/restaurant/' + restaurantUuid, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log('responseData.data');
        // console.log(responseData.data);
        // console.log(responseData.data.menu_items);

        setMenuItemCategories(responseData.data.menu_item_categories);
        setGlobalMenuItemCategories(responseData.data.global_menu_item_categories);
        // console.log('globaMenuItemCategories');
        // console.table(globaMenuItemCategories);

        const transformedMenuItems = responseData.data.menu_items.map((menuItem) => {
          return {
            menuItemUuid: menuItem.menuItemUuid,
            parentUuid: menuItem.parentUuid,
            name: menuItem.name,
            description: menuItem.description,
            details: menuItem.details,
            servingSize: menuItem.servingSize,
            feeds: menuItem.feeds,
            price: menuItem.price,
            priceFormatted: menuItem.price ? priceFormatter.format(menuItem.price) : '$0.00',
            priceDetails: menuItem.priceDetails,
            category: menuItem.menuItemCategory,
            dietaryOptions: menuItem.dietaryOptions,
            status: menuItem.status,
            // image: menuItem.image,
            image:
              menuItem.image && menuItem.image !== '' && menuItem.image !== null
                ? baseApiUploadedImagesPath + restaurantUuid + '/' + menuItem.image
                : '',
          };
        });

        // console.log('transformedMenuItems');
        // console.log(transformedMenuItems);
        // console.table(transformedMenuItems);

        setMenuItems(transformedMenuItems);
        // setDisplayedMenuItems(transformedMenuItems);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [
    authCtx.baseApiUrl,
    authCtx.token,
    restaurantUuid,
    history,
    orderStatus,
    baseApiUploadedImagesPath,
  ]);

  // const addToCartHandler = (quantity) => {
  //   console.log(' - addToCartHandler - quantity = ' + quantity);
  //   cartCtx.addOrderItem({});
  // };

  const getMenuItemsByCategory = (category) => {
    // console.log('getMenuItemsByCategory');
    // console.log('category = ' + category);
    // console.log(menuItems);
    return menuItems.filter((item) => item.category === category);
  };
  // filteredRecords = filteredRecords.filter((record) => record.city === cityFilter);

  const showMenuItemDetailsHandler = (menuItem) => {
    // console.log('showMenuItemDetailsHandler');
    // console.log(menuItem);
    popupTitle = menuItem.name;
    setSelectedMenuItem(menuItem);
    setOpenPopup(true);
  };

  const showRestaurantReviewssHandler = () => {
    // console.log('showRestaurantReviewssHandler');
    setOpenPopupReviews(true);
  };

  const displayMenuItemCardsForCategory = (category) => {
    // console.log(' ** displayMenuItemCardsForCategory - category = ' + category + ' ** ');

    // const menuItemCards = getMenuItemsByCategory(`${category}`).map((menuItem, index) => (
    const menuItemCards = getMenuItemsByCategory(`${category}`)
      .filter((menuItem) => menuItem.parentUuid === null || menuItem.parentUuid === '')
      .map((menuItem, index) => (
        <OrderMenuItemCard
          key={index}
          menuItem={menuItem}
          onShowDetails={showMenuItemDetailsHandler}
          uploadsFolder={baseApiUploadedImagesPath + selectedRestaurant.uploadsFolder}
        />
      ));
    return menuItemCards;
  };

  // console.log('***** RestaurantMenu *****');
  return (
    <Box sx={{ mb: '6rem' }}>
      <StandardPageWrapper>
        {isLoading ? <WorkflowMessage type='loading' /> : null}
        {isError ? <WorkflowMessage type='error' /> : null}

        {!isLoading && menuItems.length > 0 && (
          <Box>
            <RestaurantMenuHeader
              selectedRestaurant={selectedRestaurant}
              restaurantContacts={restaurantContacts}
              showRestaurantReviewssHandler={showRestaurantReviewssHandler}
              orderDetails={orderDetails}
            />

            <MenuCategoriesHeader menuItemCategories={globaMenuItemCategories} />

            <Box className={classes['restaurant-menu-container']}>
              <Box className={classes['restaurant-menu-section']}>
                <Box>
                  {menuItemCategories.map((category, index) => (
                    <div key={index}>
                      <MenuCategoryHeader category={category} />
                      <MenuItemCardHolderWrapper>
                        {displayMenuItemCardsForCategory(`${category}`)}
                      </MenuItemCardHolderWrapper>
                    </div>
                  ))}
                </Box>
              </Box>

              <Box className={classes['order-cart-container']}>
                <Box className={classes['cart-holder-fixed']}>
                  <Box className={classes['cart']}>
                    <OrderCart />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Notification notify={notify} setNotify={setNotify} />
            <Popup
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
              title={popupTitle}
              maxWidth='sm'
            >
              <MenuItemDetails
                selectedMenuItem={selectedMenuItem}
                menuItems={menuItems}
                setNotify={setNotify}
                setOpenPopup={setOpenPopup}
                uploadsFolder={baseApiUploadedImagesPath + selectedRestaurant.uploadsFolder}
              />
            </Popup>

            <Popup
              openPopup={openPopupReviews}
              setOpenPopup={setOpenPopupReviews}
              title={'Reviews for ' + selectedRestaurant.name}
              maxWidth='md'
            >
              <RestaurantReviewsList propRestaurantUuid={restaurantUuid} mode='popup' />
            </Popup>
          </Box>
        )}
        {isError ? <p>Error....</p> : null}
      </StandardPageWrapper>
    </Box>
  );
};

export default RestaurantMenu;
