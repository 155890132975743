import { useState } from 'react';

import { buildVersion } from '../../constants/global';

// import SystemValues from './SystemValues';
// import GlobalLeagues from './GlobalLeagues';
import TestingMenu from './TestingMenu';
import FileUploadComplex from './FileUploadComplex';
import FileUpload from './FileUpload';
import OrderEmailTesting from './OrderEmailTesting';
import ComponentDataTest from './ComponentDataTest';
import ReactPdfTestPage from './ReactPdfTestPage';
import StripePaymentForm from './StripePaymentForm';
import ImageUpload from './ImageUpload';
import AdditionalCharge from './AdditionalCharge';
import GlobalEndpoints from './GlobalEndpoints';
import GetDataTest1 from './GetDataTest1';
import classes from './Testing.module.css';

const Testing = () => {
  const [contentToDisplay, setContentToDisplay] = useState('orderEmailTesting');

  const displayContent = (content) => {
    setContentToDisplay(content);
  };

  // const testPerson = {
  //   firstName: 'Jeff',
  //   lastName: 'Cummings',
  // };

  // const [person, setPerson] = useState(testPerson);

  // console.log('**** Testing ****');
  // console.log('person');
  // console.log(person);

  // const updatePersonHandler = (person) => {
  //   console.log('updatePersonHandler');
  //   console.log('person');
  //   console.log(person);
  //   setPerson(person);
  // };

  return (
    <div className='content-container'>
      <h1>Testing</h1>
      <div className={classes['testing-main-container']}>
        <div className={classes['testing-side-menu-container']}>
          <TestingMenu displayContent={displayContent} />
        </div>
        <div className={classes['testing-content-container']}>
          {contentToDisplay === 'fileUploadComplex' ? <FileUploadComplex /> : null}
          {contentToDisplay === 'fileUploadTesting' ? <FileUpload /> : null}
          {contentToDisplay === 'orderEmailTesting' ? <OrderEmailTesting /> : null}
          {contentToDisplay === 'componentDataTest' ? (
            // <ComponentDataTest person={person} onUpdatePerson={updatePersonHandler} />
            <ComponentDataTest />
          ) : null}
          {contentToDisplay === 'reactPdfTestPage' ? <ReactPdfTestPage /> : null}
          {contentToDisplay === 'stripePaymentForm' ? <StripePaymentForm /> : null}
          {contentToDisplay === 'imageUpload' ? <ImageUpload /> : null}
          {contentToDisplay === 'additionalCharge' ? <AdditionalCharge /> : null}
          {contentToDisplay === 'getDataTest1' ? <GetDataTest1 /> : null}
          {contentToDisplay === 'globalEndpoints' ? <GlobalEndpoints /> : null}

          <div className='p-20'>
            <h4>New Version Testing</h4>
            <div>buildVersion: {buildVersion}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testing;
