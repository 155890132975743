import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import ConfirmationDialog from '../MUI/UI/ConfirmationDialog';
import Popup from '../MUI/UI/Popup';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import WorkflowMessage from '../UI/WorkflowMessage';
// import ChildMenuItemDetailsCard from './ChildMenuItemDetailsCard';
import MenuItemDetailsCard from './MenuItemDetailsCard';
import ChildMenuItemForm from './ChildMenuItemForm';
import classes from '../CSS/styled-table.module.css';

let popupTitle = 'Edit Menu Item Serving Size Option';

const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const MenuItemDetails = () => {
  const authCtx = useContext(AuthContext);

  const dashboardUrl = authCtx.dashboardUrl;
  const restaurantUuid = authCtx.selectedRestaurantUuid;
  const restaurantName = authCtx.selectedRestaurantName;
  const userRole = authCtx.userRole;

  const [menuItemDetails, setMenuItemDetails] = useState([]);
  const [menuItemChildren, setMenuItemChildren] = useState([]);
  const [selectedChildMenuItem, setSelectedChildMenuItem] = useState({});
  const [dataUpdated, setDataUpdated] = useState(false);
  // const [displayChildMenuItemForm, setDisplayChildMenuItemForm] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const history = useHistory();
  const params = useParams();
  const menuItemUuid = params.menuItemUuid;

  const displayTable = false;

  const backToListHandler = useCallback(() => {
    // history.push(dashboardUrl + 'menu-items/' + restaurantUuid);
    history.push(dashboardUrl + 'restaurants/menu-items/' + restaurantUuid);
  }, [history, dashboardUrl, restaurantUuid]);

  useEffect(() => {
    const fetchData = async () => {
      // console.log('-- MenuItemDetails - fetchData - dataUpdated = ' + dataUpdated);

      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'menuitems/' + menuItemUuid, {
        headers: {
          Authorization: authCtx.token,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(responseData);

        const returnedMenuItemDetails = {
          menuItemUuid: responseData.data.menu_items[0].menuItemUuid,
          parentUuid: responseData.data.menu_items[0].parentUuid,
          restaurantUuid: responseData.data.menu_items[0].restaurantUuid,
          name: responseData.data.menu_items[0].name,
          description: responseData.data.menu_items[0].description,
          details: responseData.data.menu_items[0].details,
          servingSize: responseData.data.menu_items[0].servingSize,
          feeds: responseData.data.menu_items[0].feeds,
          price: responseData.data.menu_items[0].price,
          priceFormatted: responseData.data.menu_items[0].price
            ? priceFormatter.format(responseData.data.menu_items[0].price)
            : 0.0,
          priceDetails: responseData.data.menu_items[0].priceDetails,
          menuItemCategoryId: responseData.data.menu_items[0].menuItemCategoryId,
          menuItemCategory: responseData.data.menu_items[0].menuItemCategory,
          dietaryOptions: responseData.data.menu_items[0].dietaryOptions,
          status: responseData.data.menu_items[0].status,
          image: responseData.data.menu_items[0].image,
        };
        // console.log(' -- returnedMenuItemDetails -- ');
        // console.log(returnedMenuItemDetails);

        setMenuItemDetails(returnedMenuItemDetails);

        // Get the Child Menu Items if there are any.
        const childMenuItems = responseData.data.menu_items
          .filter((menuItem) => menuItem.parentUuid !== null && menuItem.parentUuid !== '')
          .map((menuItem) => {
            return {
              menuItemUuid: menuItem.menuItemUuid,
              parentUuid: menuItem.parentUuid,
              restaurantUuid: menuItem.restaurantUuid,
              name: menuItem.name,
              description: menuItem.description,
              details: menuItem.details,
              servingSize: menuItem.servingSize,
              feeds: menuItem.feeds,
              price: menuItem.price,
              priceFormatted: menuItem.price ? priceFormatter.format(menuItem.price) : 0.0,
              priceDetails: menuItem.priceDetails,
              menuItemCategoryId: menuItem.menuItemCategoryId,
              menuItemCategory: menuItem.menuItemCategory,
              dietaryOptions: menuItem.dietaryOptions,
              status: menuItem.status,
              image: menuItem.image,
            };
          });

        setMenuItemChildren(childMenuItems);

        // console.log(' -- childMenuItems -- ');
        // console.log(childMenuItems);
      } else {
        setIsError(true);
        return response.json().then((responseData) => {
          setIsError(true);
          // console.log('responseData');
          // console.log(responseData);

          // If there is no data returned go back to the list.
          if (responseData.statusCode === 404) {
            backToListHandler();
          }

          // if (
          //   responseData &&
          //   Array.isArray(responseData.messages) &&
          //   responseData.messages.length
          // ) {
          //   const messageText = responseData.messages.map((message) => `${message}`).join(', ');
          //   setNotify({ isOpen: true, message: messageText, type: 'error' });
          // } else {
          //   setNotify({
          //     isOpen: true,
          //     message: 'Menu Item Not Saved',
          //     type: 'error',
          //   });
          // }
          setIsLoading(false);
        });
      }
      setIsLoading(false);
      setDataUpdated(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, menuItemUuid, dataUpdated, backToListHandler]);

  // const displayMenuItemChildren = () => {
  //   return menuItemChildren.map((menuItem, index) => (
  //     <ChildMenuItemDetailsCard menuItemDetails={menuItem} />
  //   ));
  // };

  const editHandler = () => {
    history.push(dashboardUrl + 'menu-item/edit/' + menuItemUuid);
  };

  const addChildMenuItemHandler = () => {
    // setDisplayChildMenuItemForm(true);
    setSelectedChildMenuItem({});
    setOpenPopup(true);
  };

  const dataUpdatedHandler = () => {
    // console.log('dataUpdatedHandler');
    // setMenuItemDetails([]);
    setDataUpdated(true);
    setOpenPopup(false);
  };

  // useCallback(
  //   function backToListHandler() {
  //     history.push(dashboardUrl + 'menu-items/' + restaurantUuid);
  //   },
  //   [history, dashboardUrl, restaurantUuid]
  // );

  const editChildHandler = (childMenuItem) => {
    // history.push(dashboardUrl + 'menu-item/edit/' + menuItemUuid);
    // console.log('editChildHandler');
    // console.log('childMenuItem');
    // console.table(childMenuItem);
    // setDisplayChildMenuItemForm(true);
    setSelectedChildMenuItem(childMenuItem);
    setOpenPopup(true);
  };

  const deleteHandler = (menuItemUuid) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    console.log(' - handleDelete - menuItemUuid = ' + menuItemUuid);
    // onDeleteChild(menuItemUuid);
    deleteMenuItemHandler(menuItemUuid);
    // setNotify({ isOpen: true, message: 'Deleted Successfully', type: 'error' });
  };

  const deleteMenuItemHandler = (menuItemUuid) => {
    const deleteData = async () => {
      const response = await fetch(authCtx.baseApiUrl + 'menuitems/' + menuItemUuid, {
        method: 'DELETE',
        headers: {
          Authorization: authCtx.token,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);
        setDataUpdated(true);
      } else {
        console.log(' -- setIsError(true) -- ');
        setIsError(true);
      }
      setIsLoading(false);
    };

    deleteData().catch(console.error);
  };

  // const showMenuItemDetailsHandler = (menuItem) => {
  //   setSelectedMenuItem(menuItem);
  //   setOpenPopup(true);
  // };

  const pageTitle = 'Menu Item Details (' + restaurantName + ')';

  return (
    <StandardPageWrapper>
      {!isLoading && !isError ? (
        <Box className='title-and-button-holder'>
          <Typography variant='h1' component='h1'>
            {pageTitle}
          </Typography>
          <Box className='button-holder-no-pb'>
            {userRole === 'Admin' ? (
              <Button variant='contained' size='small' onClick={addChildMenuItemHandler}>
                Add Pricing Option
              </Button>
            ) : null}

            {userRole === 'Admin' ? (
              <Button variant='contained' size='small' onClick={editHandler}>
                Edit
              </Button>
            ) : null}

            {userRole === 'Admin' ? (
              <Button
                variant='contained'
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: 'Are you sure you want to delete this menu item.',
                    subTitle: 'Any Additional Pricing Options will also be deleted.',
                    onConfirm: () => {
                      // deleteHandler(user.userUuid);
                      deleteHandler(menuItemDetails.menuItemUuid);
                    },
                  });
                }}
                color='error'
                size='small'
                // className='mui-button-small'
                // sx={{ height: '2.6rem' }}
              >
                Delete
              </Button>
            ) : null}

            <Button variant='contained' size='small' color='accent' onClick={backToListHandler}>
              Back to Menu
            </Button>
          </Box>
        </Box>
      ) : null}

      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {/* {isError ? <WorkflowMessage type='error' /> : null} */}

      {/* {!isLoading && !isError ? <div>NEW</div> : null} */}

      {!isLoading && !isError ? (
        <MenuItemDetailsCard
          menuItemDetails={menuItemDetails}
          menuItemChildren={menuItemChildren}
          onEditChild={editChildHandler}
          // onDeleteChild={deleteMenuItemHandler}
          onDataUpdated={dataUpdatedHandler}
          userRole={userRole}
          itemType='parent'
          authCtx={authCtx}
        />
      ) : null}

      {/* {displayChildMenuItemForm ? <ChildMenuItemForm menuItemDetails={menuItemDetails} /> : null} */}

      {displayTable && !isLoading && !isError ? (
        <div className='table-holder'>
          <table className={classes['styled-table']}>
            <tbody>
              <tr>
                <th>Name</th>
                <td>{menuItemDetails.name}</td>
              </tr>
              <tr>
                <th>Description</th>
                <td>{menuItemDetails.description}</td>
              </tr>
              <tr>
                <th>Details</th>
                <td>{menuItemDetails.details}</td>
              </tr>
              <tr>
                <th>Serving Size ###</th>
                <td>{menuItemDetails.servingSize}</td>
              </tr>
              <tr>
                <th>Feeds</th>
                <td>{menuItemDetails.feeds}</td>
              </tr>
              <tr>
                <th>Price</th>
                {/* <td>{priceFormatter.format(menuItemDetails.price)}</td> */}
                <td>{menuItemDetails.price}</td>
              </tr>
              <tr>
                <th>Price Details</th>
                <td>{menuItemDetails.priceDetails}</td>
              </tr>
              <tr>
                <th>Type</th>
                <td>{menuItemDetails.menuItemCategory}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{menuItemDetails.status}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}

      {/* {!isLoading && !isError ? <>{displayMenuItemChildren()}</> : null} */}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />

      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} title={popupTitle} maxWidth='sm'>
        <ChildMenuItemForm
          menuItemDetails={menuItemDetails}
          childMenuItemDetails={selectedChildMenuItem}
          onDataUpdated={dataUpdatedHandler}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
    </StandardPageWrapper>
  );
};

export default MenuItemDetails;
