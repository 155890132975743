import { Box, Typography } from '@mui/material';

import FormControls from '../MUI/FormControls/FormControls';

const TeamContactAutocompleteFormField = (props) => {
  const { teamContacts, currentTeamContacts, onChange } = props;

  // console.log('TeamContactAutocompleteFormField');
  // console.log('teamContacts');
  // console.log(teamContacts);
  // console.log('currentTeamContacts');
  // console.log(currentTeamContacts);

  return teamContacts ? (
    <Box component='div' sx={{ width: '100%' }}>
      <Typography variant='h3' component='h3' className='required-label'>
        Order Contacts
      </Typography>

      <FormControls.AutocompleteControl
        id='contact'
        options={teamContacts}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        fullWidth
        defaultValue={currentTeamContacts}
        multiple
        onChange={onChange}
        // InputLabelProps={{ shrink: true }}
      />
    </Box>
  ) : null;
};

export default TeamContactAutocompleteFormField;
