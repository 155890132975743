import React, { useContext } from 'react';
import { Box, Stack } from '@mui/material';

// import AuthContext from '../../store/auth-context';
import OrderContext from '../../store/order-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import PaymentForm from './PaymentForm';
import OrderDetails from './OrderDetails';
import DeliveryLocationDetails from './DeliveryLocationDetails';
import RestaurantDetails from './RestaurantDetails';
import classes from '../CSS/Checkout.module.css';

const Payment = () => {
  // const authCtx = useContext(AuthContext);
  const orderCtx = useContext(OrderContext);

  // const orderUuid = orderCtx.orderDetails;
  // const deliveryLocation = orderCtx.deliveryLocation;
  // const restaurant = orderCtx.restaurant;

  return (
    <div className='page-container-after-top-menu'>
      <StandardPageWrapper title='Payment'>
        <div className={classes['content-holder']}>
          <p>Not really sure what will end going here.</p>
          <p>
            We can at least display the Food and Beverage Subtotal, any Delivery & Service Fee or
            other Chargers, Tax and Total
          </p>
          <Stack direction='row' justifyContent={'space-evenly'} sx={{ mt: '2rem' }}>
            <Box component='div'>
              <PaymentForm />
            </Box>
            <Box sx={{ display: 'block' }} className={classes['details-section']}>
              <OrderDetails orderDetails={orderCtx.orderDetails} displayDeliveryAddress={false} />
              <DeliveryLocationDetails deliveryLocation={orderCtx.deliveryLocation} />
              <RestaurantDetails restaurant={orderCtx.restaurant} />
            </Box>
          </Stack>
        </div>
      </StandardPageWrapper>
    </div>
  );
};

export default Payment;
