import { useParams } from 'react-router-dom';

import StandardPageWrapper from '../Layout/StandardPageWrapper';
import RestaurantReviewsList from '../RestaurantReviews/RestaurantReviewsList';

const Reviews = () => {
  const params = useParams();
  const restaurantUuid = params.restaurantUuid;

  return (
    <StandardPageWrapper title='Reviews' usePaper='true'>
      <RestaurantReviewsList propRestaurantUuid={restaurantUuid} />
    </StandardPageWrapper>
  );
};

export default Reviews;
